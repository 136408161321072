
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import DatePicker from "react-datepicker";
import { DateTime } from 'luxon';
import "react-datepicker/dist/react-datepicker.css";
import "./delete-modal.scss";
import { capitalizeString, disableNumWheelBehaviour } from '../../../../utils/common';

type PopUpProps = {
    show:boolean,
    setShow:Function,
    submitHandler:any,
    title:string,
    content:string
}
function DeleteModal({show, setShow, title, submitHandler, content}:PopUpProps) {

  const handleClose = () => setShow(false);
  return (
    <>
      <Modal className="updateInfoModalWrapper3"  show={show} onHide={handleClose} centered >
      <Modal.Body>
        <a
          href={void 0}
          className="closeButton"
          onClick={handleClose}
        >
          <em className="fa-solid fa-close"></em>
        </a>
        <div className="formWrap p-2">
          {(
            <div className="formBlock">
              
                <div className="row gutters-10 ">
                  <div className="col-12 col-sm mb-4">
                      <h4 className="mb-2">{title}</h4>
                  </div>
                 
                </div>
                <div className="row gutters-10">
                  <div className="col-12 col-sm mb-4">
                      <p className="mb-2">{content}</p>
                  </div>
                 
                </div>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                      <button
                        type="button"
                        onClick={submitHandler}
                        className="mt-3 button button-danger button-rounded button-min-50 button-sm fontsize-16 fw700 mb-2"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={()=>setShow(false)}
                        className="mt-3 button button-primary button-rounded button-min-50 button-sm fontsize-16 fw700 mb-2"
                      >
                        No
                      </button>
                </div>
            </div>
          )}
        </div>
      </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteModal;