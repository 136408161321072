import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import DefaultProducts from './defaultproducts';


export default () => {
    return (
            <PaginationProvider>
                <DefaultProducts />
            </PaginationProvider>
    )
}