
import { useEffect, useMemo, useRef, useState, RefAttributes } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import axios from 'axios';
import IntegolfLoader from '../../../components/integolf-loader/integolf-loader';
import CKEditorComponent from "../../../shared/filters/ckEditor";
import { JSX } from "react/jsx-runtime";
import { Tooltip, OverlayTrigger, TooltipProps } from "react-bootstrap";

type PopUpProps = {
  show: boolean,
  setShow: Function,
  defaultValues?: { title?: string, editor?: string, _id?: string },
  addNewsMutation?: any;
  updateNewsMutation?: any
}
function NewsPostFormPopUp({ show, setShow, defaultValues, addNewsMutation, updateNewsMutation }: PopUpProps) {
  const handleClose = () => setShow(false);
  const { control, register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm<any>({});

  useEffect(() => {
    if (show) {
      if (defaultValues && Object.keys(defaultValues).length !== 0) {
        reset({
          title: defaultValues?.title?.trim() || '',
          editor: defaultValues?.editor || ''
        });
      }
    } else {
      reset({
        title: '',
        editor: '',
      });
    }
  }, [defaultValues, show, reset]);

  const onSubmit = async (data: any) => {
    const mutation = defaultValues?._id ? updateNewsMutation : addNewsMutation;

    let uploadObj: any = {
      title: data?.title?.trim(),
      description: data?.editor,
      type: "news"
    };
    if (defaultValues?._id) uploadObj["id"] = defaultValues._id;
    await mutation.mutate(uploadObj);
  };

  return (
    <>
      {(addNewsMutation.isLoading || updateNewsMutation.isLoading) && (
        <div className="modal-loader-overlay">
          {<IntegolfLoader show />}
        </div>
      )}
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          reset();
        }}
        className="modal-lg"
        autoFocus={false} enforceFocus={false} 
      >
        <div className="myModalDivClass">
          <Modal.Header closeButton className="myModalHeadClass">
            <Modal.Title>
              {defaultValues && Object.keys(defaultValues).length !== 0
                ? "Edit"
                : "Add"}{" "}
              Post
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="myModalBodyClass">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="postHead"
                    placeholder="postHead"
                    {...register("title", { required: true, validate: value => value.trim() !== '' })}
                  />
                  <label htmlFor="postHead">News Title</label>
                  {errors.title && <span className="isInvalidMessage text-danger d-block">Please enter news title</span>}
                </div>
                <br />
                <Controller
                  name="editor"
                  control={control}
                  rules={{ required: "Editor content is required" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="custom-ckeditor-container">
                      <CKEditorComponent
                        value={value}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onChange(data);
                        }}
                        onBlur={onBlur}
                        placeholderText="Type your content here..."
                      />
                    </div>
                  )}
                />
                {errors.editor && (
                  <span className="isInvalidMessage text-danger d-block">
                    Please add content
                  </span>
                )}
                <br />
              </div>
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default NewsPostFormPopUp;
