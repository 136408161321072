import React, { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./todos.scss";
import OnboardingList from "./components/onboarding-list/onboarding-list";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PaginationProvider from "../../contexts/PaginationContext";
import CourseIssuesList from "./components/course-issues/courseissue-list";
import CourseBatchList from "./components/course-sync-list/course-batch-list";
import { Col, Nav, Row } from "react-bootstrap";
import AccommodationList from "./components/accommodations/accommodations-issues-list";

function TodoTabs({ data }: any) {
  return (
    <div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="concerned-clubs">
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="todoTabs">
              <Nav.Item>
                <Nav.Link eventKey="concerned-clubs">Concerned Courses 
                {/* <span className="_count">00</span> */}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="batch-sync">Courses Batch Sync 
                {/* <span className="_count">00</span> */}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="onboarding">Courses Onboarding 
                {/* <span className="_count">00</span> */}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="accommodations">Accommodations 
                {/* <span className="_count">00</span> */}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="concerned-clubs">
                <PaginationProvider>
                  <CourseIssuesList />
                </PaginationProvider>
              </Tab.Pane>
              <Tab.Pane eventKey="batch-sync">
                <PaginationProvider>
                  <CourseBatchList />
                </PaginationProvider>
              </Tab.Pane>
              <Tab.Pane eventKey="onboarding">
                <PaginationProvider>
                  <OnboardingList />
                </PaginationProvider>
              </Tab.Pane>
              <Tab.Pane eventKey="accommodations">
                <PaginationProvider>
                  <AccommodationList />
                </PaginationProvider>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      {/* <Tabs
      defaultActiveKey="concerned-clubs"
      id="todos-section"
      className="mb-4 todoTabs"
    >
      <Tab eventKey="concerned-clubs" title="Concerned Clubs">
          <div>
            <div className="row justify-content-between mb-2 ">
              <PaginationProvider>
                <CourseIssuesList/>
              </PaginationProvider>
            </div>
          </div> 
      </Tab>
      <Tab eventKey="batch-sync" title="Clubs Batch Sync">
          <div>
            <div className="row justify-content-between mb-2 ">
              <PaginationProvider>
                <CourseBatchList />
              </PaginationProvider>
            </div>
          </div> 
      </Tab>
      <Tab eventKey="onboarding" title="Onboarding">
          <div>
            <div className="row justify-content-between mb-2 ">
            </div>
              <PaginationProvider>
                <OnboardingList />
              </PaginationProvider>
          </div> 
      </Tab>
    </Tabs> */}
    </div>
  );
}

interface TodosProps {}

const Todos: FC<TodosProps> = () => {
  return (
    <>
      <div className="usersWrapper">
        <h1 className="mb-4 pageTitle">Todos</h1>
        <TodoTabs />
      </div>
    </>
  );
};

export default Todos;
