import React, { FC } from 'react';
import './filters.scss';

interface CheckboxOptionProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: () => void;
}

const CheckboxOption: FC<CheckboxOptionProps> = ({ label, value, checked, onChange }) => {
  return (
    <div className='py-1'>
      <label className='ch-checkbox ch-checkbox-bordered'>
        <input
          checked={checked}
          onChange={onChange}
          type='checkbox'
          value={value}
        />
        <span className='p130 fw600'>&nbsp;&nbsp;&nbsp;{label}</span>
      </label>
    </div>
  );
};

export default CheckboxOption;