import "./statusModal.scss";
import React, { FC, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

interface CancelBookingModalProps {
    showModal: boolean;
    courseId: any;
    handleClose: (arg: any) => void;
    changeStatus: (bookingPayload: any) => void;
    courseStatus: any;
}
const StatusChangeModal: FC<CancelBookingModalProps> = ({
    showModal,
    courseId,
    handleClose,
    changeStatus,
    courseStatus,
}) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formResponse, setFormResponse] = useState<Boolean>(false);
    const onSubmit = (e: any) => {
        e.preventDefault();
        setFormResponse(true);
        setFormSubmitted(true);
    };
    useEffect(() => {
        if (formSubmitted === true && formResponse === true) {
            changeStatus(courseStatus);
            handleClose(!showModal);
        }
    }, [formSubmitted, formResponse])
    const close = () => {
        handleClose(!showModal);
    };
    return (
        <Modal
            className="cancelstatusmodal modalLayoutWrapper"
            show={showModal}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Body>
                <button
                    type="button"
                    className="closeButton d-flex align-items-center justify-content-center"
                    onClick={() => {
                        close();
                    }}
                >
                    <i className="fa-solid fa-xmark" style={{ height: '16px', width: '16px' }}></i>
                </button>
                <div className="formWrap">
                    <div className="text-center">
                        <h3 className="mb-4 pb-3">
                          Are you sure you want to {courseStatus === 2 ? 'Deactivate' : 'activate'} this course?
                        </h3>
                        <button
                            type="button"
                            className="button button-primary button-rounded button-min-120 fontsize-12 fw600"
                            onClick={() => {
                                close();
                            }}
                        >
                            No
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                            type="button"
                            className="button button-primary button-rounded button-min-120 fontsize-12 fw600"
                            onClick={(e: any) => onSubmit(e)}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default StatusChangeModal;