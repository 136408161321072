import React, { FC, forwardRef, useState, useImperativeHandle, useEffect, MouseEventHandler, ChangeEvent, ChangeEventHandler } from "react";
import Slider from "rc-slider";
import "./date-time-component.scss"

import { ClubTimings, IUpdateClub, DAY_SORTER, convertTime24to12, COURSE_ADVANCE_BOOKING_MIN_DAYS, TOUR_PORTAL_EVENT_KEY, DISCOUNTED_PORTAL_EVENT_KEY, STARTING_TEE_TIME, checkDayExists, COURSE_TEE_MINUTES, getDayIndex, COURSE_TEE_HOURS, getValuesForAllTeeTimes, COURSE_ADVANCE_BOOKING_MAX_DAYS, COURSE_ADVANCE_BOOKING_DAYS_DEFAULT_VALUE, TOURISM_PORTAL_ADVANCE_BOOKING_DEFAULT_VAL } from "../../../../models/club";

interface IDateTimeComponent {
    info: IDateTimePayload;
    headerText?: string;
    onHeaderClick?: MouseEventHandler<HTMLElement>;
    advanceBookingMinDays?: number;
    advanceBookingMaxDays?: number;
    advanceBookingDefaultDay?: number;
    clubTimingsSubmitError?: string;
    handleBookingError:any;
}

export interface IDateTimePayload {
    clubTimings?: Array<ClubTimings>;
    clubAdvanceBookingDuration?: number;
}


const DATE_TIME_SELECTOR_VALUES = getValuesForAllTeeTimes();

const DateTimeComponent = forwardRef<(IDateTimePayload | undefined), IDateTimeComponent>(({
    info,
    advanceBookingMinDays = COURSE_ADVANCE_BOOKING_MIN_DAYS,
    advanceBookingMaxDays = COURSE_ADVANCE_BOOKING_MAX_DAYS,
    advanceBookingDefaultDay = COURSE_ADVANCE_BOOKING_MIN_DAYS,
    handleBookingError,
    clubTimingsSubmitError,
    headerText = "",
    onHeaderClick = () => console.log('Header Clicked')
},
    ref
) => {

    const [clubTimings, updateClubTimings] = useState<Array<ClubTimings>>(info.clubTimings||DATE_TIME_SELECTOR_VALUES);
    const [clubAdvanceBookingDuration, setclubAdvanceBookingDuration] = useState<number>(info.clubAdvanceBookingDuration||advanceBookingDefaultDay);
    
    const onAdvanceBookingDaySelect = (e: any) => {
        const value = e?.target?.value?.trim() || e;
        const parsedValue = parseInt(value, 10);

        if (!isNaN(parsedValue)) {
            setclubAdvanceBookingDuration(parsedValue);
    
        } else {
            setclubAdvanceBookingDuration(0);
        }
    }

    const updateClubDuration = (changes: IUpdateClub) => {
        const { index, day, startHours, endHours, startMinutes, endMinutes } = changes;
        let updatedClubTimings: Array<ClubTimings> = JSON.parse(JSON.stringify(clubTimings));

        if (day) {
            const indexOfItem = getDayIndex(updatedClubTimings, day);

            if (indexOfItem !== -1) {
                updatedClubTimings[indexOfItem] = {
                    ...updatedClubTimings[indexOfItem],
                    deleted: !updatedClubTimings[indexOfItem].deleted
                };
            } else {
                updatedClubTimings.push({
                    day,
                    deleted: false
                });
            }
        }


        if (startHours !== undefined) {
            updatedClubTimings[index] = { ...updatedClubTimings[index], startHours };
        }

        if (endHours !== undefined) {
            updatedClubTimings[index] = { ...updatedClubTimings[index], endHours };
        }

        if (startMinutes !== undefined) {
            updatedClubTimings[index] = { ...updatedClubTimings[index], startMinutes };
        }

        if (endMinutes !== undefined) {
            updatedClubTimings[index] = { ...updatedClubTimings[index], endMinutes };
        }
        let updatedTimings = updatedClubTimings.filter((item: any) => item);
        updateClubTimings(updatedTimings)
    }

    const getDropdownValuesOnDropDownOptionSelect = (callback: (hour: string, minute: string) => void) => (e: ChangeEvent<HTMLSelectElement>) => {
        const [hours, minutes] = e.target.value.split(":");
        callback(hours, minutes);
    }

    useImperativeHandle(ref, () => ({
        clubTimings: clubTimings.filter(val => val && Object.keys(val).length),
        clubAdvanceBookingDuration
    }));

    const checkClubTimings = (clubTimings: any) => {
        let isValid = true;
        clubTimings.forEach((timingObject: any) => {
            if (!(timingObject?.deleted === true)) {
                if (!timingObject?.startHours || !timingObject?.endHours) {
                    isValid = false;
                } else if (parseInt(`${timingObject?.startHours}${timingObject?.startMinutes}`) >= parseInt(`${timingObject.endHours}${timingObject.endMinutes}`)) {
                    isValid = false;
                }
            }
        });
        return isValid;
    }
    useEffect(() => {

        const { clubTimings: savedClubTimings, clubAdvanceBookingDuration: savedClubAdvanceBookingDuration } = info;
    

        if(!clubTimings?.filter((item:any)=>item)?.length){
         return;
        }

        const isValid =checkClubTimings(clubTimings) ;
        let durationValid

        advanceBookingDefaultDay===4 ?  durationValid=clubAdvanceBookingDuration<=4 && clubAdvanceBookingDuration> 0 : durationValid=clubAdvanceBookingDuration>0 
    
        if (!isValid || !durationValid) {
            const errorMessage = !isValid ? "Please select valid start and end hours for selected day(s)" : !durationValid && advanceBookingDefaultDay===4  ? `Booking advance duration should be valid and not should be greater than 4` :"Booking advance duration should be valid"
            handleBookingError(errorMessage);
            return;
        }else{
            handleBookingError("");
        }
       
        if (savedClubTimings && savedClubTimings?.length) {
            const updatedClubtimings: Array<ClubTimings> = [];
            Object.keys(DAY_SORTER)
                .forEach((day: string, index: number) => {                    
                    const clubtimingObj = savedClubTimings?.find(obj => obj?.day === day);
                    if (clubtimingObj) {
                        updatedClubtimings[index] = {
                            ...clubtimingObj,
                            ...((!!!clubtimingObj?.startMinutes) ? { startMinutes: COURSE_TEE_MINUTES[0] } : {}),
                            ...((!!!clubtimingObj?.endMinutes) ? { endMinutes: COURSE_TEE_MINUTES[0] } : {})
                        };
                    }
                })
        }
    }, [clubTimings, clubAdvanceBookingDuration]);

    return <>
        <div className="border-none">
            {
                headerText && <div className="border-none" onClick={onHeaderClick}>
                    <div className="row py-2">
                        <div className="col-11 accordion-header-text">{headerText}</div>
                    </div>
                </div>
            }
            
            <div className="setDaysBlock mb-4 mt-3">
                {Object.keys(DAY_SORTER).map((key: string, index: number) => (
                    <>
                        <div className="row gutters-5 align-items-center mb-3 _dayTimesBlock">
                            <div className="col-12 col-md-3">
                                <div className="d-flex align-items-center">
                                    <label className="mb-0 ch-switch me-3 p-t2">
                                        <input type="checkbox" name={key} value={key} onChange={() => updateClubDuration({ index: getDayIndex(clubTimings, key), day: key })} checked={checkDayExists(clubTimings, key) === false || clubTimings[getDayIndex(clubTimings, key)]?.deleted === true || clubTimings[getDayIndex(clubTimings, key)] === null ? false : true} />
                                        <span>&nbsp;</span>
                                    </label>

                                    <h6 className="mb-0 fw700 text-capitalize">{key}</h6>
                                </div>
                            </div>
                            <div className="col custom-margin-left">
                                <div className="row gutters-5 align-items-center justify-content-end">
                                    <div className="col-auto">
                                        <select
                                            className="form-select"
                                            disabled={clubTimings[getDayIndex(clubTimings, key)] == undefined || clubTimings[getDayIndex(clubTimings, key)]?.deleted === true}
                                            onChange={getDropdownValuesOnDropDownOptionSelect((hours, minutes) => updateClubDuration({ index: getDayIndex(clubTimings, key), startHours: hours, startMinutes: minutes }))}
                                        >
                                            <option disabled hidden selected>
                                                Start Time
                                            </option>
                                            {
                                                COURSE_TEE_HOURS
                                                    .filter((_, index) => (index < COURSE_TEE_HOURS?.length - 1))
                                                    .map((hourVal, index) => COURSE_TEE_MINUTES.map((minuteVal) => <>
                                                        <option
                                                            value={`${hourVal}:${minuteVal}`}
                                                            key={index}
                                                            selected={(clubTimings[getDayIndex(clubTimings, key)]?.startHours == hourVal && clubTimings[getDayIndex(clubTimings, key)]?.startMinutes == minuteVal)}
                                                        >
                                                            {convertTime24to12(hourVal, minuteVal)}
                                                        </option>
                                                    </>)
                                                    )
                                                    .flat()
                                            }
                                        </select>
                                    </div>
                                    <div className="col-auto">
                                        <span className="_to">To</span>
                                    </div>
                                    <div className="col-auto">
                                        <select
                                            className="form-select"
                                            disabled={(clubTimings[getDayIndex(clubTimings, key)] == undefined || clubTimings[getDayIndex(clubTimings, key)]?.startHours == undefined || clubTimings[getDayIndex(clubTimings, key)]?.deleted === true)}
                                            onChange={getDropdownValuesOnDropDownOptionSelect((hours, minutes) => updateClubDuration({ index: getDayIndex(clubTimings, key), endHours: hours, endMinutes: minutes }))}
                                        >
                                            <option disabled hidden selected>
                                                Closing Time
                                            </option>
                                            {
                                                COURSE_TEE_HOURS
                                                    .map((hourVal, index) => COURSE_TEE_MINUTES.map((minuteVal) => <>
                                                        <option
                                                            disabled={(
                                                                !!clubTimings[getDayIndex(clubTimings, key)]?.startHours &&
                                                                !!clubTimings[getDayIndex(clubTimings, key)]?.startMinutes &&
                                                                parseInt(`${clubTimings[getDayIndex(clubTimings, key)]?.startHours}${clubTimings[getDayIndex(clubTimings, key)]?.startMinutes}`) >= parseInt(`${hourVal}${minuteVal}`)
                                                            )}
                                                            value={`${hourVal}:${minuteVal}`}
                                                            key={index}
                                                            selected={(clubTimings[getDayIndex(clubTimings, key)]?.endHours == hourVal && clubTimings[getDayIndex(clubTimings, key)]?.endMinutes == minuteVal)}
                                                            style={{
                                                                ...(!!clubTimings[getDayIndex(clubTimings, key)]?.startHours && !!clubTimings[getDayIndex(clubTimings, key)]?.startMinutes && parseInt(`${clubTimings[getDayIndex(clubTimings, key)]?.startHours}${clubTimings[getDayIndex(clubTimings, key)].startMinutes}`) >= parseInt(`${hourVal}${minuteVal}`) && { backgroundColor: "var(--bs-secondary-bg)" })
                                                            }}
                                                        >
                                                            {convertTime24to12(hourVal, minuteVal)}
                                                        </option>
                                                    </>)
                                                    )
                                                    .flat()
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            </div>
            <div className="setDaysBlock mb-2 pt-3">
                <h6 className="mb-3">
                    Set duration for advance bookings
                </h6>
                <div className="row">
                    <div className="col-12">
                        <div className="input-group daysInputGroup">
                            <input
                                type="text"
                                className="form-control"
                                value={clubAdvanceBookingDuration}
                                onChange={(e:any) => onAdvanceBookingDaySelect(e)}
                            />
                            <span className="input-group-text">
                                {clubAdvanceBookingDuration &&
                                    clubAdvanceBookingDuration > 1
                                    ? "Days"
                                    : "Day"}
                            </span>
                        </div>
                    </div>
                    {/* <div className="col-12">
                        <Slider
                                className="mb-5"
                                min={advanceBookingMinDays}
                                max={advanceBookingMaxDays}
                                railStyle={{
                                    backgroundColor: "#E3F1F0",
                                    height: 4,
                                }}
                                trackStyle={{
                                    backgroundColor: "#153230",
                                    height: 4,
                                }}
                                handleStyle={{
                                    borderColor: "#00D19F",
                                    opacity: 1,
                                    height: 24,
                                    width: 24,
                                    marginLeft: 0,
                                    marginTop: -10,
                                    backgroundColor: "#00D19F",
                                }}
                                marks={{ [advanceBookingMinDays]: `${advanceBookingMinDays} day`, [advanceBookingMaxDays]: `${advanceBookingMaxDays} days` }}
                                onChange={(e: any) => onAdvanceBookingDaySelect(e)}
                                value={clubAdvanceBookingDuration}
                            />
                    </div> */}
                </div>
                <span className="isInvalidMessage text-danger">
                    {clubTimingsSubmitError}
                </span>
            </div>
        </div>
    </>;
});

export default DateTimeComponent;