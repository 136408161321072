import React from "react";
import { useState, ReactElement } from "react";

import { 
    IFilterBar, 
    IFilterItem, 
    FilterItem,
} from '../../../bookings/components/filter-bar/filter-bar';

// export interface IFilterItem {
//     filterText: string;
//     filterFunc?: () => void;
// }

export class FilterItemType {
    private constructor(public readonly value: string) {}

    static readonly COURSE_DETAILS = new FilterItemType('Course Details').value;
    static readonly BOOKINGS = new FilterItemType('Bookings').value;
    static readonly REVIEWS = new FilterItemType('Reviews').value;
}

// export const FilterItem = ({ item, onClick, index }: {item: IFilterItem, onClick: (item: any, index: number) => void, index: number}) => {
//     // const [active, setActive] = useState(item.selected ?? false);
//   const context = React.useContext(FilterBarContext);

//   const handleClick = () => {
//     onClick(item, index);
//     context.setSelected(index);
//   }

//   return (
//     <li className="nav-item">
//       <button 
//         className={
//           `button button-sm button-rounded filter-item-button fontsize-14 px-3 
//           ${context.selected === index ? "filter-item-selected" : null}`
//         } 
//         onClick={handleClick} 
//         style={{whiteSpace: "nowrap"}}
//       >
//         {item.filterText}
//       </button>
//     </li>
//   );
// }

export const FilterBarContext = React.createContext({selected: 0, setSelected: (value: number) => {}});

export const FilterBarProvider = ({ selectedFilter, children }: { selectedFilter?: number, children: ReactElement[] }) => {
  const [selected, setSelected] = useState(selectedFilter ?? 0);
  const value = { selected, setSelected: (value: number) => setSelected(value) };

  return (
    <FilterBarContext.Provider value={value}>
      {children}
    </FilterBarContext.Provider>
  );
}

// export interface IFilterBar {
//   setShowFilters: (value: boolean) => void;
//   onFilterChange: (filterItem: any, index: number) => void;
//   selectedFilter?: number;
// }

let filterItems: IFilterItem[] = [
    { filterText: FilterItemType.COURSE_DETAILS },
    { filterText: FilterItemType.BOOKINGS },
    { filterText: FilterItemType.REVIEWS },
]

export const FilterBar = ({ setShowFilters, onFilterChange, selectedFilter }: IFilterBar) => {
    const handleClick = (item: any, index: number) => {
      onFilterChange(item, index);
    }
  
    const barItems = filterItems.map((item, index) => 
      <FilterItem key={`${index}`} item={item} onClick={ handleClick } index={index}/>
    );
  
    return (
      <div className="filter-bar">
        <ul className="nav nav-pills">
          <FilterBarProvider selectedFilter={selectedFilter}>
            {barItems}
          </FilterBarProvider>
        </ul>
        {/* <div className="">
          <button
              type="button"
              className="button button-sm button-rounded button-white button-filter fontsize-14 px-3"
              onClick={() => setShowFilters(true)}
          >
              <em className="icon-filter me-2"></em> Filters
          </button>
        </div> */}
      </div>
      
    );
}