import React, { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./newscomponent.scss";
import { useMutation, useQuery } from "react-query";
import { usePagination } from "../../contexts/PaginationContext";
import axios from "axios";
// import NewsPostFormPopUp from "./components/newsPostFormPopUp";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";

import NewsPostList from "./components/news-list/news-list";
import NewsPostFormPopUp from "./components/newsPostFormPopUp";

interface TaxesProps { }

const NewsComponent: FC<TaxesProps> = () => {
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    setItemsCountInCurrentPage,
  } = usePagination();
  const [selectedNews, setSelectedNews] = useState<any>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const accessToken: any = localStorage.getItem("accessToken");

  const fetchNews = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchBlogAndNews`,
      {
        params: {
          skip,
          limit,
          sortBy: "createdAt",
          sortOrder: -1,
          type: "news",
        },
        headers: {
          Accesstoken: accessToken,
        },
      }
    );
    return response.data;
  };

  const { data: newsData, refetch: refetchNews } = useQuery(
    "news",
    fetchNews
  );

  const addNewsMutation = useMutation({
    mutationFn: async (data: any) => {
      const response = await axios.post(
        `${process.env.REACT_APP_ADMIN_API_BASEURL}/addBlogOrNews`,
        data,
        {
          headers: {
            Accesstoken: accessToken,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("News added successfully!");
      refetchNews(); 
      setTimeout(() => {
        setShowPopup(false);
      }, 100);
      
     
    },
    onError: (error) => {
      console.error("Error adding news:", error);
    },
  });

  const updateNewsMutation = useMutation({
    mutationFn: async (data: any) => {
      const response = await axios.put(
        `${process.env.REACT_APP_ADMIN_API_BASEURL}/updateBlogOrNews`,
        data,
        {
          headers: {
            Accesstoken: accessToken,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("News updated successfully!");
      refetchNews(); 
      setShowPopup(false);
      setSelectedNews(null);
    },
    onError: (error) => {
      console.error("Error updating news:", error);
    },
  });

  const deleteNewsMutation = useMutation({
    mutationFn: async (id) => {
      const response = await axios.delete(
        `${process.env.REACT_APP_ADMIN_API_BASEURL}/deleteBlogOrNews/${id}`,
        {
          headers: {
            Accesstoken: accessToken,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("News deleted successfully!");
      refetchNews(); 
      setShowDeletePopup(false);
    },
    onError: (error) => {
      console.error("Error updating news:", error);
    },
  });

  const deleteNewsHandler = async () => {
    deleteNewsMutation.mutate(selectedNews?._id)
  }

  const handleAddEditPopup = (news: any) => {
    setSelectedNews(news);
    setShowPopup(true);
  };

  const handleEditClick = (news: any) => {
    let obj = {
      _id: news?._id,
      title: news?.title,
      editor: news?.description
    }
    setSelectedNews(obj);
    setShowPopup(true);
  };

  const handleDeleteClick = (news: any) => {
    let obj = {
      _id: news?._id,
    };
    setSelectedNews(obj);
    setShowDeletePopup(true);
  };

  useEffect(() => {
    if (newsData?.response?.count >= 0 && skip ===0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(newsData?.response?.count);
    }

  }, [newsData?.response?.count, skip]);

  useEffect(() => {
    refetchNews();
  }, [
    skip,
    itemsPerPage
  ]
  );

  return (
    <>
      <div className="usersWrapper">
        <h1 className="mb-2 pageTitle">News</h1>
        <div className="col-4"></div>
        <div className="container-fluid p-0">
          <div className="row justify-content-between mb-1">
            <div className="col-12 col-lg"></div>
          </div>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary myBTnClass mb-2"
              onClick={() => handleAddEditPopup(null)} 
            >
              Add News
            </button>
          </div>

          {newsData?.response?.count === 0 && (
            <IntegolfDataNotFound message={"No News found"} />
          )}

          {newsData?.response?.count != 0 &&
            <NewsPostList
              data={newsData?.response?.result}
              onEditNews={handleEditClick}
              showDeletePopup={showDeletePopup}
              setShowDeletePopup={setShowDeletePopup}
              deleteNewsHandler={deleteNewsHandler}
              onDeleteNews={handleDeleteClick}
            />}

          <NewsPostFormPopUp
            show={showPopup}
            setShow={setShowPopup}
            defaultValues={selectedNews|| {}}
            addNewsMutation={addNewsMutation} 
            updateNewsMutation={updateNewsMutation}
          /> 
        </div>
      </div>
    </>
  );
};

export default NewsComponent;
