import { useEffect, useState } from 'react';
import './courseissue-list.scss';
import Pagination from '../../../../components/Pagination';
import { usePagination } from '../../../../contexts/PaginationContext';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { NavLink } from 'react-router-dom';
import { stepWiseDescription } from '../../../../utils/constants';
import { useMutation } from 'react-query';
import axios from 'axios';
import IntegolfDataNotFound from '../../../../components/integolf-data-not-found/integolf-data-not-found';
import IntegolfLoader from '../../../../components/integolf-loader/integolf-loader';
import { getStatusDetail } from '../../../../utils/common';

const CourseIssuesList = () => {
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    setItemsCountInCurrentPage,
    itemsPerPage,
    totalItems,
    currentPage
  } = usePagination();
  const accessToken = localStorage.getItem('accessToken');
  const getCourseIssuesList = async (accessToken: string) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchCoursesIssues?skip=${skip}&limit=${limit}`,
    {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    }
    );
    return response.data;
  };
  const { data, mutate: getCourseIssueList, error:courseIssueError } = useMutation('courseIssuesList', () => getCourseIssuesList(accessToken!));

  useEffect(() => {
    if (data?.response?.count && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
    }
  }, [data?.response?.count, skip]);

  useEffect(() => {
    getCourseIssueList();
  }, [skip, getCourseIssueList, itemsPerPage ])

  return (
  <>
  {data?.response?.count === 0 && <IntegolfDataNotFound message={"No Data found"}/>}
  {data?.response ? data?.response?.count>0 &&
    <div className="userslist" >
      <div className="tableLayout">
        <div className="table-responsive" style={{minHeight:'300px'}} >
          <table className="table mb-0" >
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Integration</th>
                <th>Status</th>
                <th>Discounts Added</th>
                <th>Same Day Discount Less than 30</th>
                <th>Photos Less than 5</th>
                <th>Pay Cadance Set</th>
                <th>Bank Details Set</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.response?.result?.map((club: any, j: number) => {
                const { class: cssClass, label } = getStatusDetail(club?.status);

                const {courseCreationSteps} = club?.courseOnboardingDetails ?? {};
                const integration = club?.courseOnboardingDetails?.integration;
                let stepPending = Object.entries(courseCreationSteps ?? {}).find(([key,value]:any)=>value?.status === "pending")?.[0] ?? '';
                let stepPendingText = stepWiseDescription?.[stepPending]?.title;
                if(!stepPendingText){
                  stepPendingText = (integration?.status==="pending")?`${club?.courseOnboardingDetails?.type} Integration`:'-'
                }
                return (
                  <>
                    {<tr>
                      <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                      <td>
                        <div className="userContent align-items-center">
                          <span className="imgBlock rounded-circle">
                            <img
                              src={club?.photos?.[0]?.path ?? "../../assets/images/default-user.png"}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          <div className="content">
                            <h5 className="mb-0">{club?.club?.name ? club.club.name : 'n/a'}</h5>
                          </div>
                        </div>
                      </td>
                      <td>
                        {(club?.type==='teeon' || club?.type==="chronogolf")? <img
                          src={(club?.type === 'teeon') ? "../../assets/images/logo-tee-on.png" : "../../assets/images/logo-chronogolf.png"}
                          alt="../../assets/images/icons/verify.svg"
                          className="img-fluid"
                          width={"100px"}
                        />: "-"}
                      </td>
                      <td>
                        <label className={`mb-0 status ${cssClass}`}>
                            {label}
                          </label></td>
                      <td>
                        <label className={`mb-0 status ${(!club?.noDiscounts)?'status--success':'status--danger'}`}>
                            {(club?.noDiscounts)?'No':'Yes'}
                        </label>
                      </td>
                      <td>
                        <label className={`mb-0 status ${(!club?.sameDayDiscountLessThanThirty)?'status--success':'status--danger'}`}>
                            {(club?.sameDayDiscountLessThanThirty)?'Yes':'No'}
                        </label>

                        </td>
                      <td>
                        <label className={`mb-0 status ${(!club?.photosLessThanFive)?'status--success':'status--danger'}`}>
                            {(club?.photosLessThanFive)?'Yes':'No'}
                        </label>
                    </td>
                    <td>
                        <label className={`mb-0 status ${(club?.userInfo?.length> 0 && club?.userInfo[0]?.payoutInterval)?'status--success':'status--danger'}`}>
                            {(club?.userInfo?.length> 0 && club?.userInfo[0]?.payoutInterval ? 'Yes':'No')}
                        </label>
                    </td>
                    <td>
                        <label className={`mb-0 status ${(club?.bankAccountSetup)?'status--success':'status--danger'}`}>
                            {(club?.bankAccountSetup)?'Yes':'No'}
                        </label>
                    </td>
                      <td>
                        <div className="listActionDropdownBlock">
                          <Dropdown align="end">
                          <div className="text-center">
                            <Dropdown.Toggle className="toggleButton">
                              <em className="fa-solid fa-ellipsis-vertical"></em>
                            </Dropdown.Toggle>
                          </div>
                          <Dropdown.Menu>
                          <NavLink to={`/course/${club?._id}`} state={{id: club?._id}} className="dropdown-item fw600">
                              View Course Details
                            </NavLink>
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
                      </td>
                    </tr>}
                    
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="paginationBlock">
          <div className="row gutters-5 align-items-center justify-content-between">
            {totalItems > limit && <Pagination />}
          </div>
        </div>
      </div>
    </div> : <IntegolfLoader show/>
  }
  </>
    
  );
};

export default CourseIssuesList;