import React from 'react';
import FilterProvider from './payouts-detail.context';
import PayoutDetail from './payout-detail';
import PaginationProvider from '../../contexts/PaginationContext';

export default () => {
    return (
        <PaginationProvider>
            <FilterProvider>
                <PayoutDetail/>
            </FilterProvider>
        </PaginationProvider>

    )
}