import { Modal } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import "./booking-query-detail-modal.scss";
import dayjs from "dayjs";



type PopUpProps = {
    show: boolean;
    setShow: (show: boolean) => void;
    queryDetail: any;
};

export default ({ setShow, show, queryDetail }: PopUpProps) => {
    const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setShow(false);
    };


    const coursesInfo = (courses: any) => {
        let validClubNames: any[] = []
        if (Array.isArray(courses) && courses?.length > 0) {
            courses.map((ele: any) => {
                validClubNames.push(ele?.clubsInfo?.name)
            })
        }
        return validClubNames.join(", ");
    }

    return (
        <Modal
            className="payoutAccountInfoModal modalLayoutWrapper"
            show={show}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header className="d-flex justify-content-between align-items-center">
                <Modal.Title>Booking Request</Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={handleClose}
                >
                </button>
            </Modal.Header>

            <Modal.Body className="text-center payout-notification-modal">
                <div>
                    {[
                        [
                            { label: 'Name', value: queryDetail?.inquiry?.name, capitalize: true },
                            { label: 'Email', value: queryDetail?.inquiry?.email }
                        ],
                        [
                            { label: 'Arrival Date', value: dayjs(queryDetail?.inquiry?.arrivalDate).format('MMMM D YYYY') },
                            { label: 'Departure Date', value: dayjs(queryDetail?.inquiry?.departureDate).format('MMMM D YYYY') }
                        ],
                        [
                            { label: 'Time Preference', value: queryDetail?.inquiry?.timePreference, capitalize: false },
                            { label: 'Booking Option', value: queryDetail?.inquiry?.bookingOption, capitalize: true }
                        ],
                        [
                            { label: 'Courses', value: coursesInfo(queryDetail?.coursesInfo), capitalize: true },
                            { label: 'Transport', value: queryDetail?.inquiry?.cartOrWalking, capitalize: true }
                        ],
                        [
                            { label: 'Equipment Needed', value: queryDetail?.inquiry?.needEquipment, capitalize: true },
                            { label: 'Number of Players', value: queryDetail?.inquiry?.numberOfPlayers }
                        ],
                        [
                            { label: 'Rounds', value: queryDetail?.inquiry?.rounds },
                            { label: 'Rooms', value: queryDetail?.inquiry?.rooms }
                        ],
                        [
                            { label: 'Stay Option', value: queryDetail?.inquiry?.stayOption, capitalize: true },
                            { label: 'Beds', value: queryDetail?.inquiry?.beds }
                        ]
                    ].map((row, index) => (
                        <div className="row" key={index}>
                            {row.map((col, colIndex) => (
                                <div className="col" key={colIndex}>
                                    {col.value && (
                                        <p className={col.capitalize ? "text-capitalize" : ""}>
                                            <strong>{col.label}:</strong> {col.value}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </Modal.Body>

        </Modal>
    );
};
