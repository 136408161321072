import "./salestargetlist.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink } from "react-router-dom";
import dayjs from 'dayjs';
import Pagination from "../../components/Pagination";
import { usePagination } from "../../contexts/PaginationContext";
const SalesTargetList = ({
  data
}: any) => {

  const { currentPage, totalItems, itemsPerPage } = usePagination();

  return (
    <>
      {data?.length > 0 && <div className="courseslistWrapper">
        <div className="tableLayout">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <div className="d-flex align-items-center">
                      <span>Course Name</span>
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Location</th>
                  <th>Added on</th>
                  <th>Status</th>
                  <th>Sales User</th>
                  <th className="width-80">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((e: any, j: number) => {
                  return (
                    <>
                      <tr key={'key_' + j}>
                        <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="userContent">
                              <div className="content course-name-field" >
                                <h5 className="mb-1"> {e?.name ? e?.name : 'n/a'}</h5>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{e?.userName ? e?.userName : "n/a"}</td>
                        <td>{e?.email ? e?.email : "n/a"}</td>
                        <td>{e?.phoneNumber && e?.countryCode ? `+${e?.countryCode}${e?.phoneNumber}` : "n/a"}</td>
                        <td>{e?.location}</td>
                        <td>{dayjs(e?.createdAt).format('DD MMM YYYY hh:mm a')}</td>
                        <td>
                          <label className={`mb-0 status ${(e?.status === 'onboarded') ? 'status--success' : 'status--active'}`}>
                            {e?.status === 'onboarded' ? 'Onboarded' : 'Pending'}
                          </label>
                        </td>
                        <td>
                         {e?.addedBy?.email ?? "n/a"}
                        </td>
                        <td>
                          <div className="listActionDropdownBlock">
                            <Dropdown align="end">
                              <div className="text-center">
                                <Dropdown.Toggle className="toggleButton">
                                  <em className="fa-solid fa-ellipsis-vertical"></em>
                                </Dropdown.Toggle>
                              </div>
                              <Dropdown.Menu>
                                <NavLink to={`/salestarget/notes/${e?._id}`} state={{ id: e?._id }} className="dropdown-item fw600">
                                  View Notes
                                </NavLink>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>

                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="paginationBlock">
            <div className="row gutters-5 align-items-center justify-content-between">
              {totalItems > 10 && <Pagination />}
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default SalesTargetList;
