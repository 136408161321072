import React, { FC, useEffect, useState } from 'react';
import './users.scss';
import UserList from './components/user-list/user-list';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import IntegolfLoader from '../../components/integolf-loader/integolf-loader';
import { usePagination } from '../../contexts/PaginationContext';
import Filters from './components/filters/filters'
import { useUserFilters } from './user.context';
import IntegolfDataNotFound from '../../components/integolf-data-not-found/integolf-data-not-found';
let debounce = require('lodash.debounce');

interface UsersProps { }

const Users: FC<UsersProps> = () => {
  const [showTab, setShowTab] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const onShowFilter = () => {
    document.body.classList.add("overflow-hidden");
    setShowFilters(true);
  };
  const hideFilterEvent = (value: any) => {
    document.body.classList.remove("overflow-hidden");
    setShowFilters(false);
  };

  const applyFilterEvent = (value: any) => {
    hideFilterEvent(false);
  };
  const toggleNavTab = (value: any) => {
    setShowTab(value);
  };

  const exportToCSV = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    userListCSV()
  }

  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    setItemsCountInCurrentPage,
  } = usePagination();
  const {
    courseStatus,
    serchTerm,
    setSearchTerm,
    roleStatus,
    filtersApplied
  } = useUserFilters();

  const extractingCSV = async (accessToken: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ADMIN_API_BASEURL}/exportcsv`,
        { collection:"users" },
        {
          headers: {
            accesstoken: accessToken,
          },
          responseType: 'blob', 
        }
      );
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'users.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };
  
  const getUsers = async (accessToken: string) => {

    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchUser`, {
      skip,
      limit,
      "status": filtersApplied?.courseStatus,
      "sortBy": "createdAt",
      "sortOrder": -1,
      "text": serchTerm?.trim(),
      "role": filtersApplied?.roleStatus
    }, {
      headers: {
        accesstoken: `${accessToken}`,
      },
    });
    return response.data;
  };

  const accessToken = localStorage.getItem('accessToken');
  const { isLoading, error, data, mutate: getUserscall } = useMutation('users', () => getUsers(accessToken!));
  const { mutate:userListCSV } = useMutation('userscsvlist', () => extractingCSV(accessToken!));



  const changeUserStatus = async (accessToken: string, userObject: any) => {
    let bodyData: any = [{
      "id": userObject?.id,
      "status": userObject?.status,
    }]

    const response = await axios.put(`${process.env.REACT_APP_ADMIN_API_BASEURL}/userStatus`, bodyData, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  }

  const { mutate: markUserActiveInactive, data: updatedStatusData } = useMutation('markUserActiveInactive', (userObject: any) => changeUserStatus(accessToken!, userObject));

  useEffect(() => {
    if (data?.response?.count >= 0 && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
    }
  }, [data?.response?.count, skip]);

  useEffect(() => {
    getUserscall();
  }, [skip, getUserscall, , updatedStatusData, itemsPerPage, filtersApplied])


  useEffect(() => {
    if(serchTerm?.trim()?.length> 0){
      setCurrentPage(1);
      getUserscall();
    }
  }, [serchTerm])


  return (
    <>
      <div className="usersWrapper">
        <h1 className="mb-3 pageTitle">Users</h1>
        <div className="row align-items-center">
          <div className="col-md-4">
            <div className="searchBlock">
              <input
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Search by name, email"
              />
            </div>
          </div>
          <div className="col-md-8 mb-2 text-align-right">
            <button
              type="button"
              className="button button-sm button-rounded button-white button-filter fontsize-14 px-2 me-2"
              onClick={(e: any) => exportToCSV(e)}
            >
              Export <em className='fa-solid fa-file-csv'></em>
            </button>

            <button
              type="button"
              className="button button-sm button-rounded button-white button-filter fontsize-14 px-2"
              onClick={onShowFilter}
            >
              <em className="icon-filter me-2 "></em> Filters
            </button>
          </div>
        </div>

        <div className="container-fluid p-0">
          <div className="row justify-content-end mb-2">
            <div className="col-12 col-lg">
            </div>

          </div>
          {data?.response?.count === 0 && <IntegolfDataNotFound message={"No Users found"} />}
          {data?.response ? <UserList updatedStatusData={updatedStatusData} markUserActiveInactive={markUserActiveInactive} data={data?.response?.result} />
            :
            <IntegolfLoader show />}

        </div>
      </div>
      {showFilters && (
        <div className="asideFilterWrapper">
          <div className="asideFilterInnerWrap">
            <Filters
              showFilterComponent={showFilters}
              handleApplyFilters={applyFilterEvent}
              handleCloseFilterModal={hideFilterEvent}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Users;
