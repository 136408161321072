import React, { FC, useLayoutEffect, useState } from 'react';
import { useAccommodationsFilters } from '../../accommodations.context';
import InputRange from 'react-input-range';
import CheckboxOption from './CheckboxOption';
import 'react-input-range/lib/css/index.css';
import './filters.scss';

interface FiltersProps {
  handleCloseFilterModal: (arg: any) => void;
  handleApplyFilters: (arg: any) => void;
  showFilterComponent: boolean;
}

const Filters: FC<FiltersProps> = ({ handleApplyFilters, handleCloseFilterModal, showFilterComponent }) => {
  const {
    accommodationType,
    setAccommodationType,
    setAppliedFilters
  } = useAccommodationsFilters();

  const closeFilterPopup = () => {
    setAccommodationType((prevState: any) => {
      const updatedState = prevState.map((item: any) => {
        if (item.id) {
          return { ...item, checked:false  };
        }
        return item;
      });
      return updatedState;
    });
  
  };

  const applyFilters = () => {
    const appliedTypes=accommodationType.filter((accommodation:any)=>accommodation.checked===true).map((accommodation:any)=>accommodation.id)
    setAppliedFilters(appliedTypes);
    handleApplyFilters({});
  };

  const handleCheckboxNameChange = (accommodation: any) => {
    setAccommodationType((prevState: any) => {
      const updatedState = prevState.map((item: any) => {
        if (item.id === accommodation.id) {
          return { ...item, checked: item.checked ? false : true };
        }
        return item;
      });
      return updatedState;
    });
  };


  return (
    <div className='filterModal d-flex flex-column'>
      <div className='filterModal__header'>
        <div className='d-flex align-items-center justify-content-between'>
          <h4 className='mb-0'>Filters</h4>
          <a href={void 0} className='close' onClick={() =>  handleApplyFilters({})}>
            <em className='fa-solid fa-xmark'></em>
          </a>
        </div>
      </div>
      <div className='filterModal__body flex-fill'>
        <form>
          <div className='filterSection'>
            <div className='mb-0'>
              <label className='control-label mb-2'>Accommodation Type</label>
              {/* <CheckboxOption
                label="  Pent House"
                value={"Pent House"}
                checked={accommodationType.includes("Pent House")}
                onChange={() => handleCheckboxNameChange("Pent House")}
              />
              <CheckboxOption
                label="  Suite"
                value={"Suite"}
                checked={accommodationType.includes("Suite")}
                onChange={() => handleCheckboxNameChange("Suite")}
              />
              <CheckboxOption
                label="  Lodge"
                value={"Lodge"}
                checked={accommodationType.includes("Lodge")}
                onChange={() => handleCheckboxNameChange("Lodge")}
              />
              <CheckboxOption
                label="  Studio Apartment"
                value={"Studio Apartment"}
                checked={accommodationType.includes("Studio Apartment")}
                onChange={() => handleCheckboxNameChange("Studio Apartment")}
              />
              <CheckboxOption
                label="  Homestay"
                value={"Homestay"}
                checked={accommodationType.includes("Homestay")}
                onChange={() => handleCheckboxNameChange("Homestay")}
              />
              <CheckboxOption
                label="  Resort"
                value={"Resort"}
                checked={accommodationType.includes("Resort")}
                onChange={() => handleCheckboxNameChange("Resort")}
              />
              <CheckboxOption
                label="  Apartment"
                value={"Apartment"}
                checked={accommodationType.includes("Apartment")}
                onChange={() => handleCheckboxNameChange("Apartment")}
              />
              <CheckboxOption
                label="  Guest House"
                value={"Guest House"}
                checked={accommodationType.includes("Guest House")}
                onChange={() => handleCheckboxNameChange("Guest House")}
              />
              <CheckboxOption
                label="  Hostel"
                value={"Hostel"}
                checked={accommodationType.includes("Hostel")}
                onChange={() => handleCheckboxNameChange("Hostel")}
              /> */}
              {accommodationType?.length && accommodationType.map((accommodation: any, index: any) => (
                <CheckboxOption
                  key={index}
                  label={accommodation?.name}
                  value={accommodation?.id}
                  checked={accommodation?.checked}
                  onChange={() => handleCheckboxNameChange(accommodation)}
                />
              ))}

            </div>
            <div className="filterModal__footer">
              <div className="row gutters-5 align-items-center justify-content-between">
                <div className="col-auto">
                  <button
                    type="button"
                    className="button button-primary button-rounded button-min-110 fw600"
                    onClick={() => applyFilters()}
                  >
                    Apply
                  </button>
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="button button-secondary button-border button-rounded button-min-110 fw600"
                    onClick={() => closeFilterPopup()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>
  );
};

export default Filters;