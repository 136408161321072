import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import Transactions from './transactions';
import FilterProvider from './transactions.context';

export default () => {
    return (
        <PaginationProvider>
            <FilterProvider>
                <Transactions/>
            </FilterProvider>
        </PaginationProvider>
    )
}