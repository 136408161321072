import { RootState } from './../../app/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SignupPayload } from '../../models/auth'

export interface AuthState {
  signupLoginStatus: string;
  isLoggedIn: boolean;
}

const initialState: AuthState = {
  signupLoginStatus: "",
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signupLogin(state, action: PayloadAction<SignupPayload>) {
      state.signupLoginStatus = "pending";
    },
    signupLoginSuccess(state, action: PayloadAction) {
      state.signupLoginStatus = "success";
    },
    signupLoginFailed(state, action: PayloadAction<string>) {
      state.signupLoginStatus = "failed";
    },
  },
});

// Actions
export const authActions = authSlice.actions;

// Selectors
export const signupLoginStatus = (state: RootState) => state.auth.signupLoginStatus;
export const isUserLoggedIn = (state: RootState) => state.auth.isLoggedIn;

// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
