import "./qrcodescanslist.scss";
import dayjs from 'dayjs';
import Pagination from "../../components/Pagination";
import { usePagination } from "../../contexts/PaginationContext";

interface QrCodeScansListProps { }

const QrCodeScansActivitiesList = ({
  data
}: any) => {
  const { currentPage, totalItems,itemsPerPage } = usePagination();
  return (
    <>
      {data?.length > 0 && <div className="courseslistWrapper">
        <div className="tableLayout">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>User IP</th>
                  <th>Location</th>
                  <th>
                    Device Type
                  </th>
                  <th>Scanned Date</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((e: any, j: number) => {
                  return (
                    <>
                      <tr key={'key_' + j}>
                        <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                        <td>{e?.ip ? e?.ip : "n/a" }</td>
                        <td>{e?.locationInfo?.city &&  e?.locationInfo?.region && e?.locationInfo?.country ? `${e?.locationInfo?.city} ${e?.locationInfo?.region} ${e?.locationInfo?.country}` : "n/a"}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="userContent">
                              <div className="content">
                                  <h5 className="mb-1"> {e?.secChUaPlatform ? e?.secChUaPlatform?.replace(/"/g,'') : 'n/a'}</h5>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{dayjs(e?.createdAt).format('DD MMM YYYY hh:mm a')}</td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="paginationBlock">
            <div className="row gutters-5 align-items-center justify-content-between">
              {totalItems > 10 && <Pagination />}
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default QrCodeScansActivitiesList;
