import "./target-courses-form.scss";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import en from 'react-phone-number-input/locale/en.json';
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import axios from "axios";

export interface TargetCourseProps { }

type TargetCourseInput = {
  name: string;
  email: string;
  phoneNumber: string;
  location: string;
  userName: string;
  role: string;
};

export function TragetCoursesForm(props: TargetCourseProps) {

  const addTragetCourse = async (payload: any) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/addTargetCourse`, payload, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };

  const { register, handleSubmit, formState: { errors }, reset } = useForm<TargetCourseInput>();
  const { isLoading, error, data, mutate: addTargetCourseData } = useMutation('targetcourses', addTragetCourse, {
    onSuccess: () => {
      reset();
      toast.success("Details added successfully");
      navigate("/targetcourses");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.response?.message || error?.response?.data?.message || "Submission failed");
    },
  });
  const navigate = useNavigate();
  const [country, setCountry] = useState<any>('CA');
  const handleCourseSubmit = (data: any) => {
    data.countryCode = getCountryCallingCode(country) || 1;
    addTargetCourseData(data);
  };

  return (
    <div className="authWrapper d-flex align-items-center justify-content-center">
      <div className="authWrapContainer container">
        <div className="col-auto mt-3">
          <a
            onClick={() => navigate(-1)}
            className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
          >
            <em className="fas fa-arrow-left"></em>
          </a>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card p-4">
              <div className="card-body">
                <h5 className="card-title mb-4">Add Course</h5>
                <form onSubmit={handleSubmit(handleCourseSubmit)}>
             
                  <div className="form-group mb-3">
                    <label htmlFor="_personname">Name</label>
                    <input
                      type="text"
                      className={`form-control ${errors.userName ? 'is-invalid' : ''}`}
                      id="_personname"
                      {...register('userName', { required: true, validate: value => value.trim() !== '' })}
                    />
                    {errors.userName && <div className="invalid-feedback">Please enter name</div>}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="_userrole">Role</label>
                    <input
                      type="text"
                      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                      id="_userrole"
                      {...register('role', {
                        required: true,
                        validate: value => value.trim() !== ''
                      })}
                    />
                    {errors.role && <div className="invalid-feedback">Please enter role</div>}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="_email">Email</label>
                    <input
                      type="text"
                      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                      id="_email"
                      {...register('email', {
                        required: true,
                        pattern:
                          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,
                      })}
                    />
                    {errors.email?.type === 'required' && <div className="invalid-feedback">Please enter email</div>}
                    {errors.email?.type === 'pattern' && <div className="invalid-feedback">Please enter a valid email</div>}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="region">Country/Region</label>
                    <select
                      className="form-select"
                      id="region"
                      value={country}
                      onChange={(event) => setCountry(event.target.value || 'CA')}
                    >
                      {getCountries().map((country) => (
                        <option key={country} value={country}>
                          {en[country]} +{getCountryCallingCode(country)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="_phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                      id="_phoneNumber"
                      maxLength={10}
                      {...register('phoneNumber', {
                        required: true,
                        pattern: /^[0-9]+$/,
                        minLength: 10
                      })}
                    />
                    {errors.phoneNumber?.type === 'minLength' && <div className="invalid-feedback">Please enter 10 digit phone number</div>}
                    {errors.phoneNumber?.type === 'required' && <div className="invalid-feedback">Please enter phone number</div>}
                    {errors.phoneNumber?.type === 'pattern' && <div className="invalid-feedback">Please enter a valid phone number</div>}
                  </div>                
                  <div className="form-group mb-3">
                    <label htmlFor="_name">Course Name</label>
                    <input
                      type="text"
                      className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                      id="_name"
                      {...register('name', { required: true, validate: value => value.trim() !== '' })}
                    />
                    {errors.name && <div className="invalid-feedback">Please enter course name</div>}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="_location">Location</label>
                    <input
                      type="text"
                      className={`form-control ${errors.location ? 'is-invalid' : ''}`}
                      id="_location"
                      {...register('location', { required: true, validate: value => value.trim() !== '' })}
                    />
                    {errors.location && <div className="invalid-feedback">Please enter location</div>}
                  </div>
                  <button className="mt-3 button button-primary button-rounded button-sm  fontsize-16 fw500 mb-2 button-custom-padding" type="submit">Add</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TragetCoursesForm;
