import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import FilterProvider from './teeonSignup.context';
import TeeOnUsers from './teeonSignups';

export default () => {
    return (
        <PaginationProvider>
            <FilterProvider>
                <TeeOnUsers/>
            </FilterProvider>
        </PaginationProvider>
    )
}