import "./target-courses-notes-list.scss";
import Pagination from "../../../../components/Pagination";
import dayjs from 'dayjs';
import { usePagination } from "../../../../contexts/PaginationContext";

const TargetCoursesNotesList = ({
    data
}: any) => {
    
   
    const { currentPage, totalItems, itemsPerPage } = usePagination();
   
    return (
        <>
            {data?.notes?.length > 0 && <div className="courseslistWrapper">
                <div className="tableLayout">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Added on</th>
                                    <th>Contact Mode</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.notes?.map((e: any, j: number) => {
                                    return (
                                        <>
                                            <tr key={'key_' + j}>
                                                <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                                                <td>{dayjs(e?.contacton).format('DD MMM YYYY hh:mm a')} </td>
                                                <td>{e?.contactvia ? e?.contactvia : "n/a"}</td>
                                                <td>{e?.comments ? e?.comments : "n/a"}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="paginationBlock">
                        <div className="row gutters-5 align-items-center justify-content-between">
                            {totalItems > 10 && <Pagination />}
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default TargetCoursesNotesList;
