import React, { FC } from "react";
import "./tournament-courses-list.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink } from "react-router-dom";
import dayjs from 'dayjs';
import { useState } from "react";
import Pagination from "../../../components/Pagination";
import { usePagination } from "../../../contexts/PaginationContext";

interface TournamentCoursesListProps { }

const getStatusDetail = (status: number) => {
  switch (status) {
    case 1:
      return {
        label: 'Active',
        class: 'status--active'
      }
    case 2:
      return {
        label: 'Inactive',
        class: 'status--danger'
      }
    default:
      return {
        label: 'Completed',
        class: 'status--success'
      }
  }
}
const TournamentCoursesList = ({
  data
}: any) => {
  const { currentPage, totalItems,itemsPerPage } = usePagination();
  return (
    <>
      {data?.length > 0 && <div className="courseslistWrapper">
        <div className="tableLayout">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <div className="d-flex align-items-center">
                      <span>Name</span>
                    </div>
                  </th>
                  <th>Email</th>
                  <th>Course Name</th>
                  <th>Address</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th>Added on</th>
                  <th className="width-80">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((e: any, j: number) => {
                  const { class: cssClass, label } = getStatusDetail(e?.status);
                  return (
                    <>
                      <tr key={'key_' + j}>
                        <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="userContent">
                              <div className="content tournament-title">
                                <NavLink to={`/tournamentcourses/${e?.tournamentcourse?._id}/tournaments`} state={{ id: e?.tournamentcourse?._id }}>
                                  <h5 className="mb-1 text-capitalize"> {e?.firstname?.trim() ? `${e?.firstname?.trim()} ${e?.lastname?.trim()}` : 'n/a'}</h5>
                                </NavLink> 
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{e?.email ? e.email : "n/a"}</td>
                        <td className="text-capitalize">{e?.tournamentcourse?.CourseName?.trim() ?? "n/a"}</td>
                        <td className="text-capitalize">{e?.tournamentcourse?.Address1?.trim()?.length > 0  ?  e?.tournamentcourse?.Address1?.trim() : e?.tournamentcourse?.Address2?.trim()?.length > 0  ? e?.tournamentcourse?.Address2?.trim() :  "n/a"}</td>
                        <td>{e?.phone && e?.phone?.number ? `+${e?.phone?.countryCode}${e?.phone?.number}` : "n/a"}</td>
                        <td>
                          <label className={`mb-0 status ${cssClass}`}>
                            {label}
                          </label>
                        </td>
                        <td>{dayjs(e?.tournamentcourse?.CreatedAt).format('DD MMM YYYY hh:mm a')}</td>
                        <td>
                          <div className="listActionDropdownBlock">
                            <Dropdown align="end">
                              <div className="text-center">
                                <Dropdown.Toggle className="toggleButton">
                                  <em className="fa-solid fa-ellipsis-vertical"></em>
                                </Dropdown.Toggle>
                              </div>
                              <Dropdown.Menu>
                                <NavLink to={`/tournamentcourses/${e?.tournamentcourse?._id}/tournaments`} state={{ id: e?.tournamentcourse?._id }} className="dropdown-item fw600">
                                  View Tournaments
                                </NavLink>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>

                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="paginationBlock">
            <div className="row gutters-5 align-items-center justify-content-between">
              {totalItems > 10 && <Pagination />}
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default TournamentCoursesList;
