import { useMutation, useQuery } from 'react-query';
import React, { FC, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';


const teeOnProcessCompReq = async (accessToken: string, reqObject: any) => {
  const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/completeTeeOnBoarding`, reqObject, {
    headers: {
      'Accesstoken': `${accessToken}`,
    },
  })
  .catch(err=>{
    toast.error(err.message)
  })
  if(response?.data?.code){
    toast.success("Tee-On Onboarding Completed")
  }
  return response?.data || {};
}



  export const useTeeOnBoardingComp = (accessToken:any)=>{
    const {data:teeOnData, error:downloadError, mutate:teeOnBoardingReq} = useMutation('teeOnBoard',(reqObject:any)=> teeOnProcessCompReq(accessToken, reqObject));
    return {
      teeOnBoardingReq,
      teeOnData
    }
  }