
import { useEffect, useMemo, useRef, useState, RefAttributes } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import axios from 'axios';
import IntegolfLoader from '../../../components/integolf-loader/integolf-loader';
import CKEditorComponent from "../../../shared/filters/ckEditor";
import { JSX } from "react/jsx-runtime";
import { Tooltip, OverlayTrigger, TooltipProps } from "react-bootstrap";
import { toast } from 'react-toastify';
import { isImageSizeValid, isValidImage } from '../../../utils/constants';

type PopUpProps = {
  show: boolean,
  setShow: Function,
  defaultValues?: { title?: string, file?: string, editor?: string, _id?: string, fileId?: string }, // Optional default values
  addBlogMutation?: any; // Mutation passed from main component
  updateBlogMutation?: any
}
function BlogPostFormPopUp({ show, setShow, defaultValues, addBlogMutation, updateBlogMutation }: PopUpProps) {
  const handleClose = () => setShow(false);
  const { control, register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm<any>({});

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(defaultValues?.file || '');
  const accessToken: any = localStorage.getItem('accessToken');

  // React Query mutations
  const uploadFileMutation = useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append("files", file);
      const response = await axios.post(
        `${process.env.REACT_APP_ADMIN_API_BASEURL}/file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accesstoken: accessToken,
          },
        }
      );
      return response.data;
    },
  });

  // useEffect(() => {
  //   if (defaultValues && Object.keys(defaultValues).length != 0) {
  //     reset({
  //       title: defaultValues.title || '',
  //       file: defaultValues.file ? [defaultValues.file] : null,
  //       editor: defaultValues.editor || '',
  //     });

  //     // Handle image preview for edit mode
  //     if (defaultValues.file) {
  //       setPreview(defaultValues.file);
  //     } else {
  //       setPreview(null);
  //     }
  //   } else {
  //     console.log('else ---', defaultValues)
  //     reset()
  //     // reset({
  //     //   title: '',
  //     //   file: null,
  //     //   editor: '',
  //     // });
  //   }
  // }, [defaultValues, reset]);

  useEffect(() => {
    // When the popup is shown, handle form initialization
    if (show) {
      if (defaultValues && Object.keys(defaultValues).length !== 0) {
        // Editing an existing blog
        reset({
          title: defaultValues.title || '',
          file: defaultValues.file ? [defaultValues.file] : null,
          editor: defaultValues.editor || '',
          fileId: defaultValues.file ? [defaultValues.file] : null
        });
        setPreview(defaultValues.file || '');
      }
      // else {
      //   // Adding a new blog
      //   console.log('Here resetting')
      //   reset({
      //     title: '',
      //     file: null,
      //     editor: '',
      //   });
      //   setPreview(null);
      // }
    } else {
      // When popup is closed, clear preview
      reset({
        title: '',
        file: null,
        editor: '',
      });
      setPreview(null);
    }
  }, [defaultValues, show, reset]);


  // Handle form submission
  const onSubmit = async (data: any) => {
    const mutation = defaultValues?._id ? updateBlogMutation : addBlogMutation;
    // Handle file upload
   
    let uploadObj: any = {
      title: data.title,
      description: data.editor,
      type: "blog"
    }
    if (data.file instanceof FileList) {
     
      const formData = new FormData();
      formData.append("file", data.file[0]);
      try {
        // Upload file first
        const fileUploadResponse = await uploadFileMutation.mutateAsync(
          data.file[0]
        );

        if (fileUploadResponse) {
          let fileId = fileUploadResponse?.response[0]?._id;
          uploadObj["fileId"] = fileId
        }
      } catch (error) {
        console.error("Error:", error);
      }
      // value is a FileList
    } else {
      uploadObj["fileId"] = defaultValues?.fileId
    }

    if (defaultValues?._id) uploadObj["id"] = defaultValues?._id // IN case of update also send the _id of blog 
    await mutation.mutate(uploadObj); // Use the passed mutation function

    // if ((!defaultValues || Object.keys(defaultValues).length == 0) && data.file && data.file[0]) {
    //   const formData = new FormData();
    //   formData.append("file", data.file[0]);
    //   try {
    //     // Upload file first
    //     const fileUploadResponse = await uploadFileMutation.mutateAsync(
    //       data.file[0]
    //     );

    //     if (fileUploadResponse) {
    //       let fileId = fileUploadResponse?.response[0]?._id;
    //       let obj: any = {
    //         fileId: fileId,
    //         title: data.title,
    //         description: data.editor,
    //         type: "blog",
    //       };
    //       // Add blog data
    //       //  await addBlogMutation.mutateAsync(obj);
    //       await mutation.mutate(obj); // Use the passed mutation function
    //     }
    //   } catch (error) {
    //     console.error("Error:", error);
    //   }
    // } 
    // else if (defaultValues && Object.keys(defaultValues).length !== 0) {
    //   let obj: any = {
    //     "id": defaultValues?._id,
    //     "fileId": defaultValues?.fileId,
    //     "title": data.title,
    //     "description": data.editor,
    //     "type": "blog"
    //   }
    //   await mutation.mutate(obj); // Use the passed mutation function
    // } else {
    //   console.log("Else case--");
    // }
  };

  // Watch file input changes
  const file = watch('file');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, onChange: (value: FileList | null) => void) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile && !isValidImage(selectedFile)) {
      toast.error("Only PNG, JPEG, and JPG images are allowed to upload");
      return;
    }
    if ( selectedFile && !isImageSizeValid(selectedFile)) {
      toast.error("Image size must not exceed 5MB");
      return;
    }

    
    if (selectedFile) {
      onChange(event.target.files);
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setPreview(fileReader.result);
      };
      fileReader.readAsDataURL(selectedFile);
    } else {
      setPreview(null);
    }
  };

  const handleRemove = (onChange: (value: FileList | null) => void) => {
    setValue('file', null);
    setPreview(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    onChange(null);
  };

  // button for showing tooltip on image remover
  const renderTooltip = (
    props: JSX.IntrinsicAttributes &
      TooltipProps &
      RefAttributes<HTMLDivElement>
  ) => (
    <Tooltip id="button-tooltip" {...props}>
      Remove
    </Tooltip>
  );

  return (
    <>
      {(addBlogMutation.isLoading || updateBlogMutation.isLoading || uploadFileMutation.isLoading) && <div className="modal-loader-overlay">
        {<IntegolfLoader show />}
      </div>}
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          reset();
        }}
        className="modal-lg"
        autoFocus={false} enforceFocus={false} 
      >
        <div className="myModalDivClass">
          <Modal.Header closeButton className="myModalHeadClass">
            <Modal.Title>
              {defaultValues && Object.keys(defaultValues).length !== 0
                ? "Edit"
                : "Add"}{" "}
              Post
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="myModalBodyClass">

            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="postHead"
                    placeholder="postHead"
                    {...register("title", { required: true, validate: value => value.trim() !== ''})}
                  />
                  <label htmlFor="postHead">Blog Title</label>
                  {errors.title && <span className="isInvalidMessage text-danger d-block">Please enter blog title</span>}
                </div>
                <br />
                <div className="form-floating">
                  <Controller
                    name="file"
                    control={control}
                    defaultValue={null}
                    rules={{ required: "File is required" }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <>
                        <input
                          type="file"
                          accept="image/*"
                          ref={ref}
                          className="custom-file-input form-control p-8"
                          onChange={(event) =>
                            handleFileChange(event, onChange)
                          }
                          onBlur={onBlur}
                          placeholder="Choose an image file"
                        />
                        <label htmlFor="file">Choose an image file</label>
                        {preview && (
                          <div className="mt-2 position-relative">
                            <img
                              src={preview as string}
                              alt="Preview"
                              className="img-thumbnail position-relative"
                              style={{ maxWidth: "200px", maxHeight: "200px" }}
                            />

                            <OverlayTrigger
                              placement="top"
                              overlay={renderTooltip}
                            >
                              <button
                                type="button"
                                className="btn-close text-danger cst-btn"
                                aria-label="Close"
                                onClick={() => handleRemove(onChange)}
                              ></button>
                            </OverlayTrigger>

                          </div>
                        )}
                        {errors.file && (
                          <p className="text-danger mt-2">
                            Please upload a file
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
                <br />
                <Controller
                  name="editor"
                  control={control}
                  rules={{ required: "Editor content is required" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="custom-ckeditor-container">
                      <CKEditorComponent
                        value={value}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onChange(data)
                        }}
                        // onChange={(event, editor) => onChange(editor.getData())}
                        onBlur={onBlur}
                        placeholderText="Type your content here..."
                      />
                    </div>
                  )}
                />
                {errors.editor && (
                  <span className="isInvalidMessage text-danger d-block">
                    Please add content
                  </span>
                )}
                <br />
              </div>
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default BlogPostFormPopUp;
