import React, { FC, ReactElement, useEffect, useState } from "react";
import "./bookings.scss";
import BookingList from "./components/booking-list/booking-list";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";
import PaginationProvider, { usePagination } from "../../contexts/PaginationContext";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import { useBookingsFilters } from "./bookings.context";
import Filters from "./components/filters/filters";

import { FilterBar, IFilterItem, FilterItemType, getFilterItemFromIndex } from "./components/filter-bar/filter-bar";
import { useSearchParams } from "react-router-dom";

const SearchBar = ({ setSearchTerm }: { setSearchTerm: (value: string) => void }) => {
  return (
    <div className="mb-4">
      <div className="align-items-center justify-content-center search-container ">
        <span className='search-icon-container icon-search inline-flex'></span>
        <input onChange={(e) => {
          setSearchTerm(e.target.value)
          }} type="text" className="search-control" placeholder="Search by id, name, email, club name" />
      </div>
    </div>
  );
}

export enum BookingsType {
  THIRD_PARTY = 1,
  USER,
}

interface BookingsProps {
  title?: string;
  type?: BookingsType;
}

const BookingsComponent: FC<BookingsProps> = (props) => {
  const [showTab, setShowTab] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const toggleNavTab = (value: any) => {
    setShowTab(value);
  };

  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    totalPages,
    setItemsCountInCurrentPage
  } = usePagination();
  const {
    bookingStatus,
    serchTerm,
    setSearchTerm,
    appliedFilters
  } = useBookingsFilters();

  const onShowFilter = () => {
    document.body.classList.add("overflow-hidden");
    setShowFilters(true);
  };
  const hideFilterEvent = (value: any) => {
    document.body.classList.remove("overflow-hidden");
    setShowFilters(false);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  // if we have a filter in search params, restore it
  const [filterItem, setFilterItem] = useState<IFilterItem | undefined>(getFilterItemFromIndex(Number(searchParams.get('filter'))));

  const applyFilterEvent = (value: any) => {
    hideFilterEvent(false);
  };

  const bookingTypeToApiType = (type: BookingsType | undefined) : number => {
    if (type === BookingsType.THIRD_PARTY) {
      return 1;
    } else if (type === BookingsType.USER) {
      return 2;
    }
    return 1;
  }

  const getBookings = async (accessToken: string, statusFilters?: string[]) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchBooking`, {
      skip,
      limit,
      // "status":courseStatus,
      "sortBy": "createdAt",
      "sortOrder":-1,
      "text": serchTerm.trim(),
      "status": statusFilters ?? appliedFilters,
      "bookingType": props.type ? bookingTypeToApiType(props.type) : undefined,
    },{
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };

  // const getBookingsCount = async (accessToken: string) => {
  //   const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/bookingCount`, {
  //     headers: {
  //       'Accesstoken': `${accessToken}`,
  //     },
  //   });
  //   return response.data;
  // };
  const accessToken = localStorage.getItem('accessToken');
  const { isLoading, error, data, mutate: getbookings } = useMutation('bookings', (statusFilters?: string[]) => getBookings(accessToken!, statusFilters));
  //const { data: countData, refetch: getCount } = useQuery('getBookingsCount', () => getBookingsCount(accessToken!));
  useEffect(() => {
    if (data?.response?.count >= 0) {
      
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);

      const page = searchParams.get('page')
      let pageIndex = Number(page);
      
      // if our page index is out of bounds, reset it to the start
      if (data.response.result.length <= 0 || pageIndex < 1) {
        pageIndex = 1
        searchParams.set('page', `${pageIndex}`)
      }

      setSearchParams(searchParams);

      setCurrentPage(page ? pageIndex : 1);
      // setItemsCountInCurrentPage(data?.response?.length ?? 0);
    }
  }, [data?.response?.count, skip, searchParams, setItemsPerPage, setTotalItems, setCurrentPage, totalPages]);

  useEffect(()=> {
    const selectedFilter = filterItem?.filterText;

    if (!filterItem || selectedFilter === FilterItemType.ALL_BOOKINGS) {
      getbookings(undefined);
    }
    else if (selectedFilter === FilterItemType.UPCOMING) {
      getbookings(['success', 'courseConfirmationPending']);
    }
    else if (selectedFilter === FilterItemType.CANCELLED) {
      getbookings(['cancelled']);
    }
    else if (selectedFilter === FilterItemType.COMPLETED) {
      getbookings(['completed']);
    }
    else if (selectedFilter === FilterItemType.CONFIRMATION_PENDING) {
      getbookings(['courseConfirmationPending']);
    }
    else if (selectedFilter === FilterItemType.REFUNDED) {
      getbookings(['refunded']);
    }
  }, [skip, getbookings, appliedFilters, filterItem, itemsPerPage])

  useEffect(() => {
    if (serchTerm?.trim()?.length > 0){
      const selectedFilter = filterItem?.filterText;

    if (!filterItem || selectedFilter === FilterItemType.ALL_BOOKINGS) {
      getbookings(undefined);
    }
    else if (selectedFilter === FilterItemType.UPCOMING) {
      getbookings(['success', 'courseConfirmationPending']);
    }
    else if (selectedFilter === FilterItemType.CANCELLED) {
      getbookings(['cancelled']);
    }
    else if (selectedFilter === FilterItemType.COMPLETED) {
      getbookings(['completed']);
    }
    else if (selectedFilter === FilterItemType.CONFIRMATION_PENDING) {
      getbookings(['courseConfirmationPending']);
    }
    else if (selectedFilter === FilterItemType.REFUNDED) {
      getbookings(['refunded']);
    }
      setCurrentPage(1);
    }
  }, [serchTerm, setCurrentPage])

  return (
    <>
      <div className="bookingsWrapper">
        <SearchBar setSearchTerm={(value: string) => setSearchTerm(value)}/>
        <h1 className="mb-3 pageTitle">{props.title ?? 'Bookings'}</h1>
        <div className="row">
          <FilterBar 
            setShowFilters={(value: boolean) => setShowFilters(value)}
            onFilterChange={
              (item: IFilterItem, index: number) => {
                searchParams.set('filter', `${index}`);
                // setSearchParams({page: '1'})
                setFilterItem(item);
              }
            }
            selectedFilter={searchParams.has('filter') ? Number(searchParams.get('filter')) : 0}
          />
        </div>

        <div className="container-fluid p-0">
        
          {data?.response?.count ===0 && <IntegolfDataNotFound message={"No Bookings found"}/>}

          {data?.response ? 
          <BookingList data={data?.response?.result} type={props.type} />
          :
          <IntegolfLoader show/>}
         
        </div>
      </div>
      {showFilters && (
        <div className="asideFilterWrapper">
          <div className="asideFilterInnerWrap">
            <Filters
              showFilterComponent={showFilters}
              handleApplyFilters={applyFilterEvent}
              handleCloseFilterModal={hideFilterEvent}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BookingsComponent;
