import React, { FC, useEffect } from "react";
import "./tournament-bookings-detail.scss";
import { NavLink, useLocation, useNavigate, To, Outlet } from "react-router-dom";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { DateTime } from "luxon";
import { CARD_TYPE_MAPPING, getStatusObj } from "../../../../../utils/constants";
import IntegolfLoader from "../../../../../components/integolf-loader/integolf-loader";
import dayjs from "dayjs";


export interface IDetailItem {
  text: string;
  value?: string;
  boldTitle?: boolean;
}

export const DefaultBreadcrumbNav = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className="col-auto">
        <button
          className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
          onClick={() => {
            navigate(-1);
          }}
        >
          <em className="fas fa-arrow-left"></em>
        </button>
      </div>
      <div className="col">
        <nav className="breadcrumbWrap" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <NavLink to={"/bookings/tournaments"}>Bookings </NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span>Booking Details</span>
            </li>
          </ol>
        </nav>
      </div>
    </>
  )
}

export const DetailItem = ({ text, value, boldTitle = false }: IDetailItem) => {
  return (
    <>
      <div className="row gutters-5 py-2">
        <div className="col">
          <label className={`mb-0 color-700 ${boldTitle ? 'fw700' : null} text-capitalize`}>{text}</label>
        </div>
        <div className="col-auto">
          <label className="mb-0 color-800 fw700"> {value ?? ''}</label>
        </div>
      </div>
    </>
  );
}

export const DetailPriceItem = ({ text, price, boldTitle = false }: IDetailItem & { price: number | undefined }) => {
  const makeSignedPrice = (price: number): string => {
    if (price < 0) {
      return `-$${Math.abs(price).toFixed(2)}`;
    } else {
      return `$${Number(price).toFixed(2)}`;
    }
  }
  return (
    <DetailItem text={text} value={`${makeSignedPrice((price ? price : 0.00))}`} boldTitle={boldTitle}></DetailItem>
  );
}

export const InfoCard = ({ title, children }: { title: string, children: React.ReactNode }) => {
  return (
    <div className="infoCard mb-3">
      <h5 className="infoCard__cardTitle mb-3">{title}</h5>
      {children}
    </div>
  );
}

const PricingCard = ({ tournamentDetails,transactionInfo }: { tournamentDetails: any,transactionInfo:any }) => {
  return (
    <InfoCard title={'Charges'}>
      <DetailPriceItem
        text={`Tournament Price`}
        price={tournamentDetails?.tournament?.tournamentPrice ? tournamentDetails?.tournament?.tournamentPrice : tournamentDetails?.tournamentPrice ? tournamentDetails?.tournamentPrice : 0}
      />
      <DetailPriceItem
        text={`Booking Fee (5%)`}
        price={tournamentDetails?.bookingFees}
      />
      <DetailPriceItem
        text={'Taxes'}
        price={tournamentDetails?.taxAmt}
      />
      <DetailPriceItem
        text={'Total'}
        price={tournamentDetails?.totalPrice ? (tournamentDetails?.totalPrice)?.toFixed(2) : 0.00}
        boldTitle
      />

      {!tournamentDetails?.isLuckyBooking && <div className="mt-4">
        <h5 className="infoCard__cardTitle mb-2">Payment Methods</h5>
        <div className="row gutters-5 py-2">
          <div className="col">
            <label className="mb-0 color-700">Method</label>
          </div>
          <div className="col-auto">
            <h6 className="mb-0 color-800 fw700">
              {tournamentDetails?.paymentInfo?.status === "Success" ? `Card ${CARD_TYPE_MAPPING[tournamentDetails?.paymentInfo?.cardType || "Card"]}****${tournamentDetails?.paymentInfo?.last4}` : tournamentDetails?.paymentType?.Description ? tournamentDetails?.paymentType?.Description?.trim() : "Payment Failed"}

            </h6>
          </div>
        </div>
        <div className="row gutters-5 py-1">
          <div className="col">
            {transactionInfo.createdAt && <label className="mb-0 color-700">Transaction Date</label>}
          </div>
          <div className="col-auto">
            {transactionInfo?.createdAt && <h6 className="mb-0 color-800 fw700">

              {`${DateTime.fromMillis(transactionInfo?.createdAt).toFormat(
                "hh:mm a, EEE dd MMM yyyy"
              )}`}
            </h6>}
          </div>
        </div>
      </div>}
    </InfoCard>
  );
}

export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber === 'n/a') {
    return phoneNumber;
  }
  // remove any non-digit characters from our phone number string
  phoneNumber = phoneNumber?.toString()?.replace(/\D/g, '').toString();

  // get the RE matches with optional country code
  const matches = phoneNumber.match(/^(\d)?(\d{3})(\d{3})(\d{4})/);

  if (!matches) {
    return phoneNumber;
  }

  const countryCode = matches[1] ? `+${matches[1]} ` : '';
  return `${countryCode}(${matches[2]}) ${matches[3]}-${matches[4]}`;
}


interface TourismDetailsProps {
  children?: any
}

const TournamentBookingsDetails: FC<TourismDetailsProps> = ({ children }) => {
  useEffect(() => {
    const elem: any = document.getElementById("headerLayout");
    elem.className = "d-none";
    return () => {
      elem.className = "";
    };
  });
  const location = useLocation();
  const { id } = location.state;
  const getBookingDetail = async () => {
    const accessToken: any = localStorage.getItem('accessToken');
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchTounamentBookings/${id}`, {
      params: {

      },
      headers: {
        'Accesstoken': accessToken,
      }
    });
    return response.data;
  };

  const { isLoading, error, data, mutate: getBookingDetailFn } = useMutation('bookingDetails', getBookingDetail);

  useEffect(() => {
    getBookingDetailFn();
  }, [id, getBookingDetailFn])
  const tournamentDetails = data?.response?.result[0];
  const status = getStatusObj(tournamentDetails?.status?.value);
  const transactionInfo=Array.isArray(tournamentDetails?.transaction) && tournamentDetails?.transaction?.length > 0 ? tournamentDetails?.transaction[0] : tournamentDetails?.transaction
  const bookingType = tournamentDetails?.bookingType;
  let user = (tournamentDetails?.userId) ? tournamentDetails?.userId : tournamentDetails?.user;
  let userName = (user?.name) ? user?.name : (user?.firstname) ? `${user?.firstname} ${user?.lastname}` : 'Guest';
  let userPhone = (tournamentDetails?.user?.phone) ? tournamentDetails?.user?.phone : (tournamentDetails?.user?.phone?.phone) ? `+${tournamentDetails.user?.phone?.countryCode}${tournamentDetails.user?.phone?.phone}` : 'n/a';

  // let userPhone = 'n/a';
  if (tournamentDetails?.user?.phone?.phone) {
    userPhone = `+${tournamentDetails.user?.phone?.countryCode} ${formatPhoneNumber(tournamentDetails.user?.phone?.phone)}`;
  }
  else if (tournamentDetails?.user?.phone) {
    // userPhone = tournamentDetails.user.phone;
    userPhone = `+${tournamentDetails.user?.phone?.countryCode} ${formatPhoneNumber(tournamentDetails?.user?.phone?.number)}`;
  }

  const navigate = useNavigate();

  const content = React.Children.count(children) > 0 ? children : <DefaultBreadcrumbNav />

  return (
    (isLoading) ? <IntegolfLoader
      show
    /> :
      tournamentDetails &&
      <div className="bookingDetailWrapper">
        <div className="container-fluid p-0">
          <div className="row align-items-center mb-3">
            {content}
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8">
              <div className="infoCard mb-3">
                <div className="row gutters-10 infoCard__cardUserData">
                  <div className="col-auto">
                    <span className="userImage">
                      <img
                        src={tournamentDetails?.profileImage?.[0]?.path ?? "../../assets/images/default-user.png"}
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                  </div>
                  <div className="col align-self-center">
                    <div className="row gutters-5 align-items-center">
                      <div className="col">
                        <h3 className="mb-2" style={{ textTransform: 'capitalize' }}>{userName}</h3>
                        <div className="row gutters-7 align-items-center">
                          <div className="col-auto">
                            <p className="mb-0">{user?.email}</p>
                          </div>
                          <div className="col-auto color-400">|</div>
                          <div className="col-auto">
                            <p className="mb-0">{userPhone}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <InfoCard title={'Booking Details'}>
                <div className="infoCard__booking">
                  <div className="row gutters-10">
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">Booked on</label>
                      {tournamentDetails?.createdAt && <h6 className="color-800 fw700">
                        {`${DateTime.fromMillis(tournamentDetails?.createdAt).toFormat(
                          "hh:mm a, EEE dd MMM yyyy"
                        )}`}
                      </h6>}
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">Booking code</label>
                      <h6 className="color-800 fw700">{tournamentDetails?.bookingId}</h6>
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2 d-block">
                        Status
                      </label>
                      <label style={{ background: status.color, color: status.fontColor }} className={`mb-0 status`}>
                        {status?.label?.charAt(0).toUpperCase() + status?.label?.slice(1)}
                      </label>
                      {/* <span className="status status-active">{tournamentDetails?.status?.value}</span> */}
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">Start Date</label>
                      <h6 className="color-800 fw700">
                        {`${dayjs(tournamentDetails?.tournament?.tournamentStartDate).format(
                          "hh:mm a,ddd DD MMM YYYY"
                        )}`}
                      </h6>
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">End Date</label>
                      <h6 className="color-800 fw700">
                        {`${dayjs(tournamentDetails?.tournament?.tournamentEndDate).format(
                          "hh:mm a,ddd DD MMM YYYY"
                        )}`}
                      </h6>
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">
                        Max Golfers
                      </label>
                      <h6 className="color-800 fw700">{tournamentDetails?.tournament?.players ?? tournamentDetails?.tournamentInfo?.MaxGolfers} players</h6>
                    </div>
                  </div>
                </div>

              </InfoCard>
            </div>
            <div className="col-12 col-lg-5 col-xl-4">
              <div className="card mb-3 tournament-detailed-container">
                <div className="card-body">
                  <h5 className="card-title mb-4">{!tournamentDetails?.tournamentInfo?.League ? 'Tournament Details' : 'League Details'}</h5>
                  <p className="card-text course-name text-capitalize ">
                    <strong>Name:</strong>{" "}
                    {tournamentDetails?.tournament?.tournamentName?.trim()?.length > 0 ? tournamentDetails?.tournament?.tournamentName?.trim() :
                     tournamentDetails?.tournamentName?.trim()?.length > 0 ? tournamentDetails?.tournamentName?.trim() :  "n/a"}
                  </p>
                </div>
              </div>

              <div className="card mb-3 tournament-detailed-container">
                <div className="card-body">
                  <h5 className="card-title mb-4">Course Details</h5>
                  <p className="card-text course-name text-capitalize ">
                    <strong>Name:</strong>{" "}
                    {tournamentDetails?.tounamentcourse?.CourseName?.trim()?.length > 0 ? tournamentDetails?.tounamentcourse?.CourseName?.trim() :
                      "n/a"}
                  </p>
                  <p className="card-text">
                    <strong>Email:</strong>{" "}
                    {tournamentDetails?.tounamentcourse?.Email?.trim() ? tournamentDetails?.tounamentcourse?.Email?.trim() : "n/a"}
                  </p>
                </div>
              </div>

              {tournamentDetails?.status?.value?.toLowerCase()==="refunded" && <div className="card mb-3 tournament-detailed-container">
                <div className="card-body">
                  <h5 className="card-title mb-4">Refund Details</h5>
                  <p className="card-text">
                  <p className="card-text course-name text-capitalize ">
                  <strong>Amount:</strong>{" "}
                    {tournamentDetails?.tournament?.tournamentRefundResponse?.amount ? `$${Number(tournamentDetails?.tournament?.tournamentRefundResponse?.amount).toFixed(2)}` : tournamentDetails?.tournamentRefundResponse?.amount  ? `$${Number(tournamentDetails?.tournamentRefundResponse?.amount).toFixed(2)}` :"$0.00"}
                  </p>
                    <strong>Refunded On:</strong>{" "}
                    {`${DateTime.fromMillis(tournamentDetails?.tournament?.tournamentRefundResponse?.createdAt ?? tournamentDetails?.tournamentRefundResponse?.createdAt).toFormat(
                      "hh:mm a, EEE dd MMM yyyy"
                    )}`}
                  </p>
                </div>
              </div>}

              <PricingCard tournamentDetails={tournamentDetails} transactionInfo={transactionInfo} />
            </div>
          </div>
        </div>
      </div>
  );
};

export default TournamentBookingsDetails;
