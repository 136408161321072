import React from 'react';
import PaginationProvider from '../../../contexts/PaginationContext';
import SalesTargetNotes from './salestargetnotes';

export default () => {
    return (
        <PaginationProvider>
           <SalesTargetNotes/>
        </PaginationProvider>
    )
}