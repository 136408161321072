import React, { FC, useEffect, useState } from "react";
import { Navigate } from "react-router";
import "./tournament-detail-wrapper.scss";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import { NavLink, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";

interface TournamentDetailWrapperProps {}

export const TournamentRedirect = () => {
  const { id } = useParams();
  return <Navigate to={`/tournaments/${id}/detail`} />;
};

function TournamentDetailWrapper() {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const elem: any = document.getElementById("headerLayout");
    elem.className = "d-none";
    return () => {
      elem.className = "";
    };
  });
  


  const getTournamentDetail = async (accessToken: string) => {
    const response = await axios.get(
        `${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchTournament/${id}`,
        {
          headers: {
            Accesstoken: `${accessToken}`,
          },
        }
      );
    return response.data;
  };
  const accessToken = localStorage.getItem('accessToken');
  const { isLoading, error, data, mutate: tournamentDetailFn } = useMutation('tournamentsdetail', () => getTournamentDetail(accessToken!));
  useEffect(() => {
    tournamentDetailFn();
  }, [tournamentDetailFn])

  return (
    <div className="courseDetailWrapper">
      <div className="container-fluid p-0">
        <div className="row align-items-center mb-3">
          <div className="col-auto">
            <NavLink
              to={"/tournaments"}
              className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
            >
              <em className="fas fa-arrow-left"></em>
            </NavLink>
          </div>
          <div className="col">
            <nav className="breadcrumbWrap" aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <NavLink to={"/tournaments"}>Tournaments</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <span>Tournament Details</span>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        
        <div className="row ">
          <div className="col-12">
            <nav className="mb-3 navTabs">
              <NavLink
                to={`detail`}
                className={({ isActive }) =>
                  isActive ? "nav-item active" : "nav-item"
                }
              >
                Tournament Details
              </NavLink>
              <NavLink
                to={`golfers`}
                className={({ isActive }) =>
                  isActive ? "nav-item active" : "nav-item"
                }
              >
                Golfers
              </NavLink>
              <NavLink
                to={`flights`}
                className={({ isActive }) =>
                  isActive ? "nav-item active" : "nav-item"
                }
              >
                Flights
              </NavLink>
              <NavLink
                to={`winners`}
                className={({ isActive }) =>
                  isActive ? "nav-item active" : "nav-item"
                }
              >
                Winners
              </NavLink>
            </nav>
          </div>
        </div>
      </div>
      <Outlet></Outlet>
    </div>
  );
}

export default TournamentDetailWrapper;
