import React, { FC, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './taxes.scss';
import TaxesList from './components/taxes-list/taxes-list';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import IntegolfLoader from '../../components/integolf-loader/integolf-loader';
import { usePagination } from '../../contexts/PaginationContext';
import Filters from './components/filters/filters'
import { useUserFilters } from './taxes.context';
import { useCreateTaxes, useDeleteTax, useUpdateTaxes } from './taxes.hooks';
import IntegolfDataNotFound from '../../components/integolf-data-not-found/integolf-data-not-found';
let debounce = require('lodash.debounce');


interface TaxesProps { }

const Taxes: FC<TaxesProps> = () => {
  const [showTab, setShowTab] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const onShowFilter = () => {
    document.body.classList.add("overflow-hidden");
    setShowFilters(true);
  };
  const hideFilterEvent = (value: any) => {
    document.body.classList.remove("overflow-hidden");
    setShowFilters(false);
  };

  const applyFilterEvent = (value: any) => {
    hideFilterEvent(false);
  };
  const toggleNavTab = (value: any) => {
    setShowTab(value);
  };
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    setItemsCountInCurrentPage,
    itemsPerPage
  } = usePagination();
  const {
    courseStatus,
    serchTerm,
    setSearchTerm,
  } = useUserFilters();
  const getTaxes = async (accessToken: string) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/getTaxes?skip=${skip}&limit=${limit}&sortBy=createdAt&sortOrder=-1`,{
      headers: {
        accesstoken: `${accessToken}`,
      },
    });
    return response.data;
  };
  const accessToken = localStorage.getItem('accessToken');
  const { data, mutate: getTaxesCall } = useMutation('taxeslist', () => getTaxes(accessToken!));
  const {createTaxes, createTaxesData, createTaxesError } = useCreateTaxes(accessToken);
  const {updateTaxes, updateTaxesData, updateTaxesError } = useUpdateTaxes(accessToken);
  const {deleteTax, deleteTaxData, deleteTaxError } = useDeleteTax(accessToken);
  
  useEffect(() => {
    if (data?.response?.count && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
    }

  }, [data?.response?.count, skip]);

  useEffect(() => {
    getTaxesCall();
    
  }, [skip, getTaxesCall, courseStatus, serchTerm,itemsPerPage ])

  useEffect(()=>{
    if(createTaxesData?.code=="Success" || 
      updateTaxesData?.code ==="Success" || 
      deleteTaxData?.code ==="Success"){
        getTaxesCall();
    }
  },[createTaxesData, updateTaxesData, deleteTaxData])

  useEffect(() => {
    setCurrentPage(1);
  }, [courseStatus, serchTerm])


  return (
    <>
      <div className="usersWrapper">
        <h1 className="mb-3 pageTitle">Tax Constants</h1>
        <div className="col-4">
          {/* <div className="searchBlock">
            <input onChange={(e) => {
              setSearchTerm(e.target.value)
            }} type="text" className="form-control" placeholder="Search by name, email" />
          </div> */}
        </div>
        <div className="container-fluid p-0">
          <div className="row justify-content-between mb-2">
            <div className="col-12 col-lg">
            </div>
            {/* <div className="col-12 col-lg-auto">
              <button
                type="button"
                className="button button-sm button-rounded button-white button-filter fontsize-14 px-3"
                onClick={() => onShowFilter()}
              >
                <em className="icon-filter me-2"></em> Filters
              </button>
            </div> */}
          </div>
          {data?.response?.count === 0 && <IntegolfDataNotFound message={"Taxes not found"}/>}
          {data?.response ? <TaxesList 
                              data={data?.response?.result}
                              createTaxes={createTaxes}
                              updateTaxes={updateTaxes}
                              deleteTax={deleteTax}
          // resendSignupEmail={resendSignupEmail}
          // resendAcceptEmail={resendAcceptEmail}
          />
            :
            <IntegolfLoader show />}
        </div>
      </div>
      {showFilters && (
        <div className="asideFilterWrapper">
          <div className="asideFilterInnerWrap">
            <Filters
              showFilterComponent={showFilters}
              handleApplyFilters={applyFilterEvent}
              handleCloseFilterModal={hideFilterEvent}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Taxes;
