import React, { FC, useEffect, useState } from "react";
import "./tournaments-detail.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import IntegolfLoader from "../../../components/integolf-loader/integolf-loader";
import dayjs from "dayjs";

interface TournamentDetailProps { }

const TournamentDetail: FC<TournamentDetailProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const getPaymentsTypesInfo = (paymentTypes: any) => {
    return Array.isArray(paymentTypes) && paymentTypes?.length > 0 ? paymentTypes?.map((types: any) => types?.Description).join(",") : []
  }

  const getTournamentDetail = async (accessToken: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchTournament/${id}`,
      {
        headers: {
          Accesstoken: `${accessToken}`,
        },
      }
    );
    return response.data;
  };

  const accessToken: any = localStorage.getItem("accessToken");

  const { isLoading, data, mutate: tournamentDetailFn } = useMutation(
    "tournamentdetail",
    () => getTournamentDetail(accessToken)
  );

  useEffect(() => {
    tournamentDetailFn();
  }, [tournamentDetailFn]);

  const tournamentInfo = data?.response[0];
  const paymentInfo = getPaymentsTypesInfo(tournamentInfo?.PaymentTypes ?? [])

  return (
    <>
      {isLoading ? (
        <IntegolfLoader show />
      ) : (
        <div className="container mt-3">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="card mb-3 tournament-detailed-container">
                <div className="card-body">
                  <h4 className="card-title text-capitalize">
                    {tournamentInfo?.TournamentName}
                  </h4>
                  <div className="payment-types">
                    Payments Types: {paymentInfo?.length > 0 ? paymentInfo : "n/a"}
                  </div>
                  <div className="non-member-fees">
                    Non Member Fees: $ {tournamentInfo?.NonMemberPrice ? Number(tournamentInfo?.NonMemberPrice).toFixed(2) : '0.00'}
                  </div>

                </div>
              </div>

              <div className="card mb-3 tournament-detailed-container">
                <div className="card-body">
                  <h5 className="card-title mb-4">{!tournamentInfo?.League ? 'Tournament Days' : 'League Days'}</h5>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <div className='d-flex align-items-center'>
                            <span>Date</span>
                          </div>
                        </th>
                        <th>First Start Time</th>
                        {tournamentInfo?.League && <th>Week</th>}
                        <th>Golfers Placed</th>
                        <th>Golfers Not Placed</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tournamentInfo?.tournamentDays?.length > 0 ? (
                        tournamentInfo.tournamentDays.map((day: any, index: any) => (
                          <tr key={day?._id}>
                            <td>{index + 1}</td>
                            <td>{dayjs(day?.TheDate).format("DD MMM YYYY")}</td>
                            <td>{dayjs(day?.FirstStartTime).format("hh:mm a")}</td>
                            {tournamentInfo?.League && <td> {day?.weekNumber}</td>}
                            <td>{day?.totalGolfersAlreadyPlacedCount}</td>
                            <td>
                              {day?.golfersNotPlaced}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5} className="text-center">
                            No tournament days information available.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div className="card mb-3 tournament-detailed-container">
                <div className="card-body">
                  <h5 className="card-title mb-4">Course Information</h5>
                  <p className="card-text course-name">
                    <strong>Name:</strong>{" "}
                    {tournamentInfo?.tournamentCourse?.CourseName?.trim()?.length > 0 ? tournamentInfo?.tournamentCourse?.CourseName?.trim() :
                      "n/a"}
                  </p>
                  {/* <p className="card-text course-name">
                    <strong>Address:</strong>{" "}
                    {tournamentInfo?.tournamentCourse?.Address1?.trim()?.length > 0 ? tournamentInfo?.tournamentCourse?.Address1?.trim() : "n/a"}
                  </p> */}
                  <p className="card-text">
                    <strong>Email:</strong>{" "}
                    {tournamentInfo?.tournamentCourse?.Email?.trim() ? tournamentInfo?.tournamentCourse?.Email?.trim() : "n/a"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TournamentDetail;
