import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const axiosClient =  axios.create({
  baseURL: `${process.env.REACT_API_BASEURL}`,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true,
});

const logout = () => {
  const accessToken = localStorage.getItem('accessToken');

    return axios.put(`${process.env.REACT_APP_USER_API_BASEURL}/users/logout`,{
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
  }

// Interceptors
axios.interceptors.request.use(function (config: AxiosRequestConfig) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response: AxiosResponse) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  if (error?.response?.status == 401 || error?.response?.status ==403) {
    
    logout();
    localStorage.clear();
    window.location.href="/login"
  
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default axiosClient;