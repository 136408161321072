import React, { FC, ReactElement, useEffect, useState } from "react";
import "./tournaments.scss";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";
import PaginationProvider, { usePagination } from "../../contexts/PaginationContext";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import { useLocation, useSearchParams } from "react-router-dom";
import TournamentsList from "./tournaments-list/tournaments-list";


const SearchBar = ({ setSearchTerm }: { setSearchTerm: (value: string) => void }) => {
    return (
        <div className="mb-4">
            <div className="align-items-center justify-content-center search-container ">
                <span className='search-icon-container icon-search inline-flex'></span>
                <input onChange={(e) => {
                    setSearchTerm(e.target.value)
                }} type="text" className="search-control" placeholder="Search by id, name, email, club name" />
            </div>
        </div>
    );
}



const Tournaments: FC<any> = () => {
    const [showTab, setShowTab] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const {
        skip,
        limit,
        setTotalItems,
        setCurrentPage,
        setItemsPerPage,
        itemsPerPage,
        totalPages,
        setItemsCountInCurrentPage
    } = usePagination();

    const getTournaments = async () => {
        const accessToken: any = localStorage.getItem('accessToken');
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchTournamentsList`, {
            params: {
                text: searchTerm.trim(),
                limit,
                skip,
                sortBy: "createdAt",
                sortOrder: -1
            },
            headers: {
                'Accesstoken': accessToken,
            }
        });
        return response.data;
    };

    const { data, mutate: getTournamentsList,isLoading } = useMutation('tournaments', () => getTournaments());
    useEffect(() => {
        if (data?.response?.count >= 0 && skip === 0) {
            setCurrentPage(1);
            setItemsPerPage(itemsPerPage);
            setTotalItems(data?.response?.count);
        }
    }, [data?.response?.count, skip]);

    useEffect(() => {
        getTournamentsList();
    }, [
        skip,
        itemsPerPage
    ]);



    useEffect(() => {
        if (searchTerm?.trim()?.length > 0) {
            setCurrentPage(1);
            getTournamentsList();
        }
    }, [searchTerm])

    return (
        <>
			<div className='accommodationsWrapper'>
				<h1 className='mb-3 pageTitle'>Tournaments</h1>
				<div className='row'>
					<div className='col-4'>
						<div className='searchBlock'>
							<input 
							type='text'
							className='form-control'
							placeholder='Search by tournament,course name or email'
							onChange={(e) => {
								setSearchTerm(e.target.value)
							}}
							></input>
						</div>
					</div>
					<div className='col-8 mb-2 text-align-right'>
					
					</div>
				</div>
				<div className='container-fluid p-0 mt-4'>
					{data?.response?.count === 0 && <IntegolfDataNotFound message={"No Tournaments found"}/>}
					{data?.response ? <TournamentsList data={data?.response?.result} />
						:
						<IntegolfLoader show />}
				</div>
			</div>
        </>
    );
};

export default Tournaments;
