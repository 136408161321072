import React from "react";
import "./courseOnBoarding.scss";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import CourseOnBoardingDetails from "./components/courseOnBoardingDetails";
import { useQuery } from "react-query";
import { getCourseOnBoardingDetail } from "./courseOnBoarding.hooks";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";

function CourseOnBoarding() {
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) navigate("/course");

  const {
    isLoading,
    error,
    data: onBoardingCourseData,
  } = useQuery(
    `courseOnBoarding-${id}`,
    () => getCourseOnBoardingDetail(accessToken!, id as any),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 1,
      retryDelay: 3000,
    }
  );
  if (error) {
    return <IntegolfDataNotFound message={"Oops! Something went wrong..."} />;
  }
  return (
    <>
      {
        <div className="usersWrapper">
          {/* {onBoardingCourseData && onBoardingCourseData.response && (
            <h1 className="mb-3 pageTitle">
              {onBoardingCourseData.response.courseName}
            </h1>
          )} */}
          <div className="row align-items-center mb-3">
            <div className="col-auto">
              <a
                className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
                onClick={() => navigate(-1)}
              >
                <em className="fas fa-arrow-left"></em>
              </a>
            </div>
            <div className="col">
              <nav className="breadcrumbWrap" aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a className="" onClick={() => navigate(-1)}>
                      Onboarding
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <span>Course On Boarding</span>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* <div className="row align-items-center mb-3">
            <div className="col-auto">
              <a
                
                className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
              >
                <em className="fas fa-arrow-left"></em>
              </a>
            </div>
            <div className="col">
              <nav className="breadcrumbWrap" aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <NavLink to={"/courses"}>Courses </NavLink>
                    </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <span></span>
                  </li>
                </ol>
              </nav>
            </div>
          </div> */}

          <div className="container-fluid p-0">
            <div className="row justify-content-between mb-2">
              <div className="col-12 col-lg">
                {isLoading === false ? (
                  <CourseOnBoardingDetails
                    data={onBoardingCourseData?.response}
                  />
                ) : (
                  <IntegolfLoader show={true} />
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default CourseOnBoarding;
