import { useEffect, useState } from 'react';
import './course-batch-list.scss';
import Pagination from '../../../../components/Pagination';
import { usePagination } from '../../../../contexts/PaginationContext';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { NavLink } from 'react-router-dom';
import { stepWiseDescription } from '../../../../utils/constants';
import axios from 'axios';
import { useMutation } from 'react-query';
import IntegolfDataNotFound from '../../../../components/integolf-data-not-found/integolf-data-not-found';
import IntegolfLoader from '../../../../components/integolf-loader/integolf-loader';
import { getStatusDetail } from '../../../../utils/common';
import { DateTime, Zone } from 'luxon';
import SyncFormPopup from '../SyncFormPopup/sync-form-popup';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ArrowRepeat } from "react-bootstrap-icons";
import { Button } from 'react-bootstrap';

const CourseBatchList = () => {

  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    totalItems,
    currentPage
  } = usePagination();

  const getCoursesBatchList = async (accessToken:any) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchCourse`, {
      skip,
      limit,
      "sortBy": "createdAt",
      "sortOrder":-1,
    }, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };
  const fetchTeeTimes = async (data:any, accessToken:any) => {
    const response = await axios.post(`${process.env.REACT_APP_DATACOLLECTION_API_BASEURL}/integolf/fetchTeetimes`, data, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    })
    .catch(err=>{
      toast.error(err?.response?.data?.message ?? 'Something Went Wrong!')
    })
    if(response?.data){
      toast.success(response?.data?.message)
    }
    return response?.data ?? {};
  };

  const fetchAllTeeTimes = async (accessToken:any) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/startCourseSyncBatch`, {},{
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    })
    .catch(err=>{
      toast.error(err?.response?.data?.response?.message ?? 'Something Went Wrong!')
    })
    if(response?.data){
      toast.success(response?.data?.response?.message)
    }
    return response?.data ?? {};
  };

  const accessToken = localStorage.getItem('accessToken');
  const { data, mutate: getCourseBatchData } = useMutation('courseBatchData', () => getCoursesBatchList(accessToken!));
  const {mutate: teeTimesSync, isLoading:syncIsLoading} = useMutation('syncCourse',(data)=> fetchTeeTimes(data, accessToken!))
  const {data:allCoursesSyncData, mutate: allTeeTimesSync, isLoading:allSyncIsLoading} = useMutation('syncAllCourses',()=> fetchAllTeeTimes(accessToken!))

  useEffect(() => {
    if (data?.response?.count && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
    }
  }, [data?.response?.count, skip]);

  useEffect(() => {
    getCourseBatchData();
  }, [skip, getCourseBatchData, itemsPerPage ])

  const [showSyncForm, setShowSyncForm] = useState(false);
  const [selectedClubData, setSelectedClubData] = useState({name:"",clubId:"",courseId:""})
  const [popUpType, setPopUpType] = useState('course');

  const getBatchStatusDetail = (status: string) => {
    switch (status) {
      case 'success':
        return {
          label: 'Active',
          class: 'status--success'
        }
      default:
        return {
          label: 'Inactive',
          class: 'status--danger'
        }
    }
  }

  const syncFormHandler = (data:any, type="course")=>{
    if(type==="course"){
      const {from, to, clubId, courseId} = data;
      let reqData:any={
        from,
        to,
        clubId,
        courseId
      }
      teeTimesSync(reqData)
    }else if(type==="all"){
      allTeeTimesSync();
    }
    setShowSyncForm(false)
  }
  return (
  <>
  {
    (syncIsLoading || allSyncIsLoading) && <IntegolfLoader show={true}/>
  }
  <SyncFormPopup
    show={showSyncForm}
    data={selectedClubData}
    setShow={setShowSyncForm}
    submitHandler={syncFormHandler}
    type={popUpType}
  />
  {data?.response?.count === 0 && <IntegolfDataNotFound message={"No Data found"}/>}
  
  {data?.response ? data?.response?.count>0 && !syncIsLoading && <div className="userslist" >
      <div className="tableLayout">
        <div className="table-responsive" style={{minHeight:'300px'}} >
          <table className="table mb-0" >
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Integration</th>
                <th>Status</th>
                <th>Sync From Date</th>
                <th>Sync To Date</th>
                <th>Batch Start Time</th>
                <th>Sync Status</th>
                <th>Error</th>
                <th><Button variant="primary" onClick={()=>{setPopUpType("all");setShowSyncForm(true)}}><ArrowRepeat color="white" size={25}/></Button></th>
              </tr>
            </thead>
            <tbody>
              {data?.response?.result?.map((syncData: any, j: number) => {
                const {batch, course} = syncData;
                const { class: cssClassBatch } = getBatchStatusDetail(batch?.status);
                const { class: cssClass, label } = getStatusDetail(course?.status);

                return (
                  <>
                  
                    {<tr>
                      <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                      <td>
                        <div className="userContent align-items-center">
                          <span className="imgBlock rounded-circle">
                            <img
                              src={syncData?.photos?.[0]?.path ?? "../../assets/images/default-user.png"}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          <div className="content">
                            <h5 className="mb-0">{syncData?.club?.name ? syncData.club.name : '-'}</h5>
                          </div>
                        </div>
                      </td>
                      <td>
                        {(syncData?.course?.type==='teeon' || syncData?.course?.type==="chronogolf")? <img
                          src={(syncData?.course?.type === 'teeon') ? "../../assets/images/logo-tee-on.png" : "../../assets/images/logo-chronogolf.png"}
                          alt="../../assets/images/icons/verify.svg"
                          className="img-fluid"
                          width={"100px"}
                        />: "-"}
                      </td>
                      <td>
                      <label className={`mb-0 status ${cssClass}`}>
                            {label}
                          </label>
                      </td>
                      <td>{(batch?.metadata?.from) ? DateTime.fromMillis(batch?.metadata?.from, {zone:"UTC"}).toFormat('yyyy-LL-dd'):'-'}</td>
                      <td>{(batch?.metadata?.to) ? DateTime.fromMillis(batch?.metadata?.to, {zone:"UTC"}).toFormat('yyyy-LL-dd') :'-'}</td>
                      <td>{(batch?.createdAt) ?new Date(batch?.createdAt).toLocaleString() :'-'}</td>
                      <td>
                        {(batch?.status)?<label className={`mb-0 status ${cssClassBatch}`}>
                          {batch?.status}
                        </label>:'-'}
                      </td>
                      <td><p>{(batch?.errorMessage) ? batch?.errorMessage :'-'}</p></td>
                      <td>
                        <div className="listActionDropdownBlock">
                          <Dropdown align="end">
                          <div className="text-center">
                            <Dropdown.Toggle className="toggleButton">
                              <em className="fa-solid fa-ellipsis-vertical"></em>
                            </Dropdown.Toggle>
                          </div>
                          <Dropdown.Menu>
                          <NavLink to={`/course/course-sync/${syncData?.course?._id}`} state={{id: syncData?.course?._id}} className="dropdown-item fw600">
                              View Teesheet Sync Details
                            </NavLink>
                            <a onClick={()=>{setSelectedClubData({clubId:syncData?.club?._id, courseId:syncData?.course?._id, name:syncData?.club?.name});setPopUpType("course");setShowSyncForm(true)}} className="dropdown-item fw600">
                              Sync Course
                            </a>
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
                      </td>
                    </tr>}
                    
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="paginationBlock">
          <div className="row gutters-5 align-items-center justify-content-between">
            {totalItems > limit && <Pagination />}
          </div>
        </div>
      </div>
    </div>:<IntegolfLoader show/>}
  </>
    
  );
};

export default CourseBatchList;