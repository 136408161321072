import React, { FC, useEffect } from "react";
import "./tournament-winners.scss";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import Pagination from "../../../components/Pagination";
import IntegolfLoader from "../../../components/integolf-loader/integolf-loader";
import PaginationProvider, { usePagination } from "../../../contexts/PaginationContext";
import IntegolfDataNotFound from "../../../components/integolf-data-not-found/integolf-data-not-found";
interface TournamentWinnersProps { }

const TournamentWinners: FC<TournamentWinnersProps> = () => {

    const {
        skip,
        limit,
        totalItems,
        setTotalItems,
        currentPage,
        setCurrentPage,
        setItemsPerPage,
        itemsPerPage,
        totalPages,
        setItemsCountInCurrentPage
    } = usePagination();

    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();


    const accessToken: string = localStorage.getItem('accessToken') ?? 'err';

    const getTournamentWinners = async (accessToken: string) => {
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchWinners/${id}`, {
            params: {
                limit,
                skip,
                "sortBy": "CreatedAt",
                "sortOrder": -1
            },
            headers: {
                "Accesstoken": accessToken
            }
        });
        return response?.data;
    }

    const { isLoading, error, data, mutate: getWinners } = useMutation('tournamentwinners', () => getTournamentWinners(accessToken))

    useEffect(() => {
        getWinners();
    }, [
        id,
        getWinners,
    ]);

    useEffect(() => {
        if (data?.response?.count >= 0 && skip === 0) {
            setCurrentPage(1);
            setItemsPerPage(itemsPerPage);
            setTotalItems(data?.response?.count);
        }

    }, [data?.response?.count, skip]);

    useEffect(() => {
        getWinners()
    }, [
        skip,
        getWinners,
        itemsPerPage
    ]
    );

    const winners = data?.response?.result;

    
    const filteredGolfers = Array.isArray(winners) ? winners.filter((winner) => winner?._id === id) : [];
    
    const winnersCount = data?.response?.count;
    const tournamentInfo= winnersCount > 0 ? winners[0]?.tournamentInfo : null 

    return (
        <>
          {winnersCount===0 && <IntegolfDataNotFound message={"Winners not found"}/>}
            {isLoading ? (
                <IntegolfLoader show />
            ) : winnersCount > 0 && (
                <div className='accommodationsListWrapper'>
                    <div className='tableLayout'>
                        <div className='table-responsive'>
                            <table className='table mb-0'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            <div className='d-flex align-items-center'>
                                                <span>Name</span>
                                            </div>
                                        </th>
                                        <th>Email</th>
                                        <th>Score</th>
                                        <th>Winning type</th>
                                        <th>Retro Name</th>
                                        <th>Scoring Format Scores</th>
                                        {tournamentInfo?.League && <th>Tournament Day</th>}
                                        <th>Added On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {winners?.map((e: any, j: number) => {
                                        return (
                                            <tr key={'key_' + j}>
                                                <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='userContent tournament-title'>
                                                            <div className='content'>
                                                                <h5 className='mb-1 text-capitalize'>
                                                                  { e?.golferInfo?.firstname?.trim() ? `${e?.golferInfo?.firstname?.trim()} ${e?.golferInfo?.lastname?.trim()}` : e?.FirstName?.trim() ? `${e?.FirstName?.trim()} ${e?.LastName?.trim()}`: "n/a"}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{e?.golferInfo?.email?.trim() ?? "n/a"}</td>
                                                <td>{e?.Score ?? 0}</td>
                                                <td>{e?.winningtype?.Descriptor?.trim() ?? "n/a"}</td>
                                                <td className="text-capitalize">{e?.RetroName?.trim() ?? "n/a"}</td>
                                                <td>{e?.ScoringFormatScores ?? 0 }</td>
                                                {e?.tournamentInfo?.League && <td>{dayjs(e?.tournamentDays?.TheDate).format('DD MMM YYYY')}</td>}
                                                <td>{dayjs(e?.CreatedAt).format('DD MMM YYYY hh:mm a')}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className='paginationBlock'>
                                <div className='row gutters-5 align-items-center justify-content-between'>
                                    {totalItems > 10 && <Pagination />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>

    );
};

const TournamentWinnersWrapper = () => {
    return (
        <PaginationProvider>
            <TournamentWinners />
        </PaginationProvider>
    );
}

export default TournamentWinnersWrapper;
