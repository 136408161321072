import React, { createContext, useContext, useState } from 'react';

interface FilterContextType {
  parValue: any;
  slopeValue: any;
  lengthValue: any;
  setParValue: any;
  setSlopeValue: any;
  setLengthValue: any;
  courseStatus: any;
  setCoursesStatus: any;
  courseHoles: any;
  setCoursesHoles: any;
  serchTerm: any;
  setSearchTerm: any;
  setAppliedFilters:any;
  appliedFilters:any
}

const FilterContext = createContext<FilterContextType | undefined>(
  undefined
);

export const useCoursesFilters = (): FilterContextType => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('usePagination must be used within a PaginationProvider');
  }
  return context;
};

interface ContextProviderProps {
  children: React.ReactNode;
}

const FilterProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [parValue, setParValue] = useState<any>({
    min: 1,
    max: 1000,
  });
  const [slopeValue, setSlopeValue] = useState<any>({
    min: 1,
    max: 1000
  });
  const [lengthValue, setLengthValue] = useState<any>({
    min: 50,
    max: 10000
  });
  const [courseStatus, setCoursesStatus] = useState<any>([1, 2]);
  const [courseHoles, setCoursesHoles] = useState<any>([9, 18]);
  const [serchTerm, setSearchTerm] = useState('');
  const [appliedFilters,setAppliedFilters]=useState<any>({courseHoles,courseStatus})


  const value: FilterContextType = {
    parValue,
    slopeValue,
    lengthValue,
    setParValue,
    setSlopeValue,
    setLengthValue,
    courseStatus,
    setCoursesStatus,
    courseHoles,
    setCoursesHoles,
    serchTerm,
    appliedFilters,
    setSearchTerm,
    setAppliedFilters
  };

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;