import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import FilterProvider from './accommodations.context';
import Accommodations from './accommodations';

export default () => {
    return (
        <FilterProvider>
            <PaginationProvider>
                <Accommodations />
            </PaginationProvider>
        </FilterProvider>
    )
}