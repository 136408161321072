import React, { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from 'react-bootstrap/Accordion';
import dayjs from "dayjs";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import MerchantPayoutNotificationModal from "../modal/PayoutMerchantNotificationModal/PayoutMerchantNotificationModal";
import PayoutActionButton from "./payout-action-button";
import Pagination from "../../../../components/Pagination";
import { usePagination } from "../../../../contexts/PaginationContext";

import "./payout-list.scss";
import { INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_INTERACT_ID } from "../../../../utils/constants";
import PayoutAccountInfoModal from "../modal/PayoutAccountInfoModal/PayoutAccountInfoModal";

interface TransactionsListProps { }
const getStatusDetail = (status: string) => {
  switch (status) {
    case 'COMPLETED':
      return {
        label: 'Active',
        class: 'status--active'
      }
    case 'READY':
      return {
        label: 'Blocked',
        class: 'status--warning'
      }
    default:
      return {
        label: 'Blocked',
        class: 'status--danger'
      }
  }
}

// const renderTooltip = (props: any, payoutAccountInfo: any) => (
//   <Tooltip id="button-tooltip" {...props}>
//     <Card>
//       <Card.Body>
//         {
//           !!payoutAccountInfo ? <div>
//             {
//               (payoutAccountInfo?.type === INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_INTERACT_ID) ? <div className="row">
//                 <h6>Interac Id:</h6>
//                 <h5>{payoutAccountInfo?.interactId}</h5>
//               </div> : <div>
//                 <h6>Bank Acc Holder:</h6>
//                 <h5>{payoutAccountInfo?.accountHolderName}</h5>
//                 <h6>Bank Acc No:</h6>
//                 <h5>{payoutAccountInfo?.maskedAccountNumber}</h5>
//                 <h6>BIC/SWIFT:</h6>
//                 <h5>{payoutAccountInfo?.bicSwiftCode}</h5>
//               </div>
//             }
//           </div> : <h4>No Payout Account info details present for the club</h4>
//         }
//       </Card.Body>
//     </Card>
//   </Tooltip>
// );

const TransactionsList = ({ data, markPayoutComplete, markReadyData, sendEmailNotification }: any) => {
  const { currentPage, totalItems, itemsPerPage } = usePagination();
  const [showPayoutInfoModal, setShowPayoutInfoModal] = useState(false);
  const [showPayoutNotificationPopup, setShowPayoutNotificationPopup] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState<any>("");
  const [selectedPayout, setSelectedPayout] = useState<any>("")

  const handleButtonClick = (payoutAccountInfo: any, merchantObj: any) => {
    setSelectedMerchant(merchantObj);
    if (!payoutAccountInfo) {
      setShowPayoutNotificationPopup(true);
    } else {
      // Show the tooltip
      setShowPayoutInfoModal(true);
    }
  };
  return (<>
    {data?.length > 0 &&
      <div className="transactionlist">
        <div className="tableLayout">
          <div className="table-responsive">

            {data && data.map((value: any) => {

              const { _id, payouts = [], name = '', payoutAccountInfo } = value;
              let anyReadyPayouts = payouts.every((e: any) => e.status == 'READY');

              let ids = [];
              if (anyReadyPayouts) {
                ids = payouts.map((e: any) => e._id);
              }

              return (
                <Accordion defaultActiveKey={_id} key={_id}>
                  <Accordion.Item eventKey={_id}>
                    <Accordion.Header>
                      <div className="d-flex w-100 align-items-center">
                        <div className="header-text">{name}</div>
                        <div className="listActionDropdownBlock payout-block ms-auto">
                          {/* <OverlayTrigger
                              placement="right"
                              show={showToolTip&&selectedMerchant?._id==payouts[0]?.merchant?._id}
                              trigger={!!payoutAccountInfo ? "click" : []}
                              delay={{ show: 250, hide: 400 }}
                              overlay={props => payoutAccountInfo ? renderTooltip(props, payoutAccountInfo) : <></>}
                            > */}
                          {/* </OverlayTrigger> */}
                        </div>
                          {showPayoutNotificationPopup && selectedMerchant?._id == payouts[0]?.merchant?._id && <MerchantPayoutNotificationModal
                            show={showPayoutNotificationPopup}
                            setShow={(value: boolean) => setShowPayoutNotificationPopup(value)}
                            submitHandler={() => sendEmailNotification({ "id": selectedMerchant?._id })}
                          />}
                          {showPayoutInfoModal && selectedMerchant?._id == payouts[0]?.merchant?._id && <PayoutAccountInfoModal
                            showModal={showPayoutInfoModal}
                            handleClose={() => setShowPayoutInfoModal(false)}
                            payoutAccountInfo={payoutAccountInfo}
                          />}
                          <div className="ms-auto">
                          <button
                            type="button"
                            className="button button-primary button-rounded fw700 btn-sm payout-main-btn"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              handleButtonClick(payoutAccountInfo, payouts[0]?.merchant)
                            }
                            }
                          >
                            {!!payoutAccountInfo ? "View Bank Details" : "Send Merchant Notification"}
                          </button>
                          &nbsp;&nbsp;
                          {anyReadyPayouts && (<PayoutActionButton
                              markReadyData={markReadyData}
                              id={ids}
                              markBulk={true}
                              markPayoutComplete={markPayoutComplete}
                            />
                          )}
                          </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            {/* <th>User</th> */}
                            <th>Merchant</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th className="width-50">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payouts?.map((e: any, j: number) => {

                            const statusDetail = getStatusDetail(e?.status);
                            return (
                              <>
                                <tr>
                                  <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                                  <td>{dayjs(e?.createdAt).format('DD MMM YYYY hh:mm a')}</td>
                                  {/* <td>
                                  <div className="userContent align-items-center">
                                    <span className="imgBlock rounded-circle">
                                      <img
                                        src={e?.profileImage?.path ?? "../../assets/images/default-user.png"}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </span>
                                    <div className="content">
                                      <h5 className="mb-0">{`${!!e?.user?.firstname ? e?.user?.firstname : 'Guest'}`}</h5>
                                    </div>
                                  </div>
                                </td> */}
                                  <td>
                                    <div className="userContent align-items-center">
                                      <span className="imgBlock rounded-circle">
                                        <img
                                          src={e?.profileImage?.path ?? "../../assets/images/default-user.png"}
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </span>
                                      <div className="content">
                                        <h5 className="mb-0">{`${e?.merchant?.firstname} ${e?.merchant?.lastname ?? ''}`}</h5>
                                      </div>
                                    </div>
                                  </td>
                                  {/** to show actual payout amt which is minus the fees..*/}
                                  <td>${Number(e?.payout_amount).toFixed(2)}</td>
                                  <td>
                                    {
                                      (e?.status === 'READY') ?
                                        <PayoutActionButton
                                          markReadyData={markReadyData}
                                          id={[e._id]}
                                          markBulk={false}
                                          markPayoutComplete={markPayoutComplete} />
                                        :
                                        <label style={{ textTransform: 'capitalize' }} className={`mb-0 status ${statusDetail?.class}`}>
                                          {e?.status.toLowerCase()}
                                        </label>
                                    }
                                  </td>
                                  <td>
                                    <div className="listActionDropdownBlock">
                                      <Dropdown align="end">
                                        <div className="text-center">
                                          <Dropdown.Toggle className="toggleButton">
                                            <em className="fa-solid fa-ellipsis-vertical"></em>
                                          </Dropdown.Toggle>
                                        </div>
                                        <Dropdown.Menu>
                                          <NavLink to={"/payout/detail"} state={{ id: e._id }} className="dropdown-item fw600">
                                            View
                                          </NavLink>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>)
            })}
          </div>
          <div className="paginationBlock">
            {totalItems > 10 && <Pagination />}
          </div>

        </div>
      </div>
    }
  </>);
};

export default TransactionsList;
