import React, { useEffect, useState } from "react";
import AsideNavigation from "../aside-navigation/aside-navigation";
import "./private-layout.scss";
import Header from "../header/header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const SalesPrivateLayout = ({ component: Component }: any) => {
  const [role, setRole] = useState(localStorage.getItem('role'));
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!accessToken) {
      navigate('/login');
    } else if (role !== "sales") {
      navigate('/dashboard');
    } else if ((location.pathname === '' || location.pathname === '/')&& role === "sales") {
      navigate('/targetcourses');
    }
  }, [role, accessToken, location.pathname, navigate]);
  return (
    <>
      <div className="privateLayoutWrapper">
        <AsideNavigation />
        <div id="headerLayout">
          <Header />
        </div>
        <main role="main">
          <Outlet />
        </main>
      </div>
    </>
  )
};

export default SalesPrivateLayout;
