import  { FC, useEffect, useState } from "react";
import "./target-courses-notes.scss";
import { useMutation } from "react-query";
import axios from "axios";
import IntegolfDataNotFound from "../../../components/integolf-data-not-found/integolf-data-not-found";
import TargetCoursesNotesList from "./target-courses-notes-list/target-courses-notes-list";
import { usePagination } from "../../../contexts/PaginationContext";
import IntegolfLoader from "../../../components/integolf-loader/integolf-loader";
import { useNavigate, useParams } from "react-router";
import AddNotesModal from "../components/modals/addNotes";
import { toast } from "react-toastify";
interface CoursesNotesProps { }

const TargetCoursesNotes: FC<CoursesNotesProps> = () => {
  const [showTab, setShowTab] = useState(1);
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage
  } = usePagination();

  const navigate=useNavigate();
  const { id } = useParams();
  const getTragetCourseNotes = async () => {
      const accessToken = localStorage.getItem("accessToken") as string;
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchTargetCoursesNotes`,{params: {
          "courseId":id,
          limit,
          isDeleted:false,
          skip,
          sortBy: "createdAt",
          sortOrder: -1
      },
      headers: {
          'Accesstoken': accessToken,
      }});
      return response.data;
  }
  const addTragetCourseNotes=async (payload:any)=>{
    const accessToken=localStorage.getItem("accessToken");
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/addTargetCourseNote`, payload,{
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    setNotesStatusUpdate(response.data);
    return response.data;
  }
  const [showModal,setShowModal]=useState(false);
  const [targetCourseId,setTargetCourseId]=useState("");
  const [statusUpdated,setNotesStatusUpdate]=useState("");
  const { data:notesData, isLoading, isSuccess, error, mutate: addTargetCourseNotesData } = useMutation('targetcoursesnotes', addTragetCourseNotes, {
    onSuccess: () => {
        toast.success("Notes added successfully");
    },
    onError: (error: any) => {
        toast.error(error?.response?.data?.message|| error?.response?.data?.response?.message || "Submission failed");
    },
});

  const addNotes=(id:any)=>{
   setTargetCourseId(id);
   setShowModal(true);
  }

  const { isLoading:isNotesListLoading, data,mutate:getNotesDataList } = useMutation('targetcoursesnoteslist', getTragetCourseNotes);
  	
	useEffect(() => {
		if (data?.response?.count >= 0 && skip === 0) {
			setCurrentPage(1);
			setItemsPerPage(itemsPerPage);
			setTotalItems(data?.response?.count);
		}
	}, [data?.response?.count , skip]);

	useEffect(() => {
		getNotesDataList();
	}, [
		skip,
		getNotesDataList,
		itemsPerPage,
        isSuccess
	]);

  return (
    <>
      <div className="coursesWrapper">
      <div className="d-flex">
            <a
              onClick={() => navigate(-1)}
              className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
            >
              <em className="fas fa-arrow-left"></em>
            </a>
            <h1 className="pageTitle notes-title">Notes</h1>
          </div>
        
        <div className="row">
          <div className="col-4">
            <div className="searchBlock">
              {/* <input onChange={(e) => {
              setSearchTerm(e.target.value)
            }} type="text" className="form-control" placeholder="Search by course name, email or location" /> */}
            </div>
          </div>
          <div className="col-8 mb-2 text-align-right">
          <button
              type="button"
              className="button button-sm button-rounded button-white button-filter fontsize-14 px-3"
              onClick={()=>addNotes(id)}
            >
             Add Notes
            </button>
          </div>
        </div>
        <AddNotesModal
                  showModal={showModal}
                  targetCourseId={targetCourseId}
                  handleClose={() => { setShowModal(false) }}
                  addNote={addTargetCourseNotesData}
                  addNotesStatus={statusUpdated}
        />
        <div className="container-fluid p-0">
          {(data?.response?.count === 0 )&& <IntegolfDataNotFound message={"No notes added"}/>}
          {data?.response.result || !isNotesListLoading ? <TargetCoursesNotesList data={data?.response?.result[0]} />
            :
            <IntegolfLoader show />}
        </div>
      </div>
    </>
  );
};

export default TargetCoursesNotes;
