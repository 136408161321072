import React, { FC, useEffect, useState } from 'react';
import './transactions.scss';
import TransactionsList from './components/transactions-list/transactions-list';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import IntegolfLoader from '../../components/integolf-loader/integolf-loader';
import Filters from './filters/filters';
import { useTransactionFilters } from './transactions.context';
import { usePagination } from '../../contexts/PaginationContext';
import IntegolfDataNotFound from '../../components/integolf-data-not-found/integolf-data-not-found';

interface TransactionsProps { }
const Transactions = () => {
  const [showTab, setShowTab] = useState(1);
  const toggleNavTab = (value: any) => {
    setShowTab(value);
  };
  const [showFilters, setShowFilters] = useState(false);

  const onShowFilter = () => {
    document.body.classList.add("overflow-hidden");
    setShowFilters(true);
  };
  const hideFilterEvent = (value: any) => {
    document.body.classList.remove("overflow-hidden");
    setShowFilters(false);
  };

  const applyFilterEvent = (value: any) => {
    hideFilterEvent(false);
  };
  const {
    skip,
    limit,
    setTotalItems,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage,
    setItemsCountInCurrentPage,
  } = usePagination();

  const {
    courseStatus,
    serchTerm,
    setSearchTerm,
    appliedFilters
  } = useTransactionFilters();
  const getTransactions = async (accessToken: string) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchTransactions`, {
      skip,
      limit,
      status: appliedFilters,
      "sortBy": "createdAt",
      "sortOrder": -1,
      text: serchTerm?.trim()
    }, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };
  const accessToken = localStorage.getItem('accessToken');
  const { isLoading, error, data, mutate } = useMutation('transactions', () => getTransactions(accessToken!));
  useEffect(() => {
    if (data?.response?.count && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
      // setItemsCountInCurrentPage(data?.response?.length ?? 0);
    }

  }, [data?.response?.count, skip]);
  useEffect(() => {
    mutate();
  }, [mutate, skip, appliedFilters,itemsPerPage]);
  useEffect(() => {
    setCurrentPage(1);
  }, [appliedFilters]);
  
  useEffect(()=>{
    if(serchTerm?.trim()?.length>0){
      mutate();
      setCurrentPage(1);
    }
  },[serchTerm])
  return (
    <>
      <div className="transactionsWrapper">
        <h1 className="mb-3 pageTitle">Transactions</h1>
        <div className='row'>
          <div className="col-4">
            <input onChange={(e) => {
              setSearchTerm(e.target.value)
              }} type="text" className="form-control" placeholder="Search by Id, course name, user name" />
          </div>
          <div className="col-8 mb-2 text-align-right">
              <button
                type="button"
                className="button button-sm button-rounded button-white button-filter fontsize-14 px-2"
                onClick={() => onShowFilter()}
              >
                <em className="icon-filter me-2"></em> Filters
              </button>
          </div>
        </div>

        <div className="container-fluid p-0">
        {data?.response?.count === 0 && <IntegolfDataNotFound message={"Transactions not found"}/>}
          {(isLoading) && <IntegolfLoader show />}
          {(showTab === 1 && data?.response?.result) && <TransactionsList data={data.response?.result} />}
        </div>
      </div>
      {showFilters && (
        <div className="asideFilterWrapper">
          <div className="asideFilterInnerWrap">
            <Filters
              showFilterComponent={showFilters}
              handleApplyFilters={applyFilterEvent}
              handleCloseFilterModal={hideFilterEvent}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Transactions;
