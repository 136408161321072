import React, { FC, useEffect, useState } from "react";
import "./courses.scss";
import { NavLink, Outlet } from "react-router-dom";
import Filters from "./components/filters/filters";
import CoursesList from "./components/courses-list/courses-list";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";
import { usePagination } from "../../contexts/PaginationContext";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import { useCoursesFilters } from "./courses.context";
import { IFilterItem } from "../bookings/components/filter-bar/filter-bar";

interface CoursesProps { }

const Courses: FC<CoursesProps> = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [showTab, setShowTab] = useState(1);
  const [viewIntegrationStatus, setViewIntegrationStatus] = useState(false);

  const toggleNavTab = (value: any) => {
    setShowTab(value);
  };

  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    setItemsCountInCurrentPage,
  } = usePagination();

  const {
    parValue,
    slopeValue,
    lengthValue,
    courseHoles,
    courseStatus,
    serchTerm,
    appliedFilters,
    setSearchTerm,
  } = useCoursesFilters();

  const onShowFilter = () => {
    document.body.classList.add("overflow-hidden");
    setShowFilters(true);
  };

  const hideFilterEvent = (value: any) => {
    document.body.classList.remove("overflow-hidden");
    setShowFilters(false);
  };

  //const [searchParams, setSearchParams] = useSearchParams();
  //const [filterItem, setFilterItem] = useState<IFilterItem | undefined>(getFilterItemFromIndex(Number(searchParams.get('filter'))));

  const applyFilterEvent = (value: any) => {
    hideFilterEvent(false);
  };

  const getCourses = async ({ 
    accessToken, 
    parValue,
    slopeValue,
    lengthValue,
    courseHoles,
    courseStatus,
    serchTerm,
    appliedFilters,
    viewIntegrationStatus
   }: any) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchCourse`, {
      skip,
      limit,
      "holes": courseHoles,
      "status": courseStatus,
      "text": serchTerm?.trim(),
      "sortBy": "createdAt",
      "sortOrder":-1,
      "fetchIntegrationStatus":viewIntegrationStatus
    }, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };

  const extractingCSV = async (accessToken: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ADMIN_API_BASEURL}/exportcsv`,
        { collection:"courses" },
        {
          headers: {
            accesstoken: accessToken,
          },
          responseType: 'blob', 
        }
      );
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'courses.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };
  
  const getCourseCount = async (accessToken: string) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/courseCount`, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };
  

  const accessToken = localStorage.getItem('accessToken');
  const { isLoading, error, data, mutate: getCoursesData } = useMutation('courses', () => getCourses({
    accessToken: accessToken!,
    parValue,
    slopeValue,
    lengthValue,
    courseHoles,
    courseStatus,
    serchTerm,
    appliedFilters,
    viewIntegrationStatus,
  }));
  const { mutate:coursesListCSV } = useMutation('userscsvlist', () => extractingCSV(accessToken!));
  useEffect(() => {
    if (data?.response?.count >= 0 && skip ===0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
      // setItemsCountInCurrentPage(data?.response?.length ?? 0);
    }

  }, [data?.response?.count, skip]);

  const exportToCSV = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    coursesListCSV()
  }
  useEffect(() => {
    getCoursesData();
  }, [
    skip,
    getCoursesData,
    //getCount,
    parValue,
    slopeValue,
    lengthValue,
    appliedFilters,
    viewIntegrationStatus,
    itemsPerPage
  ]
  );

  useEffect(() => {
    if(serchTerm?.trim()?.length>0){
      setCurrentPage(1);
      getCoursesData();
    }
  }, [serchTerm])

  return (
    <>
      <div className="coursesWrapper">
        <h1 className="mb-3 pageTitle">Courses</h1>
        <div className="row align-items-center">
          <div className="col-md-4">
            <div className="searchBlock">
            <input onChange={(e) => {
              setSearchTerm(e.target.value)
            }} type="text" className="form-control" placeholder="Search by course name, email or location" />
            </div>
          </div>
          <div className="col-md-8 mb-2 text-align-right">
            <button
              type="button"
              className="button button-sm button-rounded button-white button-filter fontsize-14 px-2 me-2"
              onClick={(e: any) => exportToCSV(e)}
            >
              Export <em className='fa-solid fa-file-csv'></em>
            </button>

            <button
                type="button"
                className="button button-sm button-rounded button-white button-filter fontsize-14 px-3"
                onClick={() => onShowFilter()}
              >
                <em className="icon-filter me-2"></em> Filters
              </button>
          </div>
        </div>
      
        <div className="container-fluid p-0">
          {data?.response?.count === 0 && <IntegolfDataNotFound message={"Courses not found"}/>}
          {data?.response ? <CoursesList data={data?.response?.result} setViewIntegrationStatus={setViewIntegrationStatus} viewIntegrationStatus={viewIntegrationStatus}/>
            :
            <IntegolfLoader show />}
        </div>
      </div>
      {showFilters && (
        <div className="asideFilterWrapper">
          <div className="asideFilterInnerWrap">
            <Filters
              showFilterComponent={showFilters}
              handleApplyFilters={applyFilterEvent}
              handleCloseFilterModal={hideFilterEvent}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Courses;
