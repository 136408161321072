import { useEffect, useState } from "react";
import ModalContainer from "../../../../components/Modal";

export default ({ showModal, setShowModal, actionHandler, title, desc }: any) => {
    return (
        <>
            <ModalContainer
                action={() => {actionHandler(); setShowModal(false);}}
                onHide={() => setShowModal(false)}
                title= {title || 'Resend Confirmation'}
                desc={desc || `Are you sure you want to resend '${actionHandler?.args?.[0]?.text}' email to '${actionHandler?.args?.[0]?.email}' for '${actionHandler?.args?.[0]?.portal}' portal?`}
                showModal={showModal} />
        </>

    );

}