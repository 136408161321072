import React, { FC, ReactElement, useEffect, useState } from "react";
import "./booking-query.scss";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";
import PaginationProvider, { usePagination } from "../../contexts/PaginationContext";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import BookingQueryList from "./booking-query-list/booking-query-list";

interface BookingQueryProps{

}
const BookingQueriesComponent: FC<BookingQueryProps> = (props) => {
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    totalPages,
    setItemsCountInCurrentPage
  } = usePagination();


  const getContactUsRequests = async (accessToken: string) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/contactrequests`, {
        params:{
            skip,
            limit,
            "sortBy": "createdAt",
            "sortOrder":-1,
            "isDeleted":false,
            "type":"bookingInquiry"
        },
        headers:{
          'Accesstoken': `${accessToken}`,
        }
    });
    return response.data;
  };

  const accessToken = localStorage.getItem('accessToken');
  const { isLoading, error, data, mutate: getContactRequests } = useMutation('contactusrequest', () => getContactUsRequests(accessToken!));
  useEffect(() => {
    if (data?.response?.count >= 0) {
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
    }
  }, [data?.response?.count, skip, setItemsPerPage, setTotalItems, setCurrentPage, totalPages]);
 
  useEffect(()=>{
    getContactRequests()
  },[getContactRequests,itemsPerPage,skip])

  return (
    <>
      <div className="bookingsWrapper">
        <h1 className="mb-3 pageTitle">{'Booking Queries'}</h1>
        <div className="row">
        </div>

        <div className="container-fluid p-0">
        
          {data?.response?.count ===0 && <IntegolfDataNotFound message={"No Bookings Queries found"}/>}

          {data?.response ? 
          <BookingQueryList data={data?.response?.result} />
          :
          <IntegolfLoader show/>}
         
        </div>
      </div>
    </>
  );
};

export default BookingQueriesComponent;
