import  { FC, useEffect } from "react";
import "./salestargetnotes.scss";
import { useMutation } from "react-query";
import axios from "axios";
import IntegolfDataNotFound from "../../../components/integolf-data-not-found/integolf-data-not-found";
import { usePagination } from "../../../contexts/PaginationContext";
import IntegolfLoader from "../../../components/integolf-loader/integolf-loader";
import { useNavigate, useParams } from "react-router";
import NotesList from "./noteslist/noteslist";
interface SalesTargetNotesProps { }

const SalesTargetNotes: FC<SalesTargetNotesProps> = () => {
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage
  } = usePagination();

  const navigate=useNavigate();
  const { id } = useParams();
  const getTragetCourseNotes = async () => {
      const accessToken = localStorage.getItem("accessToken") as string;
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchTargetCoursesNotes`,{params: {
          "courseId":id,
          limit,
          isDeleted:false,
          skip,
          sortBy: "createdAt",
          sortOrder: -1
      },
      headers: {
          'Accesstoken': accessToken,
      }});
      return response.data;
  }

  const { isLoading:isNotesListLoading, data,mutate:getNotesDataList } = useMutation('salestargetnoteslist', getTragetCourseNotes);
  	
	useEffect(() => {
		if (data?.response?.count >= 0 && skip === 0) {
			setCurrentPage(1);
			setItemsPerPage(itemsPerPage);
			setTotalItems(data?.response?.count);
		}
	}, [data?.response?.count , skip]);

	useEffect(() => {
		getNotesDataList();
	}, [
		skip,
		getNotesDataList,
		itemsPerPage
	]);

  return (
    <>
      <div className="coursesWrapper">
      <div className="d-flex">
            <a
              onClick={() => navigate(-1)}
              className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
            >
              <em className="fas fa-arrow-left"></em>
            </a>
            <h1 className="pageTitle notes-title">Notes</h1>
          </div>
        
        <div className="row">
        </div>

        <div className="container-fluid p-0">
          {(data?.response?.count === 0 )&& <IntegolfDataNotFound message={"No notes added"}/>}
          {data?.response.result || !isNotesListLoading ? <NotesList data={data?.response?.result[0]} />
            :
            <IntegolfLoader show />}
        </div>
      </div>
    </>
  );
};

export default SalesTargetNotes;
