import React, { createContext, useContext, useLayoutEffect, useState } from 'react';

interface FilterContextType {
  courseStatus:any;
  setCoursesStatus: any;
  serchTerm: any;
  setSearchTerm:any;
  setAppliedFilters:any;
  appliedFilters:any
}

const FilterContext = createContext<FilterContextType | undefined>(
  undefined
);

export const useUserFilters = (): FilterContextType => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('usePagination must be used within a PaginationProvider');
  }
  return context;
};

interface ContextProviderProps {
  children: React.ReactNode;
}

const FilterProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [courseStatus, setCoursesStatus] = useState<any>([1,2]);
  const [serchTerm, setSearchTerm] = useState('');
  const [appliedFilters,setAppliedFilters]=useState<any>([1,2])

  const value: FilterContextType = {
    courseStatus,
    setCoursesStatus,
    serchTerm,
    setSearchTerm,
    setAppliedFilters,
    appliedFilters
  };

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;