import React, { useEffect } from 'react';
import { usePagination } from '../../contexts/PaginationContext';

import './index.scss';

interface IPaginationProps {
  onNext?: (pageIndex: number) => void;
  onPrev?: (pageIndex: number) => void;
}

const Pagination: React.FC<IPaginationProps> = (props) => {
  const { 
    currentPage, 
    totalPages, 
    nextPage, 
    previousPage, 
    totalItems, 
    limit, 
    ItemsCountInCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    availableLimits,
    setCurrentPage,
  } = usePagination();

  const firstRecordNumber = (currentPage - 1) * limit + 1;
  const lastRecordNumber = Math.min(currentPage * limit, totalItems);
  const isRecordNumbersInvalid = firstRecordNumber > lastRecordNumber;
  const isTotalRecordsExceed = (currentPage * limit) > totalItems;
  const maxRecordsPerPage = totalItems - firstRecordNumber + 1;
  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLimit = parseInt(e.target.value, 10);
    setItemsPerPage(selectedLimit);
  };

  return (
    <>
    {/* <div className="col-auto">
              <h6 className="mb-0">
                Showing <strong>{firstRecordNumber}</strong> to <strong>{lastRecordNumber}</strong> of{" "}
                <strong>{totalItems}</strong> Results
              </h6>
            </div> */}
    {/* <div className="flex-row"> */}
      <div className="pagination-container gutters-5">
        <div>
          <h5 className='pagination-text-container'>Showing <b>{firstRecordNumber}</b> to <b>{lastRecordNumber}</b> of <b>{totalItems}</b> results</h5>
          <div className="col-auto">
            <select
              className="form-select"
              onChange={handleItemsPerPageChange}
              value={itemsPerPage}
            >
              {availableLimits.map((limit: any, index: any) => (
                <option 
                  value={limit} 
                  key={index} 
                  disabled={limit > maxRecordsPerPage && currentPage !== 1}
                >
                  {limit}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='pagination-button-container'>
          <div className="col-auto">
            <button 
              disabled={currentPage === 1} 
              onClick={
                () => {
                  props.onPrev?.(currentPage);
                  previousPage();
                }
              } 
              type="button" 
              className="button button-sm fw600 px-3"
            >
              Previous
            </button>
          </div>
          <div className="col-auto">
            <button 
              disabled={currentPage === totalPages} 
              onClick={
                () => {
                  props.onNext?.(currentPage);
                  nextPage();
                }
              } 
              type="button" 
              className="button button-sm fw600 px-3"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    {/* </div> */}
    </>
  )
};

export default Pagination;
