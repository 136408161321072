import React, { createContext, useContext, useLayoutEffect, useState } from 'react';

interface FilterContextType {
  courseStatus:any;
  setCoursesStatus: any;
  serchTerm: any;
  setSearchTerm:any;
  setRoleStatus:any;
  roleStatus:any;
  filtersApplied:any;
  setFiltersApplied:any
}

const FilterContext = createContext<FilterContextType | undefined>(
  undefined
);

export const useUserFilters = (): FilterContextType => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('usePagination must be used within a PaginationProvider');
  }
  return context;
};

interface ContextProviderProps {
  children: React.ReactNode;
}

const FilterProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [courseStatus, setCoursesStatus] = useState<any>([1,2]);
  const [roleStatus, setRoleStatus] = useState<any>({"guest":true,"thirdPartyMerchant":true,"user":true,"hotel":true,"merchant":true,"sales":true,"tournamentCourse":true});
  const [serchTerm, setSearchTerm] = useState('');
  const [filtersApplied,setFiltersApplied]=useState<any>({courseStatus,roleStatus:Object.entries(roleStatus).filter(([key, value]) => value === true).map(([key, value]) => key)})


  const value: FilterContextType = {
    courseStatus,
    setCoursesStatus,
    serchTerm,
    roleStatus,
    setRoleStatus,
    setSearchTerm,
    filtersApplied,
    setFiltersApplied
  };

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;