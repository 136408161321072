import './bank-accounts-list.scss';
import { DateTime } from "luxon";
import Pagination from "../../../../components/Pagination";
import { usePagination } from '../../../../contexts/PaginationContext';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { decodeJwt, decryptData } from '../../../../utils/common';

const BankAccountsList = ({ data, }: { data?: any}) => {
  const { currentPage, totalItems,itemsPerPage } = usePagination();
  const appConfig = async () => {
    const accessToken:any=localStorage.getItem("accessToken")
    const response = await axios.get(
      `${process.env.REACT_APP_ADMIN_API_BASEURL}/config`,{
        headers: {
          Accesstoken: accessToken,
        },
      }
    );
    return response.data;
  };

  const { data: configData, refetch: fetchAppConfig } = useQuery(
    "config",
    ()=>appConfig()
  );

const appConfigData=decodeJwt(configData?.response);

  const getStatusDetail = (status: number) => {
    switch (status) {
      case 1:
        return {
          label: 'Active',
          class: 'status--active'
        }
      case 2:
        return {
          label: 'Inactive',
          class: 'status--danger'
        }
      default:
        return {
          label: 'Completed',
          class: 'status--success'
        }
    }
  }
  return (
    <>
      <div className="userslist mt-3" >
        <div className="tableLayout">
          <div className="table-responsive">
            <table className="table mb-0" >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Account Holder Name</th>
                  <th>Bank Name</th>
                  <th>Account Number</th>
                  <th>BIC/SWIFT Code</th>
                  <th>Interac Id</th>
                  <th>Added On</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 && data?.map((item: any, j: number) => {
                  const { class: cssClass, label } = getStatusDetail(item?.status);
                  return (
                    <>
                      <tr key={j}>
                      <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                        <td>{item?.type?.trim()?.length > 0 ? item?.type?.trim()==="BANK_TRANSFER" ? "Bank Transfer" : "Interac" : "n/a"}</td>
                        <td className='text-capitalize'>{item?.accountHolderName?.trim()?.length > 0 ? item?.accountHolderName?.trim() : "n/a"}</td>
                        <td className='text-capitalize'>{item?.bankName?.trim()?.length > 0 ? item?.bankName?.trim() : "n/a"}</td>
                        <td>{item?.accountNumber && appConfigData ? decryptData(item?.accountNumber,appConfigData?.encryptionSecretKey) : "n/a" }</td>
                        <td>{item?.bicSwiftCode?.trim()?.length > 0 ? item?.bicSwiftCode?.trim() : "n/a"}</td>
                        <td>
                          {item?.interactId?.trim()?.length > 0 ? item?.interactId?.trim() : "n/a"}
                        </td>
                      
                        <td>{`${DateTime.fromMillis(item?.createdAt).toFormat(
                          "hh:mm a, EEE dd MMM yyyy"
                        )}`}</td>
                        <td>  <label className={`mb-0 status ${cssClass}`}>
                            {label}
                          </label></td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
            <div className="paginationBlock">
            <div className="row gutters-5 align-items-center justify-content-between">
              {totalItems > 10 && <Pagination />}
            </div>
          </div>
          </div>

        </div>
      </div>
    </>

  );
};

export default BankAccountsList;
