import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import FilterProvider from './bookings.context';
import BookingsComponent, { BookingsType } from './bookings';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const Bookings = ({type, title}: {type?: BookingsType, title?: string}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    return (
        // Note: Pagination provider should always be on top, as we filter out the results before hand!
        <PaginationProvider startPage={ searchParams.has('page') ? Number(searchParams.get('page')) : undefined }>
            <FilterProvider>
                <BookingsComponent type={type} title={title}/> 
            </FilterProvider>
        </PaginationProvider>
    )
}

export const ThirdPartyBookings = () => {
    return (
        <Bookings type={BookingsType.THIRD_PARTY} title='Tourism Bookings'/>
    );
}


export const UserBookings = () => {
    return (
        <Bookings type={BookingsType.USER} title='Discounted Bookings' />
    );
}
  

export default Bookings;