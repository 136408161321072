import React, { FC, useEffect } from "react";
import "./course-review.scss";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router";
import PaginationProvider, { usePagination } from "../../../../contexts/PaginationContext";
import { useMutation } from "react-query";
import Pagination from "../../../../components/Pagination";
import IntegolfLoader from "../../../../components/integolf-loader/integolf-loader";
import dayjs from "dayjs";
import IntegolfDataNotFound from "../../../../components/integolf-data-not-found/integolf-data-not-found";

interface CourseReviewProps { }

const getFormattedDate = (unixTimeStamp: any) => {
  let date = new Date(unixTimeStamp);
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();

  return `${day}/${month}/${year}`;
}

const CourseReview: FC<CourseReviewProps> = () => {

  const {
    skip,
    limit,
    totalItems,
    setTotalItems,
    currentPage,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    totalPages,
    setItemsCountInCurrentPage
  } = usePagination();

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();


  const accessToken: string = localStorage.getItem('accessToken') ?? 'err';

  const getCourseReviews = async (accessToken: string) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchCourseReviews`, {
      params: {
        "courseId": id,
        limit,
        skip,
        "sortBy": "reviewDate",
        "sortOrder": -1
      },
      headers: {
        "Accesstoken": accessToken
      }
    });
    return response.data;
  }

  const { isLoading, error, data, mutate: getReviews } = useMutation('courseReviews', () => getCourseReviews(accessToken))

  useEffect(() => {
    getReviews();
  }, [
    id,
    getReviews,
  ]);

  useEffect(() => {
    if (data?.response?.count >= 0 && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
    }

  }, [data?.response?.count, skip]);

  useEffect(() => {
    getReviews();
  }, [
    skip,
    getReviews,
    itemsPerPage
  ]
  );

  const reviews = data?.response?.result;

  const filteredReviews = Array.isArray(reviews) ? reviews.filter((review) => review._id === id) : [];

  const reviewCount = data?.response?.count;


  return (
    <>
    {reviewCount=== 0&&<IntegolfDataNotFound message={"No Reviews found"}/>}
      {isLoading ? <IntegolfLoader show />  : reviewCount > 0 && <div className="courseReviewWrapper">
        {reviews?.map((e: any, j: number) => (
          <div className="courseReviewWrapper__reviewItem">
            <div className="row gutters-5">
              <div className="col-auto">
                <div className="rImgBlock">
                  <img
                    src={e?.addedBy?.profileImage?.path ?? "../../assets/images/default-user.png"}
                    alt="User Profile Picture"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col mt-1">
                <div className="row gutters-5 align-items-center">
                  <div className="col">
                    <h5 className=" text-capitalize">{e?.addedBy?.firstname ? e?.addedBy?.firstname + " " + e?.addedBy?.lastname : "Guest"}</h5>
                    {/* <h6 className="mb-2">{getFormattedDate(e?.createdAt)}</h6> */}
                  </div>
                  <div className="col-auto">
                    <div className="_rating">
                      {Array.from({ length: Math.floor(e?.rating) }).map((_, index) => (
                        <em key={index} className="fas fa-star me-1 rating-stars"></em>
                      ))} <span >{e?.rating}</span>
                    </div>
                  </div>
                </div>
                <p className="mb-0">
                  {e?.review}
                </p>
              </div>
            </div>
          </div>
        ))}

        <div className="tableLayout">
          <div className="paginationBlock">
            <div className="row gutters-5 align-items-center justify-content-between">
            {totalItems > 10 && <Pagination />}
            </div>
          </div>
        </div>
      </div>}
    </>

  );
};

const CourseReviewWrapper = () => {
  return (
    <PaginationProvider>
      <CourseReview />
    </PaginationProvider>
  );
}

export default CourseReviewWrapper;
