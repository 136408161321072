import React, { FC } from "react";
import "./integolf-loader.scss";

interface IntegolfLoaderProps {
  show: boolean;
}

const IntegolfLoader: FC<IntegolfLoaderProps> = ({ show = false }) => {
  return show ? (
    <>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{alignSelf: 'center'}}  className="loaderInnder">
          <img src={`${process.env.PUBLIC_URL}/assets/images/Loader.png`} alt="loading.." />
          {/* <span>Loading..</span> */}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default IntegolfLoader;
