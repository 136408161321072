import "./PayoutAccountInfoModal.scss";
import React, { FC } from "react";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import { PayoutAccountInfo } from "../../../../../models/payout";
import { INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_INTERACT_ID } from "../../../../../utils/constants";

interface PayoutAccountInfoModalProps {
    showModal: boolean;
    handleClose: (arg: any) => void;
    payoutAccountInfo: PayoutAccountInfo | null;
}

const PayoutAccountInfoModal: FC<PayoutAccountInfoModalProps> = ({
    showModal,
    handleClose,
    payoutAccountInfo
}) => {
    const close = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        handleClose(!showModal);
    };

    return (
        <Modal
            className="payoutAccountInfoModal modalLayoutWrapper"
            show={showModal}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Body className="p-4">
                <button
                    type="button"
                    className="closeButton d-flex align-items-center justify-content-center"
                    onClick={close}
                >
                    <i className="fa-solid fa-xmark" style={{ height: '16px', width: '16px' }}></i>
                </button>
                <div className="formWrap">
                    <div className="text-center mb-4">
                        <h3 className="mb-4 pb-3">Payout Account Information</h3>
                                {!!payoutAccountInfo ? (
                                    payoutAccountInfo.type === INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_INTERACT_ID ? (
                                        <div className="row text-start">
                                            <h6 className="col-12 mb-2">Interac Id:</h6>
                                            <h5 className="col-12 mb-3">{payoutAccountInfo.interactId}</h5>
                                        </div>
                                    ) : (
                                        <div className="row text-start">
                                            <h6 className="col-12 mb-2">Bank Account Holder:</h6>
                                            <h5 className="col-12 mb-3">{payoutAccountInfo.accountHolderName}</h5>
                                            <h6 className="col-12 mb-2">Bank Account No:</h6>
                                            <h5 className="col-12 mb-3">{payoutAccountInfo.maskedAccountNumber}</h5>
                                            <h6 className="col-12 mb-2">BIC/SWIFT:</h6>
                                            <h5 className="col-12 mb-3">{payoutAccountInfo.bicSwiftCode}</h5>
                                        </div>
                                    )
                                ) : (
                                    <h4>No Payout Account info details present for the club</h4>
                                )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PayoutAccountInfoModal;
