import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import FilterProvider from './courses.context';
import Courses from './courses';

export default () => {
    return (
        <PaginationProvider>
            <FilterProvider>
                <Courses/>
            </FilterProvider>
        </PaginationProvider>
    )
}