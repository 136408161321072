
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import DatePicker from "react-datepicker";
import { DateTime } from 'luxon';
import "react-datepicker/dist/react-datepicker.css";
import "./weather-discount-modal.scss";
import { capitalizeString, disableNumWheelBehaviour } from '../../../../utils/common';

type PopUpProps = {
    show:boolean,
    setShow:Function,
    submitHandler:any,
    data:any,
    selectedDate:Date
}
function WeatherDiscountPopup({show, setShow, data, submitHandler, selectedDate}:PopUpProps) {

  const handleClose = () => setShow(false);
  const [date, setDate] = useState(selectedDate);
  const [discount, setDiscount] = useState<number|null>();
  const [discountError, setDiscountError] = useState<string>("")

  return (
    <>

      <Modal className="updateInfoModalWrapper3"  show={show} onHide={handleClose} centered >
      <Modal.Body>
        <a
          href={void 0}
          className="closeButton"
          onClick={handleClose}
        >
          <em className="fa-solid fa-close"></em>
        </a>
        <div className="formWrap">
          {(
            <div className="formBlock">
              <form onSubmit={ (e)=>{
                  e.preventDefault();
                  if(!discount || discount <=0) setDiscountError("Enter valid discount")
                  else{
                    submitHandler({clubId:data?.club?._id, courseId:data?.course?._id, date:DateTime.fromJSDate(date).toFormat("yyyy-MM-dd"), discount})
                  }
                }}>
                <div className="row gutters-10">
                  <div className="col-12 col-sm mb-4">
                      <h4 className="mb-2">Add Bad Weather Discount</h4>
                      <h6 className="mb-2">{capitalizeString(data?.name??"")}</h6><br/>

                      {/* <div style={{display:"flex",justifyContent: "center", flexDirection:"column", alignItems:"center"}}> */}
                          
                          <div>
                          <label htmlFor="fromDate">Date</label><br/>

                            <DatePicker
                              className='form-control form-control-solid w-250px control-lg'
                              selected={date}
                              minDate={new Date()}
                              onChange={(date:Date)=>setDate(date)}
                              disabled={true}
                            />
                          </div><br/>
                          <div>
                          <label htmlFor="fromDate">Discount (%)</label><br/>

                          <input
                                type="number"
                                className="form-control control-lg"
                                placeholder="Discount"
                                value={discount ?? ""}
                                step={1}
                                max={100}
                                onWheel={disableNumWheelBehaviour}
                                onChange={(e)=>{setDiscount(Number(e.target.value)>0 ? Math.trunc(Number(e.target.value)): null); (discountError) && setDiscountError("")}}
                          />
                          {discountError && <div className="isInvalidMessage text-danger">{discountError}</div>}
                          </div>

                      {/* </div> */}
                      
                      
                  </div>
                 
                </div>
                <div style={{display:"flex", justifyContent:"center"}}>
                      <button
                        type="submit"
                        className="mt-3 button button-primary button-rounded button-min-150 button-sm fontsize-16 fw700 mb-2"
                      >
                        Submit
                      </button>
                </div>
                
              </form>
            </div>
          )}
        </div>
      </Modal.Body>
      </Modal>
    </>
  );
}

export default WeatherDiscountPopup;