import React, { FC } from 'react';
import './user-list.scss';
import dayjs from 'dayjs';
import Pagination from '../../../../components/Pagination';
import { usePagination } from '../../../../contexts/PaginationContext';
import UserStatusActionButton from './user-status-action-button';
import { useNavigate } from 'react-router';


interface UserListProps { }
const getStatusDetail = (status: number) => {
  switch (status) {
    case 1:
      return {
        label: 'Active',
        class: 'status--active'
      }
    default:
      return {
        label: 'Inactive',
        class: 'status--danger'
      }
  }
}



const getUserRole = (role: string) => {
  switch (role) {
    case "user":
      return 'Golfer'
    case "merchant":
      return 'Course'
    case "guest":
      return "Guest"
    case "thirdPartyMerchant":
      return "Tourism"
    case "hotel":
        return "Hotel";
    case "tournamentCourse":
      return "Tournament Course";
    case "sales":
    return "Sales";
    default:
      return "Admin";
  }
}

const UserList = ({
  updatedStatusData,
  markUserActiveInactive,
  data,
}: any) => {
  const navigate=useNavigate();
  const getBankDetails=(userId:any)=>{
    navigate(`/users/bankaccounts/${userId}`)
  }
  const { currentPage, totalItems,itemsPerPage } = usePagination();
  return (
    <>
    { totalItems > 0 && <div className="userslist" >
      <div className="tableLayout">
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Phone No.</th>
                <th>Status</th>
                <th>Reg Date</th>
                <th>Last Login</th>
                <th>Change Status</th>
                <th>Bank Details</th>
                <th className="width-30">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((e: any, j: number) => {
                const { label, class: cssClass } = getStatusDetail(e.status);
                const role = getUserRole(e.role)
                return (
                  <>
                    <tr>
                      <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                      <td>
                        <div className="userContent align-items-center">
                          <span className="imgBlock rounded-circle">
                            <img
                              src={e?.profileImage?.path ?? "../../assets/images/default-user.png"}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          <div className="content">
                            <h5 className="mb-0">{`${e.firstname ? e.firstname : 'Guest user'} ${(e?.lastname) ? e?.lastname : ''}`}</h5>
                          </div>
                        </div>
                      </td>
                      <td>{e.email ? e.email : 'n/a'}</td>
                      <td>{role}</td>
                      <td>{e?.phone?.countryCode ? `+${e?.phone?.countryCode} ${e?.phone?.number}` : 'n/a'}</td>
                      {!e.isDeleted && <td>
                        <label className={`mb-0 status ${cssClass}`}>
                          {label}
                        </label>
                      </td>}
                      {e.isDeleted && <td>
                        <label className={`mb-0 status status--danger`}>
                          Deleted
                        </label>
                      </td>}
                      <td>{dayjs(e.createdAt).format('DD MMM YYYY hh:mm a')}</td>
                      <td>{e?.lastLogin  ? dayjs(e.lastLogin).format('DD MMM YYYY hh:mm a'): '-'}</td>
                      <td>
                        {
                          (e?.status == 1 || e?.status == 2) && !e.isDeleted && e?.role!== 'guest' ?
                            <UserStatusActionButton
                              userStatus={e.status}
                              id={e._id} 
                              markUserActiveInactive={markUserActiveInactive}
                              updatedStatusData={updatedStatusData}
                              />
                            :
                            <label  className={`mb-0 status`}>
                             n/a
                            </label>
                        }
                      </td>
                      <td>
                      {role?.toLowerCase()?.trim()==="course" && <button
                              className="button button-primary button-rounded button-sm fontsize-14 fw500 px-3 bankLinkBtn button-min-150"
                              onClick={() => getBankDetails(e?._id)}
                            >
                              Bank Details
                        </button>}
                      </td>
                      <td>
                        <div className="listActionDropdownBlock">
                          {/* <Dropdown align="end">
                          <div className="text-center">
                            <Dropdown.Toggle className="toggleButton">
                              <em className="fa-solid fa-ellipsis-vertical"></em>
                            </Dropdown.Toggle>
                          </div>
                          <Dropdown.Menu>
                            <NavLink to={"/users/edit"} className="dropdown-item fw600">
                              View User
                            </NavLink>
                          </Dropdown.Menu>
                        </Dropdown> */}
                        </div>
                      </td>
                    </tr>
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="paginationBlock">
          <div className="row gutters-5 align-items-center justify-content-between">
            {totalItems > 10 && <Pagination />}
            {/* <div className="col-auto">
              <h6 className="mb-0">
                Showing <strong>1</strong> to <strong>10</strong> of{" "}
                <strong>100</strong> Results
              </h6>
            </div>
            <div className="col-auto">
              <div className="row gutters-5">
                <div className="col-auto">
                  <button type="button" className="button button-sm fw600 px-3">
                    Previous
                  </button>
                </div>
                <div className="col-auto">
                  <button type="button" className="button button-sm fw600 px-3">
                    Next
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};

export default UserList;
