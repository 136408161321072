import React, { FC, useEffect, useState } from 'react';
import './payouts-detail.scss';
import PayoutList from './components/payout-list/payout-list';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import IntegolfLoader from '../../components/integolf-loader/integolf-loader';
import { useLocation, useNavigate } from 'react-router';
import Filters from './components/filters/filters';
import { usePagination } from '../../contexts/PaginationContext';
import { useTransactionFilters } from './payouts-detail.context';

interface TransactionsProps {}
const Transactions = () => {
  const [showTab, setShowTab] = useState(1);
  const toggleNavTab = (value: any) => {
    setShowTab(value);
  };
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState(false);
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    setItemsCountInCurrentPage,
  } = usePagination();
  const onShowFilter = () => {
    document.body.classList.add("overflow-hidden");
    setShowFilters(true);
  };
  const hideFilterEvent = (value: any) => {
    document.body.classList.remove("overflow-hidden");
    setShowFilters(false);
  };

  const applyFilterEvent = (value: any) => {
    hideFilterEvent(false);
  };
  const location = useLocation();
  const {id} = location.state;
  const {
    courseStatus,
    appliedFilters
  } = useTransactionFilters();
  const getTrancsactions = async (accessToken: string) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchPayoutBookings`,{
      id,
      skip,
      limit,
      status: appliedFilters,
    }, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };
  const accessToken = localStorage.getItem('accessToken');
  const { isLoading: trasactionLoading, data: transactionData, mutate:getTransactionDetails } = useMutation('payouts-trasactions-details', () => getTrancsactions(accessToken!));
  useEffect(() => {
    if (transactionData?.response?.count && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(transactionData?.response?.count);
      // setItemsCountInCurrentPage(data?.response?.length ?? 0);
    }

  }, [transactionData?.response?.count, skip]);
  useEffect(() => {
    getTransactionDetails()
  }, [getTransactionDetails, skip, appliedFilters])
  useEffect(() => {
    setCurrentPage(1);
  }, [appliedFilters]);
  useEffect(() => {
    getTransactionDetails();
  }, [
    skip,
    itemsPerPage
  ]
  );
  return (
    <>
      <div className="transactionsWrapper">
      <div className="row align-items-center mb-3">
          <div className="col-auto">
            <a
              onClick={() => navigate(-1)}
              className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
            >
              <em className="fas fa-arrow-left"></em>
            </a>
          </div>
          {/* <div className="col">
            <nav className="breadcrumbWrap" aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <NavLink to={"/courses"}>Courses </NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <span>Course Detail</span>
                </li>
              </ol>
            </nav>
          </div> */}
        </div>
        <h1 className="mb-3 pageTitle">Payout Detail</h1>
        <div className="container-fluid p-0">
          <div className="row justify-content-between mb-2">
            <div className="col-12 col-lg">

            </div>
            <div className="col-12 col-lg-auto">
              <button
                type="button"
                className="button button-sm button-rounded button-white button-filter fontsize-14 px-3"
                onClick={() => onShowFilter()}
              >
                <em className="icon-filter me-2"></em> Filters
              </button>
            </div>
          </div>
          {(trasactionLoading) ? <IntegolfLoader show/>:
          <PayoutList data={transactionData?.response?.result} />
          }
        </div>
      </div>
      {showFilters && (
        <div className="asideFilterWrapper">
          <div className="asideFilterInnerWrap">
            <Filters
              showFilterComponent={showFilters}
              handleApplyFilters={applyFilterEvent}
              handleCloseFilterModal={hideFilterEvent}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Transactions;
