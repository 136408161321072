import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { authActions } from './authSlice';
import { SignupPayload } from '../../models/auth';
import httpClient from "../../api/httpClient";
import { toast } from "react-toastify";

function* handleSignupLogin(action: PayloadAction<SignupPayload>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/users`,
  });

  if (error) {
    yield put(authActions.signupLoginFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(authActions.signupLoginSuccess())
  }
  return { error, result };
}



export function* authSaga() {
  yield takeLatest(authActions.signupLogin.type, handleSignupLogin);
}
