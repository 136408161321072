import React, { FC } from "react";
import "./tournaments-list.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";

import dayjs from "dayjs";
import { DateTime } from "luxon";
import Pagination from "../../../components/Pagination";
import { usePagination } from "../../../contexts/PaginationContext";


const TournamentsList = ({ data }: { data: any }) => {
  const navigate=useNavigate();
  const { currentPage, totalItems, itemsPerPage } = usePagination();
  const [searchParams, setSearchParams] = useSearchParams();


  return (
    <>
   {data?.length>0 && 
    <div className="courseslistWrapper">
      <div className="tableLayout">
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Course Name</th>
                <th>Type</th>
                {/* <th>Added By</th> */}
                <th>Total Weeks</th>
                <th>Maximum Golfers</th>
                <th>Member Price</th>
                <th>Added On</th>
                <th className="width-40">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
            {data?.map((e: any, j: number) => {
              //  const paymentTypes=getPaymentsTypesInfo(e?.PaymentTypes ?? [])
                  return (
                    <>
                      <tr key={'key_' + j}>
                        <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='userContent tournament-title'>
                              <div className='content text-capitalize'>
                                <h5 className='mb-1 cursor-pointer' onClick={()=>navigate(`/tournaments/${e?._id}/detail`)} >
                                  {e?.TournamentName ? e?.TournamentName : 'n/a'}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{dayjs(e?.StartDateISO).format('DD MMM YYYY')}</td>
                        <td>{dayjs(e?.EndDateISO).format('DD MMM YYYY')}</td>
                        <td className="text-capitalize">{e?.tournamentCourse?.CourseName?.trim() ?? "n/a" }</td>
                        <td> {e?.League ? "League" : "Tournament"}</td>
                        {/* <td>{e?.tournamentCourse?.Email}</td> */}
                        <td>{e?.League && e?.totalWeeks ? e?.totalWeeks :  'n/a'}</td>
                        <td>{e?.MaxGolfers ?? 0}</td>
                        <td>$&nbsp;{e?.Price ? Number(e?.Price)?.toFixed(2) : 0.00}</td>
                        <td>{dayjs(e?.createdAt).format('DD MMM YYYY')}</td>
                        <td>
                          <div className="listActionDropdownBlock">
                            <Dropdown align="end">
                              <div className="text-center">
                                <Dropdown.Toggle className="toggleButton">
                                  <em className="fa-solid fa-ellipsis-vertical"></em>
                                </Dropdown.Toggle>
                              </div>
                              <Dropdown.Menu>
                                <NavLink to={`/tournaments/${e?._id}/detail`} className="dropdown-item fw600">
                                  View
                                </NavLink>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                })}
            </tbody>
          </table>
        </div>
        <div className="paginationBlock">
          <div className="row gutters-5 align-items-center justify-content-between">
            {
              totalItems > 10 && 
              <Pagination />
            }
          </div>
        </div>
      </div>
    </div>
}
</>
  );
};

export default TournamentsList;
