import React, { FC, useEffect, useState } from 'react';
import './accommodation-detail.scss';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { DateTime } from 'luxon';
import dayjs from 'dayjs';
import { access } from 'fs';

import UnavailabilityTable from "./UnavailabilityTable";

interface AccommodationDetailProps {}

const AccommodationDetail: FC<AccommodationDetailProps> = () => {
  // useEffect(() => {
  //   const elem: any = document.getElementById('headerLayout');
  //   console.log("Here is elem: " + elem.outerHTML);
  //   elem.className = 'd-none';
  //   return () => {
  //     elem.className.remove('d-none');
  //   };
  // }, []);

  const [fullDescription, setFullDescription] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const {id} = location.state;
  const getAccommodationDetail = async (accessToken: any) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchAccomdations/${id}`, {
      headers: {
        'Accesstoken': accessToken,
      },
    });
    return response.data;
  }

  const accessToken = localStorage.getItem('accessToken');
  const { isLoading, error, data, mutate: getDetails } = useMutation('accommodationDetails', () => getAccommodationDetail(accessToken));


  useEffect(() => {
    getDetails();
  }, [
    id,
    getDetails,
  ]);
  const setDescriptionToggle = () => {
    setFullDescription(!fullDescription);
  }

  const fixTime = (num: any) => {
    if (!num) return '00:00';
    const [hours, minutes] = num.split(':');

    const parsedHrs = parseInt(hours, 10);
    const parsedMins = parseInt(minutes || '0', 10);

    const fixedHrs = parsedHrs.toString().padStart(2, '0');
    const fixedMins = parsedMins.toString().padStart(2, '0');

    return `${fixedHrs}:${fixedMins}`;
  }

  const accommodationDetails = data?.response?.result[0];

  return (
    <div className='accommodationDetail'>
      <div className='row align-items-center mb-3'>
        <div className='col-auto'>
          <a
            onClick={() => navigate(-1)}
            className='button button-box button-rounded button-sm fontsize-14 commonBackButton'
          >
            <em className='fas fa-arrow-left'></em>
          </a>
        </div>
        <div className='col'>
          <nav className='breadcrumbWrap' aria-label='breadcrumb'>
            <ol className='breadcrumb mb-0'>
              <li className='breadcrumb-item'>
                <NavLink to={'/accommodations'}>Accommodations </NavLink>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                <span>Accommodation Detail</span>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-lg-8'>
          <div className='accommodationDetail__card mb-3'>
            <div className='accommodationDetail__infoHead'>
              <div className='row gutters-10'>
                <div className='col-auto'>
                  <div className='imgBlock'>
                    <img 
                      src={accommodationDetails?.pictures?.[0]?.path ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-siQWshbehfw9lvg5pH16YQ8eYJEgLQbkyQ&usqp=CAU"}
                      alt=''
                      className='img-fluid'
                    />
                  </div>
                </div>
                <div className='col align-self-center'>
                  <h2 className='mb-2 text-capitalize'>{accommodationDetails?.title}</h2>
                  <p className='mb-0 color-600 fw500 text-capitalize'>{accommodationDetails?.formattedName || accommodationDetails?.address?.addressOne}</p>
                </div>
                <div className='col-auto align-self-center'></div>
              </div>
            </div>
            <h5 className="accommodationDetail__cardTitle mb-3">Accommodation Description</h5>
            <p className='accommodation_description'>
              {accommodationDetails?.description ? 
              <>
                {fullDescription && accommodationDetails?.description?.length > 100 ?
                  accommodationDetails?.description.slice(0,101)+"  ...":
                  accommodationDetails?.description
                }
                {accommodationDetails?.description?.length > 100 && <span onClick={setDescriptionToggle} className="read-content-link"><b>{fullDescription ? "Read More":"  Read Less"}</b></span>}
              </>: "n/a"}
            </p>
          </div>

          <div className="courseDetail__card mb-3">
            <h5 className="courseDetail__cardTitle mb-3">Images</h5>
            <div className="courseDetail__imagesGrid">
              {accommodationDetails?.pictures?.map((x: any, i: number) => (
                <div key={i} className="imgBlock">
                  <img
                    src={x.path}
                    alt={'image ' + i}
                    className="img-fluid"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* <div className="courseDetail__card mb-3">
            <h5 className="courseDetail__cardTitle mb-3">Images</h5>
            <div className="courseDetail__imagesGrid">
              {accommodationDetails?.photos?.map((x: any, i: number) => (
                <div key={i} className="imgBlock">
                  <img
                    src={x.path}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              ))}
            </div>
          </div> */}
          <div className='accommodationDetail__card mb-4'>
            <h5 className='accommodationDetail__cardTitle mb-4'>Room Details</h5>
            <div className='accommodationDetail__roomSection'>
              {accommodationDetails?.rooms?.length && accommodationDetails?.rooms?.map((room:any) => 
                <div className='roomDetail__card mb-2'>
                  <div className='roomDetail__card-header'>
                    <h4>{room?.roomType[0]?.name}</h4>
                    <div className='row gutters-5'>
                      <div className='col'>
                        <p>{room?.noOfRooms ?? 'n/a'} Rooms</p>  
                      </div>
                      <div className='col-auto'>
                        <p>
                          <span className='fw500'>Guests:</span> {room?.maxOccupency ?? 'n/a'} Adults  
                        </p>  
                      </div>  
                    </div>
                  </div>
                  <div className='accommodationDetail__ card-body'>
                    <table className='table mb-0'>
                      <thead>
                        <tr>
                          <th>Pricing Type</th>
                          <th>Price/Per Day</th>
                          <th>From/To</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className='fw600'>Default price</td>
                          <td className='fw500'>{room?.price.toFixed(2)}</td>
                          <td className='fw500'>---</td>
                          <td></td>
                          <td></td>
                        </tr>
                        {room?.roompriceranges?.length > 0 && room.roompriceranges.map((roomPriceRange: any) =>
                          <tr>
                            <td className='fw600'>Special Pricing</td>
                            <td className='fw500'>{roomPriceRange?.price?.toFixed(2)}</td>
                            <td className='fw500'>
                              {DateTime.fromFormat(roomPriceRange?.startDate, 'yyyy-MM-dd').toFormat('LLLL dd, yyyy')}
                              -  
                              {DateTime.fromFormat(roomPriceRange?.endDate, 'yyyy-MM-dd').toFormat('LLLL dd, yyyy')}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='accommodationDetail__card mb-3'>
            <h5 className='accommodationDetail__cardTitle mb-4'>Black Out Dates</h5>
            <div className='accommodationDetail__unavailabilitySection'>
              <div className='table-responsive mt-4'>
                <UnavailabilityTable data={accommodationDetails} />
              </div>
            </div>
          </div>

        </div>

        <div className='col-12 col-lg-4'>
          <div className='accommodationDetail__card mb-3'>
            <div className='row gutters-10 accommodationDetail__cardUserData'>
              <div className='col-auto'>
                <span className='userImage'>
                  <img 
                    src='../../assets/images/default-user.png'
                    alt='user image'
                    className='img-fluid'
                  />
                </span>
              </div>
              <div className='col'>
                <h3>{accommodationDetails?.addedBy[0]?.firstname} {accommodationDetails?.addedBy[0]?.lastname}</h3>
                <p className='mb-0'>{accommodationDetails?.addedBy[0]?.email}</p>
                <p className='mb-2'>{accommodationDetails?.addedBy[0]?.phone?.countryCode} {accommodationDetails?.addedBy[0]?.phone?.number}</p>
              </div>
            </div>
          </div>
          <div className='accommodationDetail__card mb-3'>
            <h5 className='accommodationDetail__cardTitle mb-3'>Notes</h5>
            <p className='mb-3 fw400'>{accommodationDetails?.notes || 'n/a'}</p>
          </div>
          <div className='accommodationDetail__card mb-3'>
            <h5 className='accommodationDetail__cardTitle mb-3'>Popular Amenities</h5>
            
            <p className='mb-3 fw400'>
              Check In - {fixTime(accommodationDetails?.checkInTime) || '9:00'} AM
              <br/>
              Check Out - {fixTime(accommodationDetails?.checkOutTime) || '12:00'} PM
            </p>
            <div className="_lablesBlock">
              {/* <label>12 Reviews</label> */}
              <label>
                Pet Friendly:{' '}
                {accommodationDetails?.features?.petFriendly
                  ? 'Yes'
                  : 'No'}
              </label>
            </div>
          </div>
          
        </div>

      </div>
    </div>
  );
};

export default AccommodationDetail;