import React, { ReactElement, useMemo } from 'react'
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CARD_TYPE_MAPPING, getStatusObj } from '../../../../utils/constants';
import dayjs from 'dayjs';
import Itenararies, { ItineraryType } from './itineries.component';
import { InfoCard, formatPhoneNumber } from './booking-details';

import "./booking-details.scss";
import { DateTime } from 'luxon';

// const ItineraryBlock = ({object}: {object: any}) => {
//   return (
//     <div>
//       {object}


//     </div>

//   );
// }

interface IOrderAddress {
  city: string;
  province: string;
  country: string;
  postalCode: string;
}

export interface IItineraryAccommodationOrder {
  // accommodationId: string;
  accomodationId: string;
  data: {
    address: IOrderAddress;
    rooms: any[];
    checkInTime: any;
    checkOutTime: any;
    title: string;
    formattedName: string;
    players: number[];
  }
}

export interface IItineraryTeesheetsOrder {
  teesheets: any[];
  clubId: string;
  isRepeating: boolean;
}

export type ItineraryOrder = (IItineraryAccommodationOrder | IItineraryTeesheetsOrder);

const ItineraryTime = ({ object, lastItem }: { object: any, lastItem: boolean }) => {
  const getFormattedPostalCode = (postalCode?: string) => {
    if (!postalCode) {
      return '';
    }

    const isUnformattedPostalCode = /(\D\d\D)(\d\D\d)/.test(postalCode);
    if (isUnformattedPostalCode) {
      postalCode = `${postalCode.substring(0, 3)} ${postalCode.substring(3, 6)}`;
    }

    return postalCode + ', ';
  }
  const makeAccommodationOrder = (order: any) => {
    const location: IOrderAddress = order?.location?.address;

    return {
      accomodationId: order.accomodationId,
      data: {
        address: order?.location?.address,
        rooms: order?.rooms?.map((room: any) => {
          return {
            ...room,
            roomTypeId: {
              name: room.roomType
            }
          }
        }),
        checkInTime: (order?.checkInDateTime),
        checkOutTime: (order?.checkOutDateTime),
        title: order?.name,
        formattedName: `${getFormattedPostalCode(location?.postalCode)}${location?.city}, ${location?.province}`,
        players: order?.rooms?.reduce((acc: number, item: any) => { return acc + item?.noOfGuest }, 0)
      },
    };
  }

  const makeTeeSheetOrder = (order: any, day: any, index: number): IItineraryTeesheetsOrder => {
    const otherObject = object[day][index];

    const uniqueTees = object[day].filter((data: any) => {
      if (data?.club?._id === otherObject?.club?._id && !data.marked) {
        data.marked = true;
        return true;
      }
      return false;
    });
    // const uniqueTees = object[day].filter((data: any) => {
    //   return (data?.club?._id === otherObject?.club?._id);
    // });

    return {
      teesheets: uniqueTees,
      clubId: otherObject?.club?._id,
      isRepeating: otherObject?.club?._id === object[day]?.[index - 1]?.club?._id
    }
  }

  const getUniqueOrders = (): any[] => {
    let uniqueOrders: any[] = [];
    Object.keys(object).forEach((day: any, index: number) => {
      object[day].forEach((order: any, objectIndex: number) => {
        if (orderMap.has(order.courseId)) {
          return;
        }
        if (!order.accomodationId) {
          orderMap.set(order.courseId, order);
          uniqueOrders.push(order);
        }
        else {
          uniqueOrders.push(order);
        }
      });
    });
    return uniqueOrders;
  }

  const getItineraryType = (order: ItineraryOrder): ItineraryType => {
    if ((order as IItineraryAccommodationOrder).accomodationId) {
      return ItineraryType.ACCOMMODATION;
    }
    return ItineraryType.TEESHEET;
  }

  const lastNestedItem = (idx: number, object: any): boolean => (idx >= (object.length - 1));

  let orderMap = new Map<string, any>();
  const uniqueOrders: any[] = getUniqueOrders().sort((a: any, b: any) => Number(a.checkInDateTime) - Number(b.checkInDateTime));

  return (
    <div className='timelineBlock'>
      {
        Object.keys(object).map((day: any, index: number) => {
          // const objectDay = object.filter((objDay: any) => {
          //   console.log(day)
          //   if (processedOrders.has(day) && processedOrders.get(day) === objDay) {
          //     return false;
          //   }
          //   processedOrders.set(day, objDay);
          //   return true;
          // });

          return (
            <ul key={index} className='timeList'>
              {/* {day} */}
              <h5 className='timeline-date'>{dayjs(day).format('dddd, MMMM D')}</h5>
              {
                uniqueOrders.map((order: any, objectIndex: number): ReactElement => {
                  let orderObject: ItineraryOrder;


                  // if (processedOrders.has(order.courseId)) {
                  //   return <></>;
                  // }


                  if (order.accomodationId) {
                    orderObject = makeAccommodationOrder(order);
                  } else {
                    orderObject = makeTeeSheetOrder(order, day, objectIndex);
                    // processedOrders.set(order.courseId, order);
                  }
                  return (
                    <>
                      <div className='itinerary-container'>
                        <div className='timeline-track'>
                          <div className='itinerary-bullet' />
                          <div className={`timeline-connecting-line ${(objectIndex >= (uniqueOrders.length - 1)) ? 'timeline-connecting-none' : ''}`}></div>
                        </div>

                        <div className='itinerary-time-container'>
                          <Itenararies
                            key={objectIndex}
                            data={{ order: orderObject, type: getItineraryType(orderObject) }}
                            index={objectIndex}
                            day={day}
                          />
                        </div>
                      </div>
                    </>
                  );
                })
              }
            </ul>
          )
        })
      }

      {/* {
        object[day].map((order: any) => {
          return order.type;
        })
      } */}


    </div>
  );
}


function BookingDetailThirdparty({ courseDetails }: any) {
  const status = getStatusObj(courseDetails?.status?.value)
  let user = (courseDetails?.userId) ? courseDetails?.userId : courseDetails?.user;
  let userName = (user?.name) ? user?.name : (user?.firstname) ? `${user?.firstname} ${user?.lastname}` : 'Guest';
  // let userPhone = (courseDetails?.bookingType==1 && courseDetails?.user?.phone)? courseDetails?.user?.phone: (courseDetails?.user?.phone?.phone)? `+${courseDetails.user?.phone?.countryCode}${courseDetails.user?.phone?.phone}` : 'n/a';

  let userPhone = 'n/a';
  if (courseDetails?.user?.phone?.phone) {
    userPhone = `+${courseDetails.user?.phone?.countryCode} ${formatPhoneNumber(courseDetails.user?.phone?.phone)}`;
  }
  else if (courseDetails?.user?.phone) {
    // userPhone = courseDetails.user.phone;
    userPhone = formatPhoneNumber(courseDetails?.user?.phone);
  }


  let navigate = useNavigate();

  const itineraries = //useMemo(
    (() => {
      const days = Object.keys(courseDetails?.itenerary ?? {});
      // sort itineraries from earliest to latest.
      // NOTE: the keys for `courseDetails.itenerary` are dates encoded as strings.
      days.sort((a: any, b: any) => new Date(a) as any - (new Date(b) as any));

      const data = days.map((eachDay) => {
        const itinerary = courseDetails.itenerary[eachDay];
        const accArr = itinerary?.accomodations ? itinerary?.accomodations.map((item: any) => ({ ...item, data: item })) : [];
        const teeArr = itinerary?.teesheets ? itinerary?.teesheets?.map((item: any) => ({ ...item, data: item })) : [];
        // make sure the teesheets are in order!
        teeArr.sort((a: any, b: any) => a?.dateTime - b?.dateTime);

        return ({
          [eachDay]: [
            ...accArr,
            ...teeArr
          ]
        })
      })

      return data
    })();

  const getRefundResponseVal = (itineraries: any[]): any => {
    let refundResponse: any = {};

    if (Array.isArray(itineraries) && itineraries.length > 0) {
      itineraries.forEach(each => {
        Object.keys(each).forEach(key => {
          const item = each[key][0];
          if (item?.refundResponse) {
            refundResponse = item.refundResponse;
          }
        });
      });
    }

    return Object.keys(refundResponse).length > 0 ? refundResponse : null;
  };

  const refundResponseVal = getRefundResponseVal(itineraries)

  //, [courseDetails?.itenerary]);

  return (
    <div className="bookingDetailWrapper">
      <div className="container-fluid p-0">
        <div className="row align-items-center mb-3">
          <div className="col-auto">
            {/* <NavLink
              to={"./"}
              className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
            >
              <em className="fas fa-arrow-left"></em>
            </NavLink> */}
            <button
              className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
              onClick={
                () => {
                  navigate(-1);
                }
              }
            >
              <em className="fas fa-arrow-left"></em>
            </button>
          </div>
          <div className="col">
            <nav className="breadcrumbWrap" aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <NavLink to={"/bookings/third-party"}>Tourism Bookings </NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <span>Booking Details</span>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-7 col-xl-8">
            <div className="infoCard mb-3">
              <div className="row gutters-10 infoCard__cardUserData">
                <div className="col-auto">
                  <span className="userImage">
                    <img
                      src={courseDetails?.profileImage?.[0]?.path ?? "../../../assets/images/default-user.png"}
                      alt=""
                      className="img-fluid"
                    />
                  </span>
                </div>
                <div className="col align-self-center">
                  <div className="row gutters-5 align-items-center">
                    <div className="col">
                      <h3 className="mb-2" style={{ textTransform: 'capitalize' }}>{userName}</h3>
                      <div className="row gutters-7 align-items-center">
                        <div className="col-auto">
                          <p className="mb-0">{user?.email}</p>
                        </div>
                        <div className="col-auto color-400">|</div>
                        <div className="col-auto">
                          <p className="mb-0">{userPhone}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      {/* <a
                        href={void 0}
                        className="color-active fw700 text-decoration-underline"
                      >
                        View Profile
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <InfoCard title='Booking Details'>
              <div className="infoCard__booking">
                <div className="row gutters-10">
                  <div className="col-4 _box">
                    <label className="color-700 fw500 mb-2">Booked on</label>
                    <h6 className="color-800 fw700">
                      {`${dayjs(courseDetails?.createdAt).format('MMMM D YYYY, h:mm a')}`}
                    </h6>
                  </div>
                  <div className="col-4 _box">
                    <label className="color-700 fw500 mb-2">Booking code</label>
                    <h6 className="color-800 fw700">{courseDetails?.bookingId}</h6>
                  </div>
                  <div className="col-4 _box">
                    <label className="color-700 fw500 mb-2 d-block">
                      Status
                    </label>
                    <label style={{ background: status.color, color: status.fontColor }} className={`mb-0 status`}>
                      {status?.label?.charAt(0).toUpperCase() + status?.label?.slice(1)}
                    </label>
                    {/* <span className="status status-active">{courseDetails?.status?.value}</span> */}
                  </div>
                  <div className="col-4 _box">
                    <label className="color-700 fw500 mb-2">Start Date</label>
                    <h6 className="color-800 fw700">
                      {`${dayjs(courseDetails?.itineraryStartDate).format('MMMM D YYYY')}`}
                    </h6>
                  </div>
                  <div className="col-4 _box">
                    <label className="color-700 fw500 mb-2">End Date</label>
                    <h6 className="color-800 fw700">
                      {`${dayjs(courseDetails?.itineraryEndDate).format('MMMM D YYYY')}`}
                    </h6>
                  </div>
                  <div className="col-4 _box">
                    <label className="color-700 fw500 mb-2">
                      Number of Golfers
                    </label>
                    <h6 className="color-800 fw700">{courseDetails?.noOfGuest} players</h6>
                  </div>
                </div>
              </div>
            </InfoCard>

            {/* <div className="infoCard mb-3">
              <div className="card-body">
                <section className="itinerarySection">
                  <h2 className="mb-3">Booking Itinerary</h2>
                  <div className="timelineWrapper">
                    {iteneraries.map((each:any, index:number) => {
                      const day = (Object.keys(each)[0]);
                      return (
                        <div className='timelineBlockOuter'>
                          <div key={index} className="timelineBlock">
                        <h4>{day}</h4>
                        {
                          each[day].map((order:any, i:number) => {
                            if(order.accomodationId) {
                              const { address1, city, country, province } = order.location.address;
                              order = {
                                accomodationId: order.accomodationId,
                                data: {
                                  address: order.location.address,
                                  rooms: order.rooms.map((room: any) => {
                                    return {
                                      ...room,
                                      roomTypeId: {
                                        name: room.roomType
                                      }
                                    }
                                  }),
                                  checkInTime:order.checkInDateTime,
                                  checkOutTime: order.checkOutDateTime,
                                  title: order.name,
                                  formattedName: ` ${(address1)?address1+ ',' : ''} ${city}, ${country}, ${province}`,
                                  
                                  players:order.rooms.reduce((acc: number, item: any) => {return acc+item.noOfGuest}, 0),
                                }
                              }
                            }else {
                              order={
                                
                                teesheets: each[day].filter((eachData:any) => { 
                                  if((eachData?.club?._id === each[day][i]?.club?._id) && !eachData?.mapped){
                                    eachData.mapped = true;
                                    return eachData;
                                  } return false; 
                                }),
                                clubId: each[day][i]?.club?._id,
                                isRepeating: each[day][i]?.club?._id ===  each[day]?.[i-1]?.club?._id,
                              } 
                            }
                            return (
                            <Itenararies
                              key={i}
                              data={{...order, type:(order.accomodationId)? 'accomodation' : 'teesheet'}}
                              index={index}
                              day={day}
                            />
                            )
                          })
                        }
                         
                      </div>
                        </div>
                      
                    )})}
                  </div>
                </section>
              </div>
            </div> */}

            <InfoCard title='Booking Itinerary'>
              <ul>
                {
                  // for each itinerary, create a new time component
                  itineraries.map((each: any, baseIndex: number) => {
                    return (
                      <li>
                        {/* {baseIndex} / {itineraries.length} */}
                        <ItineraryTime object={each} lastItem={(baseIndex >= (itineraries.length - 1))} />
                      </li>
                    );
                  })
                }
              </ul>
              {/* 
              <div className="card-body">
                <section className="itinerarySection">
                  <h2 className="mb-3">Booking Itinerary</h2>
                  <div className="timelineWrapper">
                    {itineraries.map((each:any, index:number) => {
                      const day = (Object.keys(each)[0]);
                      return (
                        <div className='timelineBlockOuter'>
                          <div key={index} className="timelineBlock">
                        <h4>{day}</h4>
                        {
                          each[day].map((order:any, i:number) => {
                            if(order.accomodationId) {
                              const { address1, city, country, province } = order.location.address;
                              
                              order = {
                                accomodationId: order.accomodationId,
                                data: {
                                  address: order.location.address,
                                  rooms: order.rooms.map((room: any) => {
                                    return {
                                      ...room,
                                      roomTypeId: {
                                        name: room.roomType
                                      }
                                    }
                                  }),
                                  checkInTime:order.checkInDateTime,
                                  checkOutTime: order.checkOutDateTime,
                                  title: order.name,
                                  formattedName: ` ${(address1)?address1+ ',' : ''} ${city}, ${country}, ${province}`,
                                  
                                  players:order.rooms.reduce((acc: number, item: any) => {return acc+item.noOfGuest}, 0),
                                }
                              }
                            }else {
                              order={
                                
                                teesheets: each[day].filter((eachData:any) => { 
                                  if((eachData?.club?._id === each[day][i]?.club?._id) && !eachData?.mapped){
                                    eachData.mapped = true;
                                    return eachData;
                                  } return false; 
                                }),
                                clubId: each[day][i]?.club?._id,
                                isRepeating: each[day][i]?.club?._id ===  each[day]?.[i-1]?.club?._id,
                              } 
                            }
                            return (
                              // <Itenararies
                              //   key={i}
                              //   data={{...order, type:(order.accomodationId)? 'accomodation' : 'teesheet'}}
                              //   index={index}
                              //   day={day}
                              // />
                              <></>
                            )
                          })
                        }
                         
                        </div>
                      </div>
                    )})}
                  </div>
                </section>
              </div> */}

            </InfoCard>

            <InfoCard title={'Review'}>
              <div className="infoCard__review">
                <div className="_ratingBlock">
                  {/* {(!courseDetails?.review?.length) && <p>No reviews on this booking yet</p>}
                  {courseDetails?.review?.map(({review}: any, index: number) => {
                    return <p key={index}>{review}</p>
                  } )} */}
                  <p>{courseDetails?.review ? courseDetails?.review : 'No reviews on this booking yet'}</p>
                </div>
              </div>
            </InfoCard>



            {/* <div className="text-start">
              <button type="button" className="button button-rounded fw600 buttonCancel">Cancel Booking</button>
            </div> */}
          </div>
          <div className="col-12 col-lg-5 col-xl-4">
            <div className="infoCard mb-3">
              <h5 className="infoCard__cardTitle mb-4 pb-2">Tourism User</h5>
              <div className="infoCard__courseInfo">
                <div className="row gutters-10">
                  <div className="col-auto">
                    <div className="imgBlock">
                      <img
                        src={courseDetails?.merchantId?.profileImage?.[0]?.path || "../../../assets/images/default-user.png"}
                        alt="No Image"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <h2 className="mb-2">{courseDetails?.merchantId?.firstname ?? ''} {courseDetails?.merchantId?.lastname ?? ''}</h2>
                    <p className="mb-0 color-600 fw500">
                      <em className="icon-email"></em>{courseDetails?.merchantId?.email}
                    </p>
                    {/* <a
                      href={void 0}
                      className="color-active fw700 text-decoration-underline d-inline-block mt-4"
                    >
                      View Course
                    </a> */}
                  </div>
                </div>
              </div>
            </div>

            {courseDetails?.status?.value?.toLowerCase() === "refunded" && (
                <div className="infoCard mb-3 p-3">
                  <h5 className="infoCard__cardTitle mb-1 pb-2">Refund Details</h5>
                  <div className="infoCard__courseInfo">
                    <div className="row gutters-10 mb-2">
                      <div className="col-12 d-flex align-items-center justify-content-between">
                        <span className="fw500">Amount</span>
                        <span className="text-muted">
                          {Number(refundResponseVal?.amount) > 0
                            ? `$${Number(refundResponseVal?.amount)?.toFixed(2)}`
                            : `$0.00`}
                        </span>
                      </div>
                      </div>
                      <div className="row gutters-10">
                      <div className="col-12 d-flex align-items-center justify-content-between">
                        <span className="fw500">Refunded On</span>
                        <span className="text-muted text-end">
                          {DateTime.fromMillis(
                            refundResponseVal?.createdAt).toFormat("hh:mm a, EEE dd MMM yyyy")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <div className="infoCard mb-3">
              <h5 className="infoCard__cardTitle mb-3">Charges</h5>
              {courseDetails?.golfTotal > 0 && <div className="row gutters-5 py-2">
                <div className="col">
                  <label className="mb-0 color-700">Golf </label>
                </div>
                <div className="col-auto">
                  <h6 className="mb-0 color-800 fw700">${Number(courseDetails?.golfTotal)?.toFixed(2) ?? '0.00'}</h6>
                </div>
              </div>}

 
              {courseDetails?.accTotal > 0 && <div className="row gutters-5 py-2">
                <div className="col">
                  <label className="mb-0 color-700">Accommodations Total</label>
                </div>
                <div className="col-auto">
                  <h6 className="mb-0 color-800 fw700">${courseDetails?.accTotal ? Number(courseDetails?.accTotal).toFixed(2) : 0.00}</h6>
                </div>
              </div>}
              {courseDetails?.bookingFees > 0 && <div className="row gutters-5 py-2">
                <div className="col">
                  <label className="mb-0 color-700">Booking Fee (5%)</label>
                </div>
                <div className="col-auto">
                  <h6 className="mb-0 color-800 fw700">${courseDetails?.bookingFees ? Number(courseDetails?.bookingFees)?.toFixed(2) : 0.00}</h6>
                </div>
              </div>}
              <div className="row gutters-5 py-2">
                <div className="col">
                  <label className="mb-0 color-700">Tax</label>
                </div>
                <div className="col-auto">
                  <h6 className="mb-0 color-800 fw700">${Number(courseDetails?.taxAmt)?.toFixed(2) ?? 0.00}</h6>
                </div>
              </div>
              <div className="row gutters-5 py-2">
                <div className="col">
                  <label className="mb-0 color-700">Total</label>
                </div>
                <div className="col-auto">
                  <h6 className="mb-0 color-800 fw700">${courseDetails?.totalPrice ? Number(courseDetails?.totalPrice)?.toFixed(2) : '0.00'}</h6>
                </div>
              </div>

              <div className="mt-4">
                <h5 className="infoCard__cardTitle mb-3">Payment Methods</h5>
                <div className="row gutters-5 py-2">
                  <div className="col">
                    <label className="mb-0 color-700">Method</label>
                  </div>
                  <div className="col-auto">
                    <h6 className="mb-0 color-800 fw700">
                      {courseDetails?.paymentInfo?.status === "Success" ? `Card ${CARD_TYPE_MAPPING[courseDetails?.paymentInfo?.cardType || "Card"]}****${courseDetails?.paymentInfo?.last4}` : 'Payment Failed'}

                    </h6>
                  </div>
                </div>
                <div className="row gutters-5 py-2">
                  <div className="col">
                    <label className="mb-0 color-700">Transaction Date</label>
                  </div>
                  <div className="col-auto">
                    <h6 className="mb-0 color-800 fw700">
                      {`${dayjs(courseDetails?.transaction[0]?.createdAt).format('MMMM D YYYY, h:mm a')}`}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingDetailThirdparty;