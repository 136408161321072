import React, { FC, useEffect, useState } from 'react';
import './accommodations.scss';
import { useAccommodationsFilters } from './accommodations.context';
import IntegolfLoader from '../../components/integolf-loader/integolf-loader';
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import { usePagination } from '../../contexts/PaginationContext';
import AccommodationsList from './components/accommodations-list/accommodations-list';
import axios from 'axios';
import { useMutation } from 'react-query';
import Filters from './components/filters/filters';


interface AccommodationsProps {}

const Accommodations: FC<AccommodationsProps> = () => {
	const [showFilters, setShowFilters] = useState(false);

	const onShowFilter = () => {
    document.body.classList.add("overflow-hidden");
    setShowFilters(true);
  };
  const hideFilterEvent = (value: any) => {
    document.body.classList.remove("overflow-hidden");
    setShowFilters(false);
  };

  const applyFilterEvent = (value: any) => {
    hideFilterEvent(false);
  };

  const validAccommodations=(accommodationTypes:any)=>{
	return accommodationTypes?.map((accommodationType:any)=>{
		return {id:accommodationType?._id,name:accommodationType?.name,checked:true}
	})
  }

	const {
		skip,
		limit,
		setTotalItems,
		setCurrentPage,
		setItemsPerPage,
		itemsPerPage,
		setItemsCountInCurrentPage,
	} = usePagination();

	const {
		accommodationType,
		setAccommodationType,
		appliedFilters,
		setAppliedFilters
	} = useAccommodationsFilters();

	const getAccommodationTypes=async({
		accessToken
	}:any)=>{
		const response = await axios.get(`${process.env.REACT_APP_TOURISM_API}/api/v1/accomodationTypes`, {
			headers: {
				'Accesstoken': accessToken,
			}
		});
		const types=validAccommodations(response?.data?.response?.result);
		setAccommodationType(types);
		const filters=types?.map((type:any)=>type.id)
		setAppliedFilters(filters);
		return response.data;
	}

	const getAccommodations = async ({ 
		accessToken,
		createdAt,
		searchTerm
	 }: any) => {
		const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchAccomdations`, {
			params: {
				text: searchTerm.trim(),
				limit,
				skip,
				type:appliedFilters,
				sortBy: "createdAt",
				sortOrder: -1
			},
			headers: {
				'Accesstoken': accessToken,
			}
		});
		return response.data;
	}
	const {
		searchTerm,
		setSearchTerm,
	} = useAccommodationsFilters();
	const accessToken = localStorage.getItem('accessToken');
	const { isLoading, error, data, mutate: getAccommodationsData } = useMutation('accommodations', () => getAccommodations({
		accessToken: accessToken!,
		searchTerm
	}));
	const {  data : dataTypes,mutate: getAccommodationTypesData } = useMutation('accommodations', () => getAccommodationTypes({
		accessToken: accessToken!
	}));
	
	useEffect(() => {
		getAccommodationTypesData();
	}, [
		getAccommodationTypesData
	]);


	useEffect(() => {
		if (data?.response?.count >= 0 && skip === 0) {
			setCurrentPage(1);
			setItemsPerPage(itemsPerPage);
			setTotalItems(data?.response?.count);
		}
	}, [data?.response?.count, skip]);
	useEffect(() => {
		getAccommodationsData();
	}, [
		skip,
		getAccommodationsData,
		appliedFilters,
		itemsPerPage
	]);

	useEffect(() => {
		setCurrentPage(1);
	}, [appliedFilters]);

	useEffect(() => {
		if(searchTerm?.trim()?.length>0){
		  setCurrentPage(1);
		  getAccommodationsData()
		}
	  }, [searchTerm])
	
	return (
		<>
			<div className='accommodationsWrapper'>
				<h1 className='mb-3 pageTitle'>Accommodations</h1>
				<div className='row'>
					<div className='col-4'>
						<div className='searchBlock'>
							
							<input 
							type='text'
							className='form-control'
							placeholder='Search by accommodation name, location'
							onChange={(e) => {
								setSearchTerm(e.target.value)
							}}
							></input>
						</div>
					</div>
					<div className='col-8 mb-2 text-align-right'>
						<button
						type='button'
						className='button button-sm button-rounded button-white button-filter fontsize-14 px-3'
						onClick={() => onShowFilter()}
						>
							<em className='icon-filter me-2'></em> Filters
						</button>
					</div>
				</div>
				<div className='container-fluid p-0'>
					{data?.response?.count === 0 && <IntegolfDataNotFound message={"No Accommodations found"}/>}
					{data?.response ? <AccommodationsList data={data?.response?.result} />
						:
						<IntegolfLoader show />}
					
				</div>
			</div>
			{showFilters && (
				<div className='asideFilterWrapper'>
					<div className='asideFilterInnerWrap'>
						<Filters
							showFilterComponent={showFilters}
							handleApplyFilters={applyFilterEvent}	
							handleCloseFilterModal={hideFilterEvent}
						/>
					</div>
				</div>
			) }
		</>
	);
};

export default Accommodations;


