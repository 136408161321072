import React, { createContext, useContext, useLayoutEffect, useState } from 'react';

interface FilterContextType {
  courseStatus:any;
  setCoursesStatus: any;
  appliedFilters:any;
  setAppliedFilters:any;
}

const FilterContext = createContext<FilterContextType | undefined>(
  undefined
);

export const usePayoutsFilters = (): FilterContextType => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('usePagination must be used within a PaginationProvider');
  }
  return context;
};

interface ContextProviderProps {
  children: React.ReactNode;
}

const FilterProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [courseStatus, setCoursesStatus] = useState<any>([
    'COMPLETED',
    'READY',
    'PENDING',
  ]);
  const [appliedFilters,setAppliedFilters]=useState<any>([
    'COMPLETED',
    'READY',
    'PENDING',
  ])

  const value: FilterContextType = {
    courseStatus, 
    setCoursesStatus,
    appliedFilters,
    setAppliedFilters
  };

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;