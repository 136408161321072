/* eslint-disable @typescript-eslint/no-explicit-any */
import './TeeTimeCard.scss';

/* eslint-disable-next-line */
export interface TeeTimeCardProps {
  time: any;
  price: any;
  isRevised: any;
}

export function TeeTimeCard({time, price, isRevised}: TeeTimeCardProps) {
  let warningIndicator = {};
  if(isRevised) {
    warningIndicator = {
      ...warningIndicator,
      backgroundColor: '#0f7eff20',
      borderWidth:1,
      borderColor: '#0f7eff',
    }
  }
  return (
    <div style={warningIndicator} className='teeTimeCard'>
      <h6 className='mb-2 color-800 fontsize-14 fw600'>{time}</h6>
      <label className='mb-0 fontsize-12 fw500 color-700'>${price}</label>
    </div>
  );
}

export default TeeTimeCard;
