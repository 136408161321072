import React, { FC } from 'react';
import './accommodations-list.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';
import dayjs from 'dayjs';
import { usePagination } from '../../../../contexts/PaginationContext';

interface AccommodationsListProps { }

const AccommodationsList = ({
  data
}: any) => {
  const { currentPage, totalItems,itemsPerPage } = usePagination();
  return (
    <>
      {totalItems > 0 && <div className='accommodationsListWrapper'>
        <div className='tableLayout'>
          <div className='table-responsive'>
            <table className='table mb-0'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <div className='d-flex align-items-center'>
                      <span>Accommodation Name</span>
                    </div>
                  </th>
                  <th>Added on</th>
                  <th>Accommodation Type</th>
                  <th>Check-In Time</th>
                  <th>Check-Out Time</th>
                  <th>Added By</th>
                  <th>Email</th>
                  <th className='width-50'>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((e: any, j: number) => {
                  // const {class: cssClass, label} = getStatusDetail(e.course?.status);
                  const fixTime = (num: number): number => {
                    return num < 10 ? Number(`0${num}`) : num;
                  }

                  const [checkInHours, checkInMinutes] = e?.checkInTime.split(':').map(Number);
                  const [checkOutHours, checkOutMinutes] = e?.checkOutTime.split(':').map(Number);
                  return (
                    <>
                      <tr key={'key_' + j}>
                        <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='userContent'>
                              <span className='imgBlock'>
                                <img
                                  src={e?.pictures?.[0]?.path ?? 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-siQWshbehfw9lvg5pH16YQ8eYJEgLQbkyQ&usqp=CAU'}
                                  alt={'accomodation_' + j}
                                  className='img-fluid'
                                />
                              </span>
                              <div className='content'>
                                <h5 className='mb-1>'>
                                  {e?.title ? e?.title : 'n/a'}
                                </h5>
                                <h6 className='mb-0'>
                                  {e?.address?.city ?? e?.address?.addressOne}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{dayjs(e?.createdAt).format('DD MMM YYYY hh:mm a')}</td>
                        <td>{e?.accomodationType[0]?.name}</td>
                        <td>{dayjs().set('hour', fixTime(checkInHours)).set('minute', fixTime(checkInMinutes)).format('hh:mm')} AM</td>
                        <td>{dayjs().set('hour', fixTime(checkOutHours)).set('minutes', fixTime(checkOutMinutes)).format('hh:mm')} PM</td>
                        <td>{`${e?.addedBy?.[0]?.firstname ? `${e?.addedBy?.[0]?.firstname} ${e?.addedBy?.[0]?.lastname}` : 'Guest'}`}</td>
                        <td>{e?.addedBy[0]?.email}</td>
                        {/* <td>{e?.features?.petFriendly ? e?.features : 'n/a'}</td> */}
                        {/* <td>
                          <label className={`mb-0 status ${cssClass}`}>
                            {label}
                          </label>
                        </td> */}
                        <td>
                          <div className="listActionDropdownBlock">
                            <Dropdown align="end">
                              <div className="text-center">
                                <Dropdown.Toggle className="toggleButton">
                                  <em className="fa-solid fa-ellipsis-vertical"></em>
                                </Dropdown.Toggle>
                              </div>
                              <Dropdown.Menu>
                                <NavLink to={'/accommodations/detail'} state={{ id: e?._id }} className="dropdown-item fw600">
                                  View
                                </NavLink>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                        {/* <div className='listActionDropdownBlock'>
                            <Dropdown align='end'>
                              <div className='text-center'>
                                <Dropdown.Toggle className='toggleButton'>
                                  <em className='fa-solid fa-ellipsis-vertical'></em>
                                </Dropdown.Toggle>
                              </div>
                              <Dropdown.Menu>
                                <NavLink state={{id: e._id}} to={'/accommodations/detail'} className='dropdown-item fw600'>
                                  View
                                </NavLink>
                              </Dropdown.Menu>
                            </Dropdown>
                        </div> 
                        </td> */}
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
            <div className='paginationBlock'>
              <div className='row gutters-5 align-items-cetner justify-content-between'>
                {totalItems > 10 && <Pagination />}
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default AccommodationsList;