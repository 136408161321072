import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import BlogComp from './blogcomp';

export default () => {
    return (
        <PaginationProvider>
            <BlogComp />
        </PaginationProvider>
    )
}