import React from "react";
import { DateTime } from "luxon";

const UnavailabilityTable = (e: any) => {

  const dates = e?.data?.blackOutDates;

  if (!Array.isArray(dates)) {
    return (
      <></>
    )
  };

  const filteredDates = dates?.filter((date:any) => !date.isDeleted);

  if (!filteredDates || filteredDates.length < 1) {
    return (
      <></>
    )
  }

  return (
    <table className='table mb-0'>
      <thead>
        <tr>
          <th>From</th>
          <th>To</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {filteredDates.map((d: any, i: number) => (
          <tr>
            <td>{DateTime.fromFormat(d?.startDate, 'yyyy-MM-dd').toFormat('LLLL dd, yyyy')}</td>
            <td>{DateTime.fromFormat(d?.endDate, 'yyyy-MM-dd').toFormat('LLLL dd, yyyy')}</td>
            <td></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UnavailabilityTable;