import {
  ChangeEvent,
  FC,
  useEffect,
  useState,
  MutableRefObject,
  useRef,
} from "react";
import "react-input-range/lib/css/index.css";
import { AccordionCollapse, Modal } from "react-bootstrap";
import "./course-edit.scss";
import axios from "axios";
import Slider from "rc-slider";
import { toast } from "react-toastify";
import { Accordion, Card } from "react-bootstrap";
import {
  ClubTimings,
  IUpdateClub,
  DAY_SORTER,
  convertTime24to12,
  DISCOUNT_PORTAL_ADVANCE_BOOKING_MAX_VAL,
  TOURISM_PORTAL_ADVANCE_BOOKING_MAX_VAL,
  TOURISM_PORTAL_ADVANCE_BOOKING_DEFAULT_VAL,
  TOUR_PORTAL_EVENT_KEY,
  DISCOUNTED_PORTAL_EVENT_KEY,
  COURSE_ADVANCE_BOOKING_DAYS_DEFAULT_VALUE,
  STARTING_TEE_TIME,
  checkDayExists,
  COURSE_TEE_MINUTES,
  getDayIndex,
  COURSE_TEE_HOURS,
  getValuesForAllTeeTimes,
  IChronogolfCourseRates,
  DISCOUNTED_PORTAL_KEY,
  TOURISM_PORTAL_KEY,
} from "../../../../models/club";
import DateTimeComponent from "../date-time-component/date-time-component";

interface EditProps {
  handleCloseEditModal: () => void;
  keyVal: any;
  contentVal?: any;
  clubObjectId?: any;
  clubRatings?: any;
  checkContentEdited?: any;
  courseObjectId?: any;
  merchantId?: any;
  hasExistingDiscounts?: any;
  discountId?: any;
  advanceBookingDuration?: any;
  discountProfiles?: any;
}

const Edit: FC<EditProps> = ({
  handleCloseEditModal,
  keyVal,
  contentVal,
  hasExistingDiscounts,
  clubObjectId,
  merchantId,
  checkContentEdited,
  courseObjectId,
  discountId,
  advanceBookingDuration,
  discountProfiles,
  clubRatings,
}) => {

  const [editableRow, setEditableRow] = useState<number | null>(null);

  const [editedType, setEditedType] = useState<number | 0>(0);
  const [error, setBookingError] = useState("");
  const [ discountedPortalRateCode, setDiscountedPortalRateCode ] = useState(clubRatings?.discountedPortalRate || "");
  const [ tourismPortalRateCode, setTourismPortalRateCode ] = useState(clubRatings?.tourismPortalRate || "");
  const [editedDiscount, setEditedDiscount] = useState<number | 0>(0);
  const accessToken = localStorage.getItem("accessToken");
  const userEmail = localStorage.getItem("email");
  const clubTimings =
    (contentVal === undefined && keyVal === "bookingtimings") ||
      (contentVal?.length === 0 && keyVal === "bookingtimings")
      ? getValuesForAllTeeTimes()
      : contentVal;
  console.log(contentVal,'contentVal')
  const [content, setContent] = useState(clubTimings);
  const discountBodyObject = discountId
    ? {
      courseId: courseObjectId,

      id: discountId,
      discountProfiles: discountProfiles?.map(
        (discountprofile: any) => discountprofile._id
      ),
    }
    : {
      courseId: courseObjectId,
      discountProfiles: discountProfiles?.map(
        (discountprofile: any) => discountprofile._id
      ),
    };
  const [body, setBody] = useState<any>(
    keyVal === "course"
      ? { _id: courseObjectId }
      : keyVal !== "discounts"
        ? { _id: clubObjectId }
        : discountBodyObject
  );
  const INTEGOLF_DISCOUNT_TYPE_DEFAULT = "default";
  const INTEGOLF_DISCOUNT_TYPE_THIRD_PARTY = "thirdParty";

  interface IDateTimePayload {
    clubTimings?: Array<ClubTimings>;
    clubAdvanceBookingDuration?: number;
  }

  const editCloseHandler = () => {
    handleCloseEditModal();
  };

  const onPortalRateChange = (e: any, portalKey: string) => {

    const rateVal = e?.target?.value || "";

    if (portalKey === DISCOUNTED_PORTAL_KEY) {
      setDiscountedPortalRateCode(rateVal);
      const discountedPortalRateObject = content.find((item:any) => item.rateCode === rateVal) ;
      setBody((prevBody:any) => ({
        ...prevBody,
        ...(discountedPortalRateObject ? {
          discountedPortalRate: {
              rateCode : discountedPortalRateObject.rateCode,
              rateName : discountedPortalRateObject.rateName
          }
      }: {})
      }));
    } else {
        setTourismPortalRateCode(rateVal);
        const tourismPortalRateObject = content.find((item:any) => item.rateCode === rateVal) ;  
        setBody((prevBody:any) => ({
          ...prevBody,
          ...(tourismPortalRateObject ? {
            tourismPortalRate: {
                rateCode : tourismPortalRateObject.rateCode,
                rateName : tourismPortalRateObject.rateName
            }
        }: {})
        }));  
    }
}

  const discountedPortalInfo = {
    clubTimings: contentVal?.clubTimings || getValuesForAllTeeTimes(),
    clubAdvanceBookingDuration:
      contentVal?.advanceBookingDuration ||
      COURSE_ADVANCE_BOOKING_DAYS_DEFAULT_VALUE,
  };
  const tourPortalRef: MutableRefObject<IDateTimePayload | undefined> =
    useRef();
  const discountedPortalRef: MutableRefObject<IDateTimePayload | undefined> =
    useRef();

  const tourismPortalInfo = {
    clubTimings:
      contentVal?.tourismPortalClubTimings || getValuesForAllTeeTimes(),
    clubAdvanceBookingDuration:
      contentVal?.tourismPortalAdvanceBookingDuration ||
      TOURISM_PORTAL_ADVANCE_BOOKING_DEFAULT_VAL,
  };

  console.log(contentVal?.tourismPortalAdvanceBookingDuration,'contentVal?.tourismPortalAdvanceBookingDuration')
  const handleBookingError = (error: any) => {
    setBookingError(error);
  };

  const disableBgColor =
    keyVal === "images" && content?.length === 5
      ? "uploadImageWrap--disabled"
      : "";

  const removeHandler = (index: any) => {
    const newimages = content.filter(
      (item: any) => content.indexOf(item) !== index
    );
    setContent([...newimages]);
    setBody((prevBody:any) => ({
      ...prevBody,
      photos: newimages.map((item: any) => item._id),
    }));
  };

  const onDateTimeSubmit = async (e: any) => {
    e.preventDefault();
    let tourismPortalPayload: any = {};
    let discountedPortalPayload: any = {};

    if (tourPortalRef.current) {
      const { clubTimings, clubAdvanceBookingDuration } = tourPortalRef.current;
      tourismPortalPayload = {
        clubTimings: clubTimings
          ?.filter((item: any) => !item?.deleted && item !== null)
          .map((item: any) => {
            const { deleted, ...rest } = item;
            return rest;
          }),
        clubAdvanceBookingDuration,
      };
    }

    if (discountedPortalRef.current) {
      const { clubTimings, clubAdvanceBookingDuration } =
        discountedPortalRef.current;
      discountedPortalPayload = {
        clubTimings: clubTimings
          ?.filter((item: any) => !item?.deleted && item !== null)
          .map((item: any) => {
            const { deleted, ...rest } = item;
            return rest;
          }),
        clubAdvanceBookingDuration,
      };
    }
    const body = {
      _id: clubObjectId,
      tourismPortalClubTimings: tourismPortalPayload?.clubTimings?.sort(
        (a: any, b: any) => {
          return DAY_SORTER[a?.day] - DAY_SORTER[b?.day];
        }
      ),
      tourismPortalAdvanceBookingDuration:
        tourismPortalPayload?.clubAdvanceBookingDuration,
      advanceBookingDuration:
        discountedPortalPayload?.clubAdvanceBookingDuration,
      clubTimings: discountedPortalPayload?.clubTimings?.sort(
        (a: any, b: any) => {
          return DAY_SORTER[a?.day] - DAY_SORTER[b?.day];
        }
      ),
    };

    let response;
    try {
      response = await axios.put(
        `${process.env.REACT_APP_INTEGOLF_COURSES_PORTAL}/merchant/club`,
        body,
        {
          headers: {
            "x-auth-jwt": `${accessToken}`,
          },
        }
      );
      editCloseHandler();
      checkContentEdited(response.data);
    } catch (error: any) {
      console.error("Error:", error);
      if (error.response) {
        toast.error(error.response.data.message);
        console.error("Response data:", error.response.data);
      } else if (error.request) {
        toast.error(error.request);
        console.error("No response received:", error.request);
      } else {
        toast.error(error.message);
        console.error("Error:", error.message);
      }
    }
  };

  const handleEdit = (discounts: any, index: number) => {
    setEditableRow(index);
    if (
      discounts.type === INTEGOLF_DISCOUNT_TYPE_DEFAULT &&
      discounts.updatedDays === -1
    ) {
      setEditedType(-1);
    } else {
      setEditedType(discounts.updatedDays);
    }
    setEditedDiscount(discounts.updatedDiscount);
  };

  const handleSave = (e: any, indexOfItem: any) => {
    e.preventDefault();

    if (editableRow === null) return;

    let checkExisting = content.filter(
      (discount: any, index: any) =>
        discount.updatedDays === editedType && index !== indexOfItem
    );
    let checkExistingRequests = hasExistingDiscounts.filter(
      (discount: any) => discount.updatedDays === editedType
    );

    if (checkExisting && checkExisting.length > 0) {
      toast.error(
        "There are already discount object present for the recommended day"
      );
      return;
    }

    if (checkExistingRequests && checkExistingRequests.length) {
      toast.error(
        "There are already pending discount request for this recommended day"
      );
      return;
    }

    if (editedType < 0) {
      toast.error("Days to expire should be more than 0");
      return;
    }

    if (editedDiscount < 1 || editedDiscount > 99) {
      toast.error(
        "Discount offered should not be less than 1 and not more than 99%"
      );
      return;
    }
    const updatedContent = content.map((discount: any, index: any) => {
      if (index === editableRow) {
        const updatedDays = +editedType;
        const updatedDiscount = editedDiscount;
        const updated =
          editedType !== discount.previousDays ||
            editedDiscount !== discount.previousDiscount
            ? true
            : false;

        return {
          ...discount,
          ...(updatedDays && { updatedDays }),
          ...(updatedDiscount && {
            updatedDiscount,
          }),
          ...(updated !== undefined && { updated }),
        };
      }
      return discount;
    });

    setContent(updatedContent);

    const updatedDiscounts = updatedContent
      .filter((content: any) => content.updated === true)
      .map(({ updated, confirmation, ...rest }: any) => rest);

    setBody((prevBody:any) => ({
      ...prevBody,
      discounts: updatedDiscounts,
    }));

    setEditableRow(null);
    setEditedType(0);
    setEditedDiscount(0);
  };

  const fileUploadHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 5) {
      toast.error("No more than 5 images allowed");
    } else if (
      e.target.files &&
      e.target.files.length > 0 &&
      e.target.files.length <= 5
    ) {
      const formData = new FormData();

      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index];
        formData.append("files", file);
      }

      let response = await axios.post(
        `${process.env.REACT_APP_INTEGOLF_COURSES_PORTAL}/file`,
        formData,
        {
          headers: {
            "x-auth-jwt": `${accessToken}`,
          },
        }
      );
      let files = response.data.response.map((item: any) => {
        return { _id: item._id, path: item.path };
      });
      setContent([...content, ...files]);

      setBody((prevBody:any) => ({
        ...prevBody,
        photos: [
          ...content.map((item: any) => item._id),
          ...files.map((item: any) => item._id),
        ],
      }));
    }
  };

  const addSpaces = (str: any) => {
    return str.replace(/([A-Z])/g, " $1").trim();
  };

  const updateContent = (e: any, keyToUpdate: any) => {
    let value;

    if (typeof e.target.value === "string" && e.target.value.trim() === "") {
      toast.error("No empty values accepted.");
      return;
    }

    if (e.target.value == "false") {
      value = false;
    } else if (e.target.value == "true") {
      value = true;
    } else {
      value = e.target.value;
    }

    setContent({ ...content, [keyToUpdate]: value });
    setBody({ ...body, [keyToUpdate]: value });
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    let response;

    if (Object.keys(body).length === 1  || (Array.isArray(body[keyVal]) && body[keyVal]?.length===0)) {
      editCloseHandler();
      return;
    }
    try {
      if (keyVal === "course") {
        response = await axios.put(
          `${process.env.REACT_APP_INTEGOLF_COURSES_PORTAL}/merchant/course`,
          body,
          {
            headers: {
              "x-auth-jwt": `${accessToken}`,
            },
          }
        );
      } else if (keyVal === "discounts") {
        response = await axios.post(
          `${process.env.REACT_APP_ADMIN_API_BASEURL}/addDiscountRequest`,
          body,
          {
            headers: {
              Accesstoken: `${accessToken}`,
            },
          }
        );
      } else {
        response = await axios.put(
          `${process.env.REACT_APP_INTEGOLF_COURSES_PORTAL}/merchant/club`,
          body,
          {
            headers: {
              "x-auth-jwt": `${accessToken}`,
            },
          }
        );
      }
      editCloseHandler();
      checkContentEdited(response.data);
    } catch (error: any) {
      console.error("Error:", error);
      if (error.response) {
        toast.error(error.response.data.message);
        console.error("Response data:", error.response.data);
      } else if (error.request) {
        toast.error(error.request);
        console.error("No response received:", error.request);
      } else {
        toast.error(error.message);
        console.error("Error:", error.message);
      }
    }
  };

  const [activeKey, setActiveKey] = useState<string>(
    DISCOUNTED_PORTAL_EVENT_KEY
  );

  const [activeKeyVal1, setActiveKeyVal1] = useState(true);
  const [activeKeyVal2, setActiveKeyVal2] = useState(false);

  const onToggleAccord = (event: any, type: any) => {
    if (type === DISCOUNTED_PORTAL_EVENT_KEY) {
      setActiveKey(DISCOUNTED_PORTAL_EVENT_KEY);
      setActiveKeyVal1(true);
      setActiveKeyVal2(false)
    } else if (type === TOUR_PORTAL_EVENT_KEY) {
      setActiveKey(TOUR_PORTAL_EVENT_KEY);
      setActiveKeyVal1(false);
      setActiveKeyVal2(true);
    } else {
      setActiveKey("");
      setActiveKeyVal1(false);
      setActiveKeyVal2(false);
    }
  };
  return (
    <Modal className="updateInfoModalWrapper" show={true} centered={true}>
      <Modal.Body>
        <a
          href={void 0}
          className="closeButton"
          onClick={() => editCloseHandler()}
        >
          <em className="fa-solid fa-close"></em>
        </a>
        <div className="formWrap">
          {keyVal && keyVal === "description" && (
            <div className="formBlock">
              <form onSubmit={(e: any) => onSubmitHandler(e)}>
                <div className="row gutters-10">
                  <div className="col-12 col-sm mb-4">
                    <div className="form-floating">
                      <h4 className="mb-2"> Edit {keyVal}</h4>
                      <textarea
                        rows={10}
                        cols={5}
                        className="form-control mt-3 h-auto"
                        value={content}
                        onChange={(e) => {
                          setContent(e.target.value);
                          setBody({ ...body, [keyVal]: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="mt-3 button button-primary button-rounded button-min-150 button-sm  fontsize-16 fw700 mb-2"
                >
                  Submit
                </button>
              </form>
            </div>
          )}
          {keyVal && keyVal === "clubRatings" && (
            <div className="formBlock">
              <form onSubmit={(e: any) => onSubmitHandler(e)}>
                <div className="row gutters-10">
                  <div className="col-12 col-sm mb-4">
                    <div className="form-floating">
                      <h4 className="mb-2"> Edit Club Ratings</h4>
                      <>
                        <div className="row justify-content-between mt-2 pt-2 pb-2">
                          <h5>Discounted Portal</h5>
                          <div className="col-8">
                            <div className="formSelect" style={{ width: '100%' }}>
                              <select className="form-select"  value={discountedPortalRateCode}  onChange={e => onPortalRateChange(e, DISCOUNTED_PORTAL_KEY)}>
                                <option value="">Select discounted portal rate</option>
                                {content?.map((item:any) => (
                                  <option key={item?._id} value={item?.rateCode}>
                                    {item?.rateName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-between mt-2 pt-2 pb-2">
                          <div className="col-8">
                            <h5>Tour Portal</h5>
                            <div className="formSelect" style={{ width: '100%' }}>
                              <select className="form-select"  value={tourismPortalRateCode}  onChange={e => onPortalRateChange(e, TOURISM_PORTAL_KEY)}>
                                <option value="">Select tour portal rate</option>
                                {content?.map((item:any) => (
                                  <option key={item?._id} value={item?.rateCode}>
                                    {item?.rateName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <button
                          className="button button-primary button-rounded button-min-120 fw600 fontsize-16 mt-4"
                        disabled={( content?.length === 0 || discountedPortalRateCode?.length===0 || tourismPortalRateCode?.length===0 )}
                        onClick={(event)=>onSubmitHandler(event)}
                        >
                          Confirm Rates
                        </button>
                      </>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          {keyVal && keyVal == "basics" && (
            <div className="formBlock">
              <h5 className="mb-4">Add amenities about your golf course.</h5>
              <form onSubmit={(e: any) => onSubmitHandler(e)}>
                <ul className="courseFormDetail">
                  {Object.entries(content).map(([key, value], index) => {
                    return (
                      <li key={index}>
                        <div className="row gutters-5 py-2" key={key}>
                          <div className="col">
                            <label className="mb-0 control-label fw400 text-capitalize">
                              {addSpaces(key)}
                            </label>
                          </div>
                          <div className="col-auto d-flex align-items-center">
                            <label className="ch-radio ch-radio-border me-4 fw700">
                              <input
                                type="radio"
                                id={`radio-${index}-yes`}
                                name={`radio-${index}`}
                                checked={value == true}
                                value="true"
                                onChange={(e) => updateContent(e, key)}
                              />
                              <span className="me-1">Yes</span>
                            </label>
                            <label className="ch-radio ch-radio-border fw700">
                              <input
                                type="radio"
                                id={`radio-${index}-no`}
                                name={`radio-${index}`}
                                value="false"
                                checked={value == false}
                                onChange={(e) => updateContent(e, key)}
                              />
                              <span>No</span>
                            </label>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>

                <button className="mt-3 button button-primary button-rounded button-min-150 button-sm  fontsize-16 fw700 mb-2">
                  Submit
                </button>
              </form>
            </div>
          )}
          {keyVal && keyVal == "bookingtimings" && (
            <>
              {" "}
              <form
                onSubmit={(e: any) => onDateTimeSubmit(e)}
                className="formStepper"
              >
                <div className="mt-n15 mb-4">
                  <h5>Add time and days for tee times</h5>
                </div>
                <Accordion
                  activeKey={activeKey}
                  defaultActiveKey={DISCOUNTED_PORTAL_EVENT_KEY}
                >
                  <Card className="border-none setTeeTimeBlock">
                    <Card.Header className="border-none setTeeTimeBlock__Header d-flex align-items-center justify-content-between">
                      <h3 className="mb-0 setTeeTimeBlock__Header__title">
                        Discounted Portal{" "}
                      </h3>
                      <div className="col-1 cursor-pointer" onClick={($event) =>
                        onToggleAccord($event, DISCOUNTED_PORTAL_EVENT_KEY)
                      }>
                        <em className="fa-solid fa-chevron-down fa-lg"></em>
                      </div>
                    </Card.Header>
                    <AccordionCollapse
                      eventKey={DISCOUNTED_PORTAL_EVENT_KEY}
                      className="py-2 px-3"
                    >
                      <Card.Body>
                        <div className="d-flex align-items-center justify-content-between">
                          <label className="fontsize-14">Days</label>
                        </div>

                        <DateTimeComponent
                          info={discountedPortalInfo}
                          clubTimingsSubmitError={error}
                          ref={discountedPortalRef}
                          handleBookingError={handleBookingError}
                          advanceBookingMaxDays={
                            DISCOUNT_PORTAL_ADVANCE_BOOKING_MAX_VAL
                          }
                          advanceBookingDefaultDay={
                            DISCOUNT_PORTAL_ADVANCE_BOOKING_MAX_VAL
                          }
                        />
                      </Card.Body>
                    </AccordionCollapse>
                  </Card>
                  <Card className="border-none setTeeTimeBlock">
                    <Card.Header className="border-none setTeeTimeBlock__Header d-flex align-items-center justify-content-between">
                      <h3 className="mb-0 setTeeTimeBlock__Header__title">
                        Tour Portal
                      </h3>
                      <div className="col-1 cursor-pointer" onClick={($event) =>
                        onToggleAccord($event, TOUR_PORTAL_EVENT_KEY)
                      }>
                        <em className="fa-solid fa-chevron-down fa-lg"></em>
                      </div>
                      {/* <label className="ch-switch">
                        <input
                          type="checkbox"
                          checked={activeKeyVal2}
                          onChange=
                        />
                        <span>&nbsp;</span>
                      </label> */}
                    </Card.Header>
                    <Accordion.Collapse eventKey={TOUR_PORTAL_EVENT_KEY}>
                      <Card.Body>
                        <div className="d-flex align-items-center justify-content-between">
                          <label className="fontsize-14">Days</label>
                          {/* <label className="ch-checkbox ch-checkbox-bordered">
                            <input type="checkbox" />
                            <span className="pl25 fontsize-14">Select All</span>
                          </label> */}
                        </div>
                        <DateTimeComponent
                          info={tourismPortalInfo}
                          clubTimingsSubmitError={error}
                          ref={tourPortalRef}
                          advanceBookingMaxDays={
                            TOURISM_PORTAL_ADVANCE_BOOKING_MAX_VAL
                          }
                          handleBookingError={handleBookingError}
                          advanceBookingDefaultDay={
                            TOURISM_PORTAL_ADVANCE_BOOKING_DEFAULT_VAL
                          }
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <button
                  disabled={error.length > 0}
                  className="mt-3 button button-primary button-rounded button-min-150 button-sm  fontsize-16 fw700 mb-2"
                >
                  Submit
                </button>
              </form>
            </>
          )}
          {keyVal && keyVal == "images" && (
            <form className="formStepper" onSubmit={(e) => onSubmitHandler(e)}>
              <h5 className="mb-4">Upload visuals of Course</h5>
              <label
                className={`uploadImageWrap mb-0 d-block ${disableBgColor}`}
              >
                <input
                  type="file"
                  disabled={content.length === 5}
                  accept="image/*"
                  onChange={fileUploadHandler}
                  onClick={(event: any) => {
                    event.target.value = "";
                  }}
                  multiple
                />
                <div className="inner" id="dropzone">
                  <img src="../../assets/images/icons/gallery.svg" alt="gallery" />
                  <h5>Click here to upload course photos</h5>
                  <p>At least 2 and No more than 5 photos required</p>
                </div>
              </label>
              <div className="row gutters-7 my-4">
                {content.map((image: any, index: any) => (
                  <>
                    <div className="col-auto mt-1" key={image._id}>
                      <div className="uploadedImage">
                        <span>
                          <img src={image.path} alt="" className="img-fluid" />
                        </span>
                        <a href={void 0} onClick={() => removeHandler(index)}>
                          <em className="fa-solid fa-close"></em>
                        </a>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="pt-4">
                <button
                  type="submit"
                  disabled={content.length < 2 || content.length > 5}
                  className="mt-3 button button-primary button-rounded button-min-150 button-sm  fontsize-16 fw700 mb-2"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
          {keyVal && keyVal === "course" && (
            <div className="formBlock">
              <form
                onSubmit={(e: any) => onSubmitHandler(e)}
                className="formStepper"
              >
                <h5 className="mb-4">Add details below</h5>
                <ul className="courseFormDetail">
                  <div className="row gutters-10">
                    {Object.entries(content).map(([key, value], index) => {
                      return key === "holes" ? (
                        <li>
                          <div className="row gutters-5 align-items-center">
                            <div className="col">
                              <label className="mb-0 control-label">
                                Holes
                              </label>
                            </div>
                            <div className="col-auto">
                              <label className="ch-radio ch-radio-border my-1 me-3">
                                <input
                                  type="radio"
                                  id={`radio-${index}-9`}
                                  name={`radio-${index}`}
                                  value="9"
                                  checked={value == 9}
                                  onChange={(e) => updateContent(e, key)}
                                />
                                <span className="fw700">9 holes</span>
                              </label>
                              <label className="ch-radio ch-radio-border my-1">
                                <input
                                  type="radio"
                                  value="18"
                                  name={`radio-${index}`}
                                  id={`radio-${index}-18`}
                                  checked={value == 18}
                                  onChange={(e) => updateContent(e, key)}
                                />
                                <span className="fw700">18 holes</span>
                              </label>
                            </div>
                          </div>
                        </li>
                      ) : (
                        <li>
                          <div className="row gutters-5 align-items-center">
                            <div className="col">
                              <label className="mb-0 control-label">
                                {key
                                  .replace(/^\/+/, "")
                                  .replace(/^\w/, (c) => c.toUpperCase())}
                              </label>
                            </div>
                            <div className="col-auto">
                              <input
                                type="text"
                                className="form-control control-xl"
                                placeholder="Add here"
                                value={String(value)}
                                onChange={(e) => updateContent(e, key)}
                              />
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </div>
                </ul>
                <button className="mt-3 button button-primary button-rounded button-min-150 button-sm  fontsize-16 fw700 mb-2">
                  Submit
                </button>
              </form>
            </div>
          )}
          {keyVal && keyVal === "discounts" && (
            <div className="formBlock">
              <form className="mt-2" onSubmit={(e: any) => onSubmitHandler(e)}>
                <div className="table-responsive">
                  <table className="table table-border mb-2">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Days to Expire</th>
                        <th>Discount Given</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {content &&
                        content.length > 0 &&
                        content.map((discounts: any, index: number) => (
                          <tr key={index}>
                            <td className="fw700 fontsize-16">{index + 1}</td>
                            <>
                              {editableRow === index ? (
                                <>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={editedType as number}
                                      onChange={(e) => {
                                        const value = e.target.value.trim();
                                        const parsedValue = parseInt(value, 10);
                                        if (!isNaN(parsedValue)) {
                                          setEditedType(parsedValue);
                                        } else {
                                          setEditedType(0);
                                        }
                                      }}
                                      pattern="[0-9][0-9]*"
                                      title="Value must be a positive integer greater than 0"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={editedDiscount as number}
                                      onChange={(e) =>
                                        setEditedDiscount(
                                          parseInt(e.target.value || "0")
                                        )
                                      }
                                      pattern="[1-9]|[1-9][0-9]"
                                      title="Value must be an integer between 1 and 99"
                                    />
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <h6 className="mb-0 fw700 fontsize-16">
                                      {discounts.type ===
                                        INTEGOLF_DISCOUNT_TYPE_DEFAULT &&
                                        discounts.updatedDays === -1
                                        ? "Default"
                                        : discounts.type ===
                                          INTEGOLF_DISCOUNT_TYPE_THIRD_PARTY
                                          ? "3rd Party"
                                          : discounts.updatedDays === 0
                                            ? "0 (Same Day)"
                                            : `${discounts.updatedDays} Or Less`}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6 className="mb-0 fw700 fontsize-16">
                                      {discounts?.updatedDiscount}%
                                    </h6>
                                  </td>
                                </>
                              )}
                            </>
                            <td className="d-flex align-items-center">
                              <div className="d-flex align-items-center">
                                {editableRow === index ? (
                                  <button
                                    className="button button-primary button-rounded button-sm fw700"
                                    onClick={(e) => handleSave(e, index)}
                                  >
                                    Save
                                  </button>
                                ) : discounts.confirmation === true ? (
                                  <>
                                    <a
                                      href={void 0}
                                      className="fontsize-18 color-800 me-3"
                                      style={{ cursor: "not-allowed" }}
                                    >
                                      <em className="icon-pen "></em>
                                    </a>
                                    <em
                                      className="fa-solid fa-clock fontsize-18 text-danger"
                                      title="Waiting for Confirmation."
                                    ></em>
                                  </>
                                ) : (
                                  <>
                                    <a
                                      href={void 0}
                                      className="fontsize-18 color-800"
                                      onClick={() =>
                                        handleEdit(discounts, index)
                                      }
                                    >
                                      <em className="icon-pen"></em>
                                    </a>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <button
                  type="submit"
                  className="mt-3 button button-primary button-rounded button-min-150 button-sm  fontsize-16 fw700 mb-2"
                >
                  Submit
                </button>
              </form>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Edit;
