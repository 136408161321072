import axios from "axios";
import { FC, useEffect } from "react";
import { useMutation } from "react-query";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import IntegolfDataNotFound from "../../../../components/integolf-data-not-found/integolf-data-not-found";
import IntegolfLoader from "../../../../components/integolf-loader/integolf-loader";
import PaginationProvider, { usePagination } from "../../../../contexts/PaginationContext";
import CourseBookings from "../course-bookings/course-bookings";
import CourseBookingsList from "../course-bookings/course-bookings";

interface CourseBookingsWrapperProps {}

const CourseBookingsWrapper: FC<CourseBookingsWrapperProps> = () => {
    
    return (
      <PaginationProvider>
        <CourseBookingsList />
      </PaginationProvider>
    )
  }
  
  export default CourseBookingsWrapper;