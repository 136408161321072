import React, { FC, useLayoutEffect, useState } from "react";
import {useBookingsFilters} from '../../bookings.context';
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./filters.scss";

interface FiltersProps {
  handleCloseFilterModal: (arg: any) => void;
  handleApplyFilters: (arg: any) => void;
  showFilterComponent: boolean;
}

const Filters: FC<FiltersProps> = ({handleApplyFilters, handleCloseFilterModal, showFilterComponent}) => {
  const {
    bookingStatus,
    setBookingStatus,
    appliedFilters,
    setAppliedFilters
  } = useBookingsFilters();

  const closeFilterPopup = () => {
    setBookingStatus([]);

  };

  const applyFilters = () => {
    handleApplyFilters({});
    setAppliedFilters(bookingStatus);
  }

  const bookingStatusOnChange = (stateString:string)=>{
    setBookingStatus((state: string[]) => {
      const index = state.findIndex((e: string) => e ===stateString)
      if(index === -1) {
        return [...state, stateString]
      }else {
       return state.filter((e: string) => e !== stateString)
     }
    })
  }

  return (
    <div className="filterModal d-flex flex-column">
      <div className="filterModal__header">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Filters</h4>
          <a href={void 0} className="close" onClick={() =>handleApplyFilters({})}>
            <em className="fa-solid fa-xmark"></em>
          </a>
        </div>
      </div>
      <div className="filterModal__body flex-fill">
        <form>
          <div className="filterSection">
            <div className="mb-0">
              <label className="control-label mb-2">Booking Status</label>
              <div className="py-1">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                  checked={bookingStatus.findIndex((e: string) => e ==='refunded') !== -1}  
                  onChange={() => bookingStatusOnChange('refunded')}
                  type="checkbox" />
                  <span className="pl30 fw600">Refunded</span>
                </label>
              </div>
              <div className="py-1">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                  checked={bookingStatus.findIndex((e: string) => e ==='completed') !== -1}  
                  onChange={() => bookingStatusOnChange('completed')}
                  type="checkbox" />
                  <span className="pl30 fw600">Completed</span>
                </label>
              </div>

              <div className="py-1">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                  checked={bookingStatus.findIndex((e: string) => e ==='success') !== -1}  
                  onChange={() => bookingStatusOnChange('success')}
                  type="checkbox" />
                  <span className="pl30 fw600">Success</span>
                </label>
              </div>

              <div className="py-1">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                  checked={bookingStatus.findIndex((e: string) => e ==='courseConfirmationPending') !== -1}  
                  onChange={() => bookingStatusOnChange('courseConfirmationPending')}
                  type="checkbox" />
                  <span className="pl30 fw600">Course Confirmation Pending</span>
                </label>
              </div>

              <div className="py-1">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                  checked={bookingStatus.findIndex((e: string) => e ==='cancelled') !== -1}  
                  onChange={() => bookingStatusOnChange('cancelled')}
                  type="checkbox" />
                  <span className="pl30 fw600">Cancelled</span>
                </label>
              </div>

              <div className="py-1">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                  checked={bookingStatus.findIndex((e: string) => e ==='failed') !== -1}  
                  onChange={() => bookingStatusOnChange('failed')}
                  type="checkbox" />
                  <span className="pl30 fw600">Failed</span>
                </label>
              </div>
            </div>
            <div className="filterModal__footer">
              <div className="row gutters-5 align-items-center justify-content-between">
                <div className="col-auto">
                  <button
                    type="button"
                    className="button button-primary button-rounded button-min-110 fw600"
                    onClick={() => applyFilters()}
                  >
                    Apply
                  </button>
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="button button-secondary button-border button-rounded button-min-110 fw600"
                    onClick={() => closeFilterPopup()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filters;
