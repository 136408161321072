import axios from 'axios';

export const getCourseOnBoardingDetail = async (accessToken: string, courseId:string) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchCourseSyncInfo/${courseId}`, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };

