import React, { createContext, useContext, useState } from 'react';

interface FilterContextType {
  accommodationType?: any,
  setAccommodationType?: any,
  searchTerm: any;
  setSearchTerm: any;
  appliedFilters:any;
  setAppliedFilters:any
}

const FilterContext = createContext<FilterContextType | undefined> (
  undefined
);

export const useAccommodationsFilters = (): FilterContextType => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('usePagination must be used within a PaginationProvider');
  }
  return context;
}

interface ContextProviderProps {
  children: React.ReactNode;
}

const FilterProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [accommodationType, setAccommodationType] = useState([]);
  const [appliedFilters,setAppliedFilters]=useState<any>([])
  const [searchTerm, setSearchTerm] = useState('');

  const value: FilterContextType = {
    accommodationType,
    setAccommodationType,
    searchTerm,
    setSearchTerm,
    appliedFilters,
    setAppliedFilters
  };

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;

