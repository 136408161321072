export interface IUpdateClub {
    index: number;
    day?: string;
    startHours?: string;
    startMinutes?: string;
    endHours?: string;
    endMinutes?: string;
}


export interface ClubTimings {
    startHours?: string;
    startMinutes ?: string;
    endHours ?: string;
    endMinutes ?: string;
    day?: string;
    deleted?:any;
}

const CLUB_TIMING_MONDAY = "monday";
const CLUB_TIMING_TUESDAY = "tuesday";
const CLUB_TIMING_WEDNESDAY = "wednesday";
const CLUB_TIMING_THURSDAY = "thursday";
const CLUB_TIMING_FRIDAY = "friday";
const CLUB_TIMING_SATURDAY = "saturday";
const CLUB_TIMING_SUNDAY = "sunday";
export const DAY_SORTER: { [name: string]: number } = {
  [CLUB_TIMING_MONDAY]: 0,
  [CLUB_TIMING_TUESDAY]: 1,
  [CLUB_TIMING_WEDNESDAY]: 2,
  [CLUB_TIMING_THURSDAY]: 3,
  [CLUB_TIMING_FRIDAY]: 4,
  [CLUB_TIMING_SATURDAY]: 5,
  [CLUB_TIMING_SUNDAY]: 6
};


export const DISCOUNTED_PORTAL_EVENT_KEY = "0";
export const TOUR_PORTAL_EVENT_KEY = "1";
export const COURSE_ADVANCE_BOOKING_MIN_DAYS=1;
export const COURSE_ADVANCE_BOOKING_MAX_DAYS=4;
export const COURSE_ADVANCE_BOOKING_DAYS_DEFAULT_VALUE = 4;
export const DISCOUNT_PORTAL_ADVANCE_BOOKING_MAX_VAL = 4;
export const TOURISM_PORTAL_ADVANCE_BOOKING_DEFAULT_VAL = 30;
export const TOURISM_PORTAL_ADVANCE_BOOKING_MAX_VAL=365

export const checkDayExists = (array: any, dayToCheck: any) => {
    return array?.find((obj: any) => obj?.day === dayToCheck) !== undefined;
};

export const getDayIndex = (array:any, dayToCheck:any) => {
 
    return array?.findIndex((obj:any) => obj?.day === dayToCheck);
};

export const COURSE_TEE_TIME_MINUTES_INTERVAL = 15;

export const STARTING_TEE_TIME = 7;
export const COURSE_TEE_HOURS = Array.from({ length: 14 }, (_, v) => ((v + STARTING_TEE_TIME < 10) ? `0${v + STARTING_TEE_TIME}` : `${v + STARTING_TEE_TIME}`));
export const COURSE_TEE_MINUTES = Array.from({length: 4}, (_, index)=> (index * COURSE_TEE_TIME_MINUTES_INTERVAL < 10) ? `0${index * COURSE_TEE_TIME_MINUTES_INTERVAL}` : `${index * COURSE_TEE_TIME_MINUTES_INTERVAL}`);
export const getValuesForAllTeeTimes = () => {
    const clubTimingsArray: Array<any> = [];
    Object.keys( DAY_SORTER)
      .forEach((day: string, index: number) => clubTimingsArray[index] = null);
      
    return clubTimingsArray;
}

export  const convertTime24to12 = (time24h: string|undefined, minutes: string = "00") => {
  
    let result = time24h;
    if (time24h && time24h.trim().length) {
      result = new Date('1970-01-01T' + `${time24h}:${minutes}:00` + 'Z')
        .toLocaleTimeString('en-US',
          {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
        );
    }
    return result;
   
  };
export interface IChronogolfCourseRates {
    _id: string; 
    courseId: string;
    rateCode: string;
    rateName: string;
}
export const MERCHANT_PAYOUT_INTERVAL_WEEKLY = 'weekly';
export const MERCHANT_PAYOUT_INTERVAL_TWICE_A_MONTH = 'twiceAMonth';;
export const MERCHANT_PAYOUT_INTERVAL_MONTHLY = 'monthly';
export const PAYOUT_INTERVAL_TO_LABEL_MAPPING = [
  {
      code: MERCHANT_PAYOUT_INTERVAL_WEEKLY,
      label: 'Weekly'
  }, {
      code: MERCHANT_PAYOUT_INTERVAL_TWICE_A_MONTH,
      label: 'Twice a month'
  }, {
      code: MERCHANT_PAYOUT_INTERVAL_MONTHLY,
      label: 'Monthly'
  }
];

export const DISCOUNTED_PORTAL_KEY = 'discountedPortale';
export const TEESHEET_SOLD_COUNT_FOR_INSTANT_DISCOUNT = 150;
export const TOURISM_PORTAL_KEY = 'tourismPortal';