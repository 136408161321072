import { useEffect, useState } from 'react';
import './blogs-list.scss';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import BlogPostFormPopUp from '../blogPostFormPopUp';
import { DateTime } from "luxon";
import Pagination from "../../../../components/Pagination";
import DeleteModal from '../../../courses/components/delete-modal/delete-modal';
import { usePagination } from '../../../../contexts/PaginationContext';
import { useNavigate } from 'react-router';

const BlogPostList = ({ data, onEditBlog, showDeletePopup, setShowDeletePopup, deleteBlogHandler, onDeleteBlog }: { data?: any, onEditBlog?: any, showDeletePopup?: any, setShowDeletePopup?: any, deleteBlogHandler?: any, onDeleteBlog?: any }) => {
  const { currentPage, totalItems, itemsPerPage } = usePagination();
  const navigate=useNavigate();
  const navigateToComments=(blogId:any)=>{
    navigate(`/blogs/comments/${blogId}`)
  }
  return (
    <>
      {/* <BlogPostFormPopUp
        show={show}
        setShow={setShow}
        defaultValues={defaultValues}
        updateBlogMutation={updateBlogMutation}
      /> */}
      <div className="userslist" >
        <div className="tableLayout">
          <div className="table-responsive" style={{ minHeight: '200px' }} >
            <table className="table mb-0" >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Added By </th>
                  <th>Added At</th>
                  <th>Updated At</th>
                  <th>Comments</th>
                  <th>Status</th>
                  <th className="width-80">&nbsp;</th>
                  {/* <th><button type="button"
                    className="btn btn-primary myBTnClass"
                    onClick={() => setShowAddEditPopup(true)}
                  >Add Post</button></th>
                  <th></th> */}
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 && data?.map((post: any, j: number) => {
                  return (
                    <>
                      <tr key={j}>
                        <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                        <td>{post?.title}</td>
                        <td>{post?.createdBy[0]?.email}</td>
                        <td>{`${DateTime.fromMillis(post?.createdAt).toFormat(
                          "hh:mm a, EEE dd MMM yyyy"
                        )}`}</td>
                        <td>{`${DateTime.fromMillis(post?.updatedAt).toFormat(
                          "hh:mm a, EEE dd MMM yyyy"
                        )}`}</td>
                        <td>{post?.comments?.length > 0  ? post.comments?.length : 0}</td>
                        <td>{post?.status == 1 ? "Active" : "Inactive"}</td>
                        <td>
                          <div className="listActionDropdownBlock">
                            <Dropdown align="end">
                              <div className="text-center">
                                <Dropdown.Toggle className="toggleButton">
                                  <em className="fa-solid fa-ellipsis-vertical"></em>
                                </Dropdown.Toggle>
                              </div>
                              <Dropdown.Menu>
                                <a
                                  className="dropdown-item fw600"
                                  onClick={() => onEditBlog(post)}
                                >
                                  Edit
                                </a>
                                <a
                                  className="dropdown-item fw600"
                                  onClick={() => navigateToComments(post?._id)}
                                >
                                  View Comments
                                </a>
                                <a
                                  className="dropdown-item fw600"
                                  onClick={() => onDeleteBlog(post)}
                                >
                                  Delete
                                </a>

                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>

                    </>
                  )
                })}

                {/* ==================================================================== */}


              </tbody>
            </table>
            <div className="paginationBlock">
              <div className="row gutters-5 align-items-center justify-content-between">
                {totalItems > 10 && <Pagination />}
              </div>
            </div>
          </div>

        </div>
      </div>

      {showDeletePopup && <DeleteModal
        show={showDeletePopup}
        setShow={setShowDeletePopup}
        submitHandler={deleteBlogHandler}
        title={'Delete Blog'}
        content={`Are you sure you want to delete this blog?`}

      />}
    </>

  );
};

export default BlogPostList;
