import { FC, useEffect, useState } from "react";
import "./defaultproducts.scss";
import {  useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import { usePagination } from "../../contexts/PaginationContext";
import { toast } from "react-toastify";
import DeafultProductsList from "./components/deafult-products-list/default-products-list";
import { Modal } from "react-bootstrap";
interface DefaultProductsProps { }

const DefaultProducts: FC<DefaultProductsProps> = () => {


  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage
  } = usePagination();

  const navigate = useNavigate();
  const addDefaultProduct = () => {
    navigate("/products/add");
  }

  const [deletedProductId,setDeletedProductId]=useState("");

  const accessToken = localStorage.getItem('accessToken') as string;
  const [hasModalVisible, sethasModalVisible] = useState(false);
  const [deleteProductConfirmation,setDeleteProductConfirmation]=useState(false);
  

  const deleteProductHandler = (productId: any) => {
    sethasModalVisible(true);
    setDeletedProductId(productId);
  }

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setDeleteProductConfirmation(true);
  }



  const updateDefaultProducts = async (payload: Object) => {
    const response = await axios.put(`${process.env.REACT_APP_ADMIN_API_BASEURL}/defaultProducts`, payload, {
      headers: {
        'Accesstoken': accessToken,
      }
    });
    return response.data;
  }

  const deleteDefaultProduct = async (id: string) => {
    const payload = { id: id };
    const accessToken = localStorage.getItem("accessToken") as string;
    const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API_BASEURL}/defaultProducts`, {
      headers: {
        'Accesstoken': accessToken,
      },
      data: payload,
    });
    handleModalClose()
    return response.data;
  };
  
  const handleModalClose=()=>{
    sethasModalVisible(false);
    setDeletedProductId("");
    setDeleteProductConfirmation(false);
  }


  const getDefaultProducts = async ({
    accessToken,
    createdAt,
    searchTerm
  }: any) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/defaultProducts`, {
      params: {
        limit,
        skip,
        sortBy: "createdAt",
        sortOrder: -1,
        isDeleted:false
      },
      headers: {
        'Accesstoken': accessToken,
      }
    });
    return response.data;
  }

  const { data, mutate: getDefaultProductsList } = useMutation('defaultproducts', () => getDefaultProducts({
    accessToken: accessToken!
  }));

  const { isLoading:deleteProductLoading,isSuccess:deleteProductSuccess, error:deleteProductError, data:deleteProductData, mutate: deleteDefaultProducts } = useMutation('deletedefaultproducts', deleteDefaultProduct, {
    onSuccess: () => {
      toast.success("Product deleted successfully");
      navigate("/products");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.response?.message || error?.response?.data?.message || "Submission failed");
    },
    });
  const { isLoading, data: updatedData, error, mutate: updateDefaultProductsItem,isSuccess } = useMutation('updatedefaultproducts', updateDefaultProducts,
    {
      onSuccess: () => {
        toast.success("Details updated successfully");
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.response?.message || "Sonething went wrong!");
      },
    }
  );

  useEffect(() => {
    getDefaultProductsList();
  
  }, [
    skip,
    getDefaultProductsList,
    itemsPerPage,
    isSuccess,
    deleteProductSuccess
  ]);

  useEffect(()=>{
    if(deletedProductId&&deleteProductConfirmation===true){
      deleteDefaultProducts(deletedProductId)
    }
  },[deletedProductId,deleteProductConfirmation])


  return (
    <>
      <div className="coursesWrapper">
        <h1 className="mb-3 pageTitle">Products</h1>
        <div className="row">
          <div className="col-4">
          </div>
          <div className="col-8 mb-2 text-align-right">
            <button
              type="button"
              disabled={data?.response?.length ===4}
              className="button button-sm button-rounded button-white button-filter fontsize-14 px-3"
              onClick={() => addDefaultProduct()}
              title={data?.response?.length === 4 ? "Maximum limit for adding the products is 4" : ""}
            >
              Add Product
            </button>
          </div>
        </div>
        <div className="container-fluid p-0">

        <Modal
            className="updateInfoModalWrapper"
            show={hasModalVisible}
            centered={true}
          >
            <Modal.Body>
              <a href={void 0} className="closeButton" onClick={() => sethasModalVisible(false)}>
                <em className="fa-solid fa-close"></em>
              </a>
              <div className="formWrap">
                <div className="successConfirmationModalWrap text-center">
                  <img
                    src="../assets/images/spedometer-low.png"
                    alt="deactivate"
                    className="mb-4 d-inline-block"
                  />
                  <h2 className="mb-3">Are you sure,you want to remove this product</h2>
                  <button
                    type="button"
                    className="button button-primary button-rounded button-min-120 fontsize-12 fw600"
                    onClick={() => {
                      handleModalClose()
                    }}
                  >
                    No
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className="button button-primary button-rounded button-min-120 fontsize-12 fw600"
                    onClick={(e: any) => onSubmit(e)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {data?.response?.count === 0 && <IntegolfDataNotFound message={"No Products added"} />}
          {data?.response ? <DeafultProductsList data={data?.response} deleteProduct={deleteProductHandler}/>
            : (!updatedData || isLoading||deleteProductLoading) ?
              <IntegolfLoader show /> : ''}
        </div>
      </div>
    </>
  );
};

export default DefaultProducts;
