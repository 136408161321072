import React, { useEffect, useState } from "react";
import AsideNavigation from "../aside-navigation/aside-navigation";
import "./private-layout.scss";
import Header from "../header/header";
import { Outlet, useLocation, useNavigate  } from "react-router-dom";

const AdminPrivateLayout = ({ component: Component }: any) => {
  const [role, setRole] = useState(localStorage.getItem('role'));
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!accessToken) {
      navigate('/login');
    } else if (role !== "admin") {
      navigate('/targetcourses');
    } else if ((location.pathname === '' || location.pathname === '/')&& role === "admin") {
      navigate('/dashboard');
    }
  }, [role, accessToken, location.pathname, navigate]);
 
  return (
  <>
    <div className="privateLayoutWrapper">
      <AsideNavigation />
      <div id="headerLayout">
        <Header />
      </div>
      <main role="main">
        <Outlet />
      </main>
    </div>
  </>
)};

export default AdminPrivateLayout;
