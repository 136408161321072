import dayjs from 'dayjs';
import "./addNotes.scss";
import { FC, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, SubmitHandler } from 'react-hook-form';
type AddNotesInput = {
    contactvia: string;
    comments: string;
    contacton: string;
};
interface AddNotesProps {
    showModal: boolean;
    targetCourseId: any;
    handleClose: (arg: any) => void;
    addNote: (addNotesPayload: any) => void;
    addNotesStatus: string
}
const AddNotesModal: FC<AddNotesProps> = ({
    showModal,
    targetCourseId,
    handleClose,
    addNote,
    addNotesStatus
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm<AddNotesInput>();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formData, setFormData] = useState<any>("");
    {
        const [startDate, setStartDate] = useState(new Date())
        const onSubmit: SubmitHandler<AddNotesInput> = (data) => {
            setFormSubmitted(true);
            setFormData(data);
        };

        useEffect(() => {
            if (formSubmitted === true) {
                addNote({
                    _id: targetCourseId,
                    contactvia: formData?.contactvia,
                    contacton: startDate,
                    comments: formData?.comments
                });
                handleClose(!showModal);
                setStartDate(new Date());
                reset();
            }
        }, [formSubmitted, formData]);

        const close = () => {
            handleClose(!showModal);
        };

        const today = new Date().toISOString().split('T')[0];
        return (
            <Modal
                className="cancelBookingModal modalLayoutWrapper"
                show={showModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <button
                        type="button"
                        className="closeButton d-flex align-items-center justify-content-center"
                        onClick={() => {
                            close();
                        }}
                    >
                        <i className="fa-solid fa-xmark" style={{ height: '16px', width: '16px' }}></i>
                    </button>
                    <div className="formWrap">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h4 className="mb-4">Notes</h4>
                            <div className="mb-3">
                                <label htmlFor="contactvia" className='mb-1'>Contact Mode</label>
                                <div >
                                    <select
                                        className={`form-control ${errors.contactvia ? 'is-invalid' : ''}`}
                                        id="contactvia"
                                        {...register('contactvia', {
                                            required: true
                                        })}
                                    >
                                        <option value="">Select Mode</option>
                                        <option value="phone">Phone</option>
                                        <option value="email">Email</option>
                                    </select>

                                    {errors?.contactvia && (
                                        <div className="isInvalidMessage text-danger">
                                            This field is required
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="contacton" className='mb-1'>Contact Date</label>
                                <div className="form-floating">
                                    <DatePicker
                                        className='form-control form-control-solid w-250px'
                                        selected={startDate}
                                        onChange={(date: any) => setStartDate(date)}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        showTimeSelect
                                        timeIntervals={1}
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="comments" className='mb-1'>Comment</label>
                                <div className="">
                                    <textarea
                                        rows={3}
                                        className="form-control"
                                        id="comments"
                                        style={{ height: 'auto' }}
                                        {...register('comments', {
                                            required: 'This field is required',
                                            validate: value => value.trim() !== '' || 'Input cannot be empty or just spaces'
                                        })}
                                    ></textarea>
                                    {errors?.comments && (
                                        <div className="isInvalidMessage text-danger">
                                            {errors?.comments?.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="button button-primary button-rounded fw600 fontsize-16 button-custom-padding"
                            >
                                Add
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
}
export default AddNotesModal;