import { FC, useEffect } from "react";
import "./qrcodescans.scss";
import { useMutation } from "react-query";
import axios from "axios";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";
import { usePagination } from "../../contexts/PaginationContext";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import QrCodeScansActivitiesList from "./qrcodescanslist";

interface CoursesProps { }

const QRCodeScans: FC<CoursesProps> = () => {
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    setItemsCountInCurrentPage,
  } = usePagination();

  const getQrCodeScanActivities = async ({ 
    accessToken
   }: any) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchQrCodeScanActivity`, {
        params: {
            limit,
            skip,
            sortBy: "createdAt",
            sortOrder: -1
        },
        headers: {
            'Accesstoken': accessToken,
        }
    });
    return response.data;
  };
  

  const accessToken = localStorage.getItem('accessToken');
  const { isLoading, error, data, mutate: getQRScanCodeData } = useMutation('qrcodescanlist', () => getQrCodeScanActivities({
    accessToken: accessToken!
  }));

  useEffect(() => {
    if (data?.response?.data?.count >= 0 && skip ===0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.data?.count);
    }

  }, [data?.response?.data?.count, skip]);

  useEffect(() => {
    getQRScanCodeData();
  }, [
    skip,
    getQRScanCodeData,
    itemsPerPage
  ]
  );

  return (
    <>
      <div className="coursesWrapper">
        <h1 className="mb-3 pageTitle">QR Scans</h1>
        <div className="row align-items-center">
        </div>
      
        <div className="container-fluid p-0">
          {data?.response?.data?.count === 0 && <IntegolfDataNotFound message={"QR Scans not found"}/>}
          {data?.response?.data?.result ? <QrCodeScansActivitiesList data={data?.response?.data?.result}/>
            :
            <IntegolfLoader show />}
        </div>
      </div>
    </>
  );
};

export default QRCodeScans;
