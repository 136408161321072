import React, { FC, useLayoutEffect, useState } from "react";
import {useUserFilters} from '../../taxes.context';
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./filters.scss";

interface FiltersProps {
  handleCloseFilterModal: (arg: any) => void;
  handleApplyFilters: (arg: any) => void;
  showFilterComponent: boolean;
}

const Filters: FC<FiltersProps> = ({handleApplyFilters, handleCloseFilterModal, showFilterComponent}) => {
  const {
    courseStatus,
    setCoursesStatus,
  } = useUserFilters();

  const closeFilterPopup = () => {
    handleCloseFilterModal(!showFilterComponent);
    setTimeout(() => {
    }, 500);
  };

  const applyFilters = () => {
    handleApplyFilters({});
  }

  return (
    <div className="filterModal d-flex flex-column">
      <div className="filterModal__header">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Filters</h4>
          <a href={void 0} className="close" onClick={() => closeFilterPopup()}>
            <em className="fa-solid fa-xmark"></em>
          </a>
        </div>
      </div>
      <div className="filterModal__body flex-fill">
        <form>
          <div className="filterSection">
           
            <div className="mb-0">
              <label className="control-label mb-2">Users Status</label>
              <div className="py-1">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                  checked={courseStatus.findIndex((e: number) => e ===1) !== -1}  
                  onChange={() => setCoursesStatus((state: number[]) => {
                    const index = state.findIndex((e: number) => e ===1)
                    if(index === -1) {
                      return [...state, 1]
                    }else {
                     return state.filter((e: number) => e !== 1)
                   }
                  })
}
                  type="checkbox" />
                  <span className="pl30 fw600">Active</span>
                </label>
              </div>
              <div className="py-1">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                  checked={courseStatus.findIndex((e: number) => e ===2) !== -1}  
                  onChange={() => setCoursesStatus((state: number[]) => {
                    const index = state.findIndex((e: number) => e ===2)
                    if(index === -1) {
                      return [...state, 2]
                    }else {
                     return state.filter((e: number) => e !== 2)
                   }
                  })
   }
                  type="checkbox" />
                  <span className="pl30 fw600">In-Active</span>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filters;
