import "./default-products-form.scss";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { isImageSizeValid, isValidImage, MerchantFiles } from "../../../../utils/constants";
import IntegolfLoader from "../../../../components/integolf-loader/integolf-loader";


export interface DefaultProductProps { mode: String }

type DefaultProductsInput = {
  title: string;
  description: string;
  price: number;
  maxCartQuantity: number;
  inventory: number,
  images: string,
  isInventoryRequired: true
};

export function DefaultProductsForm(props: DefaultProductProps) {

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isInventoryNeeded, setIsInventoryNeeded] = useState(true);
  const { id } = useParams();

  const addDefaultProduct = async (payload: any) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/defaultProducts`, payload, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };

  const uploadDefaultProduct = async (formData: FormData) => {
    const accessToken = localStorage.getItem('accessToken');
    let response = await axios.post(
      `${process.env.REACT_APP_INTEGOLF_COURSES_PORTAL}/file`,
      formData,
      {
        headers: {
          "x-auth-jwt": `${accessToken}`,
        },
      }
    );
    let files = response?.data?.response.map((item: any) => {
      return { _id: item?._id, path: item?.path };
    });

    setImagesData([...imagesData, ...files]);
  }

  const fileUploadHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 1) {
      toast.error("No more than 1 images allowed");
      return;
    } else if (
      e.target.files &&
      e.target.files.length > 0 &&
      e.target.files.length <= 1
    ) {
      // Validate file size
      const files = e.target.files
      const file = files[0];
      if (!isImageSizeValid(file)) {
        toast.error("Image size must not exceed 5MB");
        return;
      }

      // Validate file type
      if (!isValidImage(file)) {
        toast.error("Only PNG, JPEG, and JPG images are allowed to upload");
        return;
      }

      // If all validations pass, create FormData and append the file
      const fileFormData = new FormData();
      fileFormData.append("files", file);
      setShowAddImageError(false);
      const formData = new FormData();
      const acceptedFiles = e.target.files;
      for (let index = 0; index < acceptedFiles.length; index++) {
        const file = acceptedFiles[index];
        formData.append("files", file);
      }
      uploadProductImages(formData);
    }
  };

  const onRemoveMerchantImage = (_id: string) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setImagesData(imagesData.filter((image: any) => image._id !== _id));
  };


  const getCurrentProduct = async () => {
    const accessToken = localStorage.getItem('accessToken') as string;
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/defaultProducts`, {
      params: {
        isDeleted: false,
        "id": id
      },
      headers: {
        "accessToken": accessToken
      }
    });
    return response.data;
  }

  const updateCurrentProduct = async (payload: any) => {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.put(`${process.env.REACT_APP_ADMIN_API_BASEURL}/defaultProducts`, payload, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  }

  useEffect(() => {
    if (id) {
      productData();
    }
  }, [])


  const [showAddImageError, setShowAddImageError] = useState(false);
  const [inventoryMaxError, setInventoryMaxError] = useState(false);
  const [imagesData, setImagesData] = useState<ImageData[]>([]);
  const [partialFormData, setPartialFormData] = useState({
    price: "",
    maxCartQuantity: "",
    inventory: ""
  })

  const { register, handleSubmit, formState: { errors }, reset } = useForm<DefaultProductsInput>({
    defaultValues: {
      inventory: 300,
    }
  });
  const { isLoading, error, data, mutate: addDefaultProducts } = useMutation('adddefaultproducts', addDefaultProduct, {
    onSuccess: () => {
      reset();
      toast.success("Details added successfully");
      navigate("/products");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.response?.message || error?.response?.data?.message || "Submission failed");
    },
  });

  const { isLoading: uploadingProduct, error: uploadingError, mutate: uploadProductImages } = useMutation('productimages', uploadDefaultProduct);
  const { data: getUploadData, mutate: productData } = useMutation("getproductsdata", getCurrentProduct)
  const { mutate: updateProductData } = useMutation("updateproductsdata", updateCurrentProduct, {
    onSuccess: () => {
      reset();
      toast.success("Details updated successfully");
      navigate("/products");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.response?.message || error?.response?.data?.message || "Submission failed");
    },
  })

  const navigate = useNavigate();
  useEffect(() => {
    if (id && getUploadData?.response?.length > 0) {
      let defaultValues: any = {};
      defaultValues.title = getUploadData?.response[0]?.title;
      defaultValues.description = getUploadData?.response[0]?.description;
      defaultValues.price = getUploadData?.response[0]?.price;
      defaultValues.maxCartQuantity = getUploadData?.response[0]?.maxQtyForCart;
      defaultValues.inventory = getUploadData?.response[0]?.inventory;
      setIsInventoryNeeded(getUploadData?.response[0]?.isInventoryRequired !== undefined && getUploadData?.response[0]?.isInventoryRequired !== null
        ? getUploadData?.response[0]?.isInventoryRequired
        : true);
      let files = getUploadData?.response[0]?.images.map((item: any) => {
        return { _id: item?._id, path: item?.path };
      });
      setImagesData(files);
      setPartialFormData({ ...defaultValues })
      reset({ ...defaultValues, isInventoryNeeded: getUploadData?.response[0]?.isInventoryRequired });
    }
  }, [getUploadData]);

  useEffect(() => {
    if (isInventoryNeeded === false) {
      setInventoryMaxError(false);
    }
  }, [isInventoryNeeded])

  const handleProductsSubmit = (data: any) => {
    if (!imagesData || imagesData.length == 0) {
      setShowAddImageError(true);
      return;
    }


    if (isInventoryNeeded && partialFormData?.maxCartQuantity > partialFormData?.inventory) {
      setInventoryMaxError(true);
      return;
    }

    else {
      setShowAddImageError(false);
      setInventoryMaxError(false);
      let photos = imagesData?.map((images: any) => images._id);

      let obj: any = {
        title: data?.title?.trim(),
        description: data?.description?.trim(),
        images: photos,
        price: Number(data.price).toFixed(2),
        maxQtyForCart: Number(data.maxCartQuantity),
        inventory: isInventoryNeeded === true ? Number(data.inventory) : 0,
        isInventoryRequired: isInventoryNeeded
      };

      if (id) {
        obj['id'] = id;
        updateProductData([obj]);
      } else {
        addDefaultProducts([obj]);
      }
    }
  };


  const handlePartialFormOnChange = (e: any) => {
    let { name, value } = e.target;
    if (name === "price" && (Number(value) - Math.floor(Number(value))) !== 0) {
      value = Number((Math.floor(Number(value) * 100) / 100).toFixed(2))
    } else if (name === "maxCartQuantity" || name === "inventory") {
      value = value ? Math.trunc(Number(value)) : ""
    }
    if (partialFormData['inventory'] !== "" && name === "maxCartQuantity" && value <= Math.trunc(Number(partialFormData['inventory'])) && isInventoryNeeded) {
      setInventoryMaxError(false);
    }

    if (partialFormData['inventory'] !== "" && name === "maxCartQuantity" && value > Math.trunc(Number(partialFormData['inventory'])) && isInventoryNeeded) {
      setInventoryMaxError(true);
    }

    if (partialFormData['inventory'] !== "" && name === "inventory" && value >= Math.trunc(Number(partialFormData['maxCartQuantity'])) && isInventoryNeeded) {
      setInventoryMaxError(false);
    }

    setPartialFormData({ ...partialFormData, [name]: value })
  }

  const disableNumWheelBehaviour = (e: any) => {
    const target = e.target;
    target.blur();
    setTimeout(() => {
      target.focus();
    }, 0);
  }


  return (
    <>
      {uploadingProduct ? <IntegolfLoader show /> :
        <div className="authWrapper d-flex align-items-center justify-content-center">
          <div className="authWrapContainer container">
            <div className="col-auto mt-3">
              <a
                onClick={() => navigate(-1)}
                className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
              >
                <em className="fas fa-arrow-left"></em>
              </a>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="card p-4">
                  <div className="card-body">
                    <h5 className="card-title mb-4">Add your product details</h5>
                    <form onSubmit={handleSubmit(handleProductsSubmit)}>
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="form-floating mb-4">
                            <input
                              type="text"
                              className="form-control"
                              id="title"
                              placeholder="Product Title"
                              {...register("title", {
                                required: "Please enter product title",
                                validate: {
                                  noEmpty: value => value.trim() !== '' || "Title cannot be empty"
                                }
                              })}
                            />
                            {errors.title && (
                              <div className="isInvalidMessage text-danger">
                                {errors.title.message}
                              </div>
                            )}
                            <label htmlFor="title">Product Title</label>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-floating mb-4">
                            <textarea
                              className="form-control"
                              id="description"
                              placeholder="Product Description"
                              style={{ height: "120px" }}
                              rows={8}
                              {...register("description", {
                                required: "Please enter product description",
                                validate: {
                                  noEmpty: value => value.trim() !== '' || "Description cannot be empty"
                                }
                              })}
                            ></textarea>
                            {errors.description && (
                              <div className="isInvalidMessage text-danger">
                                {errors.description.message}
                              </div>
                            )}
                            <label htmlFor="description">Description</label>
                          </div>
                        </div>

                        <div>
                          <h6 className="mb-3 fw700">Product Images</h6>
                          <label className={imagesData?.length === 1 ? `uploadImageWrap mb-0 d-block uploadImageWrap--disabled` : 'uploadImageWrap mb-0 d-block'}>
                            <input
                              ref={fileInputRef}
                              type="file"
                              accept="image/*"
                              onChange={fileUploadHandler}
                            />
                            <div className="inner py-5">
                              <h5>Upload your photo here</h5>
                              <p className="mb-2">Only 1 image can be added</p>
                            </div>
                          </label>
                          {showAddImageError && (
                            <div className="isInvalidMessage text-danger">Please add product images</div>
                          )}

                          <div className="row gutters-7 mt-4">
                            {imagesData && imagesData?.map((image: any) => (
                              <div className="col-auto" key={image._id}>
                                <div className="uploadedImage">
                                  <span>
                                    <img
                                      src={image.path}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </span>
                                  <a
                                    href={void 0}
                                    onClick={() =>
                                      onRemoveMerchantImage(image?._id)
                                    }
                                  >
                                    <em className="fa-solid fa-close"></em>
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-12 mb-4 mt-3">
                          <h6 className="mb-3 fw700">Pricing</h6>
                          <input
                            type="number"
                            className="form-control form-control-xl"
                            value={partialFormData.price}
                            placeholder="$0.00"
                            step="0.01"
                            onWheel={disableNumWheelBehaviour}
                            {...register("price", { required: true, min: 1, onChange: handlePartialFormOnChange })}
                          />
                          {errors.price?.type === 'required' && (
                            <div className="isInvalidMessage text-danger">Please enter product price</div>
                          )}
                          {errors.price?.type === "min" && (
                            <div className="isInvalidMessage text-danger">Entered value should not be less than 1</div>
                          )}
                        </div>
                        <div className="col-12 mb-4">
                          <h6 className="mb-3 fw700">Max Quantity For Cart</h6>
                          <input
                            type="number"
                            className="form-control form-control-xl"
                            placeholder="Add quantity"
                            value={partialFormData.maxCartQuantity}
                            onWheel={disableNumWheelBehaviour}
                            {...register("maxCartQuantity", {
                              required: true,
                              min: 1,
                              onChange: handlePartialFormOnChange
                            })}
                            step="1" // Prevents entering decimal values
                          />
                          {errors.maxCartQuantity?.type === 'required' && (
                            <div className="isInvalidMessage text-danger">Please enter max allowed cart quantity</div>
                          )}
                          {errors.maxCartQuantity?.type === "min" && (
                            <div className="isInvalidMessage text-danger">Entered value should not be less than 1</div>
                          )}
                          {inventoryMaxError && <div className="isInvalidMessage text-danger">Max Quantity cannot be greater than Inventory</div>}
                        </div>

                        <div className="col-12 mb-4">
                          <div className="d-flex align-items-center mb-3">
                            <h6 className="fw700 mb-0">Inventory</h6>
                            <div className="form-check inventory-check-styles">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="inventoryNeeded"
                                checked={isInventoryNeeded}
                                onChange={(e) => setIsInventoryNeeded(e.target.checked)}
                              />
                            </div>
                          </div>
                          {isInventoryNeeded && (
                            <input
                              type="number"
                              value={partialFormData.inventory}
                              className={`form-control form-control-xl ${errors.inventory ? 'is-invalid' : ''}`}
                              onWheel={disableNumWheelBehaviour}
                              placeholder="Add Inventory"
                              {...register("inventory", {
                                required: isInventoryNeeded,
                                min: isInventoryNeeded ? 1 : undefined,
                                onChange: handlePartialFormOnChange
                              })}

                              step="1" // Prevents entering decimal values
                            />
                          )}
                          {errors.inventory?.type === 'required' && isInventoryNeeded && (
                            <div className="isInvalidMessage text-danger">Please enter inventory count</div>
                          )}
                          {errors.inventory?.type === "min" && isInventoryNeeded && (
                            <div className="isInvalidMessage text-danger">Entered value should not be less than 1</div>
                          )}

                        </div>

                        <div className="col-12 pt-4">
                          <button
                            type="submit"
                            className="button button-primary button-rounded button-min-120 fw700"
                            disabled={showAddImageError || inventoryMaxError ? true : false}
                          >
                            {!id ? "Submit" : "Update"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>

  );
}

export default DefaultProductsForm;
