import React, { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./promosettings.scss";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useMutation } from "react-query";
import { PencilFill } from "react-bootstrap-icons";


interface PromoProps {}

const Promo: FC<PromoProps> = () => {
  const accessToken = localStorage.getItem('accessToken');
  const getPromotions = async (accessToken: string) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/promotions`,
    {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    }
    );
    return response.data?.response || {};
  };

  const editPromotionsReq = async (accessToken: string, data:any) => {
    const response = await axios.put(`${process.env.REACT_APP_ADMIN_API_BASEURL}/promotions`,data,
    {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    }
    )
    .catch((err)=>{
      let message = err?.response?.data?.response?.message;
      toast.error(message ?? "Something went wrong!")
    })
    if(response?.data){
      toast.success("Promotions Updated Successfully")
    }
    return response?.data;
  };
  const { data:promotionsData, mutate: getPromotionsData, error:getPromotionsError } = useMutation('promotions', () => getPromotions(accessToken!));

  const { data:editPromotionsData, mutate: editPromotions, error:editPromotionsError, isSuccess:editIsSuccess } = useMutation('editpromotions', (data) => editPromotionsReq(accessToken!, data));

  const { register,handleSubmit, formState: { errors }, reset, getValues  } = useForm<any>({
    values:promotionsData?.data
  });

  const handlePromoSubmit = (data: any) => {
    const {creditsPerDollar, maxLuckyDrawCount, creditsPerReferral } = data;
    if(Number(maxLuckyDrawCount).toFixed(0) != maxLuckyDrawCount) {
      toast.error("Lucky draw count cannot be in decimal")
    }else if(Number(creditsPerDollar).toFixed(0) != creditsPerDollar){
      toast.error("Credits cannot be in decimal")
    }else{
      let req:any={
        creditsPerDollar:Number(creditsPerDollar),
        maxLuckyDrawCount:Number(maxLuckyDrawCount),
        creditsPerReferral:Number(creditsPerReferral)
      }
      editPromotions(req);
    }
    
  };

  useEffect(()=>{
    getPromotionsData();
    setLuckyDrawInputEditable(true);
    setCreditsInputEditable(true);
    setReferralInputEditable(true)
  },[editIsSuccess])

  /**
   * fields used for disable true means disbaled initially
   */
  const [luckyDrawInputEditable, setLuckyDrawInputEditable] = useState(true);
  const [creditsInputEditable, setCreditsInputEditable] = useState(true);
  const [referralInputEditable, setReferralInputEditable] = useState(true);

  return (
    <>
      <div className="usersWrapper1">
        <h1 className="mb-4 pageTitle">Settings</h1>
       
        <div className="row justify-content-left">
          <div className="col-md-8">
            <div className="card p-4">
              <div className="card-body w-200">
                <h5 className="card-title mb-4">Promotions</h5>
                {promotionsData && <form onSubmit={handleSubmit(handlePromoSubmit)}>
                <div className="mb-3">
                <label htmlFor="_name">Max Lucky Draw Booking Count</label>
                <div className="input-group">
                    <input
                      disabled={luckyDrawInputEditable}
                      type="text"
                      className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                      id="_maxLuckyDrawCount"
                      {...register('maxLuckyDrawCount', { required: true})}
                    />
                    {luckyDrawInputEditable && <div className="input-group-append">
                      <span className="input-group-text cursor-pointer" onClick={()=>setLuckyDrawInputEditable(false)}><PencilFill color="black" size={25}/></span>
                    </div>}
                  </div>
                  {errors.maxLuckyDrawCount?.type==='required' && <div className="spanError mb-2">Please enter max lucky draw count</div>}
                </div>
                  
                <div className="mb-3">

                  <label htmlFor="_email">Referral Credits per Dollar <span style={{fontSize:"90%"}}> ( $1 = {getValues("creditsPerDollar")} credits )</span></label>
                  <div className="input-group">
                    <input
                      disabled={creditsInputEditable}
                      type="text"
                      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                      id="_creditsPerDollar"
                      {...register('creditsPerDollar', {
                        required: true,
                      })}
                    />
                    {creditsInputEditable && <div className="input-group-append">
                      <span className="input-group-text cursor-pointer" onClick={()=>setCreditsInputEditable(false)}><PencilFill color="black" size={25}/></span>
                    </div>}
                  </div>
                  {errors.creditsPerDollar?.type === 'required' && <div className="spanError mb-2">Please enter credits per dollar</div>}
                  </div>

                  <div className="mb-3">

                    <label htmlFor="_email">Credits per Referral <span style={{fontSize:"90%"}}>( 1 referral = {getValues("creditsPerReferral")} credits )</span></label>
                    <div className="input-group">
                      <input
                        disabled={referralInputEditable}
                        type="text"
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        id="_creditsPerReferral"
                        {...register('creditsPerReferral', {
                          required: true,
                        })}
                      />
                      {referralInputEditable && <div className="input-group-append">
                        <span className="input-group-text cursor-pointer" onClick={()=>setReferralInputEditable(false)}><PencilFill color="black" size={25}/></span>
                      </div>}
                    </div>
                    {errors.creditsPerReferral?.type === 'required' && <div className="spanError mb-2">Please enter credits per referral</div>}
                    </div>

                  {(!luckyDrawInputEditable || !creditsInputEditable || !referralInputEditable) && <button className="mt-3 button button-primary button-rounded button-min-150 button-sm  fontsize-16 fw700 mb-2" type="submit">Submit</button>}
                </form>}
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Promo;
