import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";
import axiosInstance from "./axiosClient";

function* httpClient(payload: any) {

  const payloadData = {
    ...payload,
    headers: {
      ...payload.headers,
    },
  };
  try {
    const { data: result, error } = yield call(axiosInstance, payloadData);
    return {
      error,
      result,
    };
  } catch (error: any) {
    let myError = error?.response?.status ? error.response.data : error;

    if (myError?.status) {
      if (myError.status === 401) {
        yield put(push({ pathname: "/login" }));
        toast.error("Session Expired. Please login again.");
      } else {
        toast.error(myError.message);
      }
    } else {
      toast.error(myError.message);
    }
  }

  return {
    error: true,
    result: null,
  };
}

export default httpClient;
