import { DateTime } from "luxon";
import { RoomCard } from './RoomCard';

const getCanadianTime = (date: number, format: string) => {
  return DateTime.fromMillis(Number(date)).setZone("Atlantic/Bermuda").toFormat(format)
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const AccommodationOrder = ({ deleteItinerary, data, index, day }: any) => {
  // const total = Number(data?.rooms?.[0]?.price);
  const total = data?.rooms.reduce((acc: number, item: any) => {
    return acc + Number(item.thirdPartyMerchantPrice) * Number(item.noOfRooms ?? item.noOfGuest);
  }, 0);
  return (
    <div className="timeBlock">
      <label className="timeBlockTime">
        {getCanadianTime(Number(data.checkInTime), 'h:mm a')}
      </label>
      {/* <div className="d-flex align-items-center justify-content-between"> */}
        {/* <label className="_time">
          {getCanadianTime(Number(data.checkInTime), 'h:mm a')}
        </label> */}
        {/* {deleteItinery && (
          <button
            type="button"
            className="remove"
            onClick={() => deleteItinery(day, index)}
          >
            X Remove
          </button>
        )} */}
      {/* </div> */}
      
      {/* <h5 className="_t__title mb-2">{data.title}</h5> */}

      <div className="order-title-container">
        <h4 className="order-title mt-2">{data.title}</h4>

        {
          (deleteItinerary) && 
            <button className='_t__title order-remove-button' onClick={() => deleteItinerary(day, index)}>
              remove
            </button>
        }
        
      </div>

      <p className='order-location'>{`${data.formattedName}`}</p>
      <div className="row gutters-5 mb-2">
        {data?.rooms.map((room: any, index: number) => (
          <div key={index} className="col-12 col-sm-6 col-md-4 col-xl-3 mb-2">
            <RoomCard data={room} />
          </div>
        ))}
      </div>
      <div className="row timeBlockItemFooter">
        <div className="col-12 col-md">
          <div className="row">
            <div className="col-auto">
              <div>
                <label className="_label">
                  Check In:{' '}
                  <strong>
                    {getCanadianTime(Number(data.checkInTime), 'ff')}{' '}
                  </strong>
                  
                </label>
              </div>
              <div>
                <label className="_label">
                  Check Out:{' '}
                  <strong>
                    {getCanadianTime(
                      Number(data.checkOutTime),
                      'ff'
                    )}
                  </strong>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-auto align-self-end">
          <label className="_price">
            Price: <strong>${total?.toFixed(2)}</strong>
          </label>
        </div>
      </div>
    </div>
  );
};

export default AccommodationOrder;
