import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import Tournaments from './tournaments';


export default () => {
    return (
        <PaginationProvider>
           <Tournaments/>
        </PaginationProvider>
    )
}