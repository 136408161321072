import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import NewsComponent from './newscomponent';

export default () => {
    return (
        <PaginationProvider>
            <NewsComponent />
        </PaginationProvider>
    )
}