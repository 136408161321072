
import './course-sync-list.scss';
import Pagination from '../../../../components/Pagination';
import { usePagination } from '../../../../contexts/PaginationContext';
import IntegolfDataNotFound from '../../../../components/integolf-data-not-found/integolf-data-not-found';



const CourseSyncList = ({
  data,
}: any) => {
  const { currentPage, totalItems ,itemsPerPage} = usePagination();

  return (
  <>
  {(data?.length>0)? <div className="userslist" >
      <div className="tableLayout">
        <div className="table-responsive" style={{minHeight:'300px'}} >
          <table className="table mb-0" >
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Teesheets Fetched</th>
                <th>Teesheets Inserted</th>
                <th>Error</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((syncData: any, j: number) => {
                return (
                  <>
                    {<tr>
                      <td>{(j + 1) + ((currentPage - 1) * 10)}</td>
                      
                      <td>
                        {syncData?.date}
                      </td>
                      <td>
                        {syncData?.syncInfoLatest?.teesheetsFetchedFromThirdParty}
                        </td>
                      <td>
                      {syncData?.syncInfoLatest?.teesheetsInserted}
                    </td>
                    <td>
                      {syncData?.errorLatest?.message ?? '-'}
                    </td>
                    </tr>}
                    
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="paginationBlock">
          <div className="row gutters-5 align-items-center justify-content-between">
            {totalItems > 2 && <Pagination />}
          </div>
        </div>
      </div>
    </div>:<IntegolfDataNotFound message={"No Data found"}/>}
  
  </>
  
    
  );
};

export default CourseSyncList;
