import { useEffect, useState } from 'react';
import './onboarding-list.scss';
import Pagination from '../../../../components/Pagination';
import { usePagination } from '../../../../contexts/PaginationContext';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { NavLink } from 'react-router-dom';
import { stepWiseDescription } from '../../../../utils/constants';
import axios from 'axios';
import { useMutation } from 'react-query';
import IntegolfDataNotFound from '../../../../components/integolf-data-not-found/integolf-data-not-found';
import IntegolfLoader from '../../../../components/integolf-loader/integolf-loader';
import { capitalizeString } from '../../../../utils/common';


const OnboardingList = () => {

  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    totalItems,
    currentPage
  } = usePagination();

  const getCourseOnboardings = async (accessToken: string) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchUsersOnboardingInfo?skip=${skip}&limit=${limit}`,
    {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };
  const accessToken = localStorage.getItem('accessToken');
  const { data, mutate: getCourses } = useMutation('coursesOnboarding', () => getCourseOnboardings(accessToken!));

  useEffect(() => {
    if (data?.response?.count && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
    }
  }, [data?.response?.count, skip]);

  useEffect(() => {
    getCourses();
  }, [skip, getCourses, itemsPerPage ])

  
  return ( 
  <>
  {data?.response?.count === 0 && <IntegolfDataNotFound message={"No Data found"}/>}
  { data?.response ? data?.response?.count>0 && <div className="userslist" >
      <div className="tableLayout">
        <div className="table-responsive" style={{minHeight:'300px'}} >
          <table className="table mb-0" >
            <thead>
              <tr>
                <th>#</th>
                <th>Course Name</th>
                <th>Integration</th>
                <th>Onboarding Status</th>
                <th>Step Pending</th>
                <th>Merchant Name</th>
                <th>Contact</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.response?.result?.map((user: any, j: number) => {
                const {courseCreationSteps, type} = user?.courseOnboardingDetails ?? {};
                const integration = user?.courseOnboardingDetails?.integration;
                let stepPending:any = Object.entries(courseCreationSteps ?? {}).find(([key,value]:any)=>value?.status === "pending")?.[0] ?? '';
                let stepPendingText = stepWiseDescription?.[stepPending]?.title;
                if(!stepPendingText){
                  stepPendingText = (integration?.status==="pending")?`${user?.courseOnboardingDetails?.type} Integration`:''
                }
                return (
                  <>
                    {
                    <tr>
                      <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                      
                      <td>
                        <div className="userContent align-items-center">
                          <span className="imgBlock rounded-circle">
                            <img
                              src={user?.photos?.[0]?.path ?? "../../assets/images/default-user.png"}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          <div className="content">
                            <h5 className="mb-0">{user?.club?.name ? user.club.name : 'n/a'}</h5>
                          </div>
                        </div>
                      </td>
                      <td>
                      {(type==='teeon' || type==="chronogolf")? <img
                          src={(type === 'teeon') ? "../../assets/images/logo-tee-on.png" : "../../assets/images/logo-chronogolf.png"}
                          alt="../../assets/images/icons/verify.svg"
                          className="img-fluid"
                          width={"100px"}
                        />: "-"}
                      </td>
                      <td>
                        {(user?.courseOnboardingDetails?.integration?.status)?<label className={`mb-0 status ${(user?.courseOnboardingDetails?.integration.status==="completed")?'status--success':'status--danger'}`}>
                            {user?.courseOnboardingDetails?.integration?.status ? capitalizeString(user?.courseOnboardingDetails?.integration?.status) : 'N/A'}
                        </label>
                         :'n/a'}
                      </td>
                      <td>{`${((user?.course)?(stepPending || 'step10')+' -':'All')}`} &nbsp;{stepPendingText}</td>
                      <td>
                        {(user?.firstname)?<>{capitalizeString(user.firstname)}&nbsp;</>:""}
                        {(user?.lastname)?capitalizeString(user.lastname):""}
                      </td>
                      <td>
                        {user?.email}
                        {user?.phone?.countryCode && <br/>}
                        {(user?.phone?.countryCode)?'+'+user?.phone.countryCode:''} {(user?.phone?.number)?user?.phone.number:''} 
                      </td>
                      <td>
                        {user?.course?._id && <div className="listActionDropdownBlock">
                          <Dropdown align="end">
                          <div className="text-center">
                            <Dropdown.Toggle className="toggleButton">
                              <em className="fa-solid fa-ellipsis-vertical"></em>
                            </Dropdown.Toggle>
                          </div>
                          <Dropdown.Menu>
                          <NavLink to={'/course/detail'} state={{id: user?.course?._id}} className="dropdown-item fw600">
                              View Course Details
                            </NavLink>
                            <NavLink to={`/course/course-onboarding/${user?.course?._id}`} state={{id: user?.courseId}} className="dropdown-item fw600">
                              View Onboarding Details
                            </NavLink>
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>}
                      </td>
                    </tr>}
                    
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="paginationBlock">
          <div className="row gutters-5 align-items-center justify-content-between">
            {totalItems > limit && <Pagination />}
          </div>
        </div>
      </div>
    </div>:<IntegolfLoader show/>
  }
  </>
    
  );
};

export default OnboardingList;
