import {  Modal } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import "./PayoutMerchantNotificationModal.scss"

type PopUpProps = {
    show: boolean,
    setShow: Function,
    submitHandler: any,
};

export default ({ setShow, show, submitHandler }: PopUpProps) => {
    const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e?.stopPropagation();
        setShow(false);  // Close the modal
    };

    return (
        <>
            <Modal
                className="payoutAccountInfoModal modalLayoutWrapper"
                show={show}
                backdrop="static"  
                keyboard={false}  
                centered
            >
                <Modal.Header className="d-flex justify-content-between align-items-center">
                    <Modal.Title>Merchant Payout Notification</Modal.Title>
                    <button
                        type="button"
                        className="btn-close" 
                        aria-label="Close"
                        onClick={handleClose}
                    >
                    </button>
                </Modal.Header>

                <Modal.Body className="text-center payout-notification-modal">
                    <h5>Are you sure you want to notify this merchant to setup the payout account details?</h5>
                    <button
                            type="button"
                            className="button button-primary payout-btns button-rounded mt-2 button-min-120 fontsize-12 fw600"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                            type="button"
                            className="button button-primary payout-btns  button-rounded mt-2 button-min-120 fontsize-12 fw600"
                            onClick={submitHandler}
                        >
                            Send
                        </button>
                </Modal.Body>
            </Modal>
        </>
    );
};
