import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm, SubmitHandler} from 'react-hook-form';
import './index.scss';
import { useEffect } from 'react';

function MyVerticallyCenteredModal(props: any) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm();

      useEffect(()=>{
        reset()
      },[props])
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {props.desc}
        </p>
        <form>
        <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">Course Code:</label>
            <input type="text" className="form-control" id="recipient-name"
             {...register("courseCode", {required:true})}/>
            {errors.courseCode?.type == "required" && (
                <div className="spanError">
                    Please enter Course Code
                </div>
            )}
            <label htmlFor="recipient-name" className="col-form-label">Access Token:</label>
            <input type="text" className="form-control" id="recipient-name"
             {...register("accessToken", {required:true})}/>
            {errors.accessToken?.type == "required" && (
                <div className="spanError">
                    Please enter Access Token
                </div>
            )}
          </div>
        </form>
        
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit(props.action)}>Proceed</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function ModalContainer(props: any) {

  return (
    <>
      <MyVerticallyCenteredModal
      setInput1={props.setInput1}
        show={props.showModal}
        action={props.action}
        onHide={props.onHide}
        title={props.title}
        desc={props.desc}
      />
    </>
  );
}
