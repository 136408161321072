import React, { FC, useEffect, useState } from "react";
import "./header.scss";
import { useLocation } from 'react-router-dom';

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const location = useLocation();
  const data = location.state;
  const [userData, setUserData] = useState<{
    email: string;
    role: string;
  }>(data);
  useEffect(() => {
    if(userData) {
      setUserData(userData)
    }
  }, [userData]);

  return (
    <header className="headerWrapper">
      <div className="row justify-content-between align-items-center">
        <div className="col-auto">
          {/* <div className="searchBlock">
            <input type="text" className="form-control" placeholder="Search" />
            <em className="icon-search"></em>
          </div> */}
        </div>
        <div className="col-auto">
          <div className="row gutters-5 align-items-center">
            <div className="col-auto">
              <span className="userImage">
                <img src="./assets/images/default-user.png" alt="" className="img-fluid" />
              </span>
            </div>
            <div className="col">
              <h5 className="mb-1 _name">{localStorage.getItem('email')}</h5>
              <h6 className="mb-0 _email text-capitalize">{localStorage.getItem('role')}</h6>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
