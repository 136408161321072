import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import BookingQueriesComponent from './booking-query';



export default () => {
    return (
        <PaginationProvider>
           <BookingQueriesComponent/>
        </PaginationProvider>
    )
}