import { useEffect, useState } from "react";
import ModalContainer from "../../../../components/Modal";

export default ({ markPayoutComplete, id, markReadyData,markBulk }: any) => {
    const [status, setStatus] = useState('Ready');
    const [showModal, setShowModal] = useState(false);
    
    useEffect(() => {
    
        if (markReadyData?.response) {
            setShowModal(false)
        }
    }, [markReadyData]);

    if (status === 'Ready') {
        return (
            <>
                <ModalContainer
                    action={() => markPayoutComplete(id)}
                    onHide={() => setShowModal(false)}
                    title='Release payout'
                    desc={'Are you sure you want to complete the payout ?'}
                    showModal={showModal} />
                <button
                    type="button"
                    className="button button-primary button-rounded fw700 payout-main-btn"
                    // className="button button-sm button-rounded button-primary button-filter fontsize-14 px-3"
                    onClick={() => {
                        setShowModal(true)
                        // markPayoutComplete(id)
                    }}>{status == 'Ready' ? markBulk ? 'Complete all Payouts' : 'Complete Payout' : '-'}</button>
            </>

        );
    } else {
        return (
            <label className={`mb-0 status status--active`}>
                {status}
            </label>
        )
    }

}