/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from "react";
import AccommodationOrder from "./AccomodationOrder.component";
import GolfOrder from "./GolfOrder.component"
import { IItineraryAccommodationOrder, IItineraryTeesheetsOrder, ItineraryOrder } from "./booking-detail-thirdparty";

export enum ItineraryType {
  ACCOMMODATION,
  TEESHEET,
}

interface IItinerariesComponent {
  data: {
    order: ItineraryOrder;
    type: ItineraryType;    
  };
  index: number;
  day: any;
}

export interface IItineraryOrderParams {
  deleteItinerary: () => void;
}

const Itenararies = ({data, index, day}: IItinerariesComponent) => {
  let element: ReactElement | undefined;
  switch(data.type) {
    case ItineraryType.TEESHEET: {
      const order = data?.order as IItineraryTeesheetsOrder; 

      if (order?.teesheets?.length) {
        element = (
          <GolfOrder
            deleteItinery={null}
            data={data.order}
            index={index}
            day={day}
          />
        );
      }
      break;
    }
    case ItineraryType.ACCOMMODATION: {
      const order = data?.order as IItineraryAccommodationOrder;
      element = (
        <AccommodationOrder
          deleteItinery={null}
          data={order?.data}
          index={index}
          day={day}
        />
      );
      break;
    }
  }

  return element || <></>;

}

export default Itenararies