import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import IntegolfLoader from '../../components/integolf-loader/integolf-loader';

interface EditorProps {
    value: string; // Accept value prop for CKEditor
    onChange: (event: any, editor: any) => void; // Callback for onChange
    onBlur: () => void; // Callback for onBlur
    placeholderText: string;
}

const CKEditorComponent: React.FC<EditorProps> = ({
    value,
    onChange,
    onBlur,
    placeholderText,
}) => {
    const accessToken: any = localStorage.getItem('accessToken');
    const [showLoader, setShowLoader] = useState(false)

    const handleUploadImage = async (file: any) => {
        setShowLoader(true)
        const formData = new FormData();
        formData.append('files', file);
        try {
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'accesstoken': accessToken,
                },
            });
            const imageUrl = response?.data?.response[0]?.path; // Adjust according to your API response
            console.log('imageUrl', imageUrl)
            setShowLoader(false)
            return { default: imageUrl };
        } catch (error) {
            console.error('Error uploading image', error);
            return { error: { message: 'Upload failed' } };
        }
    };

    const uploadImagePluginFactory = (editor: any) => {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
            return {
                upload: async () => {
                    const file = await loader.file;
                    const { default: imageUrl, error } = await handleUploadImage(file);
                    if (error) {
                        return Promise.reject(error);
                    }
                    return { default: imageUrl };
                },
            };
        };
    };

    const editorConfiguration = {
        toolbar: [
            'heading', '|', 'bold', 'link','italic', 'numberedList', 'bulletedList', 'imageUpload', 'insertTable', '|', 'undo', 'redo',
        ],
        link: {
            addTargetToExternalLinks: true
        },
        placeholder: placeholderText,
        content: {
            styles: [
                {
                    selector: '.ck-content',
                    styles: {
                        'min-height': '350px',
                    },
                },
            ],
        },
       
    };

    return (
        <>
            {showLoader && <div className="modal-loader-overlay">
                <IntegolfLoader show />
            </div>}
            <div>
                <CKEditor
                    editor={ClassicEditor}
                    data={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    config={editorConfiguration}
                    onReady={(editor) => uploadImagePluginFactory(editor)}
                />
            </div>
        </>
    );
};

export default CKEditorComponent;
