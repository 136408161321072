import React, { FC, useLayoutEffect, useState ,useEffect} from "react";
import { useCoursesFilters } from '../../courses.context';
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./filters.scss";

interface FiltersProps {
  handleCloseFilterModal: (arg: any) => void;
  handleApplyFilters: (arg: any) => void;
  showFilterComponent: boolean;
}

const Filters: FC<FiltersProps> = ({ handleApplyFilters, handleCloseFilterModal, showFilterComponent }) => {
  const {
    parValue,
    slopeValue,
    lengthValue,
    setParValue,
    setSlopeValue,
    setLengthValue,
    courseStatus,
    setCoursesStatus,
    courseHoles,
    setCoursesHoles,
    setAppliedFilters
  } = useCoursesFilters();

  const closeFilterPopup = () => {
    setCoursesStatus([]);
    setCoursesHoles([]);
  };


  const onChangeFilter = (val: any) => {
    if (val === 1 || val === 2) {
      setCoursesStatus((state: number[]) => {
        const index = state.findIndex((e: number) => e === val)
        if (index === -1) {
          return [...state, val]
        } else {
          return state.filter((e: number) => e !== val)
        }
      })
    } else {
      setCoursesHoles((state: number[]) => {
        const index = state.findIndex((e: number) => e === val)
        if (index === -1) {
          return [...state, val]
        } else {
          return state.filter((e: number) => e !== val)
        }
      })
    }
  }
 


  const applyFilters = () => {
    setAppliedFilters({courseStatus,courseHoles});
    handleApplyFilters({});
  }

  return (
    <div className="filterModal d-flex flex-column">
      <div className="filterModal__header">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Filters</h4>
          <a href={void 0} className="close" onClick={() => handleApplyFilters({})}>
            <em className="fa-solid fa-xmark"></em>
          </a>
        </div>
      </div>
      <div className="filterModal__body flex-fill">
        <form>
          <div className="filterSection">
            <div className="mb-0">
              <label className="control-label mb-2">Courses Status</label>
              <div className="py-1">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                    checked={courseStatus.findIndex((e: number) => e == 1) !== -1}
                    onChange={() => onChangeFilter(1)}
                    type="checkbox" />
                  <span className="pl30 fw600">Active</span>
                </label>
              </div>
              <div className="py-1">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                    checked={courseStatus.findIndex((e: number) => e == 2) !== -1}
                    onChange={() => onChangeFilter(2)}
                    type="checkbox" />
                  <span className="pl30 fw600">In-Active</span>
                </label>
              </div>
            </div>
          </div>
          <div className="filterSection">
            <label className="control-label mb-2">Holes</label>
            <div className="row gutters-10">
              <div className="col-auto">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                    checked={courseHoles.findIndex((e: number) => e === 18) !== -1}
                    onChange={() => onChangeFilter(18)}
                    type="checkbox" />
                  <span className="pl30 fw600">18 Holes</span>
                </label>
              </div>
              <div className="col-auto">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                    checked={courseHoles.findIndex((e: number) => e === 9) !== -1}
                    onChange={() => onChangeFilter(9)}
                    type="checkbox" />
                  <span className="pl30 fw600">9 Holes</span>
                </label>
              </div>
            </div>
            <div className="filterModal__footer">
              <div className="row gutters-5 align-items-center justify-content-between">
                <div className="col-auto">
                  <button
                    type="button"
                    className="button button-primary button-rounded button-min-110 fw600"
                    onClick={() => applyFilters()}
                  >
                    Apply
                  </button>
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="button button-secondary button-border button-rounded button-min-110 fw600"
                    onClick={() => closeFilterPopup()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filters;
