import "./default-products-list.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import dayjs from 'dayjs';
import { usePagination } from "../../../../contexts/PaginationContext";
import { Modal } from "react-bootstrap";
import { ReactHTML, useState } from "react";

const DeafultProductsList = ({
  data,
  deleteProduct
}: any) => {

  const { currentPage, totalItems, itemsPerPage } = usePagination();
 

  return (
    <>
      {data?.length > 0 && <div className="courseslistWrapper">
        <div className="tableLayout">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <div className="d-flex align-items-center">
                      <span>Title</span>
                    </div>
                  </th>
                  <th>Price</th>
                  <th>Inventory</th>
                  <th>Maximum Quantity</th>
                  <th>Added on</th>
                  <th className="width-80">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((e: any, j: number) => {
                  return (
                    <>
                      <tr key={'key_' + j}>
                        <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="userContent">
                              <span className="imgBlock">
                                <img
                                  src={e?.images?.[0]?.path}
                                  alt=""
                                  className="img-fluid"
                                />
                              </span>
                              <div className="content">
                                <h5 className="mb-1"> {e?.title ?? 'n/a'}</h5>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>$ {Number(e?.price)?.toFixed(2) ?? '0.00'}</td>
                        <td>{(e?.isInventoryRequired && e?.inventory) ? e?.inventory : 0}</td>
                        <td>{e.maxQtyForCart ?? 0}</td>
                        <td>{dayjs(e?.createdAt).format('DD MMM YYYY hh:mm a')}</td>
                        <td>
                          <div className="listActionDropdownBlock">
                            <Dropdown align="end">
                              <div className="text-center">
                                <Dropdown.Toggle className="toggleButton">
                                  <em className="fa-solid fa-ellipsis-vertical"></em>
                                </Dropdown.Toggle>
                              </div>
                              <Dropdown.Menu>
                                <NavLink to={`/products/edit/${e?._id}`} state={{ id: e?._id }} className="dropdown-item fw600">
                                  Edit
                                </NavLink>
                                <li className="dropdown-item fw600" onClick={() => deleteProduct(e?._id)}>
                                  Delete
                                </li>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>

                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="paginationBlock">
            <div className="row gutters-5 align-items-center justify-content-between">
              {totalItems > 10 && <Pagination />}
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default DeafultProductsList;
