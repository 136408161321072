import { Form, Modal } from 'react-bootstrap';
import './ViewScoresModal.scss';
import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';

/* eslint-disable-next-line */
export interface EnterScoreProps {
    showModal: boolean;
    scoresData: any;
    handleClose: (arg: any) => void;
    getScores: (arg: any) => void;
    daysData: any
}

const ViewScoresModal: FC<EnterScoreProps> = ({
    showModal,
    scoresData,
    handleClose,
    getScores,
    daysData
}) => {

    const [scoresInfo, setScoresInfo] = useState<any>([])
    const [tournamentDays, setTournamentDays] = useState<any>([])
    const [totalScoresData, setTotalScoresData] = useState<any>([])
    const [selectedDayId, setSelectedDayId] = useState("");
    const close = () => {
        handleClose(!showModal);
    };

    const getTournamentScores = (e: any) => {
        let selectedDay = e?.target?.value;
        setSelectedDayId(selectedDay);
        getScores(selectedDay)
    }

    const getHolesScoredHeaders = (holesScored: any) => {
        let scoresHeaders;
        switch (holesScored) {
            case "18":
                scoresHeaders = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "F9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "B9", "TS", "Par Status"];
                break;
            case "B9":
                scoresHeaders = ["10", "11", "12", "13", "14", "15", "16", "17", "18", "B9", "TS", "Par Status"];
                break;
            case "F9":
                scoresHeaders = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "F9", "TS", "Par Status"];
                break;
            default:
                scoresHeaders = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "TS", "Par Status"]; // Default to front 9 if no match
                break;
        }

        return scoresHeaders;
    }
    useEffect(() => {
        if (scoresData?.response?.length > 0) {
            setScoresInfo([scoresData?.response[0]?.scoresData]);
            setSelectedDayId(scoresData?.response[0]?.scoresData?.dayInfo?._id)
            setTotalScoresData(scoresData?.response[0]?.totalData);
        }
    }, [scoresData])

    useEffect(() => {
        if (daysData?.response?.length > 0) {
            setTournamentDays(daysData?.response[0]?.dayInfo)
        }
    }, [daysData])
    return (
        <>
            <Modal
                className="modalLayoutWrapper score-modal-wrapper"
                show={showModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className='score-modal-container'>
                    <div className="score-container px-3 py-4">
                        <header className="score-header d-flex justify-content-between align-items-center mb-3 p-3 border-bottom">
                            <h3 className="score-title text-secondary mb-0">Scores</h3>
                            <div className="d-flex align-items-center">
                                <select className="form-select me-3" aria-label="Select date" onChange={(e) => getTournamentScores(e)}>
                                    {tournamentDays?.length > 0 && tournamentDays.map((tournamentDay:any, index:any) => (
                                        <option
                                            value={tournamentDay?._id}
                                            key={index}
                                            selected={selectedDayId === tournamentDay?._id}
                                        >
                                            {dayjs(tournamentDay?.TheDate).format('DD MMM YYYY')}
                                        </option>
                                    ))}
                                </select>
                                <button
                                    type="button"
                                    className="btn btn-link p-0"
                                    aria-label="Close"
                                    onClick={() => close()}
                                >
                                    <i className="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </header>



                        {/* 
                        <section className="score-details px-2 pb-2">
                            <div className="d-flex align-items-start gap-4 border-bottom pb-3">
                                <div className="input-group flex-grow-1 ">
                                    <label htmlFor="front-score" className="form-label score-tab">Front</label>
                                    <input type="text" id="front-score" placeholder="Enter Here" className="form-control" value={Number(totalScoresData?.totalFront9Score) || 0} />
                                </div>
                                <div className="input-group flex-grow-1">
                                    <label htmlFor="back-score" className="form-label score-tab">Back</label>
                                    <input type="text" id="back-score" placeholder="Enter Here" className="form-control" value={Number(totalScoresData?.totalBack9Score) || 0}/>
                                </div>
                                <div className="input-group flex-grow-1">
                                    <label htmlFor="total-score" className="form-label score-tab">Total</label>
                                    <input type="text" id="total-score" placeholder="Enter Here" className="form-control"   value={Number(totalScoresData?.totalScore)|| 0}/>
                                </div>
                            </div>
                        </section> */}
                        <section className="score-table mt-1 mb-1">
                            <table className="table table-bordered table-sm">
                                {scoresInfo?.length > 0 && scoresInfo?.map((score: any, index: number) => {
                                    const scoresHeaders: any = getHolesScoredHeaders(score?.dayInfo?.HolesScored);
                                    const { Scores: scoresInfo } = score?.scores || [];
                                    const { Pars: parsInfo } = score?.scores || [];
                                    return (<>
                                        <thead className="fw-semibold" key={index}>
                                            <tr>
                                                <th>{dayjs(score?.dayInfo?.TheDate).format('DD MMM YYYY')}&nbsp;{dayjs(score?.roundInfo?.TeeOffTime).format("h:mm A")} {score?.roundInfo?.Start} {score?.roundInfo?.Stack}</th>
                                                {scoresHeaders?.map((header: string, headerIndex: number) => (
                                                    <th key={headerIndex}>{header}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody >
                                            <tr className='par-block'>
                                                <td>Par</td>
                                                {scoresHeaders?.length > 0 && scoresHeaders?.map((header: string, headerIndex: number) => (
                                                    <td key={headerIndex}>{header?.toLowerCase() === "f9"
                                                        ? ""
                                                        : header?.toLowerCase() === "b9"
                                                            ? ""
                                                            : header?.toLowerCase() === "ts"
                                                                ? ""
                                                                : header?.toLowerCase()?.trim() === "par status"
                                                                    ? ""
                                                                    : parsInfo[`Hole${Number(header)}Par`]
                                                    }</td>
                                                ))}
                                            </tr>
                                            <tr className='score-block'>
                                                <td>Score</td>
                                                {scoresHeaders?.length > 0 && scoresHeaders?.map((header: string, headerIndex: number) => (
                                                    <td key={headerIndex}>{header?.toLowerCase() === "f9"
                                                        ? score?.scores?.f9Score || 0
                                                        : header?.toLowerCase() === "b9"
                                                            ? score?.scores?.b9Score || 0
                                                            : header?.toLowerCase() === "ts"
                                                                ? Number(score?.scores?.f9Score) + Number(score?.scores?.b9Score) || 0
                                                                : header?.toLowerCase()?.trim() === "par status"
                                                                    ? Number(score?.scores?.totalParStatus) > 0 ? `+${Number(score?.scores?.totalParStatus)}` : Number(score?.scores?.totalParStatus) < 0 ? `${Number(score?.scores?.totalParStatus)}` : 'E'
                                                                    : scoresInfo[`Hole${Number(header)}Score`] 
                                                    }</td>
                                                ))}

                                            </tr>
                                        </tbody>
                                    </>
                                    );
                                })}
                                {scoresInfo?.length == 0 && <p>No scores available for this Golfer</p>}
                            </table>
                        </section>

                        {/* <footer className="score-footer d-flex justify-content-between align-items-center gap-3">
                            <button type="button" className="btn btn-outline-secondary btn-lg">Cancel</button>
                            <div className="button-group d-flex align-items-center gap-3">
                                <button type="button" className="btn btn-primary btn-lg">Save & Close</button>
                                <button type="button" className="btn btn-primary btn-lg">Save & Next</button>
                            </div>
                        </footer> */}
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewScoresModal;
