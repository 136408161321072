import { useState } from 'react';
import './merchant-list.scss';
import Pagination from '../../../../components/Pagination';
import { usePagination } from '../../../../contexts/PaginationContext';
import UserStatusActionButton from './merchant-status-action-button';
import ResendEmailPopup from './merchant-email-req-modal';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { mapTimeZone } from '../../../../utils/common';

const getStatusDetail = (status: string) => {
  switch (status) {
    case 'rejected':
      return {
        label: 'Active',
        class: 'status--danger'
      }
    case 'accepted':
      return {
        label: 'Active',
        class: 'status--success'
      }
    default:
      return {
        label: 'Inactive',
        class: 'status--warning'
      }
  }
}

function customJsBind(fn: Function, boundedThis: any, ...args: any) {
  const newBind = fn.bind(boundedThis, ...args);
  newBind.args = args;
  return newBind;
}

const MerchantList = ({
  updatedStatusData,
  markUserActiveInactive,
  data,
  resendSignupEmail,
  resendAcceptEmail/**function name important to have 'accept' in name */
}: any) => {

  const { currentPage, totalItems,itemsPerPage } = usePagination();
  const [emailStatusPopup, setEmailStatusPopup] = useState(false);
  /**function is being set in state from props - resendSignupEmail and resendAcceptEmail*/
  const [emailResendHandler, setEmailStatusHandler] = useState();

  return (
    <>
      {totalItems > 0 && <>
        <ResendEmailPopup
          showModal={emailStatusPopup}
          setShowModal={setEmailStatusPopup}
          actionHandler={emailResendHandler}
        />
        <div className="userslist">
          <div className="tableLayout">
            <div className="table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Portal</th>
                    <th>Date</th>
                    <th>Course Name</th>
                    <th>Phone No.</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th className="width-50">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((user: any, j: number) => {
                    const { label, class: cssClass } = getStatusDetail(user?.status);
                    return (
                      <>
                        <tr>
                          <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                          <td>
                            <div className="userContent align-items-center">
                              <span className="imgBlock rounded-circle">
                                <img
                                  src={user?.profileImage?.path ?? "../../assets/images/default-user.png"}
                                  alt=""
                                  className="img-fluid"
                                />
                              </span>
                              <div className="content">
                                <h5 className="mb-0">{user?.name || 'Guest user'}</h5>
                              </div>
                            </div>
                          </td>
                          <td>{user.email ? user.email : 'n/a'}</td>
                          <td className='text-capitalize'>{user?.portal || 'n/a'}</td>
                          <td>{ typeof user?.createdAt==='number'? DateTime.fromMillis(user?.createdAt, {zone:mapTimeZone({})}).toFormat(
                              "hh:mm a, EEE dd MMM yyyy"
                            ) 
                            : 
                            DateTime.fromJSDate(new Date(user?.createdAt), {zone:mapTimeZone({})}).toFormat(
                              "hh:mm a, dd MMM yyyy"
                            )
                          
                           }</td>
                          <td className='text-capitalize'>{user?.courseName}</td>
                          <td>{user?.phone?.countryCode ? `+${user?.phone?.countryCode} ${user?.phone?.number}` : 'n/a'}</td>
                          <td className='text-capitalize'>{user?.location}</td>
                          <td>
                            <label className={`mb-0 status ${cssClass} text-capitalize`}>
                              {user?.status}
                            </label>
                          </td>
                          <td style={{}}>
                            {(user?.status !== 'accepted' && user?.status !== 'rejected')?<>
                            <div style={{display:"flex"}}>
                                <UserStatusActionButton
                                      userStatus={user?.status}
                                      email={user?.email}
                                      markUserActiveInactive={markUserActiveInactive}
                                      updatedStatusData={updatedStatusData}
                                      portal={user?.portal || 'all'}
                                      merchantId={user?._id}
                                    />
                                <UserStatusActionButton
                                      userStatus={user?.status}
                                      email={user?.email}
                                      merchantId={user?._id}
                                      markUserActiveInactive={markUserActiveInactive}
                                      updatedStatusData={updatedStatusData}
                                      portal={user?.portal || 'all'}
                                      action={"reject"}
                                    />
                              </div>
                            </>:'-'
                            }
                            
                          </td>
                          <td>
                            <div className="listActionDropdownBlock">
                              <Dropdown align="end">
                                <div className="text-center">
                                  <Dropdown.Toggle className="toggleButton">
                                    <em className="fa-solid fa-ellipsis-vertical"></em>
                                  </Dropdown.Toggle>
                                </div>
                                <Dropdown.Menu>
                                  <a className="dropdown-item fw600"
                                    onClick={() => { setEmailStatusHandler(() => customJsBind(resendSignupEmail, this, { email: user.email, portal: user.portal || 'all', text: 'Sign up',merchantId:user?._id })); setEmailStatusPopup(true) }}>
                                    Resend Signup Email
                                  </a>
                                  {user.status === "accepted" && <a
                                    className="dropdown-item fw600"
                                    onClick={() => { setEmailStatusHandler(() => customJsBind(resendAcceptEmail, this, { email: user.email, portal: user.portal || 'all', text: 'Acceptance' ,merchantId:user?._id})); setEmailStatusPopup(true) }} >
                                    Resend Acceptance Email
                                  </a>}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="paginationBlock">
              <div className="row gutters-5 align-items-center justify-content-between">
                {totalItems > 10 && <Pagination />}
                {/* <div className="col-auto">
              <h6 className="mb-0">
                Showing <strong>1</strong> to <strong>10</strong> of{" "}
                <strong>100</strong> Results
              </h6>
            </div>
            <div className="col-auto">
              <div className="row gutters-5">
                <div className="col-auto">
                  <button type="button" className="button button-sm fw600 px-3">
                    Previous
                  </button>
                </div>
                <div className="col-auto">
                  <button type="button" className="button button-sm fw600 px-3">
                    Next
                  </button>
                </div>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
      }
    </>

  );
};

export default MerchantList;
