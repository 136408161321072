import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const PublicLayout = ({ component: Component }:any) => {
  const navigate = useNavigate();
  useEffect(() => {
    if(localStorage.getItem('accessToken')) {
      navigate(-1);
    }
  }, [localStorage.getItem('accessToken')]);
  return (
  <>
    <main role="main">
      <Outlet/>
    </main>
  </>
)};

export default PublicLayout;
