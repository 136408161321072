import React, { FC } from "react";
import "./transactions-list.scss";
import { NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import dayjs from "dayjs";
import Pagination from "../../../../components/Pagination";
import { usePagination } from "../../../../contexts/PaginationContext";
import { BookingPlatform, BOOKING_PLATFORM_DISCOUNTED, BOOKING_PLATFORM_THIRDPARTY } from "../../../../utils/constants";
import { DateTime } from "luxon";
import { mapTimeZone } from "../../../../utils/common";

interface TransactionsListProps { }
const getStatusDetail = (status: string) => {
  switch (status) {
    case 'SUCCESS':
      return {
        label: 'Active',
        class: 'status--active'
      }
    case 'REFUNDED':
      return {
        label: 'Blocked',
        class: 'status--warning'
      }
    default:
      return {
        label: 'Blocked',
        class: 'status--danger'
      }
  }
}
const TransactionsList = ({ data }: any) => {
  const { currentPage, totalItems,itemsPerPage } = usePagination();

  return (
    <>
    {data?.length > 0 && <div className="transactionlist">
      <div className="tableLayout">
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Transaction ID</th>
                <th>Date</th>
                <th>User</th>
                {/* <th>Provider</th> */}
                <th>Course Name</th>
                <th>Platform</th>
                <th>Amount</th>
                <th>Status</th>
                <th className="width-50">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {data.map((e: any, j: number) => {
                const statusDetail = getStatusDetail(e?.status);
                return (
                  <>
                    <tr>
                      <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                      <td>{e.transactionId}</td>
                      <td>{ DateTime.fromMillis(e?.createdAt, {zone:mapTimeZone(e?.club)}).toFormat(
                              "hh:mm a, EEE dd MMM yyyy"
                            ) }</td>
                      <td>
                        <div className="userContent align-items-center">
                          <span className="imgBlock rounded-circle">
                            <img
                              src={e?.profileImage?.path ?? "../../assets/images/default-user.png"}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          <div className="content">
                            <h5 style={{ textTransform: 'capitalize' }} className="mb-0">{`${!!e?.user?.firstname ?`${e?.user?.firstname} ${e?.user?.lastname}` : e?.booking?.user?.name?.trim() ?  e?.booking?.user?.name : 'Guest'}`}</h5>
                          </div>
                        </div>
                      </td>
                      {/* <td>
                        <div className="userContent align-items-center">
                          <span className="imgBlock rounded-circle">
                            <img
                              src={e?.profileImage?.path ?? "../../assets/images/default-user.png"}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          <div className="content">
                            <h5 className="mb-0">{`${e?.merchant?.firstname} ${e?.merchant?.lastname ?? ''}`}</h5>
                          </div>
                        </div>
                      </td> */}
                      <td>{e?.club?.name || "na"}</td>
                      <td style={{textAlign:'center'}}>
                       {(e?.booking?.bookingType)?((e?.booking?.bookingType === BookingPlatform.BOOKING_PLATFORM_DISCOUNTED)?BOOKING_PLATFORM_DISCOUNTED:BOOKING_PLATFORM_THIRDPARTY):'n/a'}
                      </td>
                      <td>${Number(e?.total_amount).toFixed(2)}</td>
                      <td>
                        {
                          <label style={{ textTransform: 'capitalize' }} className={`mb-0 status ${statusDetail?.class}`}>
                            {e?.status.toLowerCase()}
                          </label>
                        }
                      </td>
                    </tr>
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="paginationBlock">
          {totalItems > 10 && <Pagination />}
        </div>
      </div>
    </div>}
    </>
  );
};

export default TransactionsList;
