import React from "react";
import { useState, ReactElement } from "react";

export interface IFilterItem {
  filterText: string;
  children?: IFilterItem[];
  filterFunc?: () => void;
}

export class FilterItemType {
  private constructor(public readonly value: string) { }

  static readonly ALL_BOOKINGS = new FilterItemType('All Bookings').value;
  static readonly UPCOMING = new FilterItemType('Upcoming').value;
  static readonly CANCELLED = new FilterItemType('Cancelled').value;
  static readonly COMPLETED = new FilterItemType('Completed').value;

  // static readonly STATUS = new FilterItemType('Status').value;
  static readonly CONFIRMATION_PENDING = new FilterItemType('Confirmation Pending').value;
  static readonly REFUNDED = new FilterItemType('Refunded').value;
}

export const FilterFolder = ({item, onClick, index}: {item: IFilterItem, onClick: (item: any, index: number) => void, index: number}) => {

}

export const FilterSubItem = ({ child, index, selectedChild, onClick }: { child: IFilterItem, index: number, selectedChild: number, onClick: (item: any, index: number) => void }) => {
  // const [active, setActive] = useState(item.selected ?? false);
  const context = React.useContext(FilterBarContext);

  const handleClick = (item: any) => {
    onClick(child, index);
    // context.setSelected(index);
  }
 
  const isSelected = selectedChild === index;

  return (
    <>
      {/* <li className="nav-item"> */}
        <button 
          className={
            `button button-sm button-rounded filter-item-button fontsize-14 px-3 
            ${isSelected ? "filter-subitem-selected" : null}`
          } 
          onClick={handleClick} 
          style={{whiteSpace: "nowrap"}}
        >
          {child.filterText}
        </button>
      {/* </li> */}
    </>
  );
}

export const FilterItem = ({ item, onClick, index }: { item: IFilterItem, onClick: (item: any, index: number) => void, index: number }) => {
  // const [active, setActive] = useState(item.selected ?? false);
  const context = React.useContext(FilterBarContext);

  const [selectedChild, setSelectedChild] = useState(0);

  const handleClick = () => {
    onClick(item, index);
    context.setSelected(index);
  }

  const handleChildClick = (item: any, index: number) => {
    setSelectedChild(index);
  }

  const isSelected = context.selected === index;

  const children = item.children?.map((child, childIndex) => {
    return <li className='nav-item'>
      <FilterSubItem child={child} index={childIndex} selectedChild={selectedChild} onClick={handleChildClick}/>
    </li>
  });

  return (
    <>
      <li className="nav-item">
        <button 
          className={
            `button button-sm button-rounded filter-item-button fontsize-14 px-3 
            ${isSelected ? "filter-item-selected" : null}
            ${children ? 'filter-item-selected-folder' : ''}`
          } 
          onClick={handleClick} 
          style={{whiteSpace: "nowrap"}}
        >
          {item.filterText}
        </button>
      </li>
      {
        isSelected && children && 
        <div className='filter-subitem-container'>
          {[...children!]}
        </div>
      }
    </>
  );
}

export const FilterBarContext = React.createContext({selected: 0, setSelected: (value: number) => {}});

export const FilterBarProvider = ({ selectedFilter, children }: { selectedFilter?: number, children: ReactElement[] }) => {
  const [selected, setSelected] = useState(selectedFilter ?? 0);
  const value = { selected, setSelected: (value: number) => setSelected(value) };

  return (
    <FilterBarContext.Provider value={value}>
      {children.map((child, index) => <>{child}</>)}
    </FilterBarContext.Provider>
  );
}

export interface IFilterBar {
  setShowFilters: (value: boolean) => void;
  onFilterChange: (filterItem: any, index: number) => void;
  selectedFilter?: number;
}

let filterItems: IFilterItem[] = [
  { filterText: FilterItemType.ALL_BOOKINGS },
  { filterText: FilterItemType.UPCOMING },
  { filterText: FilterItemType.CANCELLED },
  { filterText: FilterItemType.COMPLETED },

  // { 
  //   filterText: FilterItemType.STATUS, 
  //   children: [
  //     { filterText: FilterItemType.FAILED },
  //     { filterText: FilterItemType.SUCCESSFUL },
  //   ]
  // },
  // { filterText: FilterItemType.FAILED },
  { filterText: FilterItemType.CONFIRMATION_PENDING },
  { filterText: FilterItemType.REFUNDED },
  
];

export const getFilterItemFromIndex = (index: number) : IFilterItem | undefined => {
  if (index > filterItems.length || index < 0) {
    return;
  }
  return filterItems[index]
}

export const FilterBar = ({ setShowFilters, onFilterChange, selectedFilter }: IFilterBar) => {
  let accordianItems: IFilterItem[] = [];

  const handleClick = (item: IFilterItem, index: number) => {
    if (item.children) {

    }
    onFilterChange(item, index);
  }

  const barItems = filterItems.map((item, index) => 
    <FilterItem key={`${index}`} item={ item } onClick={ handleClick } index={index}/>
  );

  return (
    <div className="filter-bar">
      <ul className="nav nav-pills">
        <FilterBarProvider selectedFilter={selectedFilter}>
          {barItems}
        </FilterBarProvider>
      </ul>
      {/* <div className="">
        <button
            type="button"
            className="button button-sm button-rounded button-white button-filter fontsize-14 px-3"
            onClick={() => setShowFilters(true)}
        >
            <em className="icon-filter me-2"></em> Filters
        </button>
      </div> */}
    </div>
    
  );
}