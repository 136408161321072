import React, { FC } from "react";
import "./integolf-data-not-found.scss";

interface IntegolfLoaderProps {
  message?:String
}

const IntegolfLoader: FC<IntegolfLoaderProps> = (props) => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }} className="mt-5">
        <div style={{ alignSelf: 'center' }} className="loaderInnder">
          <img src={`${process.env.PUBLIC_URL}/assets/images/not-found.png`} alt="loading.." />
          {props.message&&<h5 className="mt-2 pt-1 fontsize-16 fw600 text-center">{props.message}</h5>}
        </div>
      </div>
    </>

  );
};

export default IntegolfLoader;
