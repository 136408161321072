import { useMutation, useQuery } from 'react-query';
import React, { FC, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';


const createTaxReq = async (accessToken: string, reqObject: any) => {
  const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/addTaxDocument`, reqObject, {
    headers: {
      'Accesstoken': `${accessToken}`,
    },
  })
  .catch((err)=>{
    let message = err?.response?.data?.response?.message;
    toast.error(message ?? "Something went wrong!")
  })
  if(response?.data?.code){
    toast.success("Tax Document Added")
  }
  return response?.data || {};
}

const updateTaxReq = async (accessToken: string, reqObject: any) => {
  let url = `${process.env.REACT_APP_ADMIN_API_BASEURL}/updateTaxDocument/${reqObject?.id}`;
  delete reqObject.id;
  const response = await axios.put(url, reqObject, {
    headers: {
      'Accesstoken': `${accessToken}`,
    },
  })
  .catch((err)=>{
    let message = err?.response?.data?.response?.message;
    toast.error(message ?? "Something went wrong!")
  })
  if(response?.data?.code){
    toast.success("Tax Document Updated")
  }
  return response?.data || {};
}

const deleteTaxReq = async (accessToken: string, reqObject: any) => {
  let url = `${process.env.REACT_APP_ADMIN_API_BASEURL}/deleteTax/${reqObject?.id}`;
  const response = await axios.put(url, {}, {
    headers: {
      'Accesstoken': `${accessToken}`,
    },
  })
  .catch((err)=>{
    let message = err?.response?.data?.response?.message;
    toast.error(message ?? "Something went wrong!")
  })
  if(response?.data?.code){
    toast.success("Tax Document Deleted")
  }
  return response?.data || {};
}



  export const useCreateTaxes = (accessToken:any)=>{
    const {data:createTaxesData, error:createTaxesError, mutate:createTaxes} = useMutation('createTaxes',(reqObject:any)=> createTaxReq(accessToken, reqObject));
    return {
      createTaxes,
      createTaxesData,
      createTaxesError
    }
  }

  export const useUpdateTaxes = (accessToken:any)=>{
    const {data:updateTaxesData, error:updateTaxesError, mutate:updateTaxes} = useMutation('updateTaxes',(reqObject:any)=> updateTaxReq(accessToken, reqObject));
    return {
      updateTaxes,
      updateTaxesData,
      updateTaxesError
    }
  }

  export const useDeleteTax = (accessToken:any)=>{
    const {data:deleteTaxData, error:deleteTaxError, mutate:deleteTax} = useMutation('deleteTaxes',(reqObject:any)=> deleteTaxReq(accessToken, reqObject));
    return {
      deleteTax,
      deleteTaxData,
      deleteTaxError
    }
  }