import React, { FC, ReactElement, useEffect, useState } from "react";
import "./package-bookings-detail.scss";
import { NavLink, useLocation, useNavigate, To, Outlet } from "react-router-dom";
import axios from "axios";
import { useMutation } from "react-query";
import { DateTime } from "luxon";
import { CARD_TYPE_MAPPING, getStatusObj } from "../../../../../utils/constants";
import IntegolfLoader from "../../../../../components/integolf-loader/integolf-loader";
import { IItineraryAccommodationOrder } from "../../booking-details/booking-detail-thirdparty";
import Itenararies, { ItineraryType } from "../../booking-details/itineries.component";
import dayjs from "dayjs";
import { capitalizeString, mapTimeZone } from "../../../../../utils/common";


export interface IDetailItem {
  text: string;
  value?: string;
  boldTitle?: boolean;
}

interface IOrderAddress {
  city: string;
  province: string;
  country: string;
  postalCode: string;
}

export interface IItineraryTeesheetsOrder {
  teesheets: any[];
  clubId: string;
  isRepeating: boolean;
}

export type ItineraryOrder = (IItineraryAccommodationOrder | IItineraryTeesheetsOrder);
export const DefaultBreadcrumbNav = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className="col-auto">
        <button
          className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
          onClick={() => {
            navigate(-1);
          }}
        >
          <em className="fas fa-arrow-left"></em>
        </button>
      </div>
      <div className="col">
        <nav className="breadcrumbWrap" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <NavLink to={"/bookings/packages"}>Bookings </NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span>Booking Details</span>
            </li>
          </ol>
        </nav>
      </div>
    </>
  )
}

export const DetailItem = ({ text, value, boldTitle = false }: IDetailItem) => {
  return (
    <>
      <div className="row gutters-5 py-2">
        <div className="col">
          <label className={`mb-0 color-700 ${boldTitle ? 'fw700' : null} text-capitalize`}>{text}</label>
        </div>
        <div className="col-auto">
          <label className="mb-0 color-800 fw700"> {value ?? ''}</label>
        </div>
      </div>
    </>
  );
}

export const DetailPriceItem = ({ text, price, boldTitle = false }: IDetailItem & { price: number | undefined }) => {
  const makeSignedPrice = (price: number): string => {
    if (price < 0) {
      return `-$${Math.abs(price).toFixed(2)}`;
    } else {
      return `$${Number(price).toFixed(2)}`;
    }
  }
  return (
    <DetailItem text={text} value={`${makeSignedPrice((price ? price : 0.00))}`} boldTitle={boldTitle}></DetailItem>
  );
}

export const InfoCard = ({ title, children }: { title: string, children: React.ReactNode }) => {
  return (
    <div className="infoCard mb-3">
      <h5 className="infoCard__cardTitle mb-3">{title}</h5>
      {children}
    </div>
  );
}

const PricingCard = ({ bookingDetails, transactionInfo }: { bookingDetails: any, transactionInfo: any }) => {
  return (
    <InfoCard title={'Charges'}>
      <DetailPriceItem
        text={`Package Price`}
        price={bookingDetails?.golfPackage?.price}
      />
      {
        (bookingDetails?.orders?.products?.length > 0) &&
        bookingDetails?.orders?.products?.map((product: any) => (
          <DetailPriceItem key={Math.random()}
            text={`${product?.quantity} ${product?.title} × $${Number(product?.price || 0).toFixed(2)}`}
            price={(product?.price || 0) * (product?.quantity || 0)}
          />)
        )
      }
      <DetailPriceItem
        text={`Booking Fee (5%)`}
        price={bookingDetails?.bookingFees}
      />
      <DetailPriceItem
        text={'Taxes'}
        price={bookingDetails?.taxAmt}
      />
      <DetailPriceItem
        text={'Total'}
        price={bookingDetails?.totalPrice ? (bookingDetails?.totalPrice)?.toFixed(2) : 0.00}
        boldTitle
      />

      {!bookingDetails?.isLuckyBooking && <div className="mt-4">
        <h5 className="infoCard__cardTitle mb-2">Payment Methods</h5>
        <div className="row gutters-5 py-2">
          <div className="col">
            <label className="mb-0 color-700">Method</label>
          </div>
          <div className="col-auto">
            <h6 className="mb-0 color-800 fw700">
              {bookingDetails?.paymentInfo?.status === "Success" ? `Card ${CARD_TYPE_MAPPING[bookingDetails?.paymentInfo?.cardType || "Card"]}****${bookingDetails?.paymentInfo?.last4}` : bookingDetails?.paymentType?.Description ? bookingDetails?.paymentType?.Description?.trim() : "Payment Failed"}

            </h6>
          </div>
        </div>
        <div className="row gutters-5 py-1">
          <div className="col">
            {transactionInfo && <label className="mb-0 color-700">Transaction Date</label>}
          </div>
          <div className="col-auto">
            {transactionInfo?.createdAt && <h6 className="mb-0 color-800 fw700">

              {`${DateTime.fromMillis(transactionInfo?.createdAt, { zone: mapTimeZone(bookingDetails?.teesheets[0]?.teesheets[0]?.club) }).toFormat(
                "hh:mm a, EEE dd MMM yyyy"
              )}`}
            </h6>}
          </div>
        </div>
      </div>}
    </InfoCard>
  );
}

export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber === 'n/a') {
    return phoneNumber;
  }
  // remove any non-digit characters from our phone number string
  phoneNumber = phoneNumber?.toString()?.replace(/\D/g, '').toString();

  // get the RE matches with optional country code
  const matches = phoneNumber?.match(/^(\d)?(\d{3})(\d{3})(\d{4})/);

  if (!matches) {
    return phoneNumber;
  }

  const countryCode = matches[1] ? `+${matches[1]} ` : '';
  return `${countryCode}(${matches[2]}) ${matches[3]}-${matches[4]}`;
}


interface PackageDetailsProps {
  children?: any
}

const PackageBookingsDetail: FC<PackageDetailsProps> = ({ children }) => {
  useEffect(() => {
    const elem: any = document.getElementById("headerLayout");
    elem.className = "d-none";
    return () => {
      elem.className = "";
    };
  });
  const location = useLocation();
  const { id } = location.state;

  const getBookingDetail = async () => {
    const accessToken: any = localStorage.getItem('accessToken');
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchBooking`, {
      id,
    }, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };

  const { isLoading, error, data, mutate: getBookingDetailFn } = useMutation('bookingDetails', getBookingDetail);

  useEffect(() => {
    getBookingDetailFn();
  }, [id, getBookingDetailFn])
  const bookingDetails = data?.response?.result[0];

  const [validAccommodations, setValidAccommodations] = useState<any>("");
  const [validTeeSheets, setValidTeeSheets] = useState<any>();
  const status = getStatusObj(bookingDetails?.status?.value);
  const bookingType = bookingDetails?.bookingType;
  let user = (bookingDetails?.userId) ? bookingDetails?.userId : bookingDetails?.user;
  let userName = (user?.name) ? user?.name : (user?.firstname) ? `${user?.firstname} ${user?.lastname}` : 'Guest';
  let userPhone = (bookingDetails?.user?.phone) ? bookingDetails?.user?.phone : (bookingDetails?.user?.phone?.phone) ? `+${bookingDetails.user?.phone?.countryCode}${bookingDetails.user?.phone?.phone}` : 'n/a';
  const transactionInfo = Array.isArray(bookingDetails?.transaction) && bookingDetails?.transaction?.length > 0 ? bookingDetails?.transaction[0] : bookingDetails?.transaction
  // let userPhone = 'n/a';
  const itiStartDate:DateTime|null = (bookingDetails?.golfPackage?.itineraryStartDate)? DateTime.fromISO(bookingDetails?.golfPackage?.itineraryStartDate, {zone: mapTimeZone(bookingDetails?.teesheets[0]?.teesheets[0]?.club) }):null;
  const itiEndDate:DateTime|null = (bookingDetails?.golfPackage?.itineraryEndDate)? DateTime.fromISO(bookingDetails?.golfPackage?.itineraryEndDate, {zone: mapTimeZone(bookingDetails?.teesheets[0]?.teesheets[0]?.club) }):null;
  const checkInDate = itiStartDate ? itiStartDate.toFormat("LLL dd, yyyy"):'';
  const checkOutDate = itiEndDate ? itiEndDate.toFormat("LLL dd, yyyy"):'';

  if (bookingDetails?.user?.phone?.phone) {
    userPhone = `+${bookingDetails.user?.phone?.countryCode} ${formatPhoneNumber(bookingDetails.user?.phone?.phone)}`;
  }
  else if (bookingDetails?.user?.phone) {
   
    userPhone = `+${bookingDetails.user?.phone?.countryCode || ''} ${formatPhoneNumber(bookingDetails?.user?.phone?.number || bookingDetails?.user?.phone)}`;
  }

  const navigate = useNavigate(); 
  const updatedAccommodations: any[] = [];
  const updatedTeeSheets: any[] = [];
  (() => {
    const days = Object.keys(bookingDetails?.itenerary ?? {});
    // sort itineraries from earliest to latest.
    // NOTE: the keys for `courseDetails.itenerary` are dates encoded as strings.
    days.sort((a: any, b: any) => new Date(a) as any - (new Date(b) as any));

    const data = days.map((eachDay) => {
      const itinerary = bookingDetails.itenerary[eachDay];
      // console.log(itinerary,'itinerary')
      const accArr = itinerary?.accomodations ? itinerary?.accomodations.map((item: any) => ({ ...item, data: item })) : [];
      const teeArr = itinerary?.teesheets ? itinerary?.teesheets?.map((item: any) => ({ ...item, data: item })) : [];
      // make sure the teesheets are in order!
      teeArr.sort((a: any, b: any) => a?.dateTime - b?.dateTime);
      updatedAccommodations.push(...accArr);
      updatedTeeSheets.push(...teeArr);
    });
  })();

  const content = React.Children.count(children) > 0 ? children : <DefaultBreadcrumbNav />

  return (
    (isLoading) ? <IntegolfLoader
      show
    /> :
      bookingDetails &&
      <div className="bookingDetailWrapper">
        <div className="container-fluid p-0">
          <div className="row align-items-center mb-3">
            {content}
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8">
              <div className="infoCard mb-3">
                <div className="row gutters-10 infoCard__cardUserData">
                  <div className="col-auto">
                    <span className="userImage">
                      <img
                        src={bookingDetails?.profileImage?.[0]?.path ?? "../../assets/images/default-user.png"}
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                  </div>
                  <div className="col align-self-center">
                    <div className="row gutters-5 align-items-center">
                      <div className="col">
                        <h3 className="mb-2" style={{ textTransform: 'capitalize' }}>{userName}</h3>
                        <div className="row gutters-7 align-items-center">
                          <div className="col-auto">
                            <p className="mb-0">{user?.email}</p>
                          </div>
                          <div className="col-auto color-400">|</div>
                          <div className="col-auto">
                            <p className="mb-0">{userPhone}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <InfoCard title={'Booking Details'}>
                <div className="infoCard__booking">
                  <div className="row gutters-10">
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">Booked on</label>
                      {bookingDetails?.createdAt && <h6 className="color-800 fw700">
                        {`${DateTime.fromMillis(bookingDetails?.createdAt, { zone: mapTimeZone(bookingDetails?.teesheets[0]?.teesheets[0]?.club) }).toFormat(
                          "hh:mm a, EEE dd MMM yyyy"
                        )}`}
                      </h6>}
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">Booking code</label>
                      <h6 className="color-800 fw700">{bookingDetails?.bookingId}</h6>
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2 d-block">
                        Status
                      </label>
                      <label style={{ background: status.color, color: status.fontColor }} className={`mb-0 status`}>
                        {status?.label?.charAt(0).toUpperCase() + status?.label?.slice(1)}
                      </label>

                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">Itinerary Start</label>
                      <h6 className="color-800 fw700">
                        {`${DateTime.fromISO(bookingDetails?.golfPackage?.itineraryStartDate).toFormat(
                          "hh:mm a, EEE dd MMM yyyy"
                        )}`}
                      </h6>
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2"> Itinerary End</label>
                      <h6 className="color-800 fw700">
                        {`${DateTime.fromISO(bookingDetails?.golfPackage?.itineraryEndDate).toFormat(
                          "hh:mm a, EEE dd MMM yyyy"
                        )}`}
                      </h6>
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">
                        Golfers
                      </label>
                      <h6 className="color-800 fw700">{bookingDetails?.golfPackage?.players} players</h6>
                    </div>
                  </div>
                </div>

              </InfoCard>

            
              <InfoCard title='Booking Itinerary'>
                <div className="card bookingCard">
                  <div className="package-card-body">
                    <h6 className="cardTitle">Golf Course</h6>
                    <h5 className="fw-500 mt-3 text-capitalize " style={{ fontSize: "18px", lineHeight: "27px" }}>{updatedTeeSheets[0]?.club?.name}</h5>
                    <p className="d-flex align-items-center color-gray fw500" style={{ fontSize: "12px" }}>
                      <em className="icon-location me-2 text-capitalize "></em>
                      {capitalizeString(updatedTeeSheets[0]?.club?.formattedName ?? '')}
                    </p>
                    <p className="fw700 mb-2" style={{ fontSize: "14px", marginTop: "19px" }}>{updatedTeeSheets?.length} Tee Slots</p>
                    {
                      updatedTeeSheets?.length > 0 && updatedTeeSheets.map((tee: any, index: number) => (
                        <p className="mb-0 mt-1 fw500" style={{ fontSize: "14px", lineHeight: "21px" }} key={index}>
                          Day {index + 1} - <span style={{ color: "#414141", fontWeight: "600" }}>
                            {DateTime.fromISO(tee?.teesheetObj?.dateTimeISO, { zone: mapTimeZone(tee?.club) }).toFormat("hh:mm a, LLL dd, yyyy")}
                          </span>
                        </p>
                      ))

                    }

                  </div>




                </div>
                <div className="card bookingCard mt-3">

                  <div className="package-card-body row">

                    <h6 className="cardTitle">Hotel for Stay</h6>

                    <div className="col-12 col-md-7">
                      <h5 className="fw-500 mt-3 text-capitalize " style={{ fontSize: "18px", lineHeight: "27px" }}>{capitalizeString(updatedAccommodations[0]?.name ?? '')}</h5>
                      <p className="d-flex align-items-center color-gray fw500" style={{ fontSize: "12px" }}>
                        <em className="icon-location me-2 text-capitalize"></em>
                        {capitalizeString(updatedAccommodations[0]?.accommodationDetails[0]?.formattedName)}
                      </p>

                      <p className="mb-0 mt-1 fw500" style={{ fontSize: "14px", lineHeight: "21px" }}>Check In - <span style={{ color: "#414141", fontWeight: "600" }}>  {`${DateTime.fromISO(bookingDetails?.golfPackage?.itineraryStartDate).toFormat(
                        "hh:mm a, LLL dd, yyyy"
                      )}`}</span></p>
                      <p className="mb-0 mt-1 fw500" style={{ display: "inline", fontSize: "14px", lineHeight: "21px" }}>Check Out - <span style={{ color: "#414141", fontWeight: "600" }}>  {`${DateTime.fromISO(bookingDetails?.golfPackage?.itineraryEndDate).toFormat(
                        "hh:mm a, LLL dd, yyyy"
                      )}`}</span></p>


                    </div>

                    <div className="col-12 col-md-5 align-self-end mt-2 text-md-end">
                      {
                        updatedAccommodations[0]?.rooms?.map((room: any, index: number) => (
                          <span style={{ marginRight: "5px" }} key={index}>{capitalizeString(room?.roomType ?? '')}</span>

                        ))
                      }
                    </div>
                  </div>
                </div>
              </InfoCard>

              <InfoCard title={'Review'}>
                <div className="infoCard__review">
                  <div className="_ratingBlock">
                    <p>
                      {bookingDetails?.review ? (
                        <>
                          <div className="courseReviewWrapper__reviewItem rating-wrapper" >
                            <div className="_rating">
                              {Array.from({ length: Math.floor(bookingDetails?.rating) }).map((_, index) => (
                                <em key={index} className="fas fa-star me-1 rating-stars"></em>
                              ))}
                              <span className="rating-no">{bookingDetails?.rating}</span>
                            </div>
                            <p className="mb-0 text-wrap lh-base">
                              {bookingDetails?.review}
                            </p>
                          </div>
                        </>
                      ) : (
                        'No reviews on this booking yet'
                      )}
                    </p>
                  </div>
                </div>
              </InfoCard>
            </div>
            <div className="col-12 col-lg-5 col-xl-4">


            {bookingDetails?.golfPackage && <div className="card mb-3 package-detail-wrapper">
              <div className="card-body">
                  <h5 className="card-title mb-4">Package Details</h5>
                  <p className="card-text">
                    <p className="card-text course-name text-capitalize ">
                      <strong>Name:</strong>{" "}
                      {bookingDetails?.golfPackage?.name?.trim() || "NA"}
                    </p>
                    <strong>Duration:</strong>{" "}
                    {bookingDetails?.golfPackage?.duration} Days ({checkInDate} to {checkOutDate})
                  </p>
                </div>
                </div>}

              {bookingDetails?.status?.value?.toLowerCase() === "refunded" && <div className="card mb-3  package-detail-wrapper">
                <div className="card-body">
                  <h5 className="card-title mb-4">Refund Details</h5>
                  <p className="card-text">
                    <p className="card-text course-name text-capitalize ">
                      <strong>Amount:</strong>{" "}
                      {Number(bookingDetails?.teesheets?.[0]?.teesheets?.[0]?.refundResponse?.amount) > 0
                        ? `$${Number(bookingDetails?.teesheets?.[0]?.teesheets?.[0]?.refundResponse?.amount)?.toFixed(2)}`
                        : `$0.00`}
                    </p>
                    <strong>Refunded On:</strong>{" "}
                    {DateTime.fromMillis(
                      bookingDetails?.teesheets?.[0]?.teesheets?.[0]?.refundResponse?.createdAt,
                      { zone: mapTimeZone(bookingDetails?.teesheets[0]?.teesheets[0]?.club) }
                    ).toFormat("hh:mm a, EEE dd MMM yyyy")}
                  </p>
                </div>
              </div>}




              <PricingCard bookingDetails={bookingDetails} transactionInfo={transactionInfo} />
            </div>
          </div>
        </div>
      </div>
  );
};

export default PackageBookingsDetail;