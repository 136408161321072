import React, { FC, useLayoutEffect, useState } from "react";
import { useUserFilters } from '../../user.context';
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./filters.scss";

interface FiltersProps {
  handleCloseFilterModal: (arg: any) => void;
  handleApplyFilters: (arg: any) => void;
  showFilterComponent: boolean;
}

const Filters: FC<FiltersProps> = ({ handleApplyFilters, handleCloseFilterModal, showFilterComponent }) => {
  const {
    courseStatus,
    setCoursesStatus,
    roleStatus,
    setRoleStatus,
    setFiltersApplied

  } = useUserFilters();


  const handleRoleStatus = (key: any) => {

    if (roleStatus.hasOwnProperty(key) && roleStatus[key] === true) {
      return setRoleStatus((prevState: any) => ({ ...prevState, [key]: false }));
    } else {
      return setRoleStatus((prevState: any) => ({ ...prevState, [key]: true }));
    }

  }

  const getUserRole = (role: string) => {
    switch (role) {
      case "user":
        return 'Golfer'
      case "merchant":
        return 'Course'
      case "guest":
        return "Guest"
      case "thirdPartyMerchant":
        return "Tourism"
      case "hotel":
          return "Hotel";
      case "tournamentCourse":
        return "Tournament Course";
      case "sales":
      return "Sales";
      default:
        return "Admin";
    }
  }

  const closeFilterPopup = () => {
    setCoursesStatus([])
    setRoleStatus((prevState: any) => {
      const updatedState = Object.fromEntries(
        Object.keys(prevState).map(key => [key, false])
      );
      return updatedState;
    });
  };

  const applyFilters = () => {
    let updatedFilteredItems = Object.entries(roleStatus).filter(([key, value]) => value === true).map(([key, value]) => key);
    handleApplyFilters({});
    setFiltersApplied({ roleStatus: updatedFilteredItems, courseStatus });
  }

  return (
    <div className="filterModal d-flex flex-column">
      <div className="filterModal__header">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Filters</h4>
          <a href={void 0} className="close" onClick={() => handleApplyFilters({})}>
            <em className="fa-solid fa-xmark"></em>
          </a>
        </div>
      </div>
      <div className="filterModal__body flex-fill">
        <form>
          <div className="filterSection">

            <div className="mb-0">
              <label className="control-label mb-2">Users Status</label>
              <div className="py-1">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                    checked={courseStatus.findIndex((e: number) => e === 1) !== -1}
                    onChange={() => setCoursesStatus((state: number[]) => {
                      const index = state.findIndex((e: number) => e === 1)
                      if (index === -1) {
                        return [...state, 1]
                      } else {
                        return state.filter((e: number) => e !== 1)
                      }
                    })}
                    type="checkbox" />
                  <span className="pl30 fw600">Active</span>
                </label>
              </div>
              <div className="py-1">
                <label className="ch-checkbox ch-checkbox-bordered">
                  <input
                    checked={courseStatus.findIndex((e: number) => e === 2) !== -1}
                    onChange={() => setCoursesStatus((state: number[]) => {
                      const index = state.findIndex((e: number) => e === 2)
                      if (index === -1) {
                        return [...state, 2]
                      } else {
                        return state.filter((e: number) => e !== 2)
                      }
                    })}
                    type="checkbox" />
                  <span className="pl30 fw600">In-Active</span>
                </label>
              </div>
            </div>
            <div className="mb-0">
              <label className="control-label mb-2">User Role</label>
              {Object.entries(roleStatus).map(([key, value], index) => (
                <div className="py-1" key={index}>
                  <label className="ch-checkbox ch-checkbox-bordered">
                    <input
                      checked={value === true}
                      onChange={() => handleRoleStatus(key)}
                      type="checkbox"
                    />
                    <span className="pl30 fw600 text-capitalize">
                     {getUserRole(key)}
                    </span>
                  </label>
                </div>
              ))}
            </div>
            <div className="filterModal__footer">
              <div className="row gutters-5 align-items-center justify-content-between">
                <div className="col-auto">
                  <button
                    type="button"
                    className="button button-primary button-rounded button-min-110 fw600"
                    onClick={() => applyFilters()}
                  >
                    Apply
                  </button>
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="button button-secondary button-border button-rounded button-min-110 fw600"
                    onClick={() => closeFilterPopup()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filters;
