import React, { FC, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './courseSyncInfo.scss';
import CourseSyncList from './components/course-sync-list/course-sync-list';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import IntegolfLoader from '../../components/integolf-loader/integolf-loader';
import { usePagination } from '../../contexts/PaginationContext';
import Filters from './components/filters/filters'
import { useUserFilters } from './courseSyncInfo.context';
import { useTeeOnBoardingComp } from './courseSyncInfo.hooks';
import { useLocation, useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import IntegolfDataNotFound from '../../components/integolf-data-not-found/integolf-data-not-found';
let debounce = require('lodash.debounce');


interface CourseSyncProps { }

const CourseSyncInfo: FC<CourseSyncProps> = () => {
  const [showTab, setShowTab] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  let { id }: any = useParams();
  const onShowFilter = () => {
    document.body.classList.add("overflow-hidden");
    setShowFilters(true);
  };
  const hideFilterEvent = (value: any) => {
    document.body.classList.remove("overflow-hidden");
    setShowFilters(false);
  };

  const applyFilterEvent = (value: any) => {
    hideFilterEvent(false);
  };
  const toggleNavTab = (value: any) => {
    setShowTab(value);
  };
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    setItemsCountInCurrentPage,
  } = usePagination();
  const {
    courseStatus,
    serchTerm,
    setSearchTerm,
    appliedFilters
  } = useUserFilters();

  const location = useLocation();
  const {club} = location.state || {};
  
  const getCourseSync = async (accessToken: string) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchCoursesSyncDateWiseData?courseId=${id}&skip=${skip}&limit=${limit}`,{
      headers: {
        accesstoken: `${accessToken}`,
      },
    });
    return response.data;
  };
  const accessToken = localStorage.getItem('accessToken');
  const { data, mutate: getCourseSyncData, error:courseSyncError, isLoading } = useMutation('courseSync', () => getCourseSync(accessToken!));
  const navigate= useNavigate();

  useEffect(() => {
    if (data?.response?.count>=0 && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
    }

  }, [data?.response?.count, skip]);

  useEffect(() => {
    getCourseSyncData();
  }, [skip, getCourseSyncData, appliedFilters, serchTerm,itemsPerPage])

  useEffect(() => {
    setCurrentPage(1);
  }, [appliedFilters, serchTerm])

  if(courseSyncError){
    return <IntegolfDataNotFound />
  }
 
  if(isLoading){
    return <IntegolfLoader show />
  }

  

  return (
    <>
      <div className="usersWrapper">
        {club && club.name && <h1 className="mb-3 pageTitle">{club.name}</h1>}
        <div className="row align-items-center mb-3">
          <div className="col-auto">
            <a
              onClick={()=>navigate(-1)}
              className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
            >
              <em className="fas fa-arrow-left"></em>
            </a>
          </div>
          <div className="col">
            <nav className="breadcrumbWrap" aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                {/* <li className="breadcrumb-item">
                  <NavLink to={"/courses"}>Courses </NavLink>
                </li> */}
                <li className="breadcrumb-item active" aria-current="page">
                  <span>Course Teesheets Sync Data</span>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="col-4">
          {/* <div className="searchBlock">
            <input onChange={(e) => {
              setSearchTerm(e.target.value)
            }} type="text" className="form-control" placeholder="Search by name, email" />
          </div> */}
        </div>
        <div className="container-fluid p-0">
          <div className="row justify-content-between mb-2">
            <div className="col-12 col-lg">
            </div>
            {/* <div className="col-12 col-lg-auto">
              <button
                type="button"
                className="button button-sm button-rounded button-white button-filter fontsize-14 px-3"
                onClick={() => onShowFilter()}
              >
                <em className="icon-filter me-2"></em> Filters
              </button>
            </div> */}
          </div>
          {data?.response ? <CourseSyncList 
          data={data?.response?.result} 
          // resendSignupEmail={resendSignupEmail}
          // resendAcceptEmail={resendAcceptEmail}
          />
            :
            <IntegolfDataNotFound />}
        </div>
      </div>
      {showFilters && (
        <div className="asideFilterWrapper">
          <div className="asideFilterInnerWrap">
            <Filters
              showFilterComponent={showFilters}
              handleApplyFilters={applyFilterEvent}
              handleCloseFilterModal={hideFilterEvent}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CourseSyncInfo;
