import React, { FC, useEffect } from "react";
import "./booking-details.scss";
import { NavLink, useLocation, useNavigate, To, Outlet } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { CARD_TYPE_MAPPING, getStatusObj } from "../../../../utils/constants";
import BookingDetailThirdparty from "./booking-detail-thirdparty";
import { DateTime } from "luxon";
import { mapTimeZone } from "../../../../utils/common";


export interface IDetailItem {
  text: string;
  value?: string;
  boldTitle?: boolean;
}

export const DefaultBreadcrumbNav = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className="col-auto">
        {/* 
        <NavLink
          to={''}
          className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
        >
          <em className="fas fa-arrow-left"></em> 
        </NavLink> 
      */}
        <button
          className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
          onClick={() => {
            navigate(-1);
          }}
        >
          <em className="fas fa-arrow-left"></em>
        </button>
      </div>
      <div className="col">
        <nav className="breadcrumbWrap" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <NavLink to={"/bookings"}>Bookings </NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span>Booking Details</span>
            </li>
          </ol>
        </nav>
      </div>
    </>
  )
}

export const DetailItem = ({ text, value, boldTitle = false }: IDetailItem) => {
  return (
    <>
      <div className="row gutters-5 py-2">
        <div className="col">
          <label className={`mb-0 color-700 ${boldTitle ? 'fw700' : null} text-capitalize`}>{text}</label>
        </div>
        <div className="col-auto">
          <label className="mb-0 color-800 fw700"> {value ?? ''}</label>
        </div>
      </div>
    </>
  );
}

export const DetailPriceItem = ({ text, price, boldTitle = false }: IDetailItem & { price: number | undefined }) => {
  const makeSignedPrice = (price: number): string => {
    if (price < 0) {
      return `-$${Math.abs(price).toFixed(2)}`;
    } else {
      return `$${Number(price).toFixed(2)}`;
    }
  }
  return (
    <DetailItem text={text} value={`${makeSignedPrice((price ? price : 0.00))}`} boldTitle={boldTitle}></DetailItem>
  );
}

export const InfoCard = ({ title, children }: { title: string, children: React.ReactNode }) => {
  return (
    <div className="infoCard mb-3">
      <h5 className="infoCard__cardTitle mb-3">{title}</h5>
      {children}
    </div>
  );
}

const PricingCard = ({ courseDetails }: { courseDetails: any }) => {
  return (
    <InfoCard title={'Charges'}>
      {
        (courseDetails?.noOfGuest > 0 && courseDetails?.teesheets?.[0]?.teesheets?.[0]?.price) &&
        <DetailPriceItem
          text={`${courseDetails?.noOfGuest} Golfers × $${Number(courseDetails?.teesheets?.[0]?.teesheets?.[0]?.price).toFixed(2)}`}
          price={(courseDetails?.noOfGuest * courseDetails?.teesheets?.[0]?.teesheets?.[0]?.price)}
        />
      }
      {
        (courseDetails?.orders?.products?.length > 0) &&
        courseDetails?.orders?.products?.map((product: any) => (
          <DetailPriceItem
            text={`${product?.quantity} ${product?.title} × $${Number(product?.price || 0).toFixed(2)}`}
            price={(product?.price || 0) * (product?.quantity || 0)}
          />)
        )
      }
      {
        (courseDetails?.teesheets?.[0]?.teesheets?.[0]?.discountAmount > 0) &&
        <DetailPriceItem
          text={`Discount ${courseDetails?.teesheets?.[0]?.teesheets?.[0]?.discountPercent !== undefined && courseDetails?.teesheets?.[0]?.teesheets?.[0]?.discountPercent !== null ? `(${Math.abs(courseDetails?.teesheets[0]?.teesheets[0]?.discountPercent)}%)` : ''}`}
          price={-Math.abs(courseDetails?.teesheets?.[0]?.teesheets?.[0]?.discountAmount || 0)}
        />
      }

      <DetailPriceItem
        text={`Booking Fee (5%)`}
        price={courseDetails?.bookingFees}
      />
      <DetailPriceItem
        text={'Taxes'}
        price={courseDetails?.taxAmt}
      />
      {courseDetails?.credits > 0 && <DetailPriceItem
        text={`Credits ( ${courseDetails?.credits ?? 0} x ${(Number(courseDetails?.creditAmt ?? 0) / Number(courseDetails?.credits ?? 0)).toFixed(2)} )`}
        price={- + courseDetails?.credits}
      />}
      {courseDetails?.isLuckyBooking && <DetailPriceItem
        text={`Lucky booking Promo`}
        price={- + courseDetails?.totalPrice}
      />}
      <DetailPriceItem
        text={'Total'}
        price={courseDetails?.isLuckyBooking ? 0.00 : courseDetails?.totalPrice}
        boldTitle
      />

      {!courseDetails?.isLuckyBooking && <div className="mt-4">
        <h5 className="infoCard__cardTitle mb-3">Payment Methods</h5>
        <div className="row gutters-5 py-2">
          <div className="col">
            <label className="mb-0 color-700">Method</label>
          </div>
          <div className="col-auto">
            <h6 className="mb-0 color-800 fw700">
              {courseDetails?.paymentInfo?.status === "Success" ? `Card ${CARD_TYPE_MAPPING[courseDetails?.paymentInfo?.cardType || "Card"]}****${courseDetails?.paymentInfo?.last4}` : 'Payment Failed'}

            </h6>
          </div>
        </div>
        <div className="row gutters-5 py-2">
          <div className="col">
            <label className="mb-0 color-700">Transaction Date</label>
          </div>
          <div className="col-auto">
            {courseDetails?.transaction[0]?.createdAt && <h6 className="mb-0 color-800 fw700">

              {`${DateTime.fromMillis(courseDetails?.transaction[0]?.createdAt, { zone: mapTimeZone(courseDetails?.teesheets[0]?.teesheets[0]?.club) }).toFormat(
                "hh:mm a, EEE dd MMM yyyy"
              )}`}
            </h6>}
          </div>
        </div>
      </div>}
    </InfoCard>
  );
}

export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber === 'n/a') {
    return phoneNumber;
  }
  // remove any non-digit characters from our phone number string
  phoneNumber = phoneNumber?.toString()?.replace(/\D/g, '').toString();

  // get the RE matches with optional country code
  const matches = phoneNumber.match(/^(\d)?(\d{3})(\d{3})(\d{4})/);

  // const isPhoneNumberRE = /^([+]?\d{1,2})?(([(]\d{3}[)])|(\d{3}))(\d{3}[-]?\d{4})$/;

  if (!matches) {
    return phoneNumber;
  }

  const countryCode = matches[1] ? `+${matches[1]} ` : '';
  return `${countryCode}(${matches[2]}) ${matches[3]}-${matches[4]}`;
}


interface BookingDetailsProps {
  children?: any
}

const BookingDetails: FC<BookingDetailsProps> = ({ children }) => {
  useEffect(() => {
    const elem: any = document.getElementById("headerLayout");
    elem.className = "d-none";
    return () => {
      elem.className = "";
    };
  });
  const location = useLocation();
  const { id } = location.state;
  const getBookingDetail = async (accessToken: string) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchBooking`, {
      id,
    }, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };
  const accessToken = localStorage.getItem('accessToken');
  const { isLoading, error, data } = useQuery('bookingDetails', () => getBookingDetail(accessToken!));
  const courseDetails = data?.response?.result[0];
  const status = getStatusObj(courseDetails?.status?.value);
  const bookingType = courseDetails?.bookingType;
  let user = (courseDetails?.userId) ? courseDetails?.userId : courseDetails?.user;
  let userName = (user?.name) ? user?.name : (user?.firstname) ? `${user?.firstname} ${user?.lastname}` : 'Guest';
  // let userPhone = (courseDetails?.bookingType && courseDetails?.user?.phone)? courseDetails?.user?.phone: (courseDetails?.user?.phone?.phone)? `+${courseDetails.user?.phone?.countryCode}${courseDetails.user?.phone?.phone}` : 'n/a';

  let userPhone = 'n/a';
  if (courseDetails?.user?.phone?.phone) {
    userPhone = `+${courseDetails.user?.phone?.countryCode} ${formatPhoneNumber(courseDetails.user?.phone?.phone)}`;
  }
  else if (courseDetails?.user?.phone) {
    // userPhone = courseDetails.user.phone;
    userPhone = formatPhoneNumber(courseDetails?.user?.phone);
  }

  const navigate = useNavigate();

  const content = React.Children.count(children) > 0 ? children : <DefaultBreadcrumbNav />

  return (
    (bookingType === 1) ? <BookingDetailThirdparty
      courseDetails={courseDetails}
    /> :

      <div className="bookingDetailWrapper">
        <div className="container-fluid p-0">
          <div className="row align-items-center mb-3">
            {content}
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8">
              <div className="infoCard mb-3">
                <div className="row gutters-10 infoCard__cardUserData">
                  <div className="col-auto">
                    <span className="userImage">
                      <img
                        src={courseDetails?.profileImage?.[0]?.path ?? "../../../assets/images/default-user.png"}
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                  </div>
                  <div className="col align-self-center">
                    <div className="row gutters-5 align-items-center">
                      <div className="col">
                        <h3 className="mb-2" style={{ textTransform: 'capitalize' }}>{userName}</h3>
                        <div className="row gutters-7 align-items-center">
                          <div className="col-auto">
                            <p className="mb-0">{user?.email}</p>
                          </div>
                          <div className="col-auto color-400">|</div>
                          <div className="col-auto">
                            <p className="mb-0">{userPhone}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        {/* <a
                        href={void 0}
                        className="color-active fw700 text-decoration-underline"
                      >
                        View Profile
                      </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <InfoCard title={'Booking Details'}>
                <div className="infoCard__booking">
                  <div className="row gutters-10">
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">Booked on</label>
                      {courseDetails?.createdAt && <h6 className="color-800 fw700">
                        {`${DateTime.fromMillis(courseDetails?.createdAt, { zone: mapTimeZone(courseDetails?.teesheets[0]?.teesheets[0]?.club) }).toFormat(
                          "hh:mm a, EEE dd MMM yyyy"
                        )}`}
                      </h6>}
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">Booking code</label>
                      <h6 className="color-800 fw700">{courseDetails?.bookingId}</h6>
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2 d-block">
                        Status
                      </label>
                      <label style={{ background: status.color, color: status.fontColor }} className={`mb-0 status`}>
                        {status?.label?.charAt(0).toUpperCase() + status?.label?.slice(1)}
                      </label>
                      {/* <span className="status status-active">{courseDetails?.status?.value}</span> */}
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">Tee Time</label>
                      <h6 className="color-800 fw700">
                        {`${DateTime.fromISO(courseDetails?.teesheets[0]?.teesheets[0]?.teesheetObj?.dateTimeISO, { zone: mapTimeZone(courseDetails?.teesheets[0]?.teesheets[0]?.club) }).toFormat(
                          "hh:mm a, EEE dd MMM yyyy"
                        )}`}
                      </h6>
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2">
                        Number of Golfers
                      </label>
                      <h6 className="color-800 fw700">{courseDetails?.noOfGuest} players</h6>
                    </div>
                    <div className="col-4 _box">
                      <label className="color-700 fw500 mb-2 d-block">
                        Number Holes
                      </label>
                      <h6 className="color-800 fw700">{courseDetails?.teesheets?.[0]?.teesheets?.[0]?.teesheetObj?.holes ?? 0} Holes</h6>
                    </div>
                  </div>
                </div>

              </InfoCard>

              <InfoCard title={'Review'}>
                <div className="infoCard__review">
                  <div className="_ratingBlock">
                    {/* {(!courseDetails?.review?.length) && <p>No reviews on this booking yet</p>}
                  {courseDetails?.review?.map(({review}: any, index: number) => {
                    return <p key={index}>{review}</p>
                  } )} */}
                    <p>
                      {courseDetails?.review ? (
                        <>
                          <div className="courseReviewWrapper__reviewItem rating-wrapper" >
                            <div className="_rating">
                              {Array.from({ length: Math.floor(courseDetails?.rating) }).map((_, index) => (
                                <em key={index} className="fas fa-star me-1 rating-stars"></em>
                              ))}
                              <span className="rating-no">{courseDetails?.rating}</span>
                            </div>
                            <p className="mb-0 text-wrap lh-base">
                              {courseDetails?.review}
                            </p>
                          </div>
                        </>
                      ) : (
                        'No reviews on this booking yet'
                      )}
                    </p>
                  </div>
                </div>
              </InfoCard>

              {/* <div className="text-start">
              <button type="button" className="button button-rounded fw600 buttonCancel">Cancel Booking</button>
            </div> */}
            </div>
            <div className="col-12 col-lg-5 col-xl-4">
              <div className="infoCard mb-3">
                <h5 className="infoCard__cardTitle mb-4 pb-2">Course Details</h5>
                <div className="infoCard__courseInfo">
                  <div className="row gutters-10">
                    <div className="col-auto">
                      <div className="imgBlock">
                        <img
                          src={courseDetails?.teesheets?.[0]?.teesheets?.[0]?.club?.photos?.[0]?.path || "../../../assets/images/default-user.png"}
                          alt={`the golf course '${courseDetails?.teesheets?.[0]?.teesheets?.[0]?.club?.name}'`}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <h2 className="mb-2">{courseDetails?.teesheets?.[0]?.teesheets?.[0]?.club?.name || 'NA'}</h2>
                      <p className="mb-0 color-600 fw500">
                        <em className="icon-location"></em>{courseDetails?.teesheets?.[0]?.teesheets?.[0]?.club?.addressOne || 'NA'}
                      </p>
                      {/* <a
                      href={void 0}
                      className="color-active fw700 text-decoration-underline d-inline-block mt-4"
                    >
                      View Course
                    </a> */}
                    </div>
                  </div>
                </div>
              </div>

              {courseDetails?.status?.value?.toLowerCase() === "refunded" && (
                <div className="infoCard mb-3 p-3">
                  <h5 className="infoCard__cardTitle mb-1 pb-2">Refund Details</h5>
                  <div className="infoCard__courseInfo">
                    <div className="row gutters-10 mb-2">
                      <div className="col-12 d-flex align-items-center justify-content-between">
                        <span className="fw500">Amount</span>
                        <span className="text-muted">
                          {Number(courseDetails?.teesheets?.[0]?.teesheets?.[0]?.refundResponse?.amount) > 0
                            ? `$${Number(courseDetails?.teesheets?.[0]?.teesheets?.[0]?.refundResponse?.amount)?.toFixed(2)}`
                            : `$0.00`}
                        </span>
                      </div>
                      </div>
                      <div className="row gutters-10">
                      <div className="col-12 d-flex align-items-center justify-content-between">
                        <span className="fw500">Refunded On</span>
                        <span className="text-muted text-end">
                          {DateTime.fromMillis(
                            courseDetails?.teesheets?.[0]?.teesheets?.[0]?.refundResponse?.createdAt,
                            { zone: mapTimeZone(courseDetails?.teesheets[0]?.teesheets[0]?.club) }
                          ).toFormat("hh:mm a, EEE dd MMM yyyy")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <PricingCard courseDetails={courseDetails} />
            </div>


          </div>
        </div>
      </div>
  );
};

export default BookingDetails;
