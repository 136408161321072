import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import FilterProvider from './merchantSignup.context';
import Users from './merchantSignups';

export default () => {
    return (
        <PaginationProvider>
            <FilterProvider>
                <Users/>
            </FilterProvider>
        </PaginationProvider>
    )
}