
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import DatePicker from "react-datepicker";
import { DateTime } from 'luxon';
import "react-datepicker/dist/react-datepicker.css";
import "./sync-form-popup.scss";
import { capitalizeString } from '../../../../utils/common';

type PopUpProps = {
    show:boolean,
    setShow:Function,
    submitHandler:any,
    data:any,
    type?:string
}
function SyncFormPopup({show, setShow, data, submitHandler, type="course"}:PopUpProps) {

  const handleClose = () => setShow(false);
  const { register, handleSubmit, formState: { errors }, reset} = useForm<any>({});

  useEffect(()=>{
    let currentDt= new Date();
    reset({
      from: currentDt,
      to: currentDt,
    });
  },[show])

  let currentDate= new Date();

  const [fromDate, setFromDate] = useState(currentDate);
  const [toDate, setToDate] = useState(currentDate);

  useEffect(()=>{
    if(toDate<fromDate){
      setToDate(fromDate);
    }
  },[fromDate])

  return (
    <>

      <Modal className="updateInfoModalWrapper2"  show={show} onHide={handleClose} centered >
      <Modal.Body>
        <a
          href={void 0}
          className="closeButton"
          onClick={handleClose}
        >
          <em className="fa-solid fa-close"></em>
        </a>
        <div className="formWrap">
          {type==='course' && (
            <div className="formBlock">
              <form onSubmit={ (e)=>{
                  e.preventDefault();
                  submitHandler({...data, from:DateTime.fromJSDate(fromDate).toMillis(), to:DateTime.fromJSDate(toDate).toMillis()})
                }}>
                <div className="row gutters-10">
                  <div className="col-12 col-sm mb-4">
                      <h4 className="mb-2"> Sync Course</h4>
                      <h6 className="mb-2">{capitalizeString(data?.name??"")}</h6><br/>

                      {/* <div style={{display:"flex",justifyContent: "center", flexDirection:"column", alignItems:"center"}}> */}
                          
                          <div>
                          <label htmlFor="fromDate">Start Date</label><br/>

                            <DatePicker
                              className='form-control form-control-solid w-250px'
                              selected={fromDate}
                              minDate={new Date()}
                              onChange={(date:Date)=>setFromDate(date)}
                            />
                          </div><br/>
                          <div>
                          <label htmlFor="fromDate">End Date</label><br/>

                            <DatePicker
                              className='form-control form-control-solid w-250px'
                              selected={toDate}
                              minDate={fromDate}
                              onChange={(date:Date)=>setToDate(date)}
                            />

                          </div>

                      {/* </div> */}
                      
                      
                  </div>
                 
                </div>
                <div style={{display:"flex", justifyContent:"center"}}>
                      <button
                        type="submit"
                        className="mt-3 button button-primary button-rounded button-min-150 button-sm fontsize-16 fw700 mb-2"
                      >
                        Submit
                      </button>
                </div>
                
              </form>
            </div>
          )}

          {type==='all' && (
            <div className="formBlock">
            <form>
              <div className="row gutters-10">
                <div className="col-12 col-sm mb-4">
                    <h4 className="mb-2"> Sync Courses</h4><br/>
                    <h6>Are you sure you want to sync all courses?</h6>
                    
                </div>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                          <button
                            type="button"
                            onClick={()=>submitHandler({},"all")}
                            style={{margin:"5px"}}
                            className="mt-3 button button-primary button-rounded button-sm fontsize-16 fw500 mb-2"
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            onClick={() => setShow(false)}
                            className="mt-3 button button-danger button-rounded button-sm fontsize-16 fw500 mb-2"
                          >
                            No
                          </button>
                    </div>

              </div>
             
              
            </form>
          </div>
          )}
        </div>
      </Modal.Body>



        {/* <Modal.Header closeButton>
          <Modal.Title>Sync Course <strong>"{data?.name}"</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="formWrap">
          <Form onSubmit={ (e)=>{
            e.preventDefault();
            submitHandler({...data, from:DateTime.fromJSDate(fromDate).toMillis(), to:DateTime.fromJSDate(toDate).toMillis()})
          }}>
            <div className="mb-12">
                <label htmlFor="fromDate">Start Date</label>
                <div className="form-floating">
                  
                  <DatePicker
                    className='form-control form-control-solid w-250px'
                    selected={fromDate}
                    minDate={new Date()}
                    onChange={(date:Date)=>setFromDate(date)}

                  />

                </div><br/>
                <label htmlFor="fromDate">End Date</label>
                <div className="form-floating">
                  
                  <DatePicker
                    className='form-control form-control-solid w-250px'
                    selected={toDate}
                    minDate={fromDate}
                    onChange={(date:Date)=>setToDate(date)}
                  />

                </div><br/>
                

            </div>
        <Button type="submit" variant="primary">
            Submit
        </Button>
          </Form>
          </div>
          
        </Modal.Body> */}
       
      </Modal>
    </>
  );
}

export default SyncFormPopup;