import React, { FC, useEffect, useState } from 'react';
import './package-bookings.scss';
import IntegolfLoader from '../../../../components/integolf-loader/integolf-loader';
import IntegolfDataNotFound from "../../../../components/integolf-data-not-found/integolf-data-not-found";
import { usePagination } from '../../../../contexts/PaginationContext';
import axios from 'axios';
import { useMutation } from 'react-query';
import PackageBookingsList from './package-bookings-list/package-bookings-list';

interface PackageBookingProps { }
const PackageBooking: FC<PackageBookingProps> = () => {
	const {
		skip,
		limit,
		setTotalItems,
		setCurrentPage,
		setItemsPerPage,
		itemsPerPage,
		setItemsCountInCurrentPage,
	} = usePagination();
	const getPackageBookings = async ({
		accessToken,
		createdAt,
		searchTerm
	}: any) => {
		const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchBooking`, {
			skip,
			limit,
			// "status":courseStatus,
			"text": searchTerm.trim(),
			"sortBy": "createdAt",
			"sortOrder":-1,
			"bookingType": 4
		  },{
			headers: {
			  'Accesstoken': `${accessToken}`,
			},
		  })
		return response.data;
	}
	const [searchTerm, setSearchTerm] = useState<any>("");
	const accessToken = localStorage.getItem('accessToken');
	const { isLoading, error, data, mutate: getBookingsData } = useMutation('packagebookings', () => getPackageBookings({
		accessToken: accessToken!,
		searchTerm
	}));
	useEffect(() => {
		if (data?.response?.count >= 0 && skip === 0) {
			setCurrentPage(1);
			setItemsPerPage(itemsPerPage);
			setTotalItems(data?.response?.count);
		}
	}, [data?.response?.count, skip]);
	useEffect(() => {
		getBookingsData();
	}, [
		skip,
		getBookingsData,
		itemsPerPage
	]);
	useEffect(() => {
		if (searchTerm?.trim()?.length > 0) {
			setCurrentPage(1);
			getBookingsData();
		}
	}, [searchTerm]);
	return (
		<>
			<div className='accommodationsWrapper'>
				<h1 className='mb-3 pageTitle'>Package Bookings</h1>
				<div className='row'>
					<div className='col-4'>
						<div className='searchBlock'>

							<input
								type='text'
								className='form-control'
								placeholder='Search by id, name, email, club name'
								onChange={(e: any) => {
									setSearchTerm(e.target.value)
								}}
							></input>
						</div>
					</div>
					<div className='col-8 mb-2 text-align-right'>
					</div>
				</div>
				<div className='container-fluid p-0 mt-3'>
					{data?.response?.count === 0 && <IntegolfDataNotFound message={"No Bookings found"} />}
					{data?.response ? <PackageBookingsList data={data?.response?.result} />
						:
						<IntegolfLoader show />}

				</div>
			</div>
		</>
	);
};
export default PackageBooking;
