import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

interface PaginationContextType {
  currentPage: number;
  totalPages: number;
  nextPage: () => void;
  previousPage: () => void;
  skip: number
  limit: number;
  setCurrentPage: any;
  setItemsPerPage: any;
  setTotalItems:any;
  setItemsCountInCurrentPage: any;
  totalItems: number;
  ItemsCountInCurrentPage: number;
  itemsPerPage:number;
  availableLimits:any
}

const PaginationContext = createContext<PaginationContextType | undefined>(
  undefined
);

export const usePagination = (): PaginationContextType => {
  const context = useContext(PaginationContext);
  if (!context) {
    throw new Error('usePagination must be used within a PaginationProvider');
  }
  
  return context;
};

interface PaginationProviderProps {
  totalItems?: number;
  itemsPerPage?: number;
  fetchData?: any;
  startPage?: number;
  children: React.ReactNode;
}

const PaginationProvider: React.FC<PaginationProviderProps> = ({
  children,
  startPage,
}) => {
  const [currentPage, setCurrentPage] = useState(startPage ?? 1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [ItemsCountInCurrentPage, setItemsCountInCurrentPage] = useState(0);

  const availableLimits=[10,25,50,100,200,500];
  const totalPages = (totalItems && itemsPerPage) ? Math.ceil(totalItems / itemsPerPage): startPage ?? 1;

  const nextPage = (): void => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const previousPage = (): void => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const value: PaginationContextType = {
    currentPage,
    totalPages,
    nextPage,
    previousPage,
    itemsPerPage,
    availableLimits,
    skip: (currentPage - 1)*itemsPerPage,
    limit: itemsPerPage,
    setCurrentPage,
    setItemsPerPage,
    setTotalItems,
    totalItems,
    setItemsCountInCurrentPage,
    ItemsCountInCurrentPage,
  };

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  );
};

export default PaginationProvider;