import { FC, useEffect, useState } from "react";
import "./target-courses.scss";
import {  useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import TargetCoursesList from "./components/target-courses-list/target-courses-list";
import { usePagination } from "../../contexts/PaginationContext";
import { toast } from "react-toastify";
interface CoursesProps { }

const TargetCourses: FC<CoursesProps> = () => {
  const [showTab, setShowTab] = useState(1);

  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage
  } = usePagination();

  const navigate = useNavigate();
  const addTargetCourse = () => {
    navigate("/targetcourses/add");
  }

  const accessToken = localStorage.getItem('accessToken') as string

  const updateTargetCourses = async (payload: Object) => {
    const response = await axios.put(`${process.env.REACT_APP_ADMIN_API_BASEURL}/updateTargetCourse`, payload, {
      headers: {
        'Accesstoken': accessToken,
      }
    });
    return response.data;
  }

  const getTargetCourses = async ({
    accessToken,
    createdAt,
    searchTerm
  }: any) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchTargetCourses`, {
      params: {
        limit,
        skip,
        sortBy: "createdAt",
        sortOrder: -1,
        isDeleted:false
      },
      headers: {
        'Accesstoken': accessToken,
      }
    });
    return response.data;
  }

  const { data, mutate: getTargetCoursesData } = useMutation('targetcourse', () => getTargetCourses({
    accessToken: accessToken!
  }));
  const { isLoading, data: updatedData, error, mutate: updateTargetCourseData,isSuccess } = useMutation('targtecourseupdate', updateTargetCourses,
    {
      onSuccess: () => {
        toast.success("Details updated successfully");
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.response?.message || "Sonething went wrong!");
      },
    }
  );

  useEffect(() => {
    if (data?.response?.count >= 0 && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
    }
  }, [data?.response?.count, skip]);

  useEffect(() => {
    getTargetCoursesData();
  }, [
    skip,
    getTargetCoursesData,
    itemsPerPage,
    isSuccess
  ]);

  return (
    <>
      <div className="coursesWrapper">
        <h1 className="mb-3 pageTitle">Target Courses</h1>
        <div className="row">
          <div className="col-4">
            <div className="searchBlock">
              {/* <input onChange={(e) => {
              setSearchTerm(e.target.value)
            }} type="text" className="form-control" placeholder="Search by course name, email or location" /> */}
            </div>
          </div>
          <div className="col-8 mb-2 text-align-right">
            <button
              type="button"
              className="button button-sm button-rounded button-white button-filter fontsize-14 px-3"
              onClick={() => addTargetCourse()}
            >
              Add Course
            </button>
          </div>
        </div>
        <div className="container-fluid p-0">
          {data?.response?.count === 0 && <IntegolfDataNotFound message={"No Courses added"} />}
          {data?.response ? <TargetCoursesList data={data?.response?.result} updateTargetCourseData={updateTargetCourseData} />
            : (!updatedData || isLoading) ?
              <IntegolfLoader show /> : ''}
        </div>
      </div>
    </>
  );
};

export default TargetCourses;
