import React, { createContext, useContext, useLayoutEffect, useState } from 'react';

interface FilterContextType {
  courseStatus?:any;
  setCoursesStatus?: any;
  serchTerm: any;
  setSearchTerm:any;
  bookingStatus:any;
  setBookingStatus:any;
  appliedFilters:any;
  setAppliedFilters:any;
}

const FilterContext = createContext<FilterContextType | undefined>(
  undefined
);

export const useBookingsFilters = (): FilterContextType => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('usePagination must be used within a PaginationProvider');
  }
  return context;
};

interface ContextProviderProps {
  children: React.ReactNode;
}

const FilterProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {

  const [bookingStatus, setBookingStatus] = useState<any>(['refunded','completed', 'success','cancelled', 'failed', 'courseConfirmationPending']);

  // const [courseStatus, setCoursesStatus] = useState<any>([
  //   'refunded',
  //   'completed',
  // ]);
  const [serchTerm, setSearchTerm] = useState('');
  const [appliedFilters,setAppliedFilters]=useState<any>(['refunded','completed', 'success','cancelled', 'failed', 'courseConfirmationPending'])

  const value: FilterContextType = {
    // courseStatus,
    // setCoursesStatus,
    bookingStatus,
    setBookingStatus,
    serchTerm,
    setSearchTerm,
    appliedFilters,
    setAppliedFilters
  };

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;