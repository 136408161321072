import PaginationProvider from '../../../../contexts/PaginationContext';
import TournamentBookings from './tounament-bookings';
const TournamentsBookings = () => {
    return (
        <PaginationProvider>
        <TournamentBookings/>
        </PaginationProvider>
    )
}
  
export default TournamentsBookings;