import React, { FC, useEffect, useState } from "react";
import "./dashboard.scss";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { useQuery } from "react-query";
import dayjs from "dayjs";

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = () => {
  const [searchBy, setSearchBy] = useState({
    bookings: "daily",
    revenue: "daily",
    payments: "daily",
    signups: "daily",
    userexits: "daily",
  });

  const getDashboard = async (accessToken: string) => {
    const response = await axios.post(
      `${process.env.REACT_APP_ADMIN_API_BASEURL}/dashboardInfo`,
      [
        { key: "bookings", value: searchBy.bookings },
        { key: "revenue", value: searchBy.revenue },
        { key: "payments", value: searchBy.payments },
        { key: "signups", value: searchBy.signups },
        { key: "userexits", value: searchBy.userexits },
      ],
      {
        headers: {
          Accesstoken: `${accessToken}`,
        },
      }
    );
    return response.data;
  };
  const accessToken = localStorage.getItem("accessToken");

  const {
    isLoading,
    error,
    data: graphData,
    refetch: getDashboardData,
  } = useQuery("dashboard", () => getDashboard(accessToken!));
  const getUserCount = async (accessToken: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_ADMIN_API_BASEURL}/userCounts`,
      {
        headers: {
          Accesstoken: `${accessToken}`,
        },
      }
    );
    return response.data;
  };
  const { data: countData, refetch: getCount } = useQuery("usersCount", () =>
    getUserCount(accessToken!)
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Revenue",
      },
    },
  };
  const graphTypes = graphData?.response
    ? Object.keys(graphData?.response)
    : [];

  const mapData = graphTypes?.map((each: any) => {
    let valueKey = "count";
    if (each === "revenue") {
      valueKey = "revenue";
    }
    if (each === "payments") {
      valueKey = "amount";
    }
    let labels = [];
    let datasets = [];
    if (graphData?.response?.[each].length === 7) {
      labels = graphData?.response?.[each].map((each: any) => {
        return dayjs(each.from).format("DD - MMM");
      });
    } else if (
      graphData?.response?.[each].length === 4 ||
      graphData?.response?.[each].length === 5
    ) {
      labels = graphData?.response?.[each].map((each: any) => {
        return `${dayjs(each.from).format("DD - MMM")} to ${dayjs(
          each.to
        ).format("DD - MMM")}`;
      });
    } else {
      labels = graphData?.response?.[each].map((each: any) => {
        return `${dayjs(each.from).format("MMM-YYYY")}`;
      });
    }

    if (each === "signups") {
      let labels = [
        { key: "hotel", label: "Accommodation", backgroundColor: "#153230" },
        { key: "merchant", label: "Courses", backgroundColor: "#04B2A5" },
        {
          key: "thirdPartyMerchant",
          label: "Tourism",
          backgroundColor: "#2A3B5A",
        },
        {
          key: "tournamentCourse",
          label: "Tournament Courses",
          backgroundColor: "#067D71",
        },
        { key: "user", label: "Golfers", backgroundColor: "#8AB4F8" },
      ];
      labels.map((label: any) => {
        datasets.push({
          label: label.label,
          data:
            graphData?.response?.[each]?.map((obj: any) => obj[label.key]) ??
            [],
          backgroundColor: label.backgroundColor,
          borderRadius: 6,
        });
      });
    } else if (each === "revenue") {
      let labels = [
        { key: "golfer", label: "Golfer", backgroundColor: "#153230" },
        { key: "course", label: "Course", backgroundColor: "#04B2A5" },
        {
          key: "merchandise",
          label: "Merchandise",
          backgroundColor: "#2A3B5A",
        },
      ];
      labels.map((label: any) => {
        datasets.push({
          label: label.label,
          data:
            graphData?.response?.[each]?.map((obj: any) => obj[label.key]) ??
            [],
          backgroundColor: label.backgroundColor,
          borderRadius: 6,
        });
      });
    } else if (each === "userexits") {
      let labels = [
        { key: "user", label: "Golfer", backgroundColor: "#153230" },
        { key: "merchant", label: "Course", backgroundColor: "#04B2A5" },
        {
          key: "thirdPartyMerchant",
          label: "Third Party Merchant",
          backgroundColor: "#2A3B5A",
        },
      ];
      labels.map((label: any) => {
        datasets.push({
          label: label.label,
          data:
            graphData?.response?.[each]?.map((obj: any) => obj[label.key]) ??
            [],
          backgroundColor: label.backgroundColor,
          borderRadius: 6,
        });
      });
    } else {
      datasets.push({
        label: each[0].toUpperCase() + each?.substring(1, each.length),
        data:
          graphData?.response?.[each]?.map((obj: any) => obj[valueKey]) ?? [],
        backgroundColor: "#153230",
        borderRadius: 6,
      });
    }
    return {
      title: each,
      labels,
      datasets: datasets,
    };
  });

  useEffect(() => {
    getDashboardData();
    getCount();
  }, [searchBy]);
  const {
    all,
    guest,
    merchant,
    user,
    sales,
    hotel,
    tournamentCourses,
    tourism,
    coursesCountInActive,
    coursesCountActive,
    accomodationCountActive,
    accomodationCountInactive,
    coursesCount,
    accomodationCount,
  } = countData?.response?.[0] ?? {};
  return (
    <>
      <div className="dashboardWrapper py-4">
        <h1 className="mb-3 pageTitle">Dashboard</h1>
        <div className="container-fluid p-0">
          <div className="row gutters-10 mb-2">
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="dashboardWidget">
                <h5>Total Users</h5>
                <h3>{all?.total  ? all?.total+guest?.total || 0 : ''}</h3>
                <div className="row gutters-5">
                  <div className="col">
                    <label className="m-0 _label">
                      Active: <span className="_active">&nbsp;{all?.active ?  all?.active+guest?.active|| 0 : ''}</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="m-0 _label">
                      Inactive:
                      <span className="_inactive">&nbsp;{all?.inactive  ?  all?.inactive+guest?.inactive : ''}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="dashboardWidget">
                <h5>Course Users</h5>
                <h3>{merchant?.total}</h3>
                <div className="row gutters-5">
                  <div className="col">
                    <label className="m-0 _label">
                      Active: {" "}
                      <span className="_active">{merchant?.active}</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="m-0 _label">
                      Inactive:&nbsp;
                      <span className="_inactive">{merchant?.inactive}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="dashboardWidget">
                <h5>Accommodation Users</h5>
                <h3>{hotel?.total}</h3>
                <div className="row gutters-5">
                  <div className="col">
                    <label className="m-0 _label">
                      Active:&nbsp; <span className="_active">{hotel?.active}</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="m-0 _label">
                      Inactive:&nbsp;
                      <span className="_inactive">{hotel?.inactive}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="dashboardWidget">
                <h5>Tourism Users</h5>
                <h3>{tourism?.total}</h3>
                <div className="row gutters-5">
                  <div className="col">
                    <label className="m-0 _label">
                      Active: &nbsp;<span className="_active">{tourism?.active}</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="m-0 _label">
                      Inactive:&nbsp;
                      <span className="_inactive">{tourism?.inactive}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="dashboardWidget">
                <h5>Golfers</h5>
                <h3>{user?.total}</h3>
                <div className="row gutters-5">
                  <div className="col">
                    <label className="m-0 _label">
                      Active:&nbsp; <span className="_active">{user?.active}</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="m-0 _label">
                      Inactive:&nbsp;
                      <span className="_inactive">{user?.inactive}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="dashboardWidget">
                <h5>Guest Users</h5>
                <h3>{guest?.total}</h3>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="dashboardWidget">
                <h5>Sales Users</h5>
                <h3>{sales?.total}</h3>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="dashboardWidget">
                <h5>Tournament Courses</h5>
                <h3>{tournamentCourses?.total}</h3>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="dashboardWidget">
                <h5>Golf Clubs</h5>
                <h3>{coursesCount}</h3>
                <div className="row gutters-5">
                  <div className="col">
                    <label className="m-0 _label">
                      Active:{" "}
                      <span className="_active">{coursesCountActive}</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="m-0 _label">
                      Inactive:&nbsp;
                      <span className="_inactive">{coursesCountInActive}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
              <div className="dashboardWidget">
                <h5>Accommodations</h5>
                <h3>{accomodationCount}</h3>
                <div className="row gutters-5">
                  <div className="col">
                    <label className="m-0 _label">
                      Active:{" "}
                      <span className="_active">{accomodationCountActive}</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="m-0 _label">
                      Inactive:&nbsp;
                      <span className="_inactive">
                        {accomodationCountInactive}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {mapData?.map((each: any) => {
              return (
                <div className="col-6 mt-2 p-2 ">
                  <div className="card">
                    <div className="card-header">
                      <div className="row gutters-5">
                        <div className="col">
                          <h4 className="mb-0">
                            {each["title"] == "userexits"
                              ? "EXIT USERS"
                              : each["title"].toUpperCase()}
                          </h4>
                        </div>
                        <div className="col-auto">
                          <div className="row gutters-5">
                            <div className="col-auto">
                              <select
                                onChange={(e) => {
                                  setSearchBy((state) => ({
                                    ...state,
                                    [each["title"]]: e.target.value,
                                  }));
                                }}
                                className="form-select"
                              >
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                              </select>
                            </div>
                            {/* <div className="col-auto">
                          <div className="listActionDropdownBlock">
                            <Dropdown align="end">
                              <Dropdown.Toggle className="toggleButton">
                                <em className="fa-solid fa-ellipsis-vertical"></em>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <NavLink to={"/"} className="dropdown-item">
                                  Option One
                                </NavLink>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                   
                    <div className="card-body">
                      {graphData && (
                        <Bar
                          options={options}
                          height={250}
                          data={each}
                          className="barChart"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
