import React, { useEffect, useState } from 'react';
import { useMutation } from "react-query";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import VerifyOtp from "./VerifyOtp";
const login = (payload: any) => {
  return axios.post(`${process.env.REACT_APP_USER_API_BASEURL}/users`, payload);
}

export const useLogin = () => {
  return useMutation(login)
}


const LoginPage = () => {
  const { mutate: login, data, error } = useLogin();
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState("admin")
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  useEffect(() => {
    if (data) {
      setShowVerifyOtp(true)
      const userRole=data?.data?.payload?.role;
      if(userRole){
        localStorage.setItem("role",userRole)
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error('Invalid credentials')
    }
  }, [error]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (email && email.trim()) {
      login({
        "email": email,
        "role":"admin"
      })
      localStorage.setItem('email', email);
    } else {
      toast.error('Please enter valid email')
    }
  }
  if (showVerifyOtp) {
    return (
      <VerifyOtp resendOtp={login} setShowVerifyOtp={setShowVerifyOtp} email={email} />
    )
  }
  return (
    <>
      <section className="authWrapper">
        <div className="authMain flex-fill d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="authWrap">
                  <div className="mb-4 pb-3">
                    <h1>Login</h1>
                  </div>
                  <div className="formWrap">
                    <form onSubmit={onSubmit}>
                      <div className="mb-4">
                        <label className="mb-2 fw500 control-label">Email Address</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="text-center pt-3">
                        <button type="submit" className="button button-primary button-rounded button-block fw700">Sign In</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toaster position='bottom-center' />
    </>
  );
};

export default LoginPage;
