import React, { FC, useEffect, useState } from "react";
import "./tournament-courses.scss";
import axios from "axios";
import { useMutation } from "react-query";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";
import  { usePagination } from "../../contexts/PaginationContext";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import TournamentCoursesList from "./tournament-courses-list/tournament-courses-list";

const TournamentCourses: FC<any> = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const {
        skip,
        limit,
        setTotalItems,
        setCurrentPage,
        setItemsPerPage,
        itemsPerPage,
        totalPages,
        setItemsCountInCurrentPage
    } = usePagination();


    const getTournaments = async () => {
        const accessToken: any = localStorage.getItem('accessToken');
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchTournamentCourses`, {
            params: {
                limit,
                skip,
                sortBy: "createdAt",
                sortOrder: -1
            },
            headers: {
                'Accesstoken': accessToken,
            }
        });
        return response.data;
    };

    const { data, mutate: getTournamentsList,isLoading } = useMutation('tournaments', () => getTournaments());
    useEffect(() => {
        if (data?.response?.count >= 0 && skip === 0) {
            setCurrentPage(1);
            setItemsPerPage(itemsPerPage);
            setTotalItems(data?.response?.count);
        }
    }, [data?.response?.count, skip]);

    useEffect(() => {
        getTournamentsList();
    }, [
        skip,
        itemsPerPage
    ]);


    return (
        <>
			<div className='accommodationsWrapper'>
				<h1 className='mb-3 pageTitle'>Tournament Courses</h1>
				<div className='row'>
					<div className='col-4'>
						<div className='searchBlock'>
						</div>
					</div>
					<div className='col-8 mb-2 text-align-right'>
					
					</div>
				</div>
				<div className='container-fluid p-0 mt-4'>
					{data?.response?.count === 0 && <IntegolfDataNotFound message={"No Tournaments Courses found"}/>}
					{data?.response ? <TournamentCoursesList data={data?.response?.result} />
						:
						<IntegolfLoader show />}
				</div>
			</div>
        </>
    );
};

export default TournamentCourses;
