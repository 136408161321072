import PaginationProvider from '../../../../contexts/PaginationContext';
import PackageBooking from "./package-bookings"

const PackageBookings = () => {
    return (
        <PaginationProvider>
        <PackageBooking/>
        </PaginationProvider>
    )
}
  
export default PackageBookings;