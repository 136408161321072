import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props: any) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {props.desc}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.action}>Proceed</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function ModalContainer(props: any) {

  return (
    <>
      <MyVerticallyCenteredModal
        show={props.showModal}
        action={props.action}
        onHide={props.onHide}
        title={props.title}
        desc={props.desc}
      />
    </>
  );
}
