import React, { FC, useEffect, useState } from "react";
import "./tournament-golfers.scss";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import Pagination from "../../../components/Pagination";
import IntegolfLoader from "../../../components/integolf-loader/integolf-loader";
import PaginationProvider, { usePagination } from "../../../contexts/PaginationContext";
import IntegolfDataNotFound from "../../../components/integolf-data-not-found/integolf-data-not-found";
import ViewScoresModal from "../modals/ViewScoresModal";

interface TournamentGolfersProps { }

const TournamentGolfers: FC<TournamentGolfersProps> = () => {

    const {
        skip,
        limit,
        totalItems,
        setTotalItems,
        currentPage,
        setCurrentPage,
        setItemsPerPage,
        itemsPerPage,
        totalPages,
        setItemsCountInCurrentPage
    } = usePagination();

    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [golferId,setGolferId]=useState<any>("")
    const [showScoresModal,setShowScoresModal]=useState<boolean>(false);

    const accessToken: string = localStorage.getItem('accessToken') ?? 'err';

    const getTournamentGolfers = async (accessToken: string) => {
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchGolfers/${id}`, {
            params: {
                limit,
                skip,
                "sortBy": "CreatedAt",
                "sortOrder": -1
            },
            headers: {
                "Accesstoken": accessToken
            }
        });
        return response.data;
    }

    const getTournamentGolferScores=async (dayId?:any)=>{
        const params: any = {
            TournamentId: id
        };

        if (dayId) {
            params['TournamentDayId'] = dayId;
        }

        const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchScores/${golferId}`, {
            params,
            headers: {
                "Accesstoken": accessToken
            }
        });
        return response.data;
    }

    const getTournamentDays=async ()=>{
        const params: any = {
            TournamentId: id
        };
    
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchDays/${golferId}`, {
            params,
            headers: {
                "Accesstoken": accessToken
            }
        });
        return response.data;
    }
    
    const viewScores=(golferId:any)=>{
        setGolferId(golferId)
        setShowScoresModal(true);
    }
    
    
    const { isLoading, error, data, mutate: getGolfers } = useMutation('tournamentgolfers', () => getTournamentGolfers(accessToken))
    const { isLoading:daysLoading, error:tournamentDaysError, isSuccess:tournamentDaysSuccess, data:daysData, mutate: getTournamentDaysInfo } = useMutation('tournamentdays', getTournamentDays)
    const { isLoading:scoresDataLoading, error:scoresDataError, isSuccess, data:scoresData, mutate: getScoresInfo } = useMutation('tournamentscores', getTournamentGolferScores)
    useEffect(() => {
        getGolfers();
    }, [
        id,
        getGolfers,
    ]);
    
    useEffect(()=>{
        if(golferId?.trim()!==""){
            getScoresInfo("");
            getTournamentDaysInfo();
        }
    },[golferId,getScoresInfo,showScoresModal])
    
    
    useEffect(() => {
        if (data?.response?.count >= 0 && skip === 0) {
            setCurrentPage(1);
            setItemsPerPage(itemsPerPage);
            setTotalItems(data?.response?.count);
        }

    }, [data?.response?.count, skip]);

    useEffect(() => {
        getGolfers()
    }, [
        skip,
        getGolfers,
        itemsPerPage
    ]
    );

    const golfers = data?.response?.result;

    const filteredGolfers = Array.isArray(golfers) ? golfers.filter((golfer) => golfer._id === id) : [];

    const golfersCount = data?.response?.count;

    return (
        <>
          {golfersCount===0 && <IntegolfDataNotFound message={"Golfers not found"}/>}
            {isLoading || scoresDataLoading? (
                <IntegolfLoader show />
            ) : golfersCount > 0 && (
                <div className='accommodationsListWrapper'>
                    <div className='tableLayout'>
                        <div className='table-responsive'>
                            <table className='table mb-0'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            <div className='d-flex align-items-center'>
                                                <span>Name</span>
                                            </div>
                                        </th>
                                        <th>Email</th>
                                        <th>Flight Name</th>
                                        <th>Handicap Index</th>
                                        <th>Course HCP</th>
                                        <th>Gender</th>
                                        <th>Paid Status</th>
                                        <th>CheckIn Status</th>
                                        <th>NoShow Status</th>
                                        <th>Added On</th>
                                        <th className="width-50">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {golfers?.map((e: any, j: number) => {
                                        return (
                                            <tr key={'key_' + j}>
                                                <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='userContent tournament-title'>
                                                            <div className='content'>
                                                                <h5 className='mb-1 text-capitalize'>
                                                                  {e?.golferInfo?.firstname?.trim() ? `${e?.golferInfo?.firstname?.trim()} ${e?.golferInfo?.lastname?.trim()}` : "n/a"}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{e?.golferInfo?.email?.trim() ?? "n/a"}</td>
                                                <td className="text-capitalize"> {e?.flightInfo?.FlightName?.trim() ?? "n/a"}</td>
                                                <td>{e?.golderInfo?.handicapIndex ?? 0}</td>
                                                <td>{e?.CourseHCP ?? 0}</td>
                                                <td className="text-capitalize">{e?.golferInfo?.gender==1 ? "Male" : e?.golderInfo?.gender==2 ? "Female" : "Mixed"}</td>
                                                <td>{e?.Paid ? "Yes" : "No"}</td>
                                                <td>{e?.CheckedIn ? "Yes" : "No"}</td>
                                                <td>{e?.NoShow ? "Yes" :"No"}</td>
                                                <td>{dayjs(e?.golferInfo?.createdAt).format('DD MMM YYYY hh:mm a')}</td>
                                                <td>
                                                   {e?.golferRoundInfo && <button className="button button-primary button-rounded button-sm fontsize-14 fw500 px-3 mb-3 viewscoresbutton" onClick={()=>viewScores(e?.golferInfo?._id)}>View Scores</button>}
                                                 </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                           <ViewScoresModal
                             showModal={showScoresModal}
                             handleClose={()=>setShowScoresModal(false)}
                             scoresData={scoresData}
                             daysData={daysData}
                             getScores={getScoresInfo}
                           />
                            <div className='paginationBlock'>
                                <div className='row gutters-5 align-items-center justify-content-between'>
                                    {totalItems > 10 && <Pagination />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>

    );
};

const TournamentGolfersWrapper = () => {
    return (
        <PaginationProvider>
            <TournamentGolfers />
        </PaginationProvider>
    );
}

export default TournamentGolfersWrapper;
