import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import BankAccountsComponent from './bankaccountscomponent';


export default () => {
    return (
        <PaginationProvider>
            <BankAccountsComponent />
        </PaginationProvider>
    )
}