import { useEffect, useState } from "react";
import ModalContainer from "../../../../components/Modal";

export default ({ showModal, setShowModal, actionHandler, data }: any) => {
    function modalSubmitHandler(){
        actionHandler({id:data?._id}); 
        setShowModal(false);
      }
    return (
        <>
            <ModalContainer
                showModal={showModal}
                action={modalSubmitHandler}
                onHide={() => setShowModal(false)}
                title= {'Confirmation'}
                desc={`Are you sure you want to delete tax document with country '${data?.country || ''}' and province '${data?.province || ''}'? `}
                 />
        </>

    );

}