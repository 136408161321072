import React, { FC, useEffect } from "react";
import "./tournament-flights.scss";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import Pagination from "../../../components/Pagination";
import IntegolfLoader from "../../../components/integolf-loader/integolf-loader";
import PaginationProvider, { usePagination } from "../../../contexts/PaginationContext";
import IntegolfDataNotFound from "../../../components/integolf-data-not-found/integolf-data-not-found";

interface TournamentFlightsProps { }

const TournamentFlights: FC<TournamentFlightsProps> = () => {
    const {
        skip,
        limit,
        totalItems,
        setTotalItems,
        currentPage,
        setCurrentPage,
        setItemsPerPage,
        itemsPerPage,
        totalPages,
        setItemsCountInCurrentPage
    } = usePagination();

    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();


    const accessToken: string = localStorage.getItem('accessToken') ?? 'err';

    const getTournamentFlights = async (accessToken: string) => {
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchFlights/${id}`, {
            params: {
                limit,
                skip,
                "sortBy": "CreatedAt",
                "sortOrder": -1
            },
            headers: {
                "Accesstoken": accessToken
            }
        });
        return response.data;
    }

    const { isLoading, error, data, mutate: getFlights } = useMutation('tournamentflights', () => getTournamentFlights(accessToken))

    useEffect(() => {
        getFlights();
    }, [
        id,
        getFlights
    ]);

    useEffect(() => {
        if (data?.response?.count >= 0 && skip === 0) {
            setCurrentPage(1);
            setItemsPerPage(itemsPerPage);
            setTotalItems(data?.response?.count);
        }

    }, [data?.response?.count, skip]);

    useEffect(() => {
        getFlights()
    }, [
        skip,
        getFlights,
        itemsPerPage
    ]
    );

    const flights = data?.response?.result;

    const filteredGolfers = Array.isArray(flights) ? flights.filter((flight) => flight._id === id) : [];

    const flightsCount = data?.response?.count;
 

    return (
        <>
           {flightsCount===0 && <IntegolfDataNotFound message={"Flights not found"}/>}
            {isLoading ? (
                <IntegolfLoader show />
            ) : flightsCount > 0 && (
                <div className='accommodationsListWrapper'>
                    <div className='tableLayout'>
                        <div className='table-responsive'>
                            <table className='table mb-0'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            <div className='d-flex align-items-center'>
                                                <span>Flight Name</span>
                                            </div>
                                        </th>
                                        <th>Tee Name</th>
                                        <th>Upper and Lower Limit</th>
                                        <th>Gender</th>
                                        <th># Golfers</th>
                                        <th>Added On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {flights?.map((e: any, j: number) => {
                                        return (
                                            <tr key={'key_' + j}>
                                                <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='userContent tournament-title'>
                                                            <div className='content'>
                                                                <h5 className='mb-1 text-capitalize'>
                                                                    {e?.FlightName ?? "n/a"}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-capitalize">{e?.teeInfo?.TeeName ?? "n/a"}</td>
                                                <td>{`${e?.UpperLimit} - ${e?.LowerLimit}` }</td>
                                                <td className="text-capitalize">{e?.Gender===1 ? "Male" : e?.Gender===2 ? "Female" : "Mixed"}</td>
                                                <td>{e?.totalGolfers ?? 0 }</td>
                                                <td>{dayjs(e?.CreatedAt).format('DD MMM YYYY hh:mm a')}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className='paginationBlock'>
                                <div className='row gutters-5 align-items-center justify-content-between'>
                                    {totalItems > 10 && <Pagination />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>

    );
};

const TournamentFlightsWrapper = () => {
    return (
        <PaginationProvider>
            <TournamentFlights />
        </PaginationProvider>
    );
}

export default TournamentFlightsWrapper;
