import React, { FC, useEffect, useState } from 'react';
import './payouts.scss';
import PayoutList from './components/payout-list/payout-list';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import IntegolfLoader from '../../components/integolf-loader/integolf-loader';
import Filters from './components/filters/filters';
import { usePagination } from '../../contexts/PaginationContext';
import { usePayoutsFilters } from './payouts.context';

import dayjs from "dayjs";
import IntegolfDataNotFound from '../../components/integolf-data-not-found/integolf-data-not-found';
import { toast } from 'react-toastify';

interface TransactionsProps { }
const Transactions = () => {
  const [showTab, setShowTab] = useState(1);
  const [payoutGroupedData, setPayoutGroupedData] = useState({});
  const toggleNavTab = (value: any) => {
    setShowTab(value);
  };
  const [showFilters, setShowFilters] = useState(false);

  const onShowFilter = () => {
    document.body.classList.add("overflow-hidden");
    setShowFilters(true);
  };
  const hideFilterEvent = (value: any) => {
    document.body.classList.remove("overflow-hidden");
    setShowFilters(false);
  };

  const applyFilterEvent = (value: any) => {
    hideFilterEvent(false);
  };
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    setItemsCountInCurrentPage,
  } = usePagination();

  const {
    courseStatus,
    setCoursesStatus,
    appliedFilters
  } = usePayoutsFilters();
  const getPayouts = async (accessToken: string) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchAdminClubPayouts`, {
      skip,
      limit,
      status: appliedFilters,
      "sortBy": "createdAt",
      "sortOrder":-1
    }, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };

  const markReady = async (accessToken: string, id: any) => {
    let bodyData: any = []
    if (id.length > 0) {
      id.forEach((payoutId: string) => {
        bodyData.push({
          id: payoutId,
          "status": "COMPLETED"
        })
      });
    }
    const response = await axios.put(`${process.env.REACT_APP_ADMIN_API_BASEURL}/payoutStatus`, bodyData, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  }
  const accessToken = localStorage.getItem('accessToken');
  const { isLoading: payoutLoading, data: payoutData, mutate } = useMutation('payouts', () => getPayouts(accessToken!));
  const { mutate: markPayoutComplete, data: markReadyData } = useMutation('markPayoutComplete', (id: any) => markReady(accessToken!, id));
  useEffect(() => {
    if (payoutData?.response?.count && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(payoutData?.response?.count);
      // setItemsCountInCurrentPage(data?.response?.length ?? 0);
    }

  }, [payoutData?.response?.count, skip]);

  /**Modify payout data and group it by date */
  useEffect(() => {
      let payouts = payoutData?.response?.result;
      setPayoutGroupedData(payouts);
  }, [payoutData?.response?.result])

  useEffect(() => {
    mutate()
  }, [mutate, skip, appliedFilters, markReadyData,itemsPerPage]);
  useEffect(() => {
    setCurrentPage(1);
  }, [appliedFilters, setCurrentPage]);
  const sendEmailToMerchant = async (payload: any) => {
    const accessToken: any = localStorage.getItem("accessToken");
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/merchantPayoutNotification`, payload, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  }

  const { mutate: sendEmailNotification, data: notificationResponse, isSuccess, isError, isLoading } = useMutation('emailnotification', sendEmailToMerchant);
  useEffect(() => {
    if (isSuccess && notificationResponse) {
      toast.success(notificationResponse?.message || notificationResponse?.response?.message || "Notification send successfully");
      mutate();
    } else if (isError) {
      toast.error("Something went wrong")
    }
  }, [isSuccess, isError, notificationResponse])
  // useEffect(()=>{

  // },[markReadyData])
  return (
    <>
      <div className="transactionsWrapper">
        <h1 className="mb-3 pageTitle">Payout</h1>
        <div className="container-fluid p-0">
          <div className="row justify-content-end mb-2">
            <div className="col-12 col-lg-auto">
              <button
                type="button"
                className="button button-sm button-rounded button-white button-filter fontsize-14 px-3"
                onClick={() => onShowFilter()}
              >
                <em className="icon-filter me-2"></em> Filters
              </button>
            </div>
          </div>
          {payoutData?.response?.count === 0 && <IntegolfDataNotFound message={"Payouts not found"}/>}
          {(payoutLoading || isLoading) ? <IntegolfLoader show /> : <PayoutList markReadyData={markReadyData} markPayoutComplete={markPayoutComplete} data={payoutGroupedData||{}} sendEmailNotification={sendEmailNotification}/>}
        </div>
      </div>
      {showFilters && (
        <div className="asideFilterWrapper">
          <div className="asideFilterInnerWrap">
            <Filters
              showFilterComponent={showFilters}
              handleApplyFilters={applyFilterEvent}
              handleCloseFilterModal={hideFilterEvent}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Transactions;
