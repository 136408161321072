import React, { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./blogcomp.scss";
import { useMutation, useQuery } from "react-query";
import { usePagination } from "../../contexts/PaginationContext";
import axios from "axios";
import BlogPostFormPopUp from "./components/blogPostFormPopUp";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";

import BlogPostList from "./components/blogs-list/blogs-list";

interface TaxesProps { }

const BlogComp: FC<TaxesProps> = () => {
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    setItemsCountInCurrentPage,
  } = usePagination();
  const [selectedBlog, setSelectedBlog] = useState<any>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const accessToken: any = localStorage.getItem("accessToken");

  const fetchBlogs = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchBlogAndNews`,
      {
        params: {
          skip,
          limit,
          sortBy: "createdAt",
          sortOrder: -1,
          type: "blog",
        },
        headers: {
          Accesstoken: accessToken,
        },
      }
    );
    return response.data;
  };

  const { data: blogData, refetch: refetchBlogs } = useQuery(
    "blogs",
    fetchBlogs
  );

  const addBlogMutation = useMutation({
    mutationFn: async (data: any) => {
      const response = await axios.post(
        `${process.env.REACT_APP_ADMIN_API_BASEURL}/addBlogOrNews`,
        data,
        {
          headers: {
            Accesstoken: accessToken,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Blog added successfully!");
      refetchBlogs(); // Refetch blogs after adding
      // setSelectedBlog(null);
      setTimeout(() => {
        setShowPopup(false);
      }, 100);


    },
    onError: (error) => {
      console.error("Error adding blog:", error);
    },
  });

  const updateBlogMutation = useMutation({
    mutationFn: async (data: any) => {
      const response = await axios.put(
        `${process.env.REACT_APP_ADMIN_API_BASEURL}/updateBlogOrNews`,
        data,
        {
          headers: {
            Accesstoken: accessToken,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Blog updated successfully!");
      refetchBlogs(); // Refetch blogs after adding
      setShowPopup(false);
      setSelectedBlog(null);
    },
    onError: (error) => {
      console.error("Error updating blog:", error);
    },
  });

  const deleteBlogMutation = useMutation({
    mutationFn: async (id) => {
      const response = await axios.delete(
        `${process.env.REACT_APP_ADMIN_API_BASEURL}/deleteBlogOrNews/${id}`,
        {
          headers: {
            Accesstoken: accessToken,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      toast.success("Blog deleted successfully!");
      refetchBlogs(); // Refetch blogs after adding
      setShowDeletePopup(false);
    },
    onError: (error) => {
      console.error("Error updating blog:", error);
    },
  });

  const deleteBlogHandler = async () => {
    deleteBlogMutation.mutate(selectedBlog?._id)
  }

  const handleAddEditPopup = (blog: any) => {
    console.log('insdei handle add/edit popup function', blog)
    setSelectedBlog(blog);
    setShowPopup(true);
  };

  const handleEditClick = (blog: any) => {
    console.log('clicking edit blog------------')
    let obj = {
      _id: blog?._id,
      title: blog?.title,
      file: blog?.fileId[0]?.path,
      fileId: blog?.fileId[0]?._id,
      editor: blog?.description
    }
    setSelectedBlog(obj);
    setShowPopup(true);
  };

  const handleDeleteClick = (blog: any) => {
    let obj = {
      _id: blog?._id,
    };
    setSelectedBlog(obj);
    setShowDeletePopup(true);
  };

  useEffect(() => {
    if (blogData?.response?.count >= 0 && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(blogData?.response?.count);
      // setItemsCountInCurrentPage(data?.response?.length ?? 0);
    }

  }, [blogData?.response?.count, skip]);

  useEffect(() => {
    refetchBlogs();
  }, [
    skip,
    itemsPerPage
  ]
  );

  return (
    <>
      <div className="usersWrapper">
        <h1 className="mb-2 pageTitle">Blogs</h1>
        <div className="col-4"></div>
        <div className="container-fluid p-0">
          <div className="row justify-content-between mb-1">
            <div className="col-12 col-lg"></div>
          </div>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary myBTnClass mb-2"
              onClick={() => handleAddEditPopup(null)} // Open popup for adding new blog
            >
              Add New Blog
            </button>
          </div>

          {blogData?.response?.count === 0 && (
            <IntegolfDataNotFound message={"No Blogs found"} />
          )}

          {blogData?.response?.count != 0 &&
            <BlogPostList
              data={blogData?.response?.result}
              onEditBlog={handleEditClick}
              // defaultValues={selectedBlog || {}}
              // updateBlogMutation={updateBlogMutation}
              showDeletePopup={showDeletePopup}
              setShowDeletePopup={setShowDeletePopup}
              deleteBlogHandler={deleteBlogHandler}
              onDeleteBlog={handleDeleteClick}
            // show={showPopup}
            // setShow={setShowPopup}
            />}

          <BlogPostFormPopUp
            show={showPopup}
            setShow={setShowPopup}
            defaultValues={selectedBlog || {}}
            addBlogMutation={addBlogMutation} // Pass mutation to popup
            updateBlogMutation={updateBlogMutation}
          />
        </div>
      </div>
    </>
  );
};

export default BlogComp;
