import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import QRCodeScans from './qrcodescans';

export default () => {
    return (
        <PaginationProvider>
           <QRCodeScans/>
        </PaginationProvider>
    )
}