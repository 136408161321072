import { useEffect, useState } from "react";
import ModalContainer from "../../../../components/Modal";

export default ({ userStatus, id, markUserActiveInactive, updatedStatusData }: any) => {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(false)
    }, [updatedStatusData]);

    // if (status === 'Ready') {
    return (
        <>
            <ModalContainer
                action={() => markUserActiveInactive({ id: id, status: userStatus == 1 ? 2 : 1 })}
                onHide={() => setShowModal(false)}
                title='User Status Change'
                desc={`Are you sure you want to ${userStatus == 1 ? 'Deactivate' : 'Activate'} this user ?`}
                showModal={showModal} />
            <button
                type="button"
                className="button button-primary button-rounded fw700"
                // className="button button-sm button-rounded button-primary button-filter fontsize-14 px-3"
                onClick={() => {
                    setShowModal(true)
                    // markPayoutComplete(id)
                }}>{userStatus == 1 ? 'Deactivate' : 'Activate'}</button>
        </>

    );
    // } else {
    //     return (
    //         <label className={`mb-0 status status--active`}>
    //             {status}
    //         </label>
    //     )
    // }

}