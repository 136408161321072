import PaginationProvider from '../../../contexts/PaginationContext';
import TargetCoursesNotes from './target-courses-notes';


export default () => {
    return (
            <PaginationProvider>
                <TargetCoursesNotes />
            </PaginationProvider>
    )
}