import React, { FC, useEffect, useState } from "react";
import { Navigate } from "react-router";
import "./course-detail-wrapper.scss";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import { NavLink, useSearchParams } from "react-router-dom";
import { FilterBar, FilterItemType } from "../filter-bar/filter-bar";
import { IFilterItem } from "../../../bookings/components/filter-bar/filter-bar";
import { useMutation } from "react-query";
import axios from "axios";

interface CourseDetailWrapperProps {}

export const CourseRedirect = () => {
  const { id } = useParams();
  return <Navigate to={`/course/${id}/detail`} />;
};

function CourseDetailWrapper() {
  const [showTab, setShowTab] = useState(1);
  const [showEditModal, setShowEditModal] = useState(false)
  const [fullDescription, setFullDescription] = useState(true);
  const [selectedKey, setSelectedKey] = useState("");
  const [isEdited, setIsEdited] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterItem, setFilterItem] = useState<IFilterItem | undefined>();

  // const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const elem: any = document.getElementById("headerLayout");
    elem.className = "d-none";
    return () => {
      elem.className = "";
    };
  });
  
  const getCourseDetail = async (accessToken: string) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchCourse`, {
      "courseObjectId": id
    }, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
    return response.data;
  };
  const accessToken = localStorage.getItem('accessToken');
  const { isLoading, error, data, mutate: courseDetailFn } = useMutation('users', () => getCourseDetail(accessToken!));
  useEffect(() => {
    courseDetailFn();
  }, [courseDetailFn, isEdited])

  useEffect(() => {
    const selectedFilter = filterItem?.filterText;

    if (!filterItem || selectedFilter === FilterItemType.COURSE_DETAILS) {
      courseDetailFn(undefined);
    }
    else if (selectedFilter === FilterItemType.BOOKINGS) {
      // GET BOOKINGS
    }
    else if (selectedFilter === FilterItemType.REVIEWS) {
      // GET REVIEWS
    }
  }, [courseDetailFn, filterItem]);

  const location = useLocation();

  // useEffect(() => {
  //   if (location.pathname != '')
  //   navigate('detail');
  // }, [navigate]);


  return (
    <div className="courseDetailWrapper">
      <div className="container-fluid p-0">
        <div className="row align-items-center mb-3">
          <div className="col-auto">
            <NavLink
              to={"/courses"}
              className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
            >
              <em className="fas fa-arrow-left"></em>
            </NavLink>
          </div>
          <div className="col">
            <nav className="breadcrumbWrap" aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <NavLink to={"/courses"}>Courses</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <span>Course Details</span>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        
        <div className="row ">
          {/* <FilterBar
            setShowFilters={(value: boolean) => setShowFilters(value)}
            onFilterChange={
              (item: IFilterItem, index: number) => {
                setFilterItem(item)
              }
            }
            selectedFilter={searchParams.has('filter') ? Number(searchParams.get('filter')) : 0}
          /> */}
          <div className="col-12">
            <nav className="mb-3 navTabs">
              <NavLink
                to={`detail`}
                className={({ isActive }) =>
                  isActive ? "nav-item active" : "nav-item"
                }
              >
                Course Details
              </NavLink>
              <NavLink
                to={`bookings`}
                className={({ isActive }) =>
                  isActive ? "nav-item active" : "nav-item"
                }
              >
                Bookings
              </NavLink>
              <NavLink
                to={`reviews`}
                className={({ isActive }) =>
                  isActive ? "nav-item active" : "nav-item"
                }
              >
                Reviews
              </NavLink>
            </nav>
          </div>
        </div>
      </div>
      <Outlet></Outlet>
    </div>
  );
}

export default CourseDetailWrapper;
