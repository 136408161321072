import React, { FC } from "react";
import "./courses-list.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import dayjs from 'dayjs';
import { usePagination } from "../../../../contexts/PaginationContext";
import { useState } from "react";
import IntegolfLoader from "../../../../components/integolf-loader/integolf-loader";

interface CoursesListProps { }

const getStatusDetail = (status: number) => {
  switch (status) {
    case 1:
      return {
        label: 'Active',
        class: 'status--active'
      }
    case 2:
      return {
        label: 'Inactive',
        class: 'status--danger'
      }
    default:
      return {
        label: 'Completed',
        class: 'status--success'
      }
  }
}
const CoursesList = ({
  data,
  setViewIntegrationStatus,
  viewIntegrationStatus
}: any) => {
  const { currentPage, totalItems,itemsPerPage } = usePagination();
  return (
    <>
      {data?.length > 0 && <div className="courseslistWrapper">
        <div className="tableLayout">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <div className="d-flex align-items-center">
                      {/* <label className="mb-0 ch-checkbox ch-checkbox-bordered me-2">
                      <input type="checkbox" />
                      <span>&nbsp;</span>
                    </label> */}
                      <span>Name</span>
                    </div>
                  </th>
                  <th>Added on</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Holes</th>
                  <th>Previous Bookings</th>
                  <th>Upcoming Bookings</th>
                  <th>Tourism Bookings</th>
                  {/* <th>Length</th> */}
                  <th>Integration</th>
                  <th>Status</th>
                  {/* <th className="width-200">
                    {(viewIntegrationStatus)?'Onboarding Status':<button type="button"
                      className="btn btn-primary" style={{padding:"5px"}} onClick={()=>{

                        setViewIntegrationStatus(!viewIntegrationStatus);
                      }
                    }>{(viewIntegrationStatus)?"" : "Check Onboarding Status"}</button>
                  }
                </th> */}
    
                  <th className="width-80">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((e: any, j: number) => {
                  const { class: cssClass, label } = getStatusDetail(e.course?.status);
                  return (
                    <>
                      <tr key={'key_' + j}>
                        <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            {/* <label className="mb-0 ch-checkbox ch-checkbox-bordered me-2">
                          <input type="checkbox" />
                          <span>&nbsp;</span>
                        </label> */}
                            <div className="userContent">
                              <span className="imgBlock">
                                <img
                                  src={e?.photos?.[0]?.path ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-siQWshbehfw9lvg5pH16YQ8eYJEgLQbkyQ&usqp=CAU"}
                                  alt=""
                                  className="img-fluid"
                                />
                              </span>
                              <div className="content">
                                <NavLink to={`/course/${e?.course?._id}`} state={{ id: e?.course?._id }}>
                                  <h5 className="mb-1 text-capitalize"> {e?.club?.name ? e?.club?.name : 'n/a'}</h5>
                                </NavLink> 
                                <h6 className="mb-0">
                                  {e?.club?.formattedName ?? e?.club?.addressOne}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{dayjs(e?.course?.createdAt).format('DD MMM YYYY hh:mm a')}</td>
                        <td>{e?.merchant?.email ? e.merchant.email : "n/a"}</td>
                        <td>{e?.merchant && e?.merchant?.phone && e?.merchant?.phone?.number ? `+${e?.merchant?.phone?.countryCode}${e?.merchant?.phone?.number}` : "n/a"}</td>
                        <td>{e?.course?.holes}</td>
                        <td>{e?.previousBookings}</td>
                        <td>{e?.upcomingBookings}</td>
                        <td>{e?.thirdPartyBookings}</td>
                        <td> <div className="logoBlock logoTee">
                          <img
                            src={(e?.course?.type === 'teeon') ? "../../assets/images/logo-tee-on.png" : "../../assets/images/logo-chronogolf.png"}
                            alt="../../assets/images/icons/verify.svg"
                            className="img-fluid"
                            width={"100px"}

                          />
                        </div>
                          </td>
                        {/* <td>{e?.course?.distance} yards</td> */}
                        <td>
                          <label className={`mb-0 status ${cssClass}`}>
                            {label}
                          </label>
                        </td>
                        
                        <td>
                          <div className="listActionDropdownBlock">
                            <Dropdown align="end">
                              <div className="text-center">
                                <Dropdown.Toggle className="toggleButton">
                                  <em className="fa-solid fa-ellipsis-vertical"></em>
                                </Dropdown.Toggle>
                              </div>
                              <Dropdown.Menu>
                                {/* <a href={void 0} className="dropdown-item fw600">
                              Edit
                            </a> */}
                                <NavLink to={`/course/${e?.course?._id}`} state={{ id: e?.course?._id }} className="dropdown-item fw600">
                                  View
                                </NavLink>
                                <NavLink to={`/course/course-sync/${e?.course?._id}`} state={{ club: e?.club }} className="dropdown-item fw600">
                                  Course Sync Details
                                </NavLink>
                                <NavLink to={`/course/course-onboarding/${e?.course?._id}`} state={{ id: e?.course?._id }} className="dropdown-item fw600">
                                  Course On Boarding Details
                                </NavLink>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>

                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="paginationBlock">
            <div className="row gutters-5 align-items-center justify-content-between">
              {totalItems > 10 && <Pagination />}
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default CoursesList;
