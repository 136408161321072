import React, { FC, useEffect, useState } from "react";
import "./comments-by-blog.scss";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import IntegolfLoader from "../../../../components/integolf-loader/integolf-loader";
import IntegolfDataNotFound from "../../../../components/integolf-data-not-found/integolf-data-not-found";
import PaginationProvider, { usePagination } from "../../../../contexts/PaginationContext";
import Pagination from "../../../../components/Pagination";
import ViewCommentModal from "../view-comment-modal/view-comment-modal";

interface BlogCommentsProps { }

const BlogComments: FC<BlogCommentsProps> = () => {

    const {
        skip,
        limit,
        totalItems,
        setTotalItems,
        currentPage,
        setCurrentPage,
        setItemsPerPage,
        itemsPerPage,
        totalPages,
        setItemsCountInCurrentPage
    } = usePagination();
    const { id } = useParams();
    const navigate = useNavigate();
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [comment, setComment] = useState("")
    const accessToken: string = localStorage.getItem('accessToken') ?? 'err';

    const viewComment = (comment: any) => {
        setComment(comment);
        setShowCommentModal(true);
    }

    const getBlogComments = async (accessToken: string) => {
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/getComments/${id}`, {
            params: {
                limit,
                skip,
                "sortBy": "createdAt",
                "sortOrder": -1
            },
            headers: {
                "Accesstoken": accessToken
            }
        });
        return response.data;
    }


    const { isLoading, error, data, mutate: getComments } = useMutation('blogcomments', () => getBlogComments(accessToken))
    useEffect(() => {
        getComments();
    }, [
        id,
        getComments,
    ]);


    useEffect(() => {
        if (data?.response?.count >= 0 && skip === 0) {
            setCurrentPage(1);
            setItemsPerPage(itemsPerPage);
            setTotalItems(data?.response?.count);
        }
    }, [data?.response?.count, skip]);

    useEffect(() => {
        getComments()
    }, [
        skip,
        getComments,
        itemsPerPage
    ]
    );

    const comments = data?.response?.result[0]?.comments;
    const commentsCount = data?.response?.count;
    return (
        <>
            {isLoading ? (
                <IntegolfLoader show />
            ) : comments && (
                <div className='accommodationsListWrapper'>
                    <div className="d-flex mt-3">
                        <a
                            onClick={() => navigate(-1)}
                            className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
                        >
                            <em className="fas fa-arrow-left"></em>
                        </a>
                        <h1 className="pageTitle comment-title">Comments</h1>
                    </div>

                    {commentsCount === 0 && <IntegolfDataNotFound message={"Comments not found"} />}
                    {comments?.length > 0 && <div className='tableLayout mt-4'>
                        <div className='table-responsive'>
                            <table className='table mb-0'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            <div className='d-flex align-items-center'>
                                                <span>Name</span>
                                            </div>
                                        </th>
                                        <th>Email</th>
                                        <th>Added On</th>
                                        <th>Comment</th>
                                        <th className="width-50">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {comments?.map((e: any, j: number) => {
                                        return (
                                            <tr key={'key_' + j}>
                                                <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='userContent user-name-comment'>
                                                            <div className='content '>
                                                                <h5 className='mb-1 text-capitalize'>
                                                                    {e?.name?.trim() ? `${e?.name?.trim()}` : "n/a"}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{e?.email?.trim() ?? "n/a"}</td>
                                                <td>{dayjs(e?.createdAt).format('DD MMM YYYY hh:mm a')}</td>
                                                <td> {e?.comment?.trim() && <button className="button button-primary button-rounded button-sm fontsize-14 fw500 px-3 mb-3 " onClick={() => viewComment(e?.comment?.trim())}>View Comment</button>}</td>
                                                <td>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {showCommentModal && (
                            <ViewCommentModal
                                showModal={showCommentModal}
                                content={comment}
                                handleClose={() => setShowCommentModal(false)}
                            />
                        )}
                        <div className='paginationBlock'>
                            <div className='row gutters-5 align-items-center justify-content-between'>
                                {totalItems > 10 && <Pagination />}
                            </div>
                        </div>
                    </div>}
                </div>
            )}
        </>

    );
};

const BlogCommentsWrapper = () => {
    return (
        <PaginationProvider>
            <BlogComments />
        </PaginationProvider>
    );
}

export default BlogCommentsWrapper;
