import { useEffect, useState } from "react";
import ModalContainer from "../../../../components/FormModal";

export default ({ showModal, setShowModal, actionHandler, data, teeOnRequest }: any) => {
    const [input1, setInput1] = useState("");
    function modalSubmitHandler(formData:{courseCode:string, accessToken:string}){
        actionHandler(teeOnRequest(data, formData.courseCode, formData.accessToken)); 
        setShowModal(false);
      }
    return (
        <>
            <ModalContainer
                setInput1={setInput1}
                action={modalSubmitHandler}
                onHide={() => setShowModal(false)}
                title= {'Confirmation'}
                desc={`Are you sure you want to mark Tee-On integration of course '${data?.golfCourseName || ''}' as complete? The merchant will be notified.`}
                showModal={showModal} />
        </>

    );

}