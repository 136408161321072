import "./booking-query-list.scss";
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { Dropdown } from "react-bootstrap";
import Pagination from "../../../components/Pagination";
import { usePagination } from "../../../contexts/PaginationContext";
import { useState } from "react";
import QueryDetailModal from "../modal/booking-query-detail-modal"

const BookingQueryList = ({ data }: { data: any }) => {
  const { currentPage, totalItems, itemsPerPage } = usePagination();
  const [showQueryModal,setShowQueryModal]=useState(false);
  const [queryDetail,setShowQueryDetail]=useState<any>("")
  const coursesInfo=(courses:any)=>{
    let validClubNames: any[]=[]
    if(Array.isArray(courses) && courses?.length > 0){
        courses.map((ele:any)=>{
            validClubNames.push(ele?.clubsInfo?.name)
        })
    }
    return validClubNames.join(", ");
  }
  const handleQueryInfo=(event:any,queryDetail:any)=>{
    event?.preventDefault();
    setShowQueryModal(true);
    setShowQueryDetail(queryDetail);
  }
  return (
    <>
   {data?.length>0 && 
    <div className="courseslistWrapper mt-4">
      <div className="tableLayout">
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Interested Clubs</th>
                <th>Created On</th>
                <th className="width-50">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((e: any, j: number) => {
                const clubNames=coursesInfo(e?.coursesInfo)
                return (
                  <tr key={j}>
                    <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                    <td>
                      <div className="userContent align-items-center">
                        <span className="imgBlock rounded-circle">
                          <img
                            src={e?.profileImage?.[0]?.path ?? "../../assets/images/default-user.png"}
                            alt=""
                            className="img-fluid"
                          />
                        </span>
                        <div className="content">
                            <h5 className="mb-0" style={{ textTransform: 'capitalize' }}>{e?.inquiry?.name}</h5>
                        </div>
                      </div>
                    </td>
                    <td style={{whiteSpace: 'nowrap'}}>{e?.inquiry?.email}</td>
                    <td style={{minWidth:'140px'}} className="text-capitalize">{clubNames}</td>
                    <td style={{textAlign:'center',minWidth:'200px'}}>
                      {`${dayjs(e?.createdAt).format('MMMM D YYYY, h:mm a')}`}
                    </td>
                    <td>
                    <div className="listActionDropdownBlock">
                        <Dropdown align="end">
                          <div className="text-center">
                            <Dropdown.Toggle className="toggleButton">
                              <em className="fa-solid fa-ellipsis-vertical"></em>
                            </Dropdown.Toggle>
                          </div>
                          <Dropdown.Menu>
                            <li className="dropdown-item fw600"  onClick={(event) => handleQueryInfo(event, e)}>
                              View
                            </li>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                    {showQueryModal && e?._id===queryDetail?._id && <QueryDetailModal setShow={(val:any)=>setShowQueryDetail(val)} show={showQueryModal}  queryDetail={queryDetail}/>}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="paginationBlock">
          <div className="row gutters-5 align-items-center justify-content-between">
            {
              totalItems > 10 && 
              <Pagination />
            }
          </div>
        </div>
      </div>
    </div>
}
</>
  );
};
export default BookingQueryList;