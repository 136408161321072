import React, { FC, useEffect, useState } from 'react';
import './tournament-bookings.scss';
import IntegolfLoader from '../../../../components/integolf-loader/integolf-loader';
import IntegolfDataNotFound from "../../../../components/integolf-data-not-found/integolf-data-not-found";
import { usePagination } from '../../../../contexts/PaginationContext';
import axios from 'axios';
import { useMutation } from 'react-query';
import TournamentBookingList from './tournament-bookings-list/tournament-bookings-list';
import { useLocation } from 'react-router';
interface TournamentBookingProps { }
const TournamentBookings: FC<TournamentBookingProps> = () => {

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const tournamentId = queryParams.get('tournamentId');
	const {
		skip,
		limit,
		setTotalItems,
		setCurrentPage,
		setItemsPerPage,
		itemsPerPage,
		setItemsCountInCurrentPage,
	} = usePagination();
	const getTournamentBookings = async ({
		accessToken,
		createdAt,
		searchTerm
	}: any) => {
		const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchTounamentBookings`, {
			params: {
				text: searchTerm,
				limit,
				tournamentId:tournamentId,
				skip,
				sortBy: "createdAt",
				sortOrder: -1
			},
			headers: {
				'Accesstoken': accessToken,
			}
		});
		return response.data;
	}
	const [searchTerm, setSearchTerm] = useState<any>();
	const accessToken = localStorage.getItem('accessToken');
	const { isLoading, error, data, mutate: getBookingsData } = useMutation('tournamentbookings', () => getTournamentBookings({
		accessToken: accessToken!,
		searchTerm
	}));
	useEffect(() => {
		if (data?.response?.count >= 0 && skip === 0) {
			setCurrentPage(1);
			setItemsPerPage(itemsPerPage);
			setTotalItems(data?.response?.count);
		}
	}, [data?.response?.count, skip]);
	useEffect(() => {
		getBookingsData();
	}, [
		skip,
		getBookingsData,
		itemsPerPage
	]);
	useEffect(() => {
		if (searchTerm?.trim()?.length > 0) {
			setCurrentPage(1);
			getBookingsData();
		}
	}, [searchTerm]);
	return (
		<>
			<div className='accommodationsWrapper'>
				<h1 className='mb-3 pageTitle'>Tournament Bookings</h1>
				<div className='row'>
					<div className='col-4'>
						<div className='searchBlock'>

							<input
								type='text'
								className='form-control'
								placeholder='Search by course name or email'
								onChange={(e: any) => {
									setSearchTerm(e.target.value)
								}}
							></input>
						</div>
					</div>
					<div className='col-8 mb-2 text-align-right'>
					</div>
				</div>
				<div className='container-fluid p-0'>
					{data?.response?.count === 0 && <IntegolfDataNotFound message={"No Bookings found"} />}
					{data?.response ? <TournamentBookingList data={data?.response?.result} />
						:
						<IntegolfLoader show />}

				</div>
			</div>
		</>
	);
};
export default TournamentBookings;
