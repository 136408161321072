import "./course-bookings.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { usePagination } from "../../../../contexts/PaginationContext";
import dayjs from "dayjs";
import { getStatusObj } from "../../../../utils/constants";
import Pagination from "../../../../components/Pagination";
import axios from "axios";
import { useEffect } from "react";
import { useMutation } from "react-query";
import IntegolfDataNotFound from "../../../../components/integolf-data-not-found/integolf-data-not-found";
import IntegolfLoader from "../../../../components/integolf-loader/integolf-loader";
import { formatPhoneNumber } from "../../../bookings/components/booking-details/booking-details";

export const CourseBookingsBreadcrumbNav = (id: any) => {

  const navigate = useNavigate();

  return (
    <>
      <div className="col-auto">
      {/* 
        <NavLink
          to={''}
          className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
        >
          <em className="fas fa-arrow-left"></em> 
        </NavLink> 
      */}
        <button
          className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
          onClick={() => {
            navigate(-1);
          } }
        >
          <em className="fas fa-arrow-left"></em>
        </button>
      </div>
      <div className="col">
        <nav className="breadcrumbWrap" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <NavLink to={'/courses'}>Courses </NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to='/' onClick={() => navigate(-1)}>Bookings </NavLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <span>Booking Details</span>
            </li>
          </ol>
        </nav>
      </div>
    </>
  )
}

const getFirstAndLastName = (data: any) => {
  if (data?.userId && data.userId.firstname !== '') {
    return `${data?.userId?.firstname} ${data?.userId?.lastname}`
  } else if (data?.user?.name) {
    return `${data?.user?.name}`;
  } else {
    return 'Guest User';
  }
}

const getEmailAddress = (data: any) => {
  if (data?.userId) {
    return `${data?.userId?.email}`
  } else if (data?.user?.email) {
    return `${data?.user?.email}`;
  } else {
    return 'Email not found'
  }
}

const getUserPhone = (data: any) => {
  if (data?.userId?.phone && data?.userId?.phone?.number !== null) {
    return `+${data?.userId?.phone?.countryCode} ${formatPhoneNumber(data?.userId?.phone?.number)}`
  } else if (data?.user) {
    return `${formatPhoneNumber(data?.user?.phone)}`;
  } else {
    return 'No Phone Found';
  }
}

const getBookingType = (data: any) => {
  if (data?.bookingType === 1) {
    return 'Tourism';
  } else if (data?.bookingType === 2) {
    return 'Discounted';
  } else {
    return 'Other booking type'
  }
}

const CourseBookingsList = ({ data }: { data: any }) => {

  const { currentPage, totalItems, itemsPerPage } = usePagination();
  
  return (
    <>
      {totalItems > 0 && <div className="courseBookingsWrapper">
        <div className="tableLayout">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Golfers</th>
                  <th>Platform</th>
                  <th>Status</th>
                  <th>Payment</th>
                  <th className="width-50">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((e: any, j: number) => {
                  const status = getStatusObj(e?.status?.value);
                  return (
                    <>
                      <tr key={'key_' + j}>
                        <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                        <td>
                          <div className="userContent align-items-center">
                            <span className="imgBlock rounded-circle">
                              <img
                                src={e?.profileImage[0]?.path ?? "../../assets/images/default-user.png"}
                                alt="User Profile Image"
                                className="img-fluid"
                              />
                            </span>
                            <div className="content">
                              <h5 className="mb-0">{getFirstAndLastName(e)}</h5>
                            </div>
                          </div>
                        </td>
                        <td>{getEmailAddress(e)}</td>
                        <td>{getUserPhone(e)}</td>
                        <td>{e?.teesheets[0]?.teesheets[0]?.players}</td>
                        <td>{getBookingType(e)}</td>
                        <td>
                          <label style={{ background: status.color, color: status.fontColor, textTransform: 'capitalize'}} className="mb-0 status">
                            {status.label}
                          </label>
                        </td>
                        <td>${e?.totalPrice.toFixed(2)}</td>
                        <td>
                        <div className="listActionDropdownBlock">
                            <Dropdown align="end">
                              <div className="text-center">
                                <Dropdown.Toggle className="toggleButton">
                                  <em className="fa-solid fa-ellipsis-vertical"></em>
                                </Dropdown.Toggle>
                              </div>
                              <Dropdown.Menu>
                                <NavLink state={{id: e._id}} to={`detail`} className="dropdown-item fw600">
                                  View
                                </NavLink>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="paginationBlock">
            <div className="row gutters-5 align-items-center justify-content-between">
              {totalItems > 10 && <Pagination />}
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

const CourseBookings = () => {
  const {
    skip,
    limit,
    totalItems,
    setTotalItems,
    currentPage,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    totalPages,
    setItemsCountInCurrentPage
  } = usePagination();

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();


  const accessToken: string = localStorage.getItem('accessToken') ?? 'err';

  const getCourseBookings = async (courseId: string, accessToken: string) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchBooking`, {
      "limit": limit,
      "skip": skip,
      "sortBy": "createdAt",
      "sortOrder": -1,
      "courseObjectId": courseId
    }, {
      headers: {
        'Accesstoken': `${accessToken}`,
      },
    });
   
    return response.data;
  }

  const { isLoading, error, data, mutate: getBookings } = useMutation('courseBookings', () => getCourseBookings(id ?? 'err', accessToken))

  useEffect(() => {
    if (data?.response?.count >= 0 && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
    }
  }, [data?.response?.count, skip]);

  useEffect(() => {
    getBookings();
  }, [
    id,
    skip,
    getBookings,
    itemsPerPage
  ]);

  const bookings = data?.response?.result;

  const bookingCount = data?.response?.count;

  return (
    <>
      <div className="container-fluid p-0">
        {data?.response?.count === 0 && <IntegolfDataNotFound message={"No Bookings found"} />}
        {
          data?.response ? 
          <CourseBookingsList data={data?.response?.result} />
          :
          <IntegolfLoader show />
        }
      </div>
    </>
    
  )
}

export default CourseBookings;