
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

type PopUpProps = {
    show:boolean,
    setShow:Function,
    submitHandler:any,
    data:any
}
function TaxesFormPopup({show, setShow, data, submitHandler}:PopUpProps) {

  const handleClose = () => setShow(false);
  const { register, handleSubmit, formState: { errors }, reset} = useForm<any>({
    
  });

  useEffect(()=>{
    reset({
        provinceShortName : data?.provinceShortName ?? '',
        countryShortName : data?.countryShortName ?? '',
        country : data?.country ?? '',
        gst : data?.gst ?? '',
        hst: data?.hst ?? '',
        province: data?.province ?? '',
        pst :data?.pst ?? ''
    });
  },[show])

  return (
    <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{(data?._id)?"Edit":"Add"} Tax</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={ handleSubmit(submitHandler)}>
            <div className="mb-4">
                <div className="form-floating">
                    <input
                        type="text"
                        className="form-control"
                        id="country"
                        placeholder="Country"
                        {...register("country", { required: true, pattern:/^[A-Za-z ]+$/})}
                        />
                        <label htmlFor="country">Country</label>
                        {errors.country?.type === "required" && <span className="isInvalidMessage text-danger d-block">Please enter country</span>}
                        {errors.country?.type === "pattern" && <span className="isInvalidMessage text-danger d-block">Please enter valid country</span>}

                    
                </div><br/>
                <div className="form-floating">
                    <input
                        type="text"
                        className="form-control"
                        id="countryShortName"
                        maxLength={2}
                        placeholder="Country Code"
                        {...register("countryShortName", { required: true,pattern:/^[A-Za-z]+$/,
                            onChange:(e)=>e.target.value=e.target.value.toUpperCase()
                        })}
                        />
                        <label htmlFor="countryShortName">Country Code</label>
                        {errors.countryShortName?.type === "required" && <span className="isInvalidMessage text-danger d-block">Please enter country code</span>}
                        {errors.countryShortName?.type === "pattern" && <span className="isInvalidMessage text-danger d-block">Please enter valid country code</span>}

                </div><br/>
                <div className="form-floating">
                    <input
                        type="text"
                        className="form-control"
                        id="province"
                        placeholder="Province"
                        {...register("province", { required: true, pattern:/^[A-Za-z ]+$/})}
                        />
                        <label htmlFor="province">Province</label>
                        {errors.province?.type === "required" && <span className="isInvalidMessage text-danger d-block">Please enter province</span>}
                        {errors.province?.type === "pattern" && <span className="isInvalidMessage text-danger d-block">Please enter valid province</span>}

                </div><br/>
                <div className="form-floating">
                    <input
                        type="text"
                        className="form-control"
                        id="provinceShortName"
                        maxLength={2}
                        placeholder="Province Code"
                        {...register("provinceShortName", { required: true, pattern:/^[A-Za-z]+$/,
                            onChange:(e)=>e.target.value=e.target.value.toUpperCase()
                        })}
                        />
                        <label htmlFor="provinceShortName">Province Code</label>
                        {errors.provinceShortName?.type === "required" && <span className="isInvalidMessage text-danger d-block">Please enter province code</span>}
                        {errors.provinceShortName?.type === "pattern" && <span className="isInvalidMessage text-danger d-block">Please enter valid province code</span>}

                </div><br/>

                <div className="form-floating">
                    <input
                        type="number"
                        min={0}
                        max="100" step="0.001"
                        className="form-control"
                        id="gst"
                        placeholder="GST"
                        {...register("gst", { required: true})}
                        />
                        <label htmlFor="gst">GST</label>
                        {errors.gst?.type === "required" && <span className="isInvalidMessage text-danger d-block">Please enter GST</span>}
                </div><br/>
                <div className="form-floating">
                    <input
                        type="number"
                        min={0}
                        max="100" step="0.001"
                        className="form-control"
                        id="hst"
                        placeholder="HST"
                        {...register("hst", { required: true})}
                        />
                        <label htmlFor="hst">HST</label>
                        {errors.hst?.type === "required" && <span className="isInvalidMessage text-danger d-block">Please enter HST</span>}
                </div><br/>
                <div className="form-floating">
                    <input
                        type="number"
                        min={0}
                        max="100" step="0.001"
                        className="form-control"
                        id="pst"
                        placeholder="PST"
                        {...register("pst", { required: true})}
                        />
                        <label htmlFor="pst">PST</label>
                        {errors.pst?.type === "required" && <span className="isInvalidMessage text-danger d-block">Please enter PST</span>}
                </div><br/>
                

            </div>
        <Button type="submit" variant="primary">
            Submit
        </Button>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default TaxesFormPopup;