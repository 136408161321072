import React, { FC, useEffect } from "react";
import "./tournament-courses-detail.scss";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import Pagination from "../../../components/Pagination";
import IntegolfLoader from "../../../components/integolf-loader/integolf-loader";
import PaginationProvider, { usePagination } from "../../../contexts/PaginationContext";
import IntegolfDataNotFound from "../../../components/integolf-data-not-found/integolf-data-not-found";
import { Dropdown } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
interface TournamentCourseDetailProps { }

const TournamentCourseDetail: FC<TournamentCourseDetailProps> = () => {

    const {
        skip,
        limit,
        totalItems,
        setTotalItems,
        currentPage,
        setCurrentPage,
        setItemsPerPage,
        itemsPerPage,
        totalPages,
        setItemsCountInCurrentPage
    } = usePagination();

    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();


    const accessToken: string = localStorage.getItem('accessToken') ?? 'err';

    const getTournamentDetail = async (accessToken: string) => {
        const response = await axios.get(
            `${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchTournamentsList`,
            {
                headers: {
                    Accesstoken: `${accessToken}`,
                },
                params: {
                    courseId: id,
                    limit,
                    skip,
                    sortBy: "createdAt",
                    sortOrder: -1
                },
            }
        );
        return response.data;
    };

    const { isLoading, error, data, mutate: tournamentDetailFn } = useMutation('tournamentsdetailcourse', () => getTournamentDetail(accessToken!));
    useEffect(() => {
        tournamentDetailFn();
    }, [id, tournamentDetailFn])

    useEffect(() => {
        if (data?.response?.count >= 0 && skip === 0) {
            setCurrentPage(1);
            setItemsPerPage(itemsPerPage);
            setTotalItems(data?.response?.count);
        }

    }, [data?.response?.count, skip]);

    useEffect(() => {
        tournamentDetailFn()
    }, [
        skip,
        tournamentDetailFn,
        itemsPerPage
    ]
    );

    const tournamentcoursesdetail = data?.response?.result;

    const tournamentcoursesCount = data?.response?.count;

    return (
        <>
            {tournamentcoursesCount === 0 && <IntegolfDataNotFound message={"Tournaments not found"} />}
            {isLoading ? (
                <IntegolfLoader show />
            ) : tournamentcoursesCount > 0 && (
                <div className='accommodationsListWrapper'>
                    <div className='tableLayout'>
                        <div className='table-responsive'>
                            <table className='table mb-0'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Course Name</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Type</th>
                                        <th>Total Weeks</th>
                                        <th>Maximum Golfers</th>
                                        <th>Member Price</th>
                                        <th>Added On</th>
                                        <th className="width-50">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tournamentcoursesdetail?.map((e: any, j: number) => {
                                        return (
                                            <>
                                                <tr key={'key_' + j}>
                                                    <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='userContent tournament-title'>
                                                                <div className='content text-capitalize'>
                                                                    <h5 className='mb-1 cursor-pointer' onClick={() => navigate(`/tournaments/${e?._id}/detail`)} >
                                                                        {e?.TournamentName ? e?.TournamentName : 'n/a'}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-capitalize">{e?.tournamentCourse?.CourseName?.trim() ?? "n/a"}</td>
                                                    <td>{dayjs(e?.StartDate).format('DD MMM YYYY')}</td>
                                                    <td>{dayjs(e?.EndDate).format('DD MMM YYYY')}</td>
                                                    <td> {e?.League ? "League" : "Tournament"}</td>
                                                    <td>{e?.League && e?.totalWeeks ? e?.totalWeeks : 'n/a'}</td>
                                                    <td>{e?.MaxGolfers ?? 0}</td>
                                                    <td>$&nbsp;{Number(e?.Price)?.toFixed(2) ?? 0.00}</td>
                                                    <td>{dayjs(e?.CreatedAt).format('DD MMM YYYY')}</td>
                                                    <td>
                                                        <div className="listActionDropdownBlock">
                                                            <Dropdown align="end">
                                                                <div className="text-center">
                                                                    <Dropdown.Toggle className="toggleButton">
                                                                        <em className="fa-solid fa-ellipsis-vertical"></em>
                                                                    </Dropdown.Toggle>
                                                                </div>
                                                                <Dropdown.Menu>
                                                                    <NavLink to={`/tournaments/${e?._id}/detail`} className="dropdown-item fw600">
                                                                        View Details
                                                                    </NavLink>
                                                                    <NavLink to={`/bookings/tournaments?tournamentId=${e?._id}`} className="dropdown-item fw600">
                                                                        View Bookings
                                                                    </NavLink>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className='paginationBlock'>
                                <div className='row gutters-5 align-items-center justify-content-between'>
                                    {totalItems > 10 && <Pagination />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>

    );
};

const TournamentCourseDetailsWrapper = () => {
    return (
        <PaginationProvider>
            <TournamentCourseDetail />
        </PaginationProvider>
    );
}

export default TournamentCourseDetailsWrapper;
