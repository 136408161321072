import React, { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./bankaccountscomponent.scss";
import { useMutation, useQuery } from "react-query";
import { usePagination } from "../../contexts/PaginationContext";
import axios from "axios";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";
import BankAccountsList from "./components/bank-acconts-list/bank-accounts-list"
import { useNavigate, useParams } from "react-router";
interface BankAccountsProps { }

const BankAccountsComponent: FC<BankAccountsProps> = () => {
  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage,
    setItemsCountInCurrentPage,
  } = usePagination();
  const navigate=useNavigate();
  const accessToken: any = localStorage.getItem("accessToken");
  const { id } = useParams();
  const fetchAccounts = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_ADMIN_API_BASEURL}/payoutAccount/${id}`,
      {
        params: {
          skip,
          limit,
          sortBy: "createdAt",
          sortOrder: -1,
          isDeleted:false
        },
        headers: {
          Accesstoken: accessToken,
        },
      }
    );
    return response.data;
  };

  const { data: bankAccountsData, refetch: refetchBankAccounts } = useQuery(
    "bankaccounts",
    fetchAccounts
  );

  useEffect(() => {
    if (bankAccountsData?.response?.count >= 0 && skip ===0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(bankAccountsData?.response?.count);
    }

  }, [bankAccountsData?.response?.count, skip]);

  useEffect(() => {
    refetchBankAccounts();
  }, [
    skip,
    itemsPerPage
  ]
  );

  return (
    <>
      <div className="usersWrapper">
      <div className="d-flex">
            <a
              onClick={() => navigate(-1)}
              className="button button-box button-rounded button-sm fontsize-14 commonBackButton"
            >
              <em className="fas fa-arrow-left"></em>
            </a>
            <h1 className="mt-2 title-name pageTitle">Bank Accounts</h1>
          </div>
        <div className="col-4"></div>
        <div className="container-fluid p-0">
          <div className="row justify-content-between mb-1">
            <div className="col-12 col-lg"></div>
          </div>

          {bankAccountsData?.response?.count === 0 && (
            <IntegolfDataNotFound message={"No Bank Accounts found"} />
          )}

          {bankAccountsData?.response?.count != 0 &&
            <BankAccountsList
              data={bankAccountsData?.response?.result}
            />}

        </div>
      </div>
    </>
  );
};

export default BankAccountsComponent;
