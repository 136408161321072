import { FC, useEffect, useState } from "react";
import "./salestarget.scss";
import { useMutation } from "react-query";
import axios from "axios";
import IntegolfLoader from "../../components/integolf-loader/integolf-loader";
import IntegolfDataNotFound from "../../components/integolf-data-not-found/integolf-data-not-found";
import { usePagination } from "../../contexts/PaginationContext";
import SalesTargetList from "./salestargetlist";
interface SalesTargetProps { }

const SalesTarget: FC<SalesTargetProps> = () => {

  const {
    skip,
    limit,
    setTotalItems,
    setCurrentPage,
    setItemsPerPage,
    itemsPerPage
  } = usePagination();
  
  const [searchTerm, setSearchTerm] = useState("");

  const accessToken = localStorage.getItem('accessToken') as string

  const getTargetCourses = async ({
    accessToken,
    createdAt,
    searchTerm
  }: any) => {
    const response = await axios.get(`${process.env.REACT_APP_ADMIN_API_BASEURL}/fetchSalesTargetList`, {
      params: {
        limit,
        skip,
        sortBy: "createdAt",
        sortOrder: -1,
        isDeleted:false,
        text: searchTerm
      },
      headers: {
        'Accesstoken': accessToken,
      }
    });
    return response.data;
  }

  const { data, mutate: getTargetCoursesData } = useMutation('salestarget', () => getTargetCourses({
    accessToken: accessToken!,
    searchTerm
  }));

  useEffect(() => {
    if (data?.response?.count >= 0 && skip === 0) {
      setCurrentPage(1);
      setItemsPerPage(itemsPerPage);
      setTotalItems(data?.response?.count);
    }
  }, [data?.response?.count, skip]);

  useEffect(() => {
    getTargetCoursesData();
  }, [
    skip,
    getTargetCoursesData,
    itemsPerPage,
    searchTerm
  ]);
  useEffect(() => {
    if(searchTerm?.trim()?.length>0){
      setCurrentPage(1);
    }
  }, [searchTerm])


  return (
    <>
      <div className="coursesWrapper">
        <h1 className="mb-3 pageTitle">Sales Target</h1>
        <div className="row">
          <div className="col-4">
            <div className="searchBlock">
              <input onChange={(e) => {
              setSearchTerm(e.target.value)
            }} type="text" className="form-control" placeholder="Search by course name, email" />
            </div>
          </div>
          <div className="col-8 mb-2 text-align-right">
    
          </div>
        </div>
        <div className="container-fluid p-0 mt-3">
          {data?.response?.count === 0 && <IntegolfDataNotFound message={"No courses found"} />}
          {data?.response ? <SalesTargetList data={data?.response?.result}  />
            : <IntegolfLoader show /> }
        </div>
      </div>
    </>
  );
};

export default SalesTarget;
