import { useState } from 'react';
import './teeon-list.scss';
import Pagination from '../../../../components/Pagination';
import { usePagination } from '../../../../contexts/PaginationContext';
import UserStatusActionButton from './teeon-status-action-button';
import TeeOnPopup from './teeon-req-modal';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';


const getStatusDetail = (status: string) => {
  switch (status) {
    case 'edited':
      return {
        label: 'Active',
        class: 'status--active'
      }
    case 'completed':
      return {
        label: 'Active',
        class: 'status--success'
      }
    default:
      return {
        label: 'Inactive',
        class: 'status--danger'
      }
  }
}

const MerchantList = ({
  teeOnBoardingReq,
  data,
}: any) => {

  const { currentPage, totalItems,itemsPerPage } = usePagination();
  const [teeOnSubPopup, setTeeOnSubPopup] = useState(false);
  const [teeOnFormData, setTeeOnFormData] = useState();

  function getTeeOnSubmitRequest(user:any, courseCode:string, accessToken:string){
    return {
      "_id": user._id,
      courseCode: courseCode?.toString().toUpperCase() || '',
      accessToken : accessToken ?? ''
    }
  }

  return (
  <>
  <TeeOnPopup
      showModal={teeOnSubPopup}
      setShowModal={setTeeOnSubPopup}
      actionHandler={teeOnBoardingReq}
      data={teeOnFormData}
      teeOnRequest={getTeeOnSubmitRequest}
  />
  <div className="userslist" >
      <div className="tableLayout">
        <div className="table-responsive" style={{minHeight:'300px'}} >
          <table className="table mb-0" >
            <thead>
              <tr>
                <th>#</th>
                <th>Course Name</th>
                <th>Contact Email</th>
                <th>Contact Phone</th>
                <th>Technical Contact</th>
                <th>Signed By</th>
                <th>Last Updated On</th>
                <th>Merchant Form Status</th>
                <th>Tee-On Integration</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((user: any, j: number) => {
                const { label, class: cssClass } = getStatusDetail(user?.status);
                return (
                  <>
                    <tr>
                      <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                      <td>
                        <div className="userContent align-items-center">
                          <span className="imgBlock rounded-circle">
                            <img
                              src={user?.club?.photos?.[0]?.path ?? "../../assets/images/default-user.png"}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          <div className="content">
                            <h5 className="mb-0">{user.golfCourseName ? user.golfCourseName : 'n/a'}</h5>
                          </div>
                        </div>
                      </td>
                      
                      <td>{user?.golfContactEmail || 'n/a'}</td>
                      <td>{user?.golfContactPhoneNumber}</td>
                      <td>{user?.golfCourseTechnicalContact}</td>
                      <td>{user?.signedByName || 'n/a'}</td>
                      <td>{dayjs(user?.updatedAt).format('DD MMM YYYY hh:mm a') || 'n/a'}</td>
                      <td>
                        <label className={`mb-0 status ${cssClass}`}>
                          {user?.status==='edited'?'In-Progress':'Submitted'}
                        </label>
                      </td>
                      <td>
                        <label className={`mb-0 status ${(user.teeOnSignupProcess==='completed')?'status--success':'status--active'}`}>
                          {user?.teeOnSignupProcess==='completed'?'Completed':'Pending'}
                        </label>
                      </td>
                      {/* <td>
                        {
                          (user?.status !=='accepted') ?
                            <UserStatusActionButton
                              userStatus={user?.status}
                              email={user?.email} 
                              markUserActiveInactive={markUserActiveInactive}
                              updatedStatusData={updatedStatusData}
                              portal={user?.portal || 'all'}
                              />
                            :
                            <label  className={`mb-0 status`}>
                             n/a
                            </label>
                        }
                      </td> */}
                      <td>
                        <div className="listActionDropdownBlock">
                          <Dropdown align="end">
                          <div className="text-center">
                            <Dropdown.Toggle className="toggleButton">
                              <em className="fa-solid fa-ellipsis-vertical"></em>
                            </Dropdown.Toggle>
                          </div>
                          <Dropdown.Menu>
                          <NavLink to={`/course/${user?.courseId}/detail`} state={{id: user?.courseId}} className="dropdown-item fw600">
                              View Course
                            </NavLink>
                          <a className="dropdown-item fw600"
                              href={user.url || '#'} target='_blank'>
                                View PDF
                            </a>
                            <a 
                              className="dropdown-item fw600" 
                              href={(user.url)?`${process.env.REACT_APP_ADMIN_API_BASEURL}/downloadTeeOnPdfFile?url=${user.url}`: '#'}
                              target='_blank'>
                                Download PDF
                            </a>
                            <a 
                              className="dropdown-item fw600" 
                              onClick={()=>{
                                setTeeOnFormData(user);
                                //setTeeOnSubHandler(()=>customJsBind(teeOnBoardingReq, this, getTeeOnSubmitRequest(user))); 
                                setTeeOnSubPopup(true) }} >
                                Complete
                            </a>
                            
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
                      </td>
                    </tr>
                    
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="paginationBlock">
          <div className="row gutters-5 align-items-center justify-content-between">
            {totalItems > 10 && <Pagination />}
            {/* <div className="col-auto">
              <h6 className="mb-0">
                Showing <strong>1</strong> to <strong>10</strong> of{" "}
                <strong>100</strong> Results
              </h6>
            </div>
            <div className="col-auto">
              <div className="row gutters-5">
                <div className="col-auto">
                  <button type="button" className="button button-sm fw600 px-3">
                    Previous
                  </button>
                </div>
                <div className="col-auto">
                  <button type="button" className="button button-sm fw600 px-3">
                    Next
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </>
    
  );
};

export default MerchantList;
