import React from 'react';
import TargetCourses from './target-courses';
import PaginationProvider from '../../contexts/PaginationContext';


export default () => {
    return (
            <PaginationProvider>
                <TargetCourses />
            </PaginationProvider>
    )
}