import React from 'react';
import FilterProvider from './payouts.context';
import Payout from './payouts';
import PaginationProvider from '../../contexts/PaginationContext';

export default () => {
    return (
        <PaginationProvider>
            <FilterProvider>
                <Payout />
            </FilterProvider>
        </PaginationProvider>

    )
}