import React from 'react';
import PaginationProvider from '../../contexts/PaginationContext';
import TournamentCourses from './tournament-courses';


export default () => {
    return (
        <PaginationProvider>
                <TournamentCourses/>
        </PaginationProvider>
    )
}