import { useEffect, useState } from 'react';
import './taxes-list.scss';
import Pagination from '../../../../components/Pagination';
import { usePagination } from '../../../../contexts/PaginationContext';
import UserStatusActionButton from './taxes-status-action-button';
import TaxDeletePopup from './taxes-req-modal';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { NavLink } from 'react-router-dom';
import TaxesFormPopup from '../../../../components/TaxesFormPopup/taxes-form-popup';
// import { useAsync } from 'react-bootstrap-typeahead';
import { useForm } from 'react-hook-form';


const TaxesList = ({
  createTaxes,
  updateTaxes,
  deleteTax,
  data,
}: any) => {

  const { currentPage, totalItems,itemsPerPage } = usePagination();
  const [showAddEditPopup, setShowAddEditPopup] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const onSubmit = (taxData: any) => {
    if(formData?._id){
      taxData.id=formData._id
      updateTaxes(taxData)
    }else{
      createTaxes(taxData);
    }
    
  }
  useEffect(()=>{
    if(showAddEditPopup===false)
      setFormData({})
  },[showAddEditPopup])

  return (
  <>
  <TaxesFormPopup
    show={showAddEditPopup}
    setShow={setShowAddEditPopup}
    data={formData}
    submitHandler={onSubmit}
  />

  <TaxDeletePopup
        showModal={showDeletePopup}
        setShowModal={setShowDeletePopup}
        actionHandler={deleteTax}
        data={formData}
    />

  
  <div className="userslist" >
      <div className="tableLayout">
        <div className="table-responsive" style={{minHeight:'300px'}} >
          <table className="table mb-0" >
            <thead>
              <tr>
                <th>#</th>
                <th>Country</th>
                <th>Country Code</th>
                <th>Province</th>
                <th>Province Code</th>
                <th>GST</th>
                <th>HST</th>
                <th>PST</th>
                <th><button type="button"
                className="btn btn-primary" onClick={()=>setShowAddEditPopup(true)}>Add Tax</button></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((tax: any, j: number) => {
                return (
                  <>
                    <tr>
                      <td>{(j + 1) + ((currentPage - 1) * itemsPerPage)}</td>
                      {/* <td>
                        <div className="userContent align-items-center">
                          <span className="imgBlock rounded-circle">
                            <img
                              src={user?.club?.photos?.[0]?.path ?? "../../assets/images/default-user.png"}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                          <div className="content">
                            <h5 className="mb-0">{user.golfCourseName ? user.golfCourseName : 'n/a'}</h5>
                          </div>
                        </div>
                      </td> */}
                      
                      <td>{tax?.country}</td>
                      <td>{tax?.countryShortName}</td>
                      <td>{tax?.province}</td>
                      <td>{tax?.provinceShortName}</td>
                      <td>{tax?.gst}%</td>
                      <td>{tax?.hst}%</td>
                      <td>{tax?.pst}%</td>
                      
                      
                      
                      
                      <td>
                        <div className="listActionDropdownBlock">
                          <Dropdown align="end">
                          <div className="text-center">
                            <Dropdown.Toggle className="toggleButton">
                              <em className="fa-solid fa-ellipsis-vertical"></em>
                            </Dropdown.Toggle>
                          </div>
                          <Dropdown.Menu>

                            <a 
                              className="dropdown-item fw600" 
                              onClick={()=>{setFormData(tax);setShowAddEditPopup(true)}}
                             >
                                Edit
                            </a>
                          
                            <a 
                              className="dropdown-item fw600" 
                              onClick={()=>{setFormData(tax);setShowDeletePopup(true)}}
                               >
                                Delete
                            </a>
                            
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
                      </td>
                    </tr>
                    
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="paginationBlock">
          <div className="row gutters-5 align-items-center justify-content-between">
            {totalItems > 10 && <Pagination />}
            {/* <div className="col-auto">
              <h6 className="mb-0">
                Showing <strong>1</strong> to <strong>10</strong> of{" "}
                <strong>100</strong> Results
              </h6>
            </div>
            <div className="col-auto">
              <div className="row gutters-5">
                <div className="col-auto">
                  <button type="button" className="button button-sm fw600 px-3">
                    Previous
                  </button>
                </div>
                <div className="col-auto">
                  <button type="button" className="button button-sm fw600 px-3">
                    Next
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </>
    
  );
};

export default TaxesList;
