import React from "react";
import "./courseOnBoardingDetails.scss";
import { stepWiseDescription } from "../../../utils/constants";
import { usePagination } from "../../../contexts/PaginationContext";
import {capitalizeString} from "../../../utils/common"

interface CourseOnBoardingDetailsProps {
  data: any;
}

function CourseOnBoardingDetails({ data }: CourseOnBoardingDetailsProps) {
  const { courseCreationSteps, integration, type } = data;
  let courseCreationArr: any = [];
  if (courseCreationSteps) {
    courseCreationArr = Object.entries(courseCreationSteps);
    courseCreationArr[courseCreationArr.length] = [`step${courseCreationArr.length+1}`,{status:integration?.status, message:integration?.message}]
  }
  const stepWiseDescriptionArr = Object.entries(stepWiseDescription);
  //const { currentPage, totalItems } = usePagination();
  return (
    <>
      <div className="card onBoardingListWrapper">
        <div className="card-body p-4">
          <h5 className="mb-4">Steps</h5>
          {courseCreationArr?.map(([key, value]: any, j: number) => {
            return (
              <div className="step">
                <div className="_count">
                  <span>{j + 1}</span>
                </div>
                <div className="stepsCard flex-fill">
                  <div className="row gutters-5">
                    <div className="col-12 col-md-6 col-xl-3">
                      <label className="_titleLabel">Step Title</label>
                      <h4 className="fw700">
                        {stepWiseDescription[key]?.title ?? "N/A"}
                      </h4>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3">
                      <label className="_titleLabel">Step Description</label>
                      <h4 className="fw400">
                        {stepWiseDescription[key]?.description ?? "N/A"}
                      </h4>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3">
                      <label className="_titleLabel">Status</label>
                      <label
                        className={`mb-0 status ${
                          value.status === "completed"
                            ? "status--success"
                            : "status--danger"
                        }`}
                      >
                        {value?.status ? capitalizeString(value?.status): 'N/A'}
                      </label>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3">
                      <label className="_titleLabel">Message</label>
                      <h4>{(value?.message)?value.message:'--'}</h4>
                      {(key==="step9" && type==="teeon")?<><br/><h4> Tee On Form: <strong>{value?.teeOnOnBoardingForm?.toUpperCase()}</strong></h4></>:""}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="userslist">
        <div className="tableLayout">
          <div className="table-responsive" style={{ minHeight: "300px" }}>
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Step Count</th>
                  <th>Step Title</th>
                  <th>Step Description</th>
                  <th>Status</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {courseCreationArr?.map(([key, value]: any, j: number) => {
                  return (
                    <>
                      <tr>
                        <td>{j + 1}</td>
                        <td>{key}</td>
                        <td>{stepWiseDescription[key]?.title ?? "-"}</td>
                        <td>{stepWiseDescription[key]?.description ?? "-"}</td>

                        <td>
                          <label
                            className={`mb-0 status ${
                              value.status === "completed"
                                ? "status--success"
                                : "status--danger"
                            }`}
                          >
                            {value.status}
                          </label>
                        </td>
                        <td>-</td>
                      </tr>
                    </>
                  );
                })}
                <tr>
                  <td>{courseCreationArr?.length + 1}</td>
                  <td>step10</td>
                  <td>Final Integration</td>
                  <td>
                    {data.type === "teeon" ? (
                      <>
                        Tee-On Form -{" "}
                        {courseCreationSteps?.step9?.teeOnOnBoardingForm ? (
                          <label
                            className={`mb-0 status ${
                              courseCreationSteps?.step9
                                ?.teeOnOnBoardingForm === "completed"
                                ? "status--success"
                                : "status--danger"
                            }`}
                          >
                            {courseCreationSteps?.step9?.teeOnOnBoardingForm}
                          </label>
                        ) : (
                          "-"
                        )}
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    Integration with golf provider{" "}
                    <strong>"{data?.type?.toString().toUpperCase()}"</strong>
                  </td>

                  <td>
                    <label
                      className={`mb-0 status ${
                        integration.status === "completed"
                          ? "status--success"
                          : "status--danger"
                      }`}
                    >
                      {integration.status}
                    </label>
                  </td>
                  <td>{integration.message}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default CourseOnBoardingDetails;
