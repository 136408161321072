export const BOOKING_PLATFORM_THIRDPARTY = 'Tourism';
export const BOOKING_PLATFORM_DISCOUNTED = 'Discounted';
export const BOOKING_PLATFORM_TOURNAMENT= "Tournament";
export const BOOKING_PLATFORM_PACKAGES='Package Booking'

export enum BookingPlatform{
    BOOKING_PLATFORM_THIRDPARTY = 1,
    BOOKING_PLATFORM_DISCOUNTED = 2,
    BOOKING_PLATFORM_TOURNAMENT=  3,
    BOOKING_PLATFORM_PACKAGE = 4
} 

export const CARD_TYPE_MAPPING:any = {
    "VA":"Visa",
    "AX":"Amex",
    "DS":"Discover",
    "MC":"Master Card",
    "UP":"Union Pay",
    "JCB":"JCB",
    "DC":"Diners Club",
    "UK":"Master Unknown",
    "Card":"Card"
}

export const stepWiseDescription:any = {
  step1:{
      title:"Course Name",
      description:"User has filled course name"
  },
  step2:{
      title:"Course Location",
      description:"User has filled course location"
  },
  step3:{
      title:"Course Pictures",
      description:"User has uploaded course pictures"
  },
  step4:{
      title:"Course Description",
      description:"User has added course description"
  },
  step5:{
      title:"Course Details",
      description:"User has added course details - Holes, par, slope and length"
  },
  step6:{
      title:"Course Amenities",
      description:"User has added course policies, rentals/services, practice/instruction"
  },
  step7:{
      title:"Course Booking Times",
      description:"User has added course tee times and duration for advanced booking"
  },
  step8:{
      title:"Course Discounts",
      description:"User has added course discounts"
  },
  step9:{
      title:"Course Integration Selection",
      description:"User has added course thirdparty integration"
  },
  step10:{
    title:"Final Integration",
    description:"Integration with third party golf provider"
},
}

export interface IStatusObj {
  label: string;
  color: string;
  fontColor: string;
}

export const getStatusObj = (status: string): IStatusObj => {
    switch (status) {
      case 'success':
        return {
          label: 'success',
          color: '#18C23320',
          fontColor: '#18C233'
        }
      case 'courseConfirmationPending':
        return {
          label: 'success',
          color: '#18C23320',
          fontColor: '#18C233'
        }
  
      case 'cancelled':
        return {
          label: 'cancelled',
          color: '#E73F3F20',
          fontColor: '#E73F3F'
        }
      case 'completed':
        return {
          label: 'completed',
          color: '#18C23320',
          fontColor: '#18C233'
        }
      case 'refunded':
        return {
          label: 'refunded',
          color: '#E73F3F20',
          fontColor: '#E73F3F'
        }
      case 'failed':
        return {
          label: 'failed',
          color: '#E73F3F20',
          fontColor: '#E73F3F'
        }
      case 'pending':
        return {
          label: 'pending',
          color: '#fff4e4',
          fontColor: '#ff8f28'
        }
      default:
        return {
          label: 'refunded',
          color: '#E73F3F20',
          fontColor: '#E73F3F'
        }
    }
  }
  export interface MerchantFiles {
    mimeType ?: string;
    path ?: string;
    fileName ?: string;
    size ?: number;
    _id ?: string;
  }

const MAX_FILE_SIZE_MB = 5;

// Function to check if file size is within the limit
export const isImageSizeValid = (file: any): boolean => {
  const fileSizeInBytes = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes
  return file?.size <= fileSizeInBytes;
};

// Function to check if file type is valid
export const isValidImage = (file: any): boolean => {
  console.log(file,'file>>>>>>>>>>>>>>>>')
  const validExtensions = ['png', 'jpeg', 'jpg'];
  const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase() || '';
  console.log(fileExtension,'fileExtension>>>>>>>>>>>>>')
  return validExtensions.includes(fileExtension);
};

export const INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_TRANSFER = 'BANK_TRANSFER';
export const INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_INTERACT_ID = 'INTERACT_ID';