import { useLocation } from 'react-router-dom';
import { DateTime } from "luxon";
import TeeTimeCard from './TeeTimeCard';

import './itinerary-order.scss'


const getCanadianTime = (date: number, format: string) => {
  return DateTime.fromMillis(Number(date)).setZone("Atlantic/Bermuda").toFormat(format)
}


/* eslint-disable @typescript-eslint/no-explicit-any */
const GolfOrder = ({ deleteItinerary, data, index, day }: any) => {
  const params = useLocation();
  const queryParams = new URLSearchParams(params.search);
  let players = Number(queryParams.get('golfers'));
  
  if (!players) {
    players = data?.teesheets.reduce((acc: number, item: any) => {
      return acc + item.players;
    }, 0);
  }

  const calculatePrice = () : string => {
    let price = 0;
    for (const tee of data.teesheets) {
      price += (tee?.thirdPartyMerchantPrice * tee?.players);
    }
    return price.toFixed(2);
  }

  return (
    <div className="timeBlock">
      <label className="timeBlockTime">
        {getCanadianTime(Number(data?.teesheets?.[0]?.dateTime), 'h:mm a')}
      </label>
      <div className="mt-2 order-title-container">
        {/* <div /> */}
        <h4 className="order-title">{data?.teesheets?.[0]?.club?.name}
        {/* {(deleteItinerary || 1) && (
          <button
            type="button"
            className="remove"
            onClick={() => deleteItinery(day, index)}
          >
            X Remove
          </button>
        )} */}
        
        
        </h4>
        {
          (deleteItinerary) &&
            <button className='_t__title order-remove-button' onClick={() => deleteItinerary(day, index)}>
              remove
            </button>
        }
        
      </div>
      <p>{data?.teesheets?.[0]?.clubId?.address}</p>

      <div className="row gutters-5 mb-3">
        <div className="col-12">
          <h6 className="fontsize-14 fw600 color-600 mb-3">Tee Times</h6>
        </div>
        {data?.teesheets?.map(({players, thirdPartyMerchantPrice, dateTime, isRevised}: any, index: number) => (
          <div key={index} className="col-auto mb-2">
            <TeeTimeCard isRevised={isRevised} time={getCanadianTime(Number(dateTime), 'h:mm a')} price={`${thirdPartyMerchantPrice?.toFixed(2)} × ${players}`} />
          </div>
        ))}
      </div>

      <div className="row timeBlockItemFooter">
        <div className="col-12 col-md">
          <div className="row">
            <div className="col-auto">
              <label className="_label">
                Total Golfers:{' '}
                <strong>
                  {players}
                </strong>
              </label>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-auto">
          <label className="_price">
            Price:{' '}
            <strong>
              ${calculatePrice()}
            </strong>
          </label>
        </div>
      </div>
    </div>
  );
};

export default GolfOrder;
