import { useNavigate, NavLink } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import OtpInput from 'react-otp-input';
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
const login = (payload: any) => {
  return axios.post(`${process.env.REACT_APP_USER_API_BASEURL}/users`, payload);
}

export const useLogin = () => {
  return useMutation(login)
}

const verifyOtp = (payload: any) => {
  return axios.post(`${process.env.REACT_APP_USER_API_BASEURL}/users/verifyOtp`, payload);
}
const useVerifyOtp = () => {
  return useMutation(verifyOtp)
}
function padLeadingZeros(num: any, size: any) {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

const VerifyOtp = ({ email, setShowVerifyOtp, resendOtp }: {
  email: string;
  setShowVerifyOtp: React.Dispatch<React.SetStateAction<boolean>>;
  resendOtp: any;
}) => {
  const [otp, setOtp] = useState('');
  const [showTimer, setShowTimer] = useState(true);
  const navigate = useNavigate();
  const { mutate: verify, data: verificationData, error } = useVerifyOtp();
  const accessToken = verificationData?.headers?.accesstoken!;
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    const userRole = localStorage.getItem("role");
      if (userRole === "admin") {
        navigate("/dashboard", {
          state:
          {
            email: verificationData?.data?.payload?.email,
            role: verificationData?.data?.payload?.role,
          }
        });
      } else {
        navigate("/targetcourses", {
          state: {
            email: verificationData?.data?.payload?.email,
            role: verificationData?.data?.payload?.role,
          }
        });
      }
  }

  const [time, setTime] = useState(180);
  const [minutes, setMinutes] = useState(Math.floor(time / 60));
  const [seconds, setSeconds] = useState(time % 60);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(time - 1);
      setMinutes(Math.floor(time / 60));
      setSeconds(time % 60);
    }, 1000);
    if (time < 0) {
      setShowTimer(false);
    }

    return () => clearInterval(interval);
  }, [time, minutes, seconds]);

  useEffect(() => {
    if (error) {
      toast.error('Invalid OTP')
    }
  }, [error]);

  const onAddOtp = (otp: any) => {
    setOtp(otp);
    if (otp && otp.length === 6) {
      verify({
        "email": email,
        "otp": Number(otp),
        "role": localStorage.getItem("role")
      })
    }
  }

  return (
    <>
      <section className="authWrapper">
        <div className="authMain flex-fill d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="authWrap">
                  <div className="authBackButton">
                    <NavLink
                      onClick={(e) => {
                        e.preventDefault();
                        setShowVerifyOtp(false);
                      }}
                      to={"/"}
                      className="fa-solid fa-arrow-left"
                    >
                    </NavLink>
                  </div>
                  <div className="text-center">
                    <h1>Confirm your OTP</h1>
                    <h6 className="color-gray">
                      Enter the code we've sent via email
                    </h6>
                  </div>
                  <div className="formWrap">
                    <OtpInput
                      value={otp}
                      onChange={onAddOtp}
                      numInputs={6}
                      containerStyle={"optInputWrap"}
                      inputStyle={"optInput"}
                      // focusStyle={"optInputFocus"}
                      renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                    <div className="formWrap d-flex justify-content-between align-items-center pt-2 mb-4">
                      {showTimer && <p className="mb-0 fw600">Time remaining {padLeadingZeros(minutes, 2)} : {padLeadingZeros(seconds, 2)} </p>}
                      {!showTimer && <p className="mb-0 fw600">Time remaining 00:00 </p>}
                      <button
                        type="button"
                        style={{ borderWidth: 0, borderRadius: 5 }}
                        onClick={() => {
                          resendOtp({
                            "email": localStorage.getItem('email'),
                            "role": localStorage.getItem('role')
                          });
                          toast.success('OTP send successfully')
                          setTime(180);
                          setMinutes((Math.floor(time / 60)));
                          setSeconds(time % 60)
                        }}
                        className="resendLink fw600 color-grey">
                        Resend
                      </button>
                    </div>
                    {/* <div className="mb-4 pt-2">
                      <button
                        onClick={() => {
                          verify({
                            "email": email,
                            "otp": Number(otp),
                            "role": "admin"
                          })
                        }} type="submit" className="button button-primary button-rounded button-block fw700">Verify Otp</button>
                    </div> */}
                  </div>
                  {/* {error.message && <p>{error.message}</p>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toaster position='bottom-center' />
    </>
  );
  // return (
  //   <>
  //     <div className="authWrap">
  //       <div className="text-center">
  //         <h1>Confirm your {props.isPhoneInput ? "number" : "email"}</h1>
  //         <h6 className="color-gray">
  //           Enter the code we've sent via {props.isPhoneInput ? "SMS" : "email"} to {props.isPhoneInput ? `${props.countryCode}${props.inputData}`: props.inputData}
  //         </h6>
  //       </div>
  //       <div className="formSection formSectionVerify">
  //         <div className="formWrap pb-0">
  //           <form>
  //             <div className="mb-4">
  //                 <OtpInput
  //                   value={OTP}
  //                   shouldAutoFocus={true}
  //                   numInputs={6}
  //                   containerStyle={"optInputWrap"}
  //                   inputStyle={"optInput"}
  //                   focusStyle={"optInputFocus"}
  //                   isInputNum={true}
  //                   placeholder=""
  //                   onChange={setOTP}
  //                 />
  //               {showRequiredError && <div className="isInvalidMessage text-danger">Please enter code</div>}
  //             </div>
  //             <div className="d-flex justify-content-between align-items-center mb-4">
  //               {showTimer && <p className="mb-0 fw600">Time remaining {padLeadingZeros(minutes, 2)} : {padLeadingZeros(seconds, 2)} </p>}
  //               {!showTimer && <p className="mb-0 fw600">Time remaining 00:00 </p>}
  //               <button
  //                 type="button"
  //                 onClick={() => resend()}
  //                 className="resendLink fw600 color-gray">
  //                 Resend
  //               </button>
  //             </div>
  //             <div className="mb-4 pt-2">
  //               <button
  //                 type="button"
  //                 className="button button-primary button-block button-rounded button-large fw700 fontsize-16"
  //                 onClick={() => onSubmit()}
  //               >
  //                 Confirm
  //               </button>
  //             </div>
  //           </form>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

export default VerifyOtp;
