import "./view-comment-modal.scss";
import React, { FC, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

interface ViewCommentModalProps {
    showModal: boolean;
    content:any;
    handleClose: (arg: any) => void;
}
const ViewCommentModal: FC<ViewCommentModalProps> = ({
    showModal,
    handleClose,
    content
}) => {
    const close = () => {
        handleClose(!showModal);
    };
    return (
        <Modal
            className="cancelstatusmodal modalLayoutWrapper"
            show={showModal}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Body>
                <button
                    type="button"
                    className="closeButton d-flex align-items-center justify-content-center"
                    onClick={() => {
                        close();
                    }}
                >
                    <i className="fa-solid fa-xmark" style={{ height: '16px', width: '16px' }}></i>
                </button>
                <div className="formWrap">
                    <div className="text-center">
                        <p className="mb-2 pb-2 modal-text-comment">
                          {content}
                        </p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default ViewCommentModal;